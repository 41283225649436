<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FEATURES</h6>
                    </div>
                   
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p>The <b>WSFX Smart Currency Card</b>(WSCC) helps you travel smart on your trip abroad. It is accepted globally at over <b>100 million+</b> VISA merchant outlets. This prepaid multicurrency forex card is app-enabled, and comes with 14 key international currencies, namely <b>USD, GBP, CAD, SGD, AUD, NZD, HKD, THB, JPY, AED, SAR and ZAR.</b></p>
                    <p>The card also comes along with an INR Wallet, which can be utilized to transfer your leftover currency at the end of your trip, thus enabling you to spend effortlessly on domestic purchases.</p>
                    
                </div>

                
               
              
                <div class="row mt-6">  
                    <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of menuItems"> 
                        <div class="d-flex align-items-center feature-section">
                            <img src="assets/images/feature/{{singleItem.icon}}" style="width: 60px; height: 60px;">
                            <div class="fs-14 fw-600">
                                {{ singleItem.title }}
                            </div>
                        </div>
                    </div>
                </div>
            
            
                <div class="col-md-6 text-left ">
                    <button  class="btn btn-feature-dark" (click)="goToBackPage()">
                        Back
                    </button>
                    </div>
            </div>
        </div>
    </div>
</div>