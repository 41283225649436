import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeneficiaryCreateRequest, OrderItemModel, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationCurrencyResponsesModel, QuotationRequestModel, QuotationResponseModel } from 'app/modules/card/models/card.model';
import { CardService } from 'app/modules/card/services/card.service';
import { OrderService } from 'app/modules/order/services/order.service';
import { RatesService } from 'app/modules/rates/services/rates.service';
import { DobFormat, UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';

@Component({
  selector: 'sell-currency-notes',
  templateUrl: './sell-currency-notes.component.html',
  styleUrls: ['./sell-currency-notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SellCurrencyNotesComponent implements OnInit {
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public currencyItems: any;
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public showLoader: boolean = false;
  public bankAccountItems = [];
  public ratesAll: any;

  public selectedBankId: any;
  public branchAddressList: any;
  public orderResponse: any;

  public selectedBankDetails: any;
  public usdRate: any;
  public showBankDetails: boolean = false;
  public showEligibilityCheck: boolean = false;
  public cryptoUtils: CryptoUtils = new CryptoUtils();

  public isPANCBChecked: boolean = false;
  public currencyAmountCash = 0;
  public isCartItemsAdded: boolean = false;
  public currencySelectedCash: any;
  public showAddBankForm: boolean = false;
  public dob;
  public ngbDatepicker: NgbDateStruct;
  public dobFormat = new DobFormat();
  public defaultDob;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();


  public isIndianResident: boolean = false;
  public orderRequestModel = new OrderRequestModel();
  orderItems: OrderItemModel[] = new Array<OrderItemModel>();
  beneficiaryDetails = new BeneficiaryCreateRequest();


  public branchName: any;
  ssoResponseData: any;
  public clockDisplay: string;
  public nameAsPerPAN: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public orderRequestList = new Array<OrderRequestModel>();
  public duration: any;
  public seconds = "00";
  public minutes = "00";
  public brnachId: string;
  public clockDisplayPer = 0;
  public orderCartType = 'CARD_PURCHASE';
  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  // public quotationResponse = new QuotationResponseModel();
  public quotationResponse: any;
  public vcipStatusDetails: any;
  currencyAmount: any;
  currencySelected: any;
  public updateProfileModel = new UpdateProfileModel;
  public modalReference: any;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('NotAnIndianResidentPopup') NotAnIndianResidentPopup: TemplateRef<any>;
  @ViewChild('EditCardModal') EditCardModal: TemplateRef<any>;
  @ViewChild('EligibilityModal') EligibilityModal: TemplateRef<any>; 
  @ViewChild('errorModal') errorModal: TemplateRef<any>; 
  public consumerDetail: any;
  paxId: any;
  public isVcipComplete: boolean = false;
  public showBankAccounts: boolean = false;
  public isPANAvailable: boolean = false;
  public uploadedSignature: File;

  rateRange: any;
  public rateList: any;
  currencySelectedCashEdit: any;
  currencyAmountCashEdit: any;
  showCheckedMarkForSignature: boolean=false;
  dobMaxDate: any;
  panVerificationErrMsg: string;


  constructor(
    public masterDataService: MasterDataService,
    public toast: ToastrService,
    public router: Router,
    public modalService: NgbModal,
    public cardService: CardService,
    public updateProfileService: UpdateProfileService,
    private orderService: OrderService,
    public rateService: RatesService,
    public gtmPushService: GTMDataLayerService
  ) {
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }

  }

  ngOnInit() {

    this.getSessionData();
    this.getBankAccounts();
    this.getCurrencyRate();
    this.getCurrencies();
    this.getProfileDetails();
    this.getNearByBranchLocation();
    this.timer();
  }

  getSessionData() {

    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      this.ssoResponseData = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.currencyAmount = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.currencySelected = this.ssoResponseData['requiredCurrencyCode'];
      console.log('SSO resp');
      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.currencyAmount = this.currencyAmount;
      quotationCurrencies.currencyCode = this.currencySelected;
      quotationCurrencies.product = Constants.productsEnum[1];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      this.callQuotation();
    }
  }

  addCash() {
    if(this.currencySelectedCash) {
      if(this.currencyAmountCash && this.currencyAmountCash > 0) {
      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.currencyAmount = this.currencyAmountCash;
      quotationCurrencies.currencyCode = this.currencySelectedCash;
      quotationCurrencies.product = Constants.productsEnum[1];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      this.callQuotation();
      this.currencyAmountCash = 0;
      this.currencySelectedCash = 'USD';
    } else {
      this.toast.error('Please enter amount');
    }
  } else {
    this.toast.error('Please select currency');    
  }
  }

  editCash() {
    if (this.currencySelectedCashEdit && this.currencyAmountCashEdit) {
      this.modalReference.close();
      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.currencyAmount = this.currencyAmountCashEdit;
      quotationCurrencies.currencyCode = this.currencySelectedCashEdit;
      quotationCurrencies.product = Constants.productsEnum[1];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      this.callQuotation();
      this.currencyAmountCashEdit = 0;
      this.currencySelectedCashEdit = 'USD';
    } else {
      this.toast.error('Please enter the cash amount/currency.')
    }
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  
  callQuotation() {

    this.showLoader = true;
    console.log("this.cartCurrenciesList >> ", this.cartCurrenciesList);
    this.cartCurrenciesList.filter((singleItem, index) => {
      if (!singleItem.currencyAmount || singleItem.currencyAmount < 1) {
        this.cartCurrenciesList.splice(index, 1);
      }
    });

    this.quotationRequest.sellType = Constants.sellTypes[1];
    this.quotationRequest.partnerId = Constants.partnerId;
    this.quotationRequest.nostroChargeType = 'REMITTER';
    this.quotationRequest.quotationCurrencies = this.cartCurrenciesList;
    if (localStorage.getItem(ConstantsKey.CONSUMER_PAN) != null) {
      this.quotationRequest.pan = localStorage.getItem(ConstantsKey.CONSUMER_PAN);
    }
    this.quotationRequest.tcsPaymentSource = 'OWN';

    this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe(response => {
      // this.showLoader = false;
      // this.currencyAmount = null;

      // this.currencySelected = null;
      // this.quotationResponse = JSON.parse(JSON.stringify(response.body));
      // this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
      // this.isCartItemsAdded = true;
      // localStorage.setItem('quotationCurrencyResponsesList', JSON.stringify(this.quotationCurrencyResponsesList));

      
      // let message = "";

      // this.duration = this.defaultTimerLimitInSec;
      // clearInterval(this.interval);
      // this.startTimer();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
            this.currencyAmount = null;

            this.currencySelected = null;
            this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
            this.isCartItemsAdded = true;
            localStorage.setItem('quotationCurrencyResponsesList', JSON.stringify(this.quotationCurrencyResponsesList));

              // Save local storage for further recall
              //localStorage.setItem('CARD-QUOTATION-REQ', JSON.stringify(this.quotationRequest));

              let message = "";

              this.duration = this.defaultTimerLimitInSec;
              clearInterval(this.interval);
              this.startTimer();
        
      } else {
      
        this.cartCurrenciesList.forEach((singleItem) => {
          this.cartCurrenciesList.splice(this.cartCurrenciesList.findIndex(item => item.product === "CASH"), 1);
        });

        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        Swal.fire("Dear Customer",error.error.message, "info");
      }
    });
  }
  removeCartItem(item) {
    this.cartCurrenciesList = JSON.parse(localStorage.getItem('quotationCurrencyResponsesList'));
    this.cartCurrenciesList.filter((singleItem, index) => {
      if (item.product === singleItem.product && singleItem.currencyCode === item.currencyCode) {
        this.cartCurrenciesList.splice(index, 1);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        if (this.cartCurrenciesList.length > 0) {
          this.callQuotation();
        } else {
          this.quotationResponse = new QuotationResponseModel();
          this.quotationCurrencyResponsesList = [];
          this.isCartItemsAdded = false;

        }
      }
    });
  }
  openModalForEditCart() {
    // this.modalReference.close();
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
    this.getCurrencies();
  }
  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          this.callQuotation();
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }
  openSnackBar(message) {
    // this._snackBar.open(message, '', {
    //   duration: 5000,
    //   panelClass: ['green-snackbar'],
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
  }


  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccounts();
    }
  }

  getCurrencies() {
    this.reqGetCurrencyMaster.cartType = "CASH_PURCHASE"
   // this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[1];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      this.removeCurrency('NOK'); 
      this.removeCurrency('DKK');
      this.removeCurrency('INR'); 
    });
  }
  removeCurrency(doc){
   this.currencyItems.forEach( (item, index) => {
     if(item['currencyCode'] === doc) this.currencyItems.splice(index,1);
   });
}

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }

  getNearByBranchLocation() {
    this.cardService.getNearByBranch(0.0, 0.0).subscribe(respBranchList => {
      this.branchAddressList = JSON.parse(JSON.stringify(respBranchList.body));
    });
  }

  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    this.paxId = this.consumerDetail.paxResponse.id;
    this.isVcipComplete = this.consumerDetail?.vcipKyc;
    if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
      this.isVcipComplete = true;
    } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
      this.isVcipComplete = true;
    }
    if (!this.isVcipComplete) {
      this.getVCIPStatus();
    }
    if(this.consumerDetail?.paxResponse!=null && this.consumerDetail?.paxResponse?.pan){
      this.isPANAvailable=true;
    }
    console.log("this.consumerDetail >>", this.consumerDetail);

    return true;
  }
  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.vcipStatusDetails = resp.body;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.showLoader = false;
    });
  }

  uploadSignature(event, docType) {
    this.showLoader = true;
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    console.log("consumerId++", consumerId);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log("File >>>");
      console.log(file);
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('photo', file, file.name);
        formData.append('type', docType);
        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.uploadedSignature = fileList[0];
          this.updateProfileService.uploadUserPhoto(formData, false).subscribe((respDoc) => {
            this.showLoader = false;
            this.showCheckedMarkForSignature = true;
            this.toast.success('Signature is uploaded successfully!');
            // this.getProfileList();
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    }
  }
  downloadUploadedSignature(){ 
    this.viewLocalFileUtils.viewLocalDocument(this.uploadedSignature);
  }

  openModalForPANNo() {
    this.modalReference.close();
    if (this.isVcipComplete || this.isPANAvailable) {
      this.showBankAccounts = true;
      this.showEligibilityCheck = false;
      this.isIndianResident = true;
    } else {
      this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
    }
  }

  validatePanAPI(){
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.updateProfileService.validatePAN(this.updateProfileModel.pan,this.updateProfileModel.name,dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if(panResponse.panStatus=='E' && panResponse.fullName!="" && panResponse.dob!=""){
        this.updatePAN();
      }else if(panResponse.panStatus!='E'){
        this.panVerificationErrMsg="Please enter valid PAN";
        this.openErrorModal();
      }else if(panResponse.fullName==""){
        this.panVerificationErrMsg="Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if(panResponse.dob==""){
        this.panVerificationErrMsg="Please enter valid Date of Birth";
        this.openErrorModal();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    }); 
  }
  
  getPanName() {
    if(this.validatePANDetails()){
    this.showLoader = true;
    this.updateProfileService.getPANName(this.updateProfileModel.pan).subscribe(async resp => {
      this.showLoader = false;
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.nameAsPerPAN = panResponse.fullName;
      if(this.updateProfileModel.name.toUpperCase() == this.nameAsPerPAN){
        this.updatePAN();
      }else{
        this.openErrorModal();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  }
  validatePANDetails(){
    if(!(this.updateProfileModel.pan!=null )){
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!(this.updateProfileModel.name!=null )){
      this.toast.error('Please enter name as per PAN');
      return false;
    } 
    if(!this.isPANCBChecked){
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if(!(this.updateProfileModel.dob!=null )){
      this.toast.error('Please select your Date of Birth ');
      return false;
    }
    
    
      return true;
  
  }

  updatePAN() {
    this.showLoader = true;
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
    let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob=dateofBirth;
    console.log("updateProfile>>"+ JSON.stringify(this.updateProfileModel));
    this.updateProfileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      console.log(resp.body);
      this.isVcipComplete = true;
      this.modalReference.close();
      this.showBankAccounts = true;
      this.showEligibilityCheck = false;
      this.isIndianResident = true;

      //this.proceedToViewCart();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        this.isVcipComplete = true;
        this.showBankAccounts = true;
        this.showEligibilityCheck = false;
        this.modalReference.close();
        this.isIndianResident = true;
        //this.proceedToViewCart();
      } else {
        this.toast.error('Something went wrong ! Please try again !');
        this.modalReference.close();

      }
    });
  }

  openNotAnIndianResidentPopup() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.NotAnIndianResidentPopup, { centered: true, size: 'sm' });
  }

  goToEligibilityCheck() {
    if (this.checkAmountLimit()) {
      if (this.isIndianResident) {
        if (this.brnachId != null && this.selectedBankDetails != null) {
          this.placeOrder();
        } else if (!(this.brnachId != null)) {
          this.toast.error('Please Select Nearest WSFx Branch');
        } else if (!(this.selectedBankDetails != null)) {
          this.toast.error('Please Select Bank Account');
        }
      } else {
        //this.showEligibilityCheck = true;
        this.openEligibiltyModal();

      }
    } else {



    }
    //this.router.navigate(['/eligibility-check']);
  }
  openEligibiltyModal(){
    this.modalReference = this.modalService.open(this.EligibilityModal, { centered: true, size: 'sm' });

  }
  openErrorModal(){
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  checkAmountLimit() {
    if (this.quotationResponse?.billableAmount / this.usdRate < 10000) {
      return true;
    } else {
      this.toast.error('Maximum Order Value should be 10,000 USD equivalent');

    }
  }
  getCurrencyRate() {
    this.rateService.getProductWiseRatesList('CASH').subscribe(res => {
      this.ratesAll = res.body;
      var cashRates = this.ratesAll.filter((products) => products.product == 'CASH');
      this.rateList = [];
      this.rateList = cashRates[0].rates;
      var rateObject = this.rateList.filter((currency) => currency.currencyCode == 'USD');
      this.usdRate = rateObject[0].rateBuy;
      console.log(this.usdRate);
    }, error => {
    })
  }

  placeOrder() {
    this.showLoader = true;

    for (var i = 0; i < this.quotationCurrencyResponsesList.length; i++) {
      var orderItemModel = new OrderItemModel();
      orderItemModel.currencyCode = this.quotationCurrencyResponsesList[i].currencyCode;
      orderItemModel.currencyAmount = this.quotationCurrencyResponsesList[i].currencyAmount;
      orderItemModel.product = 'CASH';
      orderItemModel.purpose = 'PRIVATE_VISIT';
      this.orderRequestModel.orderItems.push(orderItemModel);
    }
    this.orderRequestModel.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.orderRequestModel.sellType = Constants.sellTypes[1];
    this.orderRequestModel.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    this.orderRequestModel.kitId = null;
    this.orderRequestModel.kycMode = 'AUTO';
    this.orderRequestModel.bankId = this.selectedBankDetails.id;
    this.orderRequestModel.wsflReferenceBranchId = this.brnachId;
    this.orderRequestModel.orderDocumentMappingRequest = null;
    this.orderRequestModel.payerType = 'SELF';
    this.orderRequestModel.beneficiaryDetails = this.beneficiaryDetails;

    this.orderRequestList.push(this.orderRequestModel);

    this.cardService.createOrder(this.orderRequestList).subscribe((respCOrder) => {
      this.showLoader = false;
      

    }, (error) => {
      this.showLoader = false;
      if(error.status==200){
        this.quotationRequest = new QuotationRequestModel();
        var orderId;
        this.orderResponse= JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));

        let eventObj = {
          'name': 'web_sell_currency_order',
          'event': 'web_sell_currency_order',
          'number': this.consumerDetail.mobile,
          'amount': this.orderRequestModel.orderItems[0].currencyAmount,
          'currency': this.orderRequestModel.orderItems[0].currencyCode
        };
  
        this.gamoogaEvent(eventObj);

        //this.orderResponse = JSON.parse(JSON.stringify(respCOrder.body));
        orderId = this.orderResponse[0].orderId;
        console.log("orderId>>>" + orderId);
        this.router.navigate(['/currency-notes-order-success', orderId]);
      }else{
        this.toast.error(error.error.message);

      }


    });
  }

  getBankAccounts() {
    this.cardService.getBankAccounts().subscribe((respData) => {
      this.bankAccountItems = [];
      var bankAccountItemsLocal = JSON.parse(JSON.stringify(respData.body));
      bankAccountItemsLocal.forEach(singleBankAccount => {
        singleBankAccount.showTitle = singleBankAccount.bankName + "-" + singleBankAccount.number + " | IFSC-" + singleBankAccount.ifscCode + " | " + singleBankAccount.branch
        this.bankAccountItems.push(singleBankAccount);
      });
    });
  }

  setSelectedBank(selectedBankId) {
    var bank: any;
    this.bankAccountItems.forEach(singleBankAccount => {
      if (singleBankAccount.id == selectedBankId.id) {
        bank = singleBankAccount;
      }
    });

    this.selectedBankDetails = bank;
    console.log("bank>>" + JSON.stringify(this.selectedBankDetails));
    this.showBankDetails = true;

  }
  changeWSFxBranch(branchName) {
    this.branchAddressList.forEach(singlebranchAddress => {
      if (singlebranchAddress.branchName == branchName.branchName) {
        this.brnachId = branchName.branchId;
        sessionStorage.setItem("cashSellBranchId", branchName.branchName);
        console.log("event>>" + this.brnachId);

      }
    });

  }
  gotoDashboard() {
    // this.router.navigate(['/currency-notes-landing'])
    //   .then(() => {
    //     window.location.reload();
    //   });
    this.router.navigate(['/currency-notes-landing']);
  }

  numberOnly(e) {
    var charCode = e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }  

  gamoogaEvent(obj) {
    this.gtmPushService.dataLayerPushData(obj);
  }          

}
