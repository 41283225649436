import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { OfferRewardsService } from '../../services/offer-rewards.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'refer-and-earn',
  templateUrl: './refer-and-earn.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
            './refer-and-earn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReferAndEarnNewComponent implements OnInit {
  referralCode: string;
  referralList = new Array<any[]>();
  public modalReference: any;
  public message: string = "";
  
  constructor(
    public router: Router,
    public offerRewardsService: OfferRewardsService,
    public toast : ToastrService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.getConsumerReferralDetails();
  }


  getConsumerReferralDetails() {
    this.offerRewardsService.getConsumerReferral().subscribe(
      result => {
        var res = result.body;
        this.referralCode = res['code'];
        this.message = res['referralMessage'];
      },
      error => {
      });
  }

  copyMessage(val: string, copyType) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if(copyType == "CODE") {
      this.toast.success("Referral Code Copied ");
    } else if(copyType == "MSG") {
      this.toast.success("Your's referral message has been copied!");
    }
  }

  openModalForTermsAndCondition(TCModal) {
    this.modalReference = this.modalService.open(TCModal, { centered: true, size: 'lg' });
  }

  closeTCModal() {
    this.modalReference.close();
  }

  goToLink(urlType: string){

    if(urlType == "whatsapp") {  
      window.open('https://wa.me/?text='+ this.message, "_blank");
    } else if(urlType == "mail") {
      window.open('mailto:?body='+ this.message + '&subject=Refer and Earn', "_blank");
    } else if(urlType == "insta") {
      this.copyMessage(this.message, "MSG");

      window.open('https://www.instagram.com/direct/new', "_blank");
    } else if(urlType == "facebook") {
      this.copyMessage(this.message, "MSG");
      this.toast.success("Your's referral message has been copied!");
      window.open("https://www.facebook.com/dialog/share?app_id=415353616422371&display=popup&hashtag=sslk", "_blank");
    }
  }

}
