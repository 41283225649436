<style>
    .input-group-text {
        padding-right: 25px !important;
    }
</style>
<loader *ngIf="showLoader"></loader>
<loader *ngIf="showLoaderForCardDetails"></loader>

<div class="card mt-30" *ngIf="!showLoaderForCardDetails">
    <div class="card-body">
        <div class="row">
            <div class="col-md-7">
                <div class="fw-600 fs-18">Buy Forex</div>
                <div class="fs-12">Here are some recommended actions for you </div>

                <div class="col-md-9 mt-20">
                    <!-- <section>
                        <div class="row card-border" (click)="showBuyForex()" [ngClass]="activeBuyForexCard ? 'blue-card-selected' : 'dash-card'" (click)="checkVCIPComplete()">
                            <div class="col-md-4 col-sm-5 col-xs-5">
                                <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" class="card-img" >
                            </div>
                            <div class="col-md-8 col-sm-7 col-xs-7 mt-20">
                                <div class="fs-18 fw-600">Buy New Card</div>
                                <div class="fs-11 mt-5">Buy the WSFx Smart Currency Card and leave your money management worries at home.</div>
                            </div>
                        </div>
                    </section> -->
                    <section>
                        <div class="row card-border" (click)="showBuyForex()" [ngClass]="activeBuyForexCard ? 'blue-card-selected' : 'dash-card'" (click)="checkVCIPComplete()"  (click)="onMobileClick($event)">
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" class="card-img" >
                            </div>
                            <div class="col-md-8 col-sm-8 col-xs-8 mt-20">
                                <div class="fs-18 fw-600">Buy New Card</div>
                                <div class="fs-11 mt-5">Buy the WSFx Smart Currency Card and leave your money management worries at home.</div>
                            </div>
                        </div>
                    </section>
                    <section >
                        <div class="row  card-border dash-card" routerLink="/link-existing-card">
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <img src="assets/images/smartfx-portal-assets/buy-forex/link-card.png" class="link-card-img">
                            </div>
                            <div class="col-md-8 col-sm-8 col-xs-8 mt-20">
                                <div class="fs-18 fw-600">Link Existing Card</div>
                                <div class="fs-11 mt-5">Link your WSFx Smart Currency Card and manage it from anywhere in the world.</div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-md-1 dashed-border"></div>
            <div class="col-md-4 d-none d-md-block">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills" style="margin-bottom: 0px !important; ">
                    <li class="nav-item w-100" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#buyForex" data-toggle="tab">Buy Forex</a>
                        <ng-template ngbNavContent>
                            <section id="buyForex">
                                <div class="row">
                                    <div class="col-md-12">
                                        <select placeholder="Select" style="height: 36.54px;" class="form-control card-shadow fs-12" readonly>
                                        <option selected> Forex Card </option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencySelected" (change)='getCurrencyFields()' [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <input type="number" class="form-control" placeholder="Amount" (keypress)="keyPressNumbers($event)"[(ngModel)]="currencyAmount" name="currencyAmount" 
                                                        (keyup)="convertAmount()" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 ">
                                                <div class="input-group  mt-20">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag"  style="height:15px;width: 15px;">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" [value]="rateCalculatorResponse?.inrAmount" readonly />
                                                </div>
                                                <small class="form-text blue-text fs-9 text-right fw-600" *ngIf="rateCalculatorResponse?.rate !=null"> 
                                                    <!-- 1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}} -->
                                                    1 {{ currencySelected }} = {{
                                                        rateCalculatorResponse?.rate }} INR

                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-10">
                                        <span class="fs-18 fw-600">You Pay -  <span class="fs-19 fw-600"> ₹ {{rateCalculatorResponse?.inrAmountWithCharges}}
                                        </span></span>

                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100" (click)="proceedToBuySelectedCard()">
                                            Book Now
                                        </button>
                                    </div>

                                </div>
                            </section>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="card p-20"></div>
            </div>
        </div>
    </div>
</div>

<ng-template let-modal #vcipModalNewUser>
    <!-- <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Dear Customer, Your <span class="fw-600 text-black">Video Based Identification Process (VCIP)</span> is pending.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row button-container">
            <button class="col-md-4 offset-md-1  btn  fs-10 vcip-btn  " rippleEffect (click)="VCIPNow()">
                I will complete my VCIP Now
            </button>
            <!-- <div class="col-md-1"></div> -->
            <button class="col-md-4  offset-md-1 btn btn-primary fs-10" rippleEffect (click)="proceedWithourVCIP()">
                I will complete my VCIP Later
            </button>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipCompleteOrNotModal>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="proceedWithourVCIP()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="fw-600 text-black mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p>Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p>Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="fw-600 text-black mb-20">{{vkycTimingText}}</p>

                <p class="blue-text mb-20 fw-600">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <!-- <button class="btn vcip-btn fs-13" (click)="proceedWithourVCIP()"> Schedule your VKYC later</button> &nbsp; &nbsp; -->
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-modal #PANDetailModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Costumer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" (keypress)="alphaNumberOnly($event)" oninput="this.value = this.value.toUpperCase()" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN"  maxlength="10" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required minlength="10" />
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input"  style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10"  rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
        </div>
</ng-template>
<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center">Please be informed that our online portal facilitates allow transaction up to $25,000 (or equivalent).</span><br>
        <span class="text-center">If your transaction exceeds this limit, we kindly request you to utilize our branches. </span>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
        Okay </button>
    </div>
</ng-template>
<ng-template #errorModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center red-text-delete">Invalid Details
        </div>
        <div class="text-center">
            {{panVerificationErrMsg}}        
        </div>
    </div>
    
    <div class="col-md-12  text-center">
        <div class="mb-10 fw-500 fs-10 btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>