<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- account setting page -->
        <section id="page-account-settings">
            <div class="row  ">
                <div class="col-md-12 mb-2 mb-md-0">

                    <div class="card">
                        <div class="card-body">
                            <loader *ngIf="showLoader"></loader>
                            <!-- header media -->
                            <div class="media">
                                <a class="mr-25" *ngIf="consumerDetail?.photo != null && consumerDetail?.photo != '' ">
                                    <img src="{{consumerDetail?.photo}}" class="b-radius50 mr-50" height="90" width="90" />
                                </a>
                                <a class="mr-25" *ngIf="consumerDetail?.photo == null || consumerDetail?.photo == '' ">
                                    <img src="assets/images/avatars/user.png" class="b-radius50 mr-50" height="70" width="70" />
                                </a>
                                <!-- upload and reset button -->
                                <div class="media-body   ml-1">

                                    <div class="text-black fs-12 fw-600" style="border-color:black">
                                        {{updateProfileModel.name}}</div>
                                    <div class="text-black20 fs-11" style="color: black">{{updateProfileModel.mobile}}
                                    </div>
                                    <div class="media-body mt-75" *ngIf="!freezeIfVCIPCompleted">
                                        <button (click)="file.click()" for="account-upload" class="btn btn-sm btn-primary mb-75 mr-75" rippleEffect>
                                            Upload
                                        </button>
                                        <input type="file" #file id="account-upload" hidden accept="image/*" (change)="uploadProfilePicture($event,'image/png')" />
                                    </div>
                                    <div class="fs-9 mt-5 text-danger">Allowed JPG or PNG. Max size of 1MB</div>
                                </div>
                            </div>

                            <div class="row mt-20">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>PAN*</b></label>
                                    <input type="text" name="pan_no" class="form-control fs-12 text-uppercase" style="border-color:black" placeholder="PAN*"  (keydown.space)="$event.preventDefault()" [(ngModel)]="updateProfileModel.pan" (keypress)="alphaNumberOnly($event)"
                                        (paste)="onPaste($event)" required [readonly]="panReceived!=null">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Name as per PAN*</b></label>
                                    <!-- <input type="text" id="name_as_per_pan" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN*" [(ngModel)]="updateProfileModel.name" required [readonly]="updateProfileModel.name!=null"> -->
                                                                        <input type="text" id="name_as_per_pan" class="form-control fs-12" style="border-color:black"
                                                                            placeholder="Name as per PAN*" [(ngModel)]="updateProfileModel.name" required
                                                                            >

                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Gender*</b></label>
                                    <select id="gender" name="gender" class="form-control fs-12" style="border-color:black" [(ngModel)]="updateProfileModel.gender" [disabled]="freezeIfVCIPCompleted">
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                        <option value="OTHER">Other</option>
                                    </select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>DOB*</b></label>
                                    <div class="input-group">
                                        <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}"
                                            [markDisabled]="updateProfileModel.dob!=null" [readonly]="updateProfileModel.dob!=null">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Mobile No*</b></label>
                                    <input type="number" name="mobileno" class="form-control fs-12" style="border-color:black" placeholder="Mobile No*" [(ngModel)]="updateProfileModel.mobile" maxlength="10" readonly>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Email ID*</b></label>
                                    <input type="text" name="email_id" class="form-control fs-12" style="border-color:black" placeholder="Email ID*" [(ngModel)]="updateProfileModel.email" readonly>
                                </div>
                                <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':consumerDetail?.signature || showCheckedMarkForSignature, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!consumerDetail?.signature || !showCheckedMarkForSignature}">
                                    <div class="form-group">
                                        <label for="basicInputFile">
                                            <b>Upload Signature*</b>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" id="customFile" class="custom-file-input fs-12" style="border-color:black" (change)="uploadSignature($event, 'image/png')" />
                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-5" *ngIf="consumerDetail?.signature || showCheckedMarkForSignature">
                                    <div class="input-group-append mt-23" style="height: 38px;">
                                        <span class="input-group-text">
                                            <a  *ngIf="consumerDetail?.signature && !showCheckedMarkForSignature" href={{consumerDetail?.signature}} target="_blank">
                                                <img src="assets/images/icon/view-document.png" class="w-h-20"></a>
                                                
                                                <img 
                                                    src="assets/images/icon/view-document.png"
                                                    style="width:20px;width:20px"
                                                    (click)="downloadUploadedSignature()" *ngIf="showCheckedMarkForSignature"> 
                                        </span>
                                        <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                                class="w-h-20"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-offset-6 form-group">
                                    <label class="form-group"><b>Address Proof*</b></label>
                                    <ng-select class="text-capitalize fs-12" style="border-color:black" [items]="addressProofListItems" [(ngModel)]="selectedAddressProofType" (change)="getSelectedAddressProofType(selectedAddressProofType)" name="selectedAddressProofType" id="addressProofList"
                                        required [clearable]="false" [readonly]="freezeIfVCIPCompleted" [searchable]="true">
                                    </ng-select>
                                </div>

                                <div *ngIf="showFileUploadCartDocument" [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMark, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMark}">
                                    <div class="form-group">
                                        <label for="basicInputFile">
                                            <b>
                                                Upload
                                                {{ showPassportFields ? 'Passport Front' : selectedAddressProofType }} *
                                            </b>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" id="customFile" (change)="fileUploadPaxDocument($event,selectedAddressProof)" class="custom-file-input fs-12" [disabled]="freezeIfVCIPCompleted" />
                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-5" *ngIf="showCheckedMark || (showFileUploadCartDocument && addressProofDocAvailable)">
                                    <div class="input-group-append mt-23" style="height: 38px;">
                                        <span class="input-group-text">
                                            <a href={{passportFrontUrl}} target="_blank">
                                                <img src="assets/images/icon/view-document.png" class="w-h-20"></a>
                                        </span>
                                        <span class="input-group-text">
                                            <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="showFileUploadCartDocument && showPassportFields " [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMarkForPassportBack, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPassportBack}">
                                    <div class="form-group">
                                        <label for="basicInputFile">
                                           <b> Upload Passport Back *</b>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" id="customFile" class="custom-file-input fs-12" style="border-color:black" [disabled]="freezeIfVCIPCompleted" (change)="fileUploadPaxDocument($event,'PASSPORTBACK')" />
                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-5" *ngIf="showFileUploadCartDocument && showPassportFields && showCheckedMarkForPassportBack">
                                    <div class="input-group-append mt-23" style="height: 38px;">
                                        <span class="input-group-text">
                                            <a href={{passportBackUrl}} target="_blank">
                                                <img src="assets/images/icon/view-document.png" class="w-h-20"></a>
                                        </span>
                                        <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                                class="w-h-20"></span>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group mt-13" *ngIf="showFileUploadCartDocument && showPassportFields">
                                    <label class="form-label">Upload Passport Back*</label>
                                    <div class="input-group">
                                        <input type="file" id="LOAN_DOCUMENT" placeholder="Financial Institution*" (change)="fileUploadCartDocument($event,'LOAN_DOCUMENT')" class="form-control fs-12">
                                        <div class="input-group-append">
                                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                                    class="w-h-20"></span>
                                            <span class="input-group-text"><img
                                                    src="assets/images/icon/view-document.png" class="w-h-20"></span>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label">Name as per Passport*</label>
                                    <input type="text" id="name_as_per_passport" class="form-control fs-12"
                                        placeholder="Name as per Passport*"
                                        [(ngModel)]="updateProfileModel.passportPersonName"
                                        [readonly]="freezeIfVCIPCompleted">
                                </div> -->
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label"><b>Passport Number*</b></label>
                                    <input type="text" id="passport_no" class="form-control fs-12" style="border-color:black" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" placeholder="Passport Number*" [(ngModel)]="updateProfileModel.passportNumber" [readonly]="freezeIfVCIPCompleted">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label"><b>Passport Issue Date*</b></label>
                                    <div class="input-group">
                                        <input class="form-control fs-12" (click)="basicDP1.toggle()" style="border-color:black" name="passportIssueDate" [(ngModel)]="updateProfileModel.passportIssueDate" ngbDatepicker #basicDP1="ngbDatepicker" required [maxDate]="passportIssueMaxDate" [markDisabled]="freezeIfVCIPCompleted">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP1.toggle()" type="button" rippleEffect></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label"><b>Passport Expiry Date*</b></label>
                                    <div class="input-group">
                                        <input class="form-control fs-12" (click)="basicDP2.toggle()" style="border-color:black" name="passportExpiryDate" [(ngModel)]="updateProfileModel.passportExpiryDate" ngbDatepicker #basicDP2="ngbDatepicker" required [minDate]="passportExpiryMinDate" [markDisabled]="freezeIfVCIPCompleted">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP2.toggle()" type="button" rippleEffect></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label"><b>Passport File Number*</b></label>
                                    <input type="text" id="passport_file_no" class="form-control fs-12" style="border-color:black" placeholder="Passport File Number*" [(ngModel)]="updateProfileModel.passportFileNumber" [readonly]="freezeIfVCIPCompleted" (keypress)="alphaNumberOnly($event)"
                                        (paste)="onPaste($event)">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                                    <label class="form-label"><b>Passport Issue Place*</b></label>
                                    <input type="text" id="passport_issue_place" class="form-control fs-12" style="border-color:black" placeholder="Passport Issue Place*" [(ngModel)]="updateProfileModel.passportIssuePlace" [readonly]="freezeIfVCIPCompleted" (keypress)="alphaNumberOnly($event)"
                                        (paste)="onPaste($event)">
                                </div>
                                <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group " *ngIf="showPassportFields ">
                                    <label class="form-label "><b>Birth Place*</b></label>
                                    <input type="text " id="birth_place " class="form-control fs-12 " style="border-color:black" placeholder="Birth Place* " [(ngModel)]="updateProfileModel.birthCity" [readonly]="freezeIfVCIPCompleted">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group ">
                                    <label class="form-label "><b>City/Town*</b></label>
                                    <input type="text " id="city_town " class="form-control fs-12 " style="border-color:black" placeholder="City/Town* " [(ngModel)]="updateProfileModel.cityTown" [readonly]="freezeIfVCIPCompleted">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group ">
                                    <label class="form-label "><b>Pincode*</b></label>
                                    <input type="number " id="pincode " class="form-control fs-12 " style="border-color:black" placeholder="Pincode* " [(ngModel)]="updateProfileModel.pinCode" maxlength="6" oninput="javascript: if (this.value.length>                                    this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode
                                    !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190" [readonly]="freezeIfVCIPCompleted">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Country</b></label>
                                    <input type="text" id="country" class="form-control fs-12" readonly="readonly" style="border-color:black" placeholder="Enter Country" [readonly]="freezeIfVCIPCompleted" value="INDIA">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label class="form-label"><b>Select Occupation*</b></label>
                                    <select id="select_occupation" name="occupation" class="form-control fs-12" style="border-color:black" [(ngModel)]="updateProfileModel.occupation" [disabled]="freezeIfVCIPCompleted">
                                        <option *ngFor="let singleItem of occupationListItems" [ngValue]="singleItem">
                                            {{singleItem}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label class="form-label"><b>Address as per proof provided*</b></label>
                                    <input type="text" id="address_as_per_proof" class="form-control fs-11" style="border-color:black" placeholder="Address as per proof provided*" [(ngModel)]="updateProfileModel.address" [readonly]="freezeIfVCIPCompleted" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" >
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="invalid-state fs-9 text-black mt-10">*Please note upload document in either
                                    <span class="fw-600"> JPEG/PNG/PDF </span>under <span class="fw-600">1MB</span>
                                </div>
                            </div>
                            <!-- <div class="col-12 text-right" *ngIf="(vcipAccepted && !isProfileComplete) || (consumerProfileStatus == 'APPROVED') || (isVcipComplete)">
                                <button type="submit" class="btn btn-primary mt-2 mr-1 fs-11" (click)="updateProfile(paxId)" rippleEffect>Update</button>
                            </div> -->
                            <div class="col-12 text-right"
                                >
                                <button type="submit" class="btn btn-primary mt-2 mr-1 fs-11" (click)="updateProfile(paxId)"
                                    rippleEffect>Update</button>
                            </div>


                            <!-- <div class="col-12 mt-30 mt-75" *ngIf="showButtonOnVCIP && !isVcipComplete">
                                <div class="alert mb-50" role="alert" [ngClass]="vcipAccepted ? 'alert-success' : 'alert-warning'">
                                    <h4 class="alert-heading fs-11 text-center" style="box-shadow: 0 0 0 0 !important;">
                                        {{vcipMessage}}</h4>
                                </div>
                                <div style="cursor: pointer;" (click)="initiateVcip()" class="col-md-2 offset-md-5  btn btn-sm btn-primary mr-75 mt-10 waves-effect waves-float waves-light">
                                    Initiate your KYC</div>
                            </div> -->
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!--/ account setting page -->
    </div>
</div>


<ng-template #paxDocument let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">{{ documentTitle }}</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <img src={{documentUrl}} style="height:400px;display:block;margin:auto;width:100%;object-fit: contain;">
        </div>
    </div>
</ng-template>