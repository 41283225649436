<nav class="container navbar navbar-expand-lg  ">
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button> -->
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand" href="#"> <img class="logo-icon" src="assets/images/logo/logo.png"> </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
                <a class="nav-link" href="#"> <span class="sr-only"> </span></a>
            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <ng-select class="selectcurrency" [(ngModel)]="rateDropdownModel.currencyCode" [clearable]="false" [searchable]="true" name="currencyCode">
                <ng-option *ngFor="let currency of currencyRatesAll " [value]="currency.currencyCode " style="background:#fff !important">
                    <img src="assets/images/currencyFlag/{{currency.currencyCode}}.png " style="width:20px ">
                    <span style="font-weight: 900 !important; " class="fs-10"> {{ currency.currencyCode }}</span>&nbsp;
                    <span class="fs-10">Buy: {{ currency.salePriceInINR | number : '1.2-2' }}</span> &nbsp;
                    <span class="fs-10">Sell: {{ currency.buyPriceInINR | number : '1.2-2'}}</span>
                </ng-option>
            </ng-select>

            <!-- <div class="bot-icon " fxFlex="60 " fxFlex.xs="15 ">
                <a href="javascript:zE.activate(); "><img class="" src="assets/images/bot-top.png " width="45 "><span class="support ">Support</span></a>
            </div> -->
        </form>
    </div>
    
</nav>

<!-- <div class="container main bg-image">
    <div class="row">
        <div class="col-md-8 col-12 banner text-center text-md-left">
            <h1 class="head1" style="color: #1d2a62;">Swap your anxiety with </h1>
            <h1 class="head2" style="color: #1d2a62;">Excitement.</h1><br>
            <h1 class="head3" style="color: #1d2a62;"><span style="color: #1d2a62;">Smart forex</span> for a digital world.</h1>
        </div>

        <div class="col-md-4 col-12">
            <div id="login-form" class="login-form-wrapper mt-20">
                <section id="login-password">
                    <div class="title fs-16 fw-600">Log in</div>
                    <form class="mt-30" (ngSubmit)="loginPass(loginPassForm.form.valid)" #loginPassForm="ngForm">
                        <div class="form-group">
                            <label for="phoneno" class="control-label">Phone Number</label>
                            <input type="number" name="phoneno" class="form-control fs-11" id="phoneno" #phoneNoRef="ngModel" [class.error]="loginPassForm.submitted && phoneNoRef.invalid" [(ngModel)]="loginRequest.id" required onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190">
                            <span *ngIf="loginPassForm.submitted && phoneNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="phoneNoRef.errors.required">Please enter phone no!</small>
                            </span>
                        </div>

                        <div class="form-group">
                            <label for="password" class="control-label">Password</label>
                            <div class="row">
                                <div class="col-10">
                                    <input type="password" name="password" class="form-control fs-11" id="password" [(ngModel)]="loginRequest.token" #passwordRef="ngModel" [class.error]="loginPassForm.submitted && passwordRef.invalid" [type]="hide ? 'password' : 'text'" placeholder="Enter password" required>
                                </div>
                                <div class="col-2 pl-0 text-right">
                                    <i class="fa fa-eye" (click)="showPassword()"></i>
                                </div>
                            </div>

                            <div *ngIf="loginPassForm.submitted && passwordRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="passwordRef.errors.required">Please enter password!</small>
                            </div>
                        </div>

                        <div class="form-group mt-3">
                            <div id="CaptchaDiv">{{ captcha?.replaceAll('\"', ' ') }}</div>
                            &nbsp;&nbsp;
                            <input type="text" [(ngModel)]="captchaInput" name="captchaInput" id="captchaInput" placeholder="Answer" size="18" #captchaRef="ngModel" [class.error]="loginPassForm.submitted && captchaRef.invalid" required onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190">
                            <div *ngIf="loginPassForm.submitted && captchaRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="captchaRef.errors.required">Please enter answer!</small>
                            </div>
                        </div>

                        <div class="row mt-30">
                            <div class="col-md-6">
                                <button class="btn btn-primary rounded-btn-black login-button">Login</button>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="fs-11 mt-10">New User? <a class="text-black" (click)="openModalRegister(registerModal)">Sign Up</a></label>
                            </div>
                        </div>

                        <div class="row mt-20 d-flex justify-content-between">
                            <div class="col-6">
                                <a class="link fs-9 text-black fw-600" (click)="openModalLogin(loginModal)">Login with OTP</a>
                            </div>
                            <div class="col-6 text-right">
                                <a class="link fs-9 text-black fw-600" (click)="openModalForgotPwd(forgotPwdModal)">Forgot Password</a>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</div> -->

<div class="container main bg-image ">
    <div class="row">
        <div class="col-md-8 col-12 banner text-center text-md-left">
            <h1 class="head1" style="color: #1d2a62;">Swap your anxiety with </h1>
            <h1 class="head2" style="color: #1d2a62;">Excitement.</h1><br>
            <h1 class="head3" style="color: #1d2a62;"><span style="color: #1d2a62;">Smart forex</span> for a digital world.</h1>
        </div>

        <div class="col-md-4 col-12">
            <div id="login-form" class="login-form-wrapper mt-20">
                <section id="login-password">
                    <div class="title fs-16 fw-600">Log in</div>
                    <form class="mt-30" (ngSubmit)="loginPass(loginPassForm.form.valid)" #loginPassForm="ngForm">
                        <div class="form-group">
                            <label for="phoneno" class="control-label">Phone Number</label>
                            <input type="number" (keypress)="keyPressNumbers($event)" name="phoneno" class="form-control fs-11" id="phoneno" #phoneNoRef="ngModel" [class.error]="loginPassForm.submitted && phoneNoRef.invalid" [(ngModel)]="loginRequest.id" required><!-- onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190"-->
                            <span *ngIf="loginPassForm.submitted && phoneNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="phoneNoRef.errors.required">Please enter phone no!</small>
                            </span>
                        </div>

                        <div class="form-group">
                            <label for="password" class="control-label">Password</label>
                            <div class="row">
                                <div class="col-10">
                                    <input type="password" name="password" class="form-control fs-11" id="password" [(ngModel)]="loginRequest.token" #passwordRef="ngModel" [class.error]="loginPassForm.submitted && passwordRef.invalid" [type]="hide ? 'password' : 'text'" placeholder="Enter password" required>
                                </div>
                                <div class="col-2 pl-0 text-right">
                                    <i class="fa fa-eye" (click)="showPassword()"></i>
                                </div>
                            </div>

                            <div *ngIf="loginPassForm.submitted && passwordRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="passwordRef.errors.required">Please enter password!</small>
                            </div>
                        </div>

                        <div class="form-group mt-3">
                            <div id="CaptchaDiv">{{ captcha?.replaceAll('\"', ' ') }}</div>
                            &nbsp;&nbsp;
                            <input type="text" [(ngModel)]="captchaInput" name="captchaInput" id="captchaInput" (keypress)="keyPressNumbers($event)"  placeholder="Answer" size="18" #captchaRef="ngModel" [class.error]="loginPassForm.submitted && captchaRef.invalid" required>
                            <div *ngIf="loginPassForm.submitted && captchaRef.invalid" class="invalid-form">
                                <small class="form-text text-danger fs-11" *ngIf="captchaRef.errors.required">Please enter answer!</small>
                            </div>
                        </div>

                        <div class="row mt-30">
                            <div class="col-md-6">
                                <button class="btn btn-primary rounded-btn-black login-button">Login</button>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="fs-11 mt-10">New User? <a class="text-black" (click)="openModalRegister(registerModal)">Sign Up</a></label>
                            </div>
                        </div>

                        <div class="row mt-20 d-flex justify-content-between">
                            <div class="col-6">
                                <a class="link fs-9 text-black fw-600" (click)="openModalLogin(loginModal)">Login with OTP</a>
                            </div>
                            <div class="col-6 text-right">
                                <a class="link fs-9 text-black fw-600" (click)="openModalForgotPwd(forgotPwdModal)">Forgot Password</a>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</div>


<ng-template #registerModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">Sign Up</h6>
        <button type="button" class="close" (click)="closePopupForSignUp()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <form [formGroup]="signupForm">
                <div class="row">
                    <!-- <div class="col-md-6 form-group">
                        <label for="name" class="control-label">Full Name (Name as per PAN )</label>
                        <input type="text" name="fullName" formControlName="fullName" class="form-control fs-11" (keypress)="onlyAlpha($event)" autocomplete="nope" [(ngModel)]="signUpRequest.panName" placeholder="Full name" [ngClass]="{ 'is-invalid': submitted && func.fullName.errors }">
                        <div *ngIf="submitted && func.fullName.errors" class="invalid-feedback">
                            <div *ngIf="func.fullName.errors.required">Full name is required</div>
                        </div>
                    </div> -->
                    <div class="col-md-6 form-group">
                        <label for="name" class="control-label">Phone Number</label>
                        <input type="number" name="phoneno" formControlName="phoneno" class="form-control fs-11" onKeyPress="if(this.value.length==10) return false;" pattern="^((\+91-?)|0)?[0-9]{10}$" [(ngModel)]="signUpRequest.mobile"(keypress)="keyPressNumbers($event)" placeholder="Mobile no" 
                            [ngClass]="{ 'is-invalid': submitted && func.phoneno.errors }">
                        <a class="resendOtp text-right ng-star-inserted float-right fs-9" (click)="otpForSignUp(signUpRequest.mobile)" *ngIf="showSend == true && !showTimer">Send OTP</a>
                        <a class="resendOtp text-right ng-star-inserted float-right fs-9" (click)="otpResend(signUpRequest.mobile)" *ngIf="showSend == false && showResendButton">Resend OTP</a>
                        <div class="float-right fs-9" *ngIf="showTimer">{{displayTimer}}</div>
                        <div *ngIf="signupForm.get('phoneno')?.hasError('pattern')" class="text-danger fs-10 ">Invalid Phone Number Format</div>
                        <div *ngIf="submitted && func.phoneno.errors" class="invalid-feedback">
                            <div *ngIf="func.phoneno.errors.required">Mobile no is required</div>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="name" class="control-label">Email</label>
                        <input type="text" formControlName="email" name="email" class="form-control fs-11" autocomplete="nope" [(ngModel)]="signUpRequest.email" placeholder="Email Id" formControlName="email" [ngClass]="{ 'is-invalid': submitted && func.email.errors }">

                        <div *ngIf="submitted && func.email.errors" class="invalid-feedback">
                            <div *ngIf="func.email.errors.required">Email is required</div>
                            <div *ngIf="!signupForm.get('email').valid && signupForm.get('email').touched">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                   
                    <div class="col-md-6 form-group">
                        <label for="name" class="control-label">Phone OTP</label>
                        <input type="text" name="otp" class="form-control fs-11" type="number" onKeyPress="if(this.value.length==6) return false;" autocomplete="nope" formControlName="otp"(keypress)="keyPressNumbers($event)" [(ngModel)]="signUpRequest.otp" autocomplete="off" placeholder="Phone OTP" [ngClass]="{ 'is-invalid': submitted && func.otp.errors }">
                        <div *ngIf="submitted && func.otp.errors" class="invalid-feedback">
                            <div *ngIf="func.otp.errors.required">OTP is required</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-6 offset-md-3 form-group">
                        <div class="referral-input">
                            <input type="text" name="referral" class="form-control fs-11 referral-code" [(ngModel)]="referral" placeholder="Enter referral code here" (keypress)="alphaNumberOnly($event)" autocomplete="nope" formControlName="referral" style=" border-bottom: 0px;">
                        </div>
                    </div>
                </div>
                <div class="termsAndCondition">
                    <div class="row">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="tncCheckBox" name="" id="flexCheckDefault" [(ngModel)]="tncChecked" name="tncCheckBox" (change)="getCheckedValue(tncChecked)">
                            <label class="form-check-label" for="flexCheckDefault">
                                <span class="tnc fs-9" style="white-space: pre-line">
                                    I consent to my data being processed as per the 
                                    <a href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/Privacy_Policy_WebPortal.pdf"
                                        target="_blank" style="color: #3CC9EA">
                                        Privacy Policy</a>
                                    and 
                                    <a href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/Terms_and_Conditions_webPortal.pdf"
                                        target="_blank" style="color: #3CC9EA">Terms and Conditions</a>, and I am open to receiving service - related information and promotions.
                                </span>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="policyCheckBox" id="flexCheckDefault" [(ngModel)]="policyChecked">
                            <label class="form-check-label" for="flexCheckDefault">
                                <span class="policy fs-9" style="white-space: pre-line">
                                    I agree to be contacted for service related information and promotional
                                    purposes.
                                </span>
                            </label>
                        </div>
                    </div> -->
                    <div class="text-danger fs-10"> {{errorText}}</div>
                    <br />
                </div>

                <div fxLayout="row">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary rounded-btn-black  login-button" (click)="signUp()">
                            Sign Up
                        </button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-md-12 AlreadyMember text-center">Already Member ?
                        <a style="color: black; cursor: pointer" (click)="openModalLogin(loginModal)">Login</a></label>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #loginModal>
    <div class="modal-header">
        <h6 class="modal-title " id="myModalLabel1">Login</h6>
        <button type="button" class="close" (click)="closePopupForLogin()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <form (ngSubmit)="loginWithOTPSubmit()" (keyup.enter)="loginWithOTPSubmit()" [formGroup]="loginForm">
                <div class="row">
                    <div class="col-md-12 form-group mt-20">
                        <label for="name" class="control-label">Phone Number</label>
                        <input type="number" name="phoneno" class="form-control fs-11" placeholder="Mobile no" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" style="border-color:black"
                            formControlName="id" class="form-control fs-12" [(ngModel)]="requestLoginModel.id" placeholder="Enter Mobile" [ngClass]="{ 'is-invalid': submitted && f.id.errors }" (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)"/>
                            
                        <div *ngIf="submitted && f.id.errors" class="invalid-feedback">
                            <div *ngIf="f.id.errors.required">Mobile no is required</div>
                        </div>
                        <a class="resendOtp text-right ng-star-inserted float-right fs-10"  style="color: #3CC9EA" *ngIf="!showTimer" (click)="getOTPForLogin()">
                            Send OTP
                        </a>

                        <div *ngIf="showTimer" class="text-right float-right fs-9">{{displayTimer}}</div>
                    </div>
                    <div class="col-md-12 form-group">
                        <label for="name" class="control-label">Phone OTP</label>
                        <input type="number" style="border-color:black" maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Enter OTP"
                            formControlName="otp" class="form-control fs-12 form-control-merge" [(ngModel)]="requestLoginModel.otp" aria-describedby="login-password" [ngClass]="{ 'is-invalid error': submitted && f.otp.errors }" (keypress)="keyPressNumbers($event)"
                            (paste)="onPaste($event)" />
                        <div *ngIf="submitted && f.otp.errors" class=" invalid-feedback" [ngClass]="{ 'd-block': submitted && f.otp.errors }">
                            <div *ngIf="f.otp.errors.required">OTP is required</div>
                        </div>
                    </div>


                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary rounded-btn-black  login-button">
                            Login
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
            <div class="col-md-12 text-center fs-11 mt-20">
                <div class="cursor" (click)="closePopupForLogin()">Login with Password </div>
            </div>
        </div>

        <div class="row mt-20">
            <label class="col-md-12 AlreadyMember text-center">Not a member ?
                <a style="color: black; cursor: pointer" (click)="openModalRegister(registerModal)">
                    Sign Up
                </a>
            </label>
        </div>

    </div>

</ng-template>


<ng-template #forgotPwdModal>
    <div class="modal-header">
        <h6 class="modal-title " id="myModalLabel1">Forgot Password</h6>
        <button type="button" class="close" (click)="closePopupForForgotPwd()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <form #ForgotPwdForm="ngForm" (ngSubmit)="openModalForResetPassword(ForgotPwdForm.form.valid,resetPwdModal)">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="name" class="control-label">Phone Number</label>
                        <input type="number" name="phoneno" class="form-control fs-11" [(ngModel)]="passwordResetRequest.id" (keypress)="keyPressNumbers($event)" placeholder="Mobile no" #phoneNoRef="ngModel" [class.error]="ForgotPwdForm.submitted && phoneNoRef.invalid" maxlength="10" onKeyPress="if(this.value.length==10) return false;"
                            required>
                        <!-- <a class="resendOtp text-right ng-star-inserted float-right fs-9" *ngIf=" !showTimer" (click)="forgotPassOTP()">Send OTP</a> -->
                        <a class="resendOtp text-right ng-star-inserted float-right fs-9" *ngIf="!showTimer && sendOTPButtonVisible" (click)="forgotPassOTP()">Send OTP</a>

                        <div *ngIf="showTimer" class="text-right float-right fs-9">{{displayTimer}}</div>
                        <a class="resendOtp text-right ng-star-inserted float-right fs-9" *ngIf="resendOTPBtnActive" (click)="forgotPassResendOTP()"> Resend OTP</a>
                        <span *ngIf="ForgotPwdForm.submitted && phoneNoRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="phoneNoRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="col-md-12 form-group">
                        <label for="name" class="control-label">OTP</label>
                        <input type="text" class="form-control fs-11" type="number" autocomplete="nope" name="otp" placeholder="OTP"(keypress)="keyPressNumbers($event)" [(ngModel)]="passwordResetRequest.otp" #otpRef="ngModel" [class.error]="ForgotPwdForm.submitted && otpRef.invalid" maxlength="6" onKeyPress="if(this.value.length==6) return false;"
                            required>
                        <span *ngIf="ForgotPwdForm.submitted && otpRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="otpRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>
                <div class="row mb-20 mt-20">
                    <div class="col-md-12 text-center">
                        <button type="submit" class="btn btn-primary rounded-btn-black  login-button" [disabled]="ForgotPwdForm.invalid">
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #resetPwdModal>
    <div class="modal-header">
        <h6 class="modal-title " id="myModalLabel1">Forgot Password</h6>
        <button type="button" class="close" (click)="closePopupForResetPwd()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <form #ResetPwdForm="ngForm" (ngSubmit)="createNewPass(ResetPwdForm.form.valid)">
                <div class="row">
                    <div class="col-md-11 form-group">
                        <label for="name" class="control-label">Set Password</label>
                        <input type="password" name="new-password" class="form-control fs-11" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,15}" [(ngModel)]="passwordResetPatchRequest.newPassword" #newPasswordRef="ngModel" [class.error]="ResetPwdForm.submitted && newPasswordRef.invalid"
                            required [type]="hidePassword ? 'password' : 'text'">
                        <span *ngIf="passwordResetPatchForm.get('newPassword').hasError('pattern')">
                            Invalid Format
                            </span>
                        <span *ngIf="ResetPwdForm.submitted && newPasswordRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="newPasswordRef.errors.required">This field is required!</small>
                            </span>
                    </div>
                    <div class="col-md-1 pl-0">
                        <i class="fa fa-eye" (click)="showSetPassword()" style="margin-top:25px"></i>
                    </div>
                </div>

                <div class="row mb-20 mt-20">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary rounded-btn-black  login-button" style="width:150px">
                            Set Password
                        </button>
                    </div>

                </div>

            </form>
        </div>
    </div>
</ng-template>