import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { UpdateProfileModel } from "../models/update-profile.model";

@Injectable()
export class UpdateProfileService extends BaseProvider {

    getProfileListAll() {
        var resourceUrl = 'consumers/profile';
        return this.makeGetCall(resourceUrl);
    }

    updateProfileDetails(paxId, UpdateProfileModel) {
        var resourceUrl = 'b2c/consumers/pax/' + paxId;;
        return this.makePatchCall(resourceUrl, UpdateProfileModel);
    }

    getVCIPRedirectionURL() {
        var resourceUrl = "onBoardCustomer";
        return this.makeGetCall(resourceUrl);
    }

    getVCIPStaus() {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "getVcipStatus";
        return this.makeGetCall(resourceUrl);
    }
    getPANName(pan) {
        var resourceUrl = "pan?pan=" + pan;
        return this.makeGetCall(resourceUrl);
    }
    validatePAN(pan,name, dob) {
        var resourceUrl = "panV2?pan=" + pan+"&name="+name+"&dob="+dob;
        return this.makeGetCall(resourceUrl);
    }

    uploadDocument(imageData) {
        var resourceUrl = "cartDocument";
        return this.makeUploadCall(resourceUrl, imageData);
    }

    paxDocumentUploadUpdate(imageData) {
        var resourceUrl = "paxDocumentUploadUpdate";
        return this.makeUploadCall(resourceUrl, imageData);
    }

    getPaxesList(id) {
        var resourceUrl = "consumers/" + id + "/paxes";
        return this.makeGetCall(resourceUrl);
    }

    passwordNewSet(request) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "b2c/consumers/" + consumerId + "/passwordSet";
        return this.makePatchCall(resourceUrl, request);
    }

    passwordChange(request) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "b2c/consumers/" + consumerId + "/password";
        return this.makePatchCall(resourceUrl, request);
    }

    uploadUserPhoto(imageData, isPhoto) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "consumers/" + consumerId + "/photo?isPhoto=" + isPhoto;
        return this.makeUploadCall(resourceUrl, imageData);
    }

    getPanName(pan) {
        var resourceUrl = "pan?pan=" + pan;
        return this.makeGetCall(resourceUrl);
    }

    passportOCR(request: any) {
        return this.makeUploadCall("passportOcr", request);
    }
}