import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddBankAccountRequest } from 'app/modules/bank-account/models/bank-account.model';
import { BankAccountService } from 'app/modules/bank-account/services/bank-account.service';
import { CartItemCreateRequestList, InitiatePaymentforCartRequest, QuotationCurrenciesRequestModel, QuotationRequestModel, QuotationResponseModel } from 'app/modules/card/models/card.model';
import { LRSDeclarationRequestModel } from 'app/modules/card/models/lrs.model';
import { Step04DataPayment, StepperDataCardModel, TripInfoRequestModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RequestAadhaarEsignDetail } from 'app/modules/remittance/models/cart.model';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { EsignMode } from 'app/shared/constant/esign-mode';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { ReqGetCurrencyMaster, SinglePurposeItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { DateUtils } from 'app/shared/utility/date-utils';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { NgSelectComponent } from '@ng-select/ng-select/lib/ng-select.component';
import Swal from 'sweetalert2';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';
@Component({
  selector: 'card-stepper',
  templateUrl: './card-stepper.component.html',
  styleUrls: ['./card-stepper.component.scss',
    '../../../../remittance/stepper.scss',

  ],
  encapsulation: ViewEncapsulation.None
})

export class CardStepperComponent implements OnInit {

 

  mobStep: { id: string, imageUrl: string, altText: string, description: string }[] = [
    { id: 'trip-info', imageUrl: 'assets/images/cartStepper/TripInfo.png', altText: 'TripInfo', description: 'Trip Info' },
    { id: 'student-details', imageUrl: 'assets/images/cartStepper/Traveller.png', altText: 'Traveller', description: 'Traveller' },
    { id: 'kyc', imageUrl: 'assets/images/cartStepper/KYC.png', altText: 'KYC', description: 'KYC' },
    { id: 'kyc-self-declaration', imageUrl: 'assets/images/cartStepper/payment.png', altText: 'payment', description: 'payment' }
  ];

 

  public return_date: NgbDateStruct;
  private horizontalWizardStepper: Stepper;
  public selectedCountry: any;
  public countryListItems: any;
  public financialInstitutionItem: any;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationResponse: any;
  public tripMinDepartureDate: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public cartItemsResponse: any;
  public tripInfoRequestModel: TripInfoRequestModel = new TripInfoRequestModel();
  public lrsDeclarationRequestModel: LRSDeclarationRequestModel = new LRSDeclarationRequestModel();
  
  public cartType: string;
  public getRefCartId: string;
  public backNavigationUrl: string;
  public deleteItemMesg: string;
  public deleteItemIndex: any;
  public departure_date: NgbDateStruct;

  public bankBranchName: any;

  public editItemIndex: any;


  public quotationCurrencyResponsesList = [];
  public exoticCurrenyList = ['JPY', 'HKD', 'ZAR', 'NOK', 'DKK', 'SEK' ];

  public quotationSnapshotId: any;
  public isCalculateTCS: boolean = false;
  public isTCSApplicable: boolean = true;
  public isExoticDepartureDate: boolean = false;

  public isCartEdited: boolean = false;

  public showLoader: boolean = false;

  public modalReference: any;
  public paymentInstruction: any;
  public paymentMode: any;
  public clockDisplay: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public duration: any;
  public seconds = "00";
  public minutes = "00";

  public paymentMethodsList = [];
  public banksList = [];
  public payScript: string;
  public initiatePaymentforCartRequest = new InitiatePaymentforCartRequest();
  public selectedPayMode = "CARD";

  public loanDocumentUploadedUrl: string;
  public hideProceedButton: boolean = false;
  public showDiligenceForm: boolean = false;
  public isEditCart: boolean = false;
  public bankAccountId: any;
  public isPartial: boolean = false;

  public departure: any;
  public cardDeliveryDate: any;
  public step04DataPayment: Step04DataPayment = new Step04DataPayment();
  public showAddBankForm: boolean = false;
  public ALLOW_NEFT_PAYMENT = Constants.ALLOW_NEFT_PAYMENT;
  public selectedPaymentType: any;
  lrsForm: FormGroup;
  localLRSArray: Array<{ transactionDate: string; lrsDeclarationAmount: string; institudeName: string }> = [];
  public lrsDeclarationAmount: any;
  public instituteName: any;
  public showLocalTransactionDetails: boolean = false;
  public dateOfTransaction: any;
  public selectedDateOfTransaction: any;
  public showLRSForm: boolean = false;
  public showTransactionDetails: boolean = false;
  public transactionList: any;
  public selectedOption: string;
  public bankAccountList: any;

  public redeemableCredits: any;
  public listOffers: any;
  public promoCode: any;
  public pointsToRedeem: any;
  public showESignIframe: boolean = false;
  public selectedPurpose: SinglePurposeItem;

  @Output() childCallbackSender = new EventEmitter<string>();
  @ViewChild('EditCardModal') EditCardModal: TemplateRef<any>;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;
  @ViewChild('deleteConfirmationDailog') deleteConfirmationDailog: TemplateRef<any>;

  
  public productTypeList = Constants.productItemList;
  public productTypeBindValue = "FOREX CARD";

  public selectedProductType: String = "FOREX CARD";
  public travellerStepText: String = "Card's delivery date and address";
  public editAmount: any;
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public refreshQuotationCount: number = 0;

  public creditPointApplied: boolean = false;
  public promoCodeApplied: boolean = false;
  public signatureMode = EsignMode.activeEsignMode;
  public esignResp: any;
  public esignUrl: any;
  public url: any;
  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;
  public urlSafe: SafeResourceUrl;
  public addBankAccountRequest = new AddBankAccountRequest();
  public consumerId: any;
  public bankDropdownItemList: any;
  public selectedPaymentMode: any;
  public currencyItems: any;
  public currencyCode: any;
  public productEnum: any;
  public navigatedFromProduct: any;

  isCartEmpty: boolean = false;
  public consumerDetail: any;
  public signatureUploaded: boolean = false;
  public showSignatureUpload: boolean = false;
  public showImageUploadedIcon: boolean = false;
  public signatureURL: any;
  public bankRelationshipList = Constants.payerList;
  public showCashRefundNote: boolean=false;
  public isPromoCodeAdded: boolean = false
  @ViewChild('refPromoCodeList') refPromoCodeList: NgSelectComponent;
  isExoticCurrencyAdded: boolean=false;
  public maxLRSDate: any;
  public minLRSDate: any;
  public isLRSformSubmit: boolean = false;
  public isLRSSave: boolean = false;  
  public referredStatus: any = false;
  public referralCode: any = "";
  public referralCodeApplied: boolean = false;
  

  constructor(
    private router: Router,
    public masterDataService: MasterDataService,
    private modalService: NgbModal,
    public bankAccountService: BankAccountService,
    public updateProfileService: UpdateProfileService,
    public cardService: CardService,
    public sanitizer: DomSanitizer,private fb: FormBuilder,
    public toast: ToastrService,
    private remittanceService: RemittanceService,
    public gtmPushService: GTMDataLayerService
  ) {
    this.getBankList();
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.lrsForm = this.fb.group({
      instituteName: ['', Validators.required],
      lrsDeclarationAmount: ['', Validators.required],
      selectedDateOfTransaction: ['', Validators.required]
    });
    this.maxLRSDate =  { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.minLRSDate = { year: new Date().getFullYear(), month: 4, day: 1 };

  }

  ngOnInit() {
    this.navigatedFromProduct = sessionStorage.getItem("fromProduct");
    this.productEnum = Constants.productsEnum[0];
    this.backNavigationUrl="/view-cart";
    if (this.navigatedFromProduct==Constants.productsEnum[1]) {
      this.travellerStepText = 'Forex pick up date and location';
       this.productTypeList = Constants.productItemListCashFlow;
      this.selectedProductType = "CURRENCY NOTES";
      this.productTypeBindValue = "CURRENCY NOTES";
      this.productEnum = Constants.productsEnum[1];
       this.backNavigationUrl="/currency-notes-view-cart"; 
    }
    this.getMenuItemFromConfig();
    this.getCountryList();
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this.getSessionData();
    this.getPaymentMode();
    this.getBankAccountList();
    this.getRedeemableCredits();
    this.getOffers();
    this.getReferredStatus();
    this.timer();
    this.getCurrencies(0);
    this.getSignatureStatus();
    this.getAllOrderLrsDetail();
    this.currencyCode = this.quotationResponse.quotationCurrencyResponses[0]['currencyCode'];

  }
  getCurrencies(productIndex) {
    // this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = this.stepperDataCardModel.cartType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[productIndex];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      //Constants.currenciesListGlobal=result.body;
    });
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    this.stepperDataCardModel = JSON.parse(getActiveOrderData);
    this.quotationRequest = this.stepperDataCardModel.quotationRequest;
    this.quotationResponse = this.stepperDataCardModel.quotationResponse;
    this.cartType = this.stepperDataCardModel.cartType;
    if (this.stepperDataCardModel?.ofacCardData?.isOfacRequired) {
      this.showDiligenceForm = true;
    }
    
    console.log("GET session data >>>", this.stepperDataCardModel);

    console.log("GET session data >>>", this.stepperDataCardModel);

  }
  





  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          this.callQuotation();
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        // this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }
  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }
  parentCallbackFormChild($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.hideProceedButton = this.stepperDataCardModel.step01Data.hideProceedButton;
      this.refreshQuotationCount = this.refreshQuotationCount + 1;
      //this.checkExoticCurrency();
    }
    if ($event == 'REFRESH_DATA_UPDATE_QUOTATION') {
      this.callQuotation();
      this.refreshQuotationCount = this.refreshQuotationCount + 1;

    }
  }
  checkExoticCurrency() {
     for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
      var currCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
       if (this.quotationResponse.quotationCurrencyResponses[i].product == Constants.productsEnum[1]) {
            
           this.exoticCurrenyList.filter((singleItem, index) => {
              if (singleItem == currCode ) {
                this.exoticCurrenyList.splice(index, 1);
                  this.isExoticCurrencyAdded = true;
              }
           });
       }
     }
    
    this.departure_date = JSON.parse(localStorage.getItem('departureDate'));
    this.tripMinDepartureDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
     if (this.departure_date.day <= this.tripMinDepartureDate.day + 2) {
              this.isExoticDepartureDate=true;
              
            } else {
              this.isExoticDepartureDate=false;

            }
     var showExoticCurrencyNote = localStorage.getItem("showExoticCurrencyNote");
      if (this.isExoticDepartureDate && this.isExoticCurrencyAdded) {
         Swal.fire({
          title:"Dear Customer", 
          html: "Order will be fulfilled depending on the availability. If the currency is not available, the amount paid will be refunded back to the source account", 
          imageUrl: "assets/images/icons/info_icon.png",
          imageWidth: 80,
          imageHeight: 80,
          focusConfirm: false,
          confirmButtonColor: '#1D2A62'
         });
       localStorage.setItem("showExoticCurrencyNote", "false");

      }
  }

  async mapTripDetailsStep01() {
    if (!this.isCartEmpty) {


      ///get session data
      this.refreshQuotationCount = this.refreshQuotationCount + 1;

      this.getSessionData();
      this.tripInfoRequestModel = this.stepperDataCardModel.step01Data;
      if (this.tripInfoRequestModel?.departure) {
        this.departure = this.tripInfoRequestModel.departure;
      }
      // debugger
      // if (this.stepperDataCardModel?.step01Data.subPurpose == 'MEDICAL') {
      //   this.tripInfoRequestModel.purpose = ConstantsKey.MEDICAL_TREATMENT_ABROAD;
      // } else if (this.stepperDataCardModel?.step01Data.subPurpose == 'IMMIGRATION') {
      //   this.tripInfoRequestModel.purpose = ConstantsKey.EMIGRATION;
      // } else if (this.stepperDataCardModel?.step01Data.subPurpose == 'STUDENT_TRAVEL') {
      //   this.tripInfoRequestModel.purpose = ConstantsKey.OVERSEAS_EDUCATION;
      // } else {
      //   this.tripInfoRequestModel.purpose = ConstantsKey.PRIVATE_VISIT;
      // }
      if (this.validateStep01()) {
         this.tripInfoRequestModel.purpose = "PRIVATE_VISIT";
        this.showLoader = true;

        this.cardService.mapTripDetails(this.tripInfoRequestModel).subscribe(async (respTripData) => {
          this.showLoader = false;
          this.isCalculateTCS = true;
          var step1Result = JSON.parse(JSON.stringify(respTripData.body));
          this.cartType = step1Result.cartDetails.cartType;
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          if (this.isCartEdited) {
            this.isCartEdited = false;
            // await this.callQuotation();

            //  this.cartInitiate(1);
            await this.callQuotation();
            this.callCartItem(1);
            await this.updateStepNo(2);

            let gamoogaEventName = "";
            if(this.cartType == "CARD_PURCHASE") {
              gamoogaEventName = "web_buy_card_order_step1";
            } else if(this.cartType == "CARD_RELOAD") {
              gamoogaEventName = "web_reload_order_step1"
            } else if(this.cartType == "CASH_PURCHASE") {
              gamoogaEventName = "web_currency_buy_order_step1"
            }
            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              'number': this.consumerDetail.mobile,
              'country': this.tripInfoRequestModel.travellingCountryCode,
              'purpose': this.tripInfoRequestModel.purpose
            };

            this.gamoogaEvent(eventObj);

            this.horizontalWizardStepper.next();

          } else {
            await this.callQuotation();
            await this.updateStepNo(2);

            let gamoogaEventName = "";
            if(this.cartType == "CARD_PURCHASE") {
              gamoogaEventName = "web_buy_card_order_step1";
            } else if(this.cartType == "CARD_RELOAD") {
              gamoogaEventName = "web_reload_order_step1"
            } else if(this.cartType == "CASH_PURCHASE") {
              gamoogaEventName = "web_currency_buy_order_step1"
            }
            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              'number': this.consumerDetail.mobile,
              'country': this.tripInfoRequestModel.travellingCountryCode,
              'purpose': this.tripInfoRequestModel.purpose
            };

            this.gamoogaEvent(eventObj);

            this.horizontalWizardStepper.next();
          }

        }, (error) => {
          this.showLoader = false;
          this.toast.error(error.error.message);

          console.log("step1Result ", error);
        });
      }
    } else {
      this.toast.error(Constants.emptyCartMsg);

    }
  }

  validateStep01() {
    if (this.tripInfoRequestModel) {
      if (!Object.keys(this.tripInfoRequestModel).length) {
        this.toast.error('Please enter all required data.');
        return false;
      }

      if (!this.tripInfoRequestModel.departure) {
        this.toast.error('Please select departure date.');
        return false;
      }

      if (!this.tripInfoRequestModel.returnDate && this.tripInfoRequestModel.subPurpose!='IMMIGRATION' &&  this.tripInfoRequestModel.subPurpose!='STUDENT_TRAVEL') {
        this.toast.error('Please select return date.');
        return false;
      }

      if (this.tripInfoRequestModel.departure > this.tripInfoRequestModel.returnDate) {
        this.toast.error('Please select departure date properly. It should not be greater than return date!');
        return false
      }

      if (!this.tripInfoRequestModel.subPurpose) {
        this.toast.error('Please select purpose.');
        return false;
      }

      if (!this.tripInfoRequestModel.travellingCountryCode) {
        this.toast.error('Please select travelling country.');
        return false;
      }
    } else {
      this.toast.error('Please enter all required data.');
      return false;

    }

    return true;
  }

  mapPayerDetailsStep02() {
    if (!this.isCartEmpty) {

      this.refreshQuotationCount = this.refreshQuotationCount + 1;

      //this.cardDeliveryDate = this.stepperDataCardModel.step02Data.payerTravelerRequestModel.deliveryRequest.scheduledDate;
      this.getSessionData();
     
      if (this.validateStep02()) {
        var payerTravelerRequestModel = this.stepperDataCardModel.step02Data.payerTravelerRequestModel;
        this.refreshQuotationCount = this.refreshQuotationCount + 1;
        this.showLoader = true;

        this.cardService.mapPayerTraveller(payerTravelerRequestModel).subscribe(async (respPayer) => {
          this.showLoader = false;
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          
          let gamoogaEventName = "";
          if(this.cartType == "CARD_PURCHASE") {
            gamoogaEventName = "web_buy_card_order_step2";
          } else if(this.cartType == "CARD_RELOAD") {
            gamoogaEventName = "web_reload_order_step2"
          } else if(this.cartType == "CASH_PURCHASE") {
            gamoogaEventName = "web_currency_buy_order_step2"
          }      
          
           this.getDocumentsList(); 
          if (this.isCartEdited) {
            this.isCartEdited = false;
            // await this.callQuotation();
            //  this.cartInitiate(2);
            await this.callQuotation();
            this.callCartItem(1);
            await this.getDocumentForSign();
            await this.updateStepNo(3);

            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              'number': this.consumerDetail.mobile,
              "source-of-fund": this.stepperDataCardModel.step02Data.paymentSourceDetails,
            };

            this.gamoogaEvent(eventObj);            
            this.horizontalWizardStepper.next();
          } else {
            await this.callQuotation();
            await this.getDocumentForSign();
            await this.updateStepNo(3);

            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              'number': this.consumerDetail.mobile,
              "source-of-fund": this.stepperDataCardModel.step02Data.paymentSourceDetails,
            };
            this.gamoogaEvent(eventObj);            
            this.horizontalWizardStepper.next();
          }
          //this.cartInitiate(2);
          // this.horizontalWizardStepper.next(); /// SKIP DOCUMENT UPLOAD
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      }
    } else {
      this.toast.error(Constants.emptyCartMsg);

    }
  }

  validateStep02() {

    if (!this.stepperDataCardModel.step02Data || !Object.keys(this.stepperDataCardModel.step02Data).length) {
      this.toast.error('Please select source of fund.');
      return false;
    }
    if (sessionStorage.getItem('PASSPORT_AVAILABLE') == 'false') {
      this.toast.error('Please upload passport details');
      return false;
    }
    console.log("depature++", this.departure);
    console.log("cardDelivery++", this.cardDeliveryDate);
    if (this.departure < this.cardDeliveryDate) {
      this.toast.error('Card Delivery date should not exceed then date of departure');
      return false;
    }
    if (!this.stepperDataCardModel.step02Data?.paymentSourceDetails) {
      this.toast.error('Please select source of fund.');
      return false;
    }
    if (this.cartType == 'CARD_PURCHASE' || this.cartType == 'CASH_PURCHASE') {
      var deliveryRequestMap = this.stepperDataCardModel.step02Data.payerTravelerRequestModel.deliveryRequest;
      // if (Object.keys(deliveryRequestMap).length == 0) {
      //   this.toast.error('Please select mandatory fields');
      //   return false;
      // } 

      if (!deliveryRequestMap.deliveryAddressType) {
        if (this.navigatedFromProduct==Constants.productsEnum[1]) {
            this.toast.error('Please select Pick up type and address also');

        } else {
           this.toast.error('Please select delivery address type and address also');

        }
        return false;
      }

      if (!deliveryRequestMap.address) {
        if (this.navigatedFromProduct == Constants.productsEnum[1]) { 
             this.toast.error('Please select pickup address');

        } else {
              this.toast.error('Please select address');
        }
        return false;
      }

      if (!deliveryRequestMap.scheduledDate) {

         if (this.navigatedFromProduct == Constants.productsEnum[1]) { 
            this.toast.error('Please select pickup date');

        } else {
             this.toast.error('Please select delivery date');
        }
        return false;
      }

      if (!deliveryRequestMap.scheduledTime) {
        this.toast.error('Please select time slot');
        return false;
      }
    }

    return true;
  }

  checkDocumentUplodStep03() {
    if (!this.isCartEmpty) {

      this.refreshQuotationCount = this.refreshQuotationCount + 1;
      // this.stepperDataCardModel.kycDocumentUploadStatus
      var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
      if (getActiveOrderData) {
        this.stepperDataCardModel = JSON.parse(getActiveOrderData);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        if (this.stepperDataCardModel?.kycDocumentUploadStatus) {
          if (this.signatureUploaded) {
            this.horizontalWizardStepper.next();
          } else {
            this.toast.error('Please upload signature');
          }
          // if(this.isCartEdited){
          //   this.isCartEdited= !this.isCartEdited;
          //    this.cartInitiate(4);
          // }else{

          // }
          //this.cartInitiate(4);
        } else {
          this.toast.error('Please upload all mandatory documents.');
        }
      }
    } else {
      this.toast.error(Constants.emptyCartMsg);
    }
  }

  validateStep03() {
    if (!this.stepperDataCardModel.step03Data || !Object.keys(this.stepperDataCardModel.step03Data).length) {
      this.toast.error('Please accept the terms and conditions to proceed.');
      return false;
    }

    if (!this.stepperDataCardModel?.step03Data?.checkboxChecked) {
      this.toast.error('Please accept the terms and conditions to proceed.');
      return false;
    }

    if (!this.stepperDataCardModel?.step03Data?.otpValue) {
      this.toast.error('Please enter the OTP received.');
      return false;
    }

    return true;
  }

  downloadA2FormStep04() {
    // if (this.signatureMode == 'AADHAR_ESIGN') {
    //   this.aadharEsignDocumentDetails();
    // } else {
    //   this.otpVerification();
    // }
        for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
          if (this.quotationResponse.quotationCurrencyResponses[i].product==Constants.productsEnum[1]) {
            this.showCashRefundNote = true;
            break;
          }

          
        }
      
    this.otpVerification();
  }

  async getDocumentForSign() {
    this.showLoader = true;
    let orderId = this.cartItemsResponse['cartDetails']['refOrderId'];
    let cartType = this.stepperDataCardModel.cartType;

    const respEsign = await this.remittanceService.getDocumentSigned(orderId, cartType).toPromise();
    this.esignResp = JSON.parse(JSON.stringify(respEsign.body));
    this.esignUrl = this.esignResp.result.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.esignUrl);
    console.log(this.url);
    console.log(this.esignUrl);

    this.showLoader = false;
  }


  async aadharEsignDocumentDetails() {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;

    let requestAadhaarEsignDetail = new RequestAadhaarEsignDetail();
    requestAadhaarEsignDetail.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);;
    requestAadhaarEsignDetail.refOrderId = this.cartItemsResponse['cartDetails']['refOrderId'];

    this.remittanceService.getA2FormSignedDoc(requestAadhaarEsignDetail).subscribe(async resp => {
      var resAadhaarEsignDetail = JSON.parse(JSON.stringify(resp.body));
      if (resp.status == 200) {
        if (resAadhaarEsignDetail.esignFileUrl && resAadhaarEsignDetail.esignFileUrl != null &&
          resAadhaarEsignDetail.esignFileUrl != "") {
          await this.updateStepNo(4);
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

          this.showLoader = false;
          this.horizontalWizardStepper.next();
        } else {
          this.toast.error("Please esign document properly.");
        }
      } else {
        this.toast.error('Please esign document properly!');
      }
    });

  }

  otpVerification() {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;
    this.getSessionData();
    // this.showLoader = true;
    if (this.validateStep03()) {
      this.showLoader = true;
      let a2AcceptanceRequest = {
        "otp": this.stepperDataCardModel?.step03Data?.otpValue
      };
      //TODO:: Please fix this for production  
      // let otpValue
      this.cardService.getA2Form(this.stepperDataCardModel?.step03Data?.otpValue, {}).subscribe((respDownloadA2) => {
        this.showLoader = false;
        var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
        if (jsonResult.cartDetails.a2FormUrl) {
          let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
          window.open(a2FormUrl, "_blank");
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.updateStepNo(4);
        this.horizontalWizardStepper.next();
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }
  }

  step04Payment() {
    if (!this.isCartEmpty) {

      var paymentMode = this.selectedPaymentMode;
      if (paymentMode) {
        if (paymentMode == 'MANUAL' || (paymentMode == 'NB' && this.bankAccountId != null)) {
          this.showLoader = true;
          this.refreshQuotationCount = this.refreshQuotationCount + 1;
          this.modalReference.close();

          this.remittanceService.cartPlaceOrder(this.bankAccountId, this.paymentMode).subscribe(respPayment => {
              this.showLoader = false;

            var respPaymentBody = JSON.parse(JSON.stringify(respPayment.body));
            console.log("respPaymentBody cart place order >", respPaymentBody);

            /* let gamoogaEventName = "";
            if(this.cartType == "CARD_PURCHASE") {
              gamoogaEventName = "web_buy_card_order_payment";
            } else if(this.cartType == "CARD_RELOAD") {
              gamoogaEventName = "web_reload_order_payment"
            } else if(this.cartType == "CASH_PURCHASE") {
              gamoogaEventName = "web_currency_buy_order_payment"
            }

            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              "payment-mode": paymentMode,
              "number": this.consumerDetail.mobile
            };

            this.gamoogaEvent(eventObj);  */    

            if (paymentMode == PaymentMode.NET_BANKING) {
              this.getPaymentGatewayLink(respPaymentBody.orderId);
            } else {
              this.router.navigate(['/payment-success', respPaymentBody.orderId]);
            }
          }, error => {
                this.showLoader = false;

            if (error.error.message.includes('PaymentMode.undefined')) {
              this.toast.error('Please select payment method.');
            } else {
              this.toast.error(error.error.message);
            }
          })
        } else {
          this.toast.error('Please select your bank account');
        }
      } else {
        this.toast.error('Please select your payment method.');
      }
    } else {
      this.toast.error(Constants.emptyCartMsg);

    }
  }

  getPaymentGatewayLink(orderId: string) {
    this.showLoader = true;
    this.getSessionData();
    this.isPartial = this.stepperDataCardModel.isPartial;
    this.remittanceService.getPaymentGatewayLink(orderId, this.isPartial,this.bankAccountId).subscribe(respPaymentLink => {
      this.showLoader = false;
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      window.location.href = responsePaymentLinkBody.payment_link;
    }, error => {
      this.showLoader = false;
      console.log("Error in generating payment link", error);
    });
  }

  proceedForPayment() {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;

    this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    console.log("this.quotationResponse >>", this.quotationResponse);

    if (this.selectedPayMode == "NB") {
      this.initiatePaymentforCartRequest.amount = this.stepperDataCardModel.quotationResponse.totalBillableAmountString;
      this.initiatePaymentforCartRequest.paymentMedium = "B2C_WEB";
      this.initiatePaymentforCartRequest.payertype = this.cartItemsResponse.cartPayer.paidBy;
      this.initiatePaymentforCartRequest.agreement = "true";
      this.cardService.initiatePaymentforCart(this.initiatePaymentforCartRequest, this.quotationSnapshotId).subscribe((respPay) => {
        var jsonResult = JSON.parse(JSON.stringify(respPay.body));
        localStorage.setItem("OrderId", jsonResult.orderId);
        console.log(jsonResult.redirectUrl);
        this.initiatePG(jsonResult.redirectUrl);
      });
    } else if (this.selectedPayMode == "CARD") {
      this.initiatePaymentforCartRequest.amount = this.stepperDataCardModel.quotationResponse.totalBillableAmountString;
      this.initiatePaymentforCartRequest.paymentMedium = "B2C_WEB";
      this.initiatePaymentforCartRequest.paymentModeCategoryId = this.paymentMethodsList[0]["paymentModeCategories"][0]["id"];
      this.initiatePaymentforCartRequest.payertype = this.cartItemsResponse.cartPayer.paidBy;
      this.initiatePaymentforCartRequest.agreement = "true";
      // this.loading = true;
      this.cardService.initiatePaymentforCart(this.initiatePaymentforCartRequest, this.quotationSnapshotId).subscribe((respPay) => {
        // this.loading = false;
        let jsonResult = JSON.parse(JSON.stringify(respPay.body));
        localStorage.setItem("OrderId", jsonResult.orderId);
        console.log(jsonResult.redirectUrl);
        this.initiatePG(jsonResult.redirectUrl);
      });
    } else {
      this.toast.error("Please select the mode of payment.");
    }
  }

  initiatePG(pgURL) {
    const url = pgURL;
    this.cardService.makePGCall(url).subscribe((respPgCall) => {
    }, (error) => {
      //ERROR
    });

    const payScript = "var form = document.getElementById('ts-app-payment-form-redirect');";
    this.payScript = payScript;
    const iframe = document.getElementById("pgContainer");
    const script = document.createElement("script");
    script.type = "text/javascript";
    localStorage.setItem("pgRedirect", "true");
    script.text = payScript;
    iframe.appendChild(script);
    console.log(payScript);
  }

  /// call this when mode is N
  getPaymentMode() {
    this.cardService.getPaymentMethods().subscribe((respPayMethods) => {
      let jsonResult = JSON.parse(JSON.stringify(respPayMethods.body));
      var netBankingPayMode = jsonResult.filter((payment) => payment.paymentMode === "NB");
      this.banksList = netBankingPayMode[0].paymentModeCategories;
      this.paymentMethodsList = jsonResult;
    });

    // if (payMode == "NB") {
    //   this.showListNatbaking = true;
    // } else {
    //   this.showListNatbaking = false;
    // }
  }

  async updateStepNo(stepNo: number) {
    const response = await this.cardService.getCartToUpdateStepNumber(stepNo).toPromise();
    this.cartItemsResponse = response.body;
    this.getRefCartId = this.cartItemsResponse['cartDetails']['refOrderId'];
    this.getSessionData();
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.stepperDataCardModel.cartItemsResponse = this.cartItemsResponse;
    }

    console.log("this.stepperDataCardModel.cartItemsResponse 123 >>>>", this.stepperDataCardModel.cartItemsResponse);

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    return response;
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  productDropdownClicked(value) {
    console.log("product>>>>" + value);
    this.selectedProductType = value;
    if (value == 'FOREX CARD') {
      this.productEnum = Constants.productsEnum[0];
      this.getCurrencies(0);
    } else {
      this.productEnum = Constants.productsEnum[1];
      this.getCurrencies(1);
    }

  }

  async editCart() {
    if (this.editAmount && this.editAmount != '') {
      if (this.editAmount < 25000) {
        if (this.currencyCode) {
          this.isCartEdited = true;
          this.modalReference.close();

          this.showLoader = true;

          await this.callQuotation();

          this.showLoader = false;
        } else {
          this.toast.error('Please enter currency.')
        }
      } else {
        this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
      }
    } else {
      this.toast.error('Please enter amount.');
    }
  }

  openModalDeleteItem() {
    // this.modalReference.close();
    // if (singleItemDetails != null && singleItemDetails.product == 'CASH') {
    //   this.productTypeList = ["CURRENCY NOTES"];
    // }
    this.isEditCart=false;
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
    this.getCurrencies(0);
  }
  openModalForEditCart(item) {
    // this.modalReference.close();
    // if (singleItemDetails != null && singleItemDetails.product == 'CASH') {
    //   this.productTypeList = ["CURRENCY NOTES"];
    // }
    this.isEditCart=true;
    this.currencyCode = item.currencyCode;
    for (let i = 0; i < this.stepperDataCardModel.quotationRequest.quotationCurrencies.length; i++) {
      if (item['product'] == this.stepperDataCardModel.quotationRequest.quotationCurrencies[i]['product'] && item['currencyCode'] == this.stepperDataCardModel.quotationRequest.quotationCurrencies[i]['currencyCode']) { 
        this.editItemIndex = i;
      }
    }
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
    this.getCurrencies(0);
  }
  openModalForAddCart(singleItemDetails) {
    // this.modalReference.close();
    // if (singleItemDetails != null && singleItemDetails.product == 'CASH') {
    //   this.productTypeList = ["CURRENCY NOTES"];
    // }
    this.isEditCart=false;
    this.currencyCode = singleItemDetails.currencyCode;
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
    this.getCurrencies(0);
  }

   removeCartItemClicked(item) {
    console.log("item>>>" + JSON.stringify(item));
    for (let i = 0; i < this.stepperDataCardModel.quotationRequest.quotationCurrencies.length; i++) {
      if (item['product'] == this.stepperDataCardModel.quotationRequest.quotationCurrencies[i]['product'] && item['currencyCode'] == this.stepperDataCardModel.quotationRequest.quotationCurrencies[i]['currencyCode']) { 
        this.deleteItemMesg = item.product + " " + item.currencyCode + " " + item.currencyAmount + " from Cart?";
        this.deleteItemIndex = i;
        this.modalReference = this.modalService.open(this.deleteConfirmationDailog, { centered: true, size: 'sm' });
      }

    }

  }

  removeCartItem() {
         this.modalReference.close();
        this.stepperDataCardModel.quotationRequest.quotationCurrencies.splice(this.deleteItemIndex, 1);

        if (this.stepperDataCardModel.quotationRequest.quotationCurrencies.length > 0) {
          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

          this.callQuotation();

        } else {
          this.stepperDataCardModel.quotationResponse = new QuotationResponseModel();
          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
          this.quotationResponse = new QuotationResponseModel();
          this.quotationCurrencyResponsesList = [];
          this.isCartEmpty = true;
          this.getSessionData();

        }
     
   
  }
  cartInitiate(step) {
    this.showLoader = true;
    this.callCartItem(step);

    // this.cardService.cartPrepare(this.cartType).subscribe(response => {
    //   this.callCartItem(step);
    // });
  }
  callCartItem(step) {
    if (this.quotationResponse?.quotationSnapshotId) {
      localStorage.setItem(this.quotationResponse.quotationSnapshotId, JSON.stringify(this.quotationResponse));
    }
    let orderRequestList = [];
    this.showLoader = false;
    for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
      let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
      singleRequestCurrency.currencyAmount = this.quotationResponse.quotationCurrencyResponses[i].currencyAmount;
      singleRequestCurrency.currencyCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
      singleRequestCurrency.product = this.quotationResponse.quotationCurrencyResponses[i].product;
      singleRequestCurrency.bidRate = this.quotationResponse.quotationCurrencyResponses[i].rate;
      singleRequestCurrency.sellType = Constants.sellTypes[0];
      singleRequestCurrency.purpose = "PRIVATE_VISIT";
      orderRequestList.push(singleRequestCurrency);
    }

    // this.stepperDataCardModel.quotationResponse = this.quotationResponse;
    // this.stepperDataCardModel.quotationRequest = this.quotationRequest;
    // this.stepperDataCardModel.cartType = this.cartType;

    // sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

    this.cardService.cartItemPost(orderRequestList).subscribe(async response => {
      //  if(step==1){
      //   await this.updateStepNo(2);
      //   this.horizontalWizardStepper.next();
      //  }if(step==2){
      //   await this.getDocumentForSign();
      //    await this.updateStepNo(3);
      //    this.horizontalWizardStepper.next()
      //  }else{
      //   this.horizontalWizardStepper.next()

      //  }

    }, error => {
      this.toast.error(error.error.message);
    });
  }

  async callQuotation() {
    this.showLoader = true;
    this.getSessionData();
    if(this.isEditCart && this.editAmount != null){
      this.stepperDataCardModel.quotationRequest.quotationCurrencies[this.editItemIndex]['currencyAmount']=this.editAmount;
      this.stepperDataCardModel.quotationRequest.quotationCurrencies[this.editItemIndex]['currencyCode']=this.currencyCode;
      this.stepperDataCardModel.quotationRequest.quotationCurrencies[this.editItemIndex]['product']=this.productEnum;

    }
    var quotationRequest = this.stepperDataCardModel.quotationRequest;
    if (this.editAmount != null && !this.isEditCart) {
      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.currencyAmount = this.editAmount;
      quotationCurrencies.currencyCode = this.currencyCode;
      quotationCurrencies.product = this.productEnum;
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      quotationRequest.quotationCurrencies.push(quotationCurrencies);
    }
    for (let i = 0; i < quotationRequest.quotationCurrencies.length; i++) {
      quotationRequest.quotationCurrencies[i].purpose = 'PRIVATE_VISIT';
      if (this.promoCode!=null) {
        quotationRequest.quotationCurrencies[i].bidRate = this.quotationResponse.quotationCurrencyResponses[i].rate;
      }

      if (this.stepperDataCardModel.step01Data != null && this.stepperDataCardModel.step01Data.subPurpose != null) {
        quotationRequest.quotationCurrencies[i].subPurpose = this.stepperDataCardModel.step01Data.subPurpose;
        if (this.stepperDataCardModel.step01Data.subPurpose == 'LEISURE') {
          quotationRequest.quotationCurrencies[i].purpose = 'PRIVATE_VISIT';
        } else if (this.stepperDataCardModel.step01Data.subPurpose == 'MEDICAL') {
          quotationRequest.quotationCurrencies[i].purpose = 'MEDICAL_TREATMENT_ABROAD';
        } else if (this.stepperDataCardModel.step01Data.subPurpose == 'IMMIGRATION') {
          quotationRequest.quotationCurrencies[i].purpose = 'EMIGRATION';
        } else if (this.stepperDataCardModel.step01Data.subPurpose == 'OTHERS') {
          quotationRequest.quotationCurrencies[i].purpose = 'PRIVATE_VISIT';
        } else if (this.stepperDataCardModel.step01Data.subPurpose == 'STUDENT_TRAVEL') {
          quotationRequest.quotationCurrencies[i].purpose = 'OVERSEAS_EDUCATION';
        } else {
          quotationRequest.quotationCurrencies[i].purpose = 'PRIVATE_VISIT';
        }

      }
    }

    if (this.stepperDataCardModel?.step02Data?.paymentSourceDetails == 'LOAN') {
      quotationRequest.paymentSourceDetails = 'LOAN';
      quotationRequest.tcsPaymentSource = 'LOAN';
    } else {
      quotationRequest.paymentSourceDetails = this.stepperDataCardModel?.step02Data?.paymentSourceDetails;
      quotationRequest.tcsPaymentSource = 'OWN';

    }
    quotationRequest.calculateTcs = this.isCalculateTCS;



    ///APPLY PROMO CODE IF AVAILABLE
    if (this.promoCode!=null) {

      this.quotationRequest.promoCode = this.promoCode;
    } else {
      this.quotationRequest.promoCode = null;
    }
    if (this.pointsToRedeem!=null) {
      this.quotationRequest.pointsToRedeem = this.pointsToRedeem;
    } else {
      this.quotationRequest.pointsToRedeem = null;
    }
    console.log("Quotation request >>>>>>>>>", quotationRequest);
    if (this.stepperDataCardModel.quotationResponse != null && this.stepperDataCardModel.quotationResponse.quotationSnapshotId != null) {
      quotationRequest.quotationSnapshotId = this.stepperDataCardModel.quotationResponse.quotationSnapshotId;
    }
    return new Promise((resolve, reject) => {
    this.cardService.getQuotationEncrypted(quotationRequest).subscribe((respQuote) => {
    }, (error) => {
      this.showLoader = false;
      if (error.status == 200) {
        this.isExoticCurrencyAdded = false;
        if(this.isEditCart){
          this.isEditCart=false;
        }
        var quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        this.quotationCurrencyResponsesList = quotationResponse["quotationCurrencyResponses"];
        this.stepperDataCardModel.quotationResponse = quotationResponse;
        console.log("quotationResponse>>> " ,quotationResponse);
        
        if (this.isCalculateTCS && quotationResponse["tcs"]["tcsAmountString"] != "0.00") {
          this.isTCSApplicable = true;
        } else {
          this.isTCSApplicable = false;
        }
        if (this.editAmount != null) {
          this.editAmount = null;
        }

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
        this.refreshQuotationCount = this.refreshQuotationCount + 1;
        this.getSessionData();
        this.duration = this.defaultTimerLimitInSec;
        clearInterval(this.interval);
        this.startTimer();
        resolve("success");
        this.checkExoticCurrency();

      } else {
        console.log("Error callQuotation >", error);
        this.toast.error(error.error.message);
        reject("fail");

      }

    });
  });
  }

  horizontalWizardStepperNext(data) {
    if (data.form.valid === true) {
      this.horizontalWizardStepper.next();
    }
  }

  horizontalWizardStepperPrevious() {
    this.horizontalWizardStepper.previous();
  }

  openModalForPaymentInfo(paymentInstructionModal) {
    sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
    if (this.cartType == 'CARD_PURCHASE' || this.cartType == 'CASH_PURCHASE') {
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
       sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
    } else {
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'true');
       sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
    }

    let gamoogaEventName = "";
    if(this.cartType == "CARD_PURCHASE") {
      gamoogaEventName = "web_buy_card_order_payment";
    } else if(this.cartType == "CARD_RELOAD") {
      gamoogaEventName = "web_reload_order_payment"
    } else if(this.cartType == "CASH_PURCHASE") {
      gamoogaEventName = "web_currency_buy_order_payment"
    }
    
    this.getSessionData();
    if (this.stepperDataCardModel.step04Data) {
      if (this.stepperDataCardModel.step04Data.bankAccountId) {
        if (this.selectedPaymentType != null) {
          this.paymentMode = this.stepperDataCardModel.step04Data.paymentMode;
          this.bankAccountId = this.stepperDataCardModel.step04Data.bankAccountId;
          if (this.paymentMode == PaymentMode?.NET_BANKING) {
            this.paymentInstruction = "Please note, you are requested to upload a proof of your payment in the Order Details section of My Orders, if you do choose to proceed with this mode of payment.\n";
            this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              "payment-mode": this.paymentMode,
              "number": this.consumerDetail.mobile
            };
        
            this.gamoogaEvent(eventObj); 
            this.callQuotation();
  
          } else if (this.paymentMode == PaymentMode?.NEFT_RTGS || this.paymentMode == PaymentMode?.TPV) {
            this.paymentInstruction = "Please note, if a payment is not received within 4 hours, your order will stand cancelled. Please do contact customer care at +91 72088 21166 for any queries.\n";
            this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
            let eventObj = {
              'name': gamoogaEventName,
              'event': gamoogaEventName,
              "payment-mode": this.paymentMode,
              "number": this.consumerDetail.mobile
            };
        
            this.gamoogaEvent(eventObj); 
            this.callQuotation();

          } else {
              this.toast.error('Please select payment method !');

          }
        } else {
          this.toast.error('Please select payment type!');
        }
      } else {
        if(this.bankAccountList.length == 0) {
          this.toast.error('Kindly add a new Bank Account');            
        } else {
          this.toast.error('Please select your bank account.');
        }
      }
    } else {
      this.toast.error('Please select payment method !');
    }

  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
    this.step04DataPayment.bankAccountId = this.bankAccountId;
    this.updateSessionData();
  }

  getSelectedPaymentMode(paymentModeValue) {
    this.selectedPaymentMode = paymentModeValue;
    this.step04DataPayment.paymentMode = paymentModeValue;
    sessionStorage.setItem(ConstantsKey.PAYMENT_METHOD, paymentModeValue);
    this.updateSessionData();
  }

  getSelectedPaymentType(paymentType) {
    if (paymentType == false) {
      this.showNetBanking = true;
      this.showNEFT = true;
    } else {
      this.showNetBanking = true;
      this.showNEFT = false;
    }
    this.selectedPaymentType = paymentType;
    this.updateSessionData();
  }

  updateSessionData() {
    this.getSessionData();
    this.stepperDataCardModel.step04Data = this.step04DataPayment;
    this.stepperDataCardModel.isPartial = this.selectedPaymentType;
    console.log("partial flag>>> ", this.stepperDataCardModel);

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    console.log("STEP 4 DATA >>>>>> ", this.stepperDataCardModel.step04Data);

  }

  getBankAccountList() {
    this.cardService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
      console.log("this.bankAccountList >>", this.bankAccountList);
    });
  }

  goToAddBankAccount() {
    this.router.navigate(['/add-bank-account']);
  }
  handleTripInfoBackClick() {
    this.router.navigate([this.backNavigationUrl]);
  }

  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }


  setPaymentModeAsManual() {
    this.stepperDataCardModel.isPartial = false;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    console.log("STEP 4 DATA >>>>>> ", this.stepperDataCardModel.step04Data);
  }

  getRedeemableCredits() {
    this.remittanceService.getRedeemableCredits().subscribe(resp => {
      this.redeemableCredits = resp.body;
      console.log("this.redeemableCredits >>", this.redeemableCredits);
    }, error => {
      console.log("Unable to get credit points!", error);
    });
  }

  applyCreditPointToQuote() {
    if (this.pointsToRedeem) {
      if (this.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("Entered Points are more than available credit points");
      } else if (this.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("You can redeem maximum upto " + this.redeemableCredits?.maximumRedeemableCredit + "points!");
      } else {
        this.callQuotation();
        this.getSessionData();
        this.creditPointApplied = true;
        this.toast.success("Credit points applied");
      }
    } else {
      this.toast.error("Please enter redeemable points");
    }
  }

  async removeCreditPoint() {
    this.pointsToRedeem = null;
    await this.callQuotation();
    this.getSessionData();
    this.creditPointApplied = false;
    this.toast.success("Credit points removed");
  }


  getOffers() {
    this.listOffers = [];
    let listOfferLocal = [];
    this.remittanceService.getOffers().subscribe(resp => {
      var offerListAll = JSON.parse(JSON.stringify(resp.body));
      for (let singleOffer of offerListAll) {
        if (singleOffer.status == "ACTIVE" && (singleOffer.scope == "PRIVATE" || singleOffer.scope == "PUBLIC")) {
          listOfferLocal.push(singleOffer);
        }
      }
      this.listOffers = listOfferLocal;
      console.log('OFFERS >>', this.listOffers);

    }, error => {
      this.toast.error(error.error.message);
    });
  }

  async applyPromoCode() {
    if (this.promoCode) {
      this.isPromoCodeAdded = true;
      await this.callQuotation().then((res) => {
        if(res == "success") {
      this.getSessionData();
      this.toast.success("Promocode applied successfully!");
      this.promoCodeApplied = true;
    }
    }).catch(err => {
      if(err == "fail") {
        this.refPromoCodeList.handleClearClick();
      }
    });
    } else {
      this.toast.error("Please enter promocode!");
    }
  }

  async removePromoCode() {
    this.promoCode = null;
    await this.callQuotation();
    this.getSessionData();
    this.toast.success("Promocode removed successfully!");
    this.promoCodeApplied = false;
  }

  // async hitGetQuotationCall() {
  //   var quotationRequest = this.stepperDataCardModel.quotationRequest;
  //   quotationRequest.paymentSourceDetails = this.stepperDataCardModel?.step02Data?.paymentSourceDetails;
  //   quotationRequest.calculateTcs = this.isCalculateTCS;
  //   var respQuotation = await this.cardService.getQuotation(quotationRequest).toPromise();
  //   this.stepperDataCardModel.quotationResponse = respQuotation.body;
  //   this.stepperDataCardModel.quotationRequest = quotationRequest;
  //   sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
  //   console.log("Quotation called");
  // }

  onSubmit() {
    this.modalReference.close();
    this.router.navigate(['/card-payment-success']);
  }


  showIframeForEsign() {
    this.getSessionData();
    if (this.stepperDataCardModel.step03Data) {
      if (!this.stepperDataCardModel.step03Data.checkboxChecked) {
        this.toast.error('Please select checkbox !');
      } else {
        this.showESignIframe = true;
      }
    } else {
      this.toast.error('Please select checkbox !');
    }
  }


  public configData: any;
  public showNetBanking: boolean = true;
  public showNEFT: boolean = true;
  public partialPaymentAllowed: boolean = true;

  getMenuItemFromConfig() {
    // let data = sessionStorage.getItem('CONFIG_DATA');
    // this.configData = JSON.parse(data);
    // console.log(JSON.stringify(this.configData));

    this.configData = {
      "remittanceFlowEnable": true,
      "personalFlowEnable": true,
      "cardFlowEnable": true,
      "insuranceEnable": true,
      "dashboardEnable": true,
      "myAccountMenuEnable": true,
      "myAccountSubMenuEnable": true,
      "relationshipEnable": true,
      "beneficiaryEnable": true,
      "bankAccountEnable": true,
      "ordersEnable": true,
      "pendingOrdersEnable": true,
      "rewardsEnable": true,
      "offersEnable": true,
      "branchNetworkEnable": true,
      "currencyConverterEnable": true,
      "resetPasswordEnable": true,
      "ratesEnable": true,
      "setRateAlertEnable": true,
      "supportEnable": true,
      "addressEnable": true,
      "faqEnable": true,
      "tutorialEnable": true,
      "paymentModeNBEnable": true,
      "paymentModeNEFTEnable": true,
      "partialPaymentEnable": true,
      "openGICAccountEnable": true,
      "blockedAccountEnable": true,
      "zolveCreditCardEnable": true,
      "isicCardEnable": true
    };
    if (!this.configData?.paymentModeNBEnable) {
      this.showNetBanking = false;
    } else if (!this.configData?.paymentModeNEFTEnable) {
      this.showNEFT = false;
    } else if (!this.configData?.partialPaymentEnable) {
      this.partialPaymentAllowed = false;
    }

  }

  showAddBankAccForm() {
    this.showAddBankForm = true;
  }


  addBankAccount(data) {
    if (data === true) {
      this.showLoader = true;
      if (this.addBankAccountRequest.number === this.addBankAccountRequest.confirmAccNo) {
        this.addBankAccountRequest.consumerId = this.consumerId;
        this.addBankAccountRequest.default = true;
        this.addBankAccountRequest.type = 'SAVINGS';
        this.addBankAccountRequest.isDefault = true;
        this.addBankAccountRequest.branch = this.bankBranchName;
        console.log(JSON.stringify(this.addBankAccountRequest));

        this.bankAccountService.addMyBankAccount(this.addBankAccountRequest).subscribe(resp => {
          this.showLoader = false;
          this.toast.success("Bank account added successfully", "Success !!");
          this.modalReference.close();

          this.getBankAccountList();
        }, error => {
          this.showLoader = false;
          console.log(error);
          this.toast.error(error.error.message);
        });
      } else {
        this.showLoader = false;
        this.toast.error("Account no and confirm account no. must be same");
      }

    }
  }

  openModalForAddBankAccount(addBankModal) {
    this.addBankAccountRequest.branch = null;
    this.modalReference = this.modalService.open(addBankModal, { size: 'lg', centered: true });

  }

  getBankList() {
    var singleObject;
    var localBankDropdownItemList;
    this.bankAccountService.getPaymentModes().subscribe(res => {

      localBankDropdownItemList = res.body;
      for (let i = 0; i < localBankDropdownItemList.length; i++) {
        singleObject = localBankDropdownItemList[i];
        if (singleObject.categoryName == "Net Banking") {
          this.bankDropdownItemList = singleObject.paymentModeCategories;
        }
      }

    });
  }

  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  public otp: string;
  public tncCheckBox: any;
  public documentListResponse: any;
  public transactionDocListAwaiting: any;
  public transactionDocListUploaded: any;
  public skipDocumentUpload: boolean = false;
  public documenturl: any;

  getDocumentsList() {
    this.showLoader=true;
    this.cardService.cartTotalVersusUploadedDocuments().subscribe((respDocList) => {
      this.showLoader=false;

      this.documentListResponse = respDocList.body;
      this.transactionDocListAwaiting = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.AWAITING;
      this.transactionDocListUploaded = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.UPLOADED;
      console.log("transactionDocument++", this.transactionDocListAwaiting)
      var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
      if (getActiveOrderData) {
        this.stepperDataCardModel = JSON.parse(getActiveOrderData);
        if (this.documentListResponse.skipDocumentUpload) {
          this.stepperDataCardModel.kycDocumentUploadStatus = true;
          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
        }
      }

    }), error => {
      this.showLoader=false;

      this.toast.error(error.error.message);
    };
  }

  uploadDownloaddocument(documentDetails) {
    this.documenturl = documentDetails.location;
    window.location.assign(this.documenturl);
  }

  fileUploadCartDocument(event, docType) {
    // this.showLoader = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];

      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "CONSUMER");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
        formData.append('documentPurpose', "TRANSACTION");

        // if (file.size > 1000000) {
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.cardService.uploadDocument(formData).subscribe((respDoc) => {
            console.log("In fild upload success", respDoc);
            if (docType != "LOAN_DOCUMENT") {
              this.getDocumentsList();
            }
          }, error => {
            this.toast.error(error.error.message);
          });
        
      }
    }
  }

  getSignatureStatus() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;

      this.consumerDetail = resp.body;

      if (this.consumerDetail?.paxResponse?.consumer?.signature && this.consumerDetail?.paxResponse?.consumer?.signature != null) {
        this.signatureUploaded = true;
        this.signatureURL = this.consumerDetail?.paxResponse?.consumer?.signature;
      }else{
        this.showSignatureUpload=true;
      }
    });

  }

  uploadSignature(event) {
    this.getSessionData();
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      formData.append('file', file, file.name);
      formData.append('photo', file, file.name);
      formData.append('type', 'SIGNATURE');

      console.log(JSON.stringify(this.stepperDataCardModel.cartItemsResponse));
      this.updateProfileService.uploadUserPhoto(formData, false).subscribe((resp) => {
        this.showImageUploadedIcon = true;
        this.signatureUploaded = true;
        var response=resp.body;
        this.signatureURL=response['photo'];
        this.toast.success("Signatured uploaded successfully");
      });
    }
  }

  downloadSignature() {
    window.open(this.signatureURL);
  }
  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.dateOfTransaction = year + "-" + month + "-" + day;
  }

  showLRSFormSection(booleanValue){
    this.showLRSForm = booleanValue;
  }
  
  saveLRSDataLocally() {
    this.isLRSformSubmit = true;
    if(this.lrsForm.valid) {
      this.lrsDeclarationRequestModel.transactionDate = DateUtils.getNgbDateStructToDate(this.lrsForm.controls['selectedDateOfTransaction'].value);
      const newTransaction = {
        transactionDate: this.lrsDeclarationRequestModel.transactionDate,
        lrsDeclarationAmount: this.lrsForm.controls['lrsDeclarationAmount'].value,
        institudeName: this.lrsForm.controls['instituteName'].value
      };
      this.localLRSArray.push(newTransaction);
      this.lrsForm.reset();    
      this.showLRSForm = false;
      if(this.localLRSArray.length > 0){
        this.showLocalTransactionDetails = true;
      }      
    }
  }

  proceedToLRSDeclaration(){
    // this.lrsDeclarationRequestModel.transactionDate = DateUtils.getNgbDateStructToDate(this.selectedDateOfTransaction)
    // console.log(this.lrsDeclarationRequestModel);
    // let lrsArray = [];
    // lrsArray.push(this.lrsDeclarationRequestModel);
    // console.log(lrsArray);
    
    this.cardService.lrsDeclaration(this.localLRSArray).subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.toast.success('LRS Declaration Details saved successfully!');
      this.getAllOrderLrsDetail();
      this.showLocalTransactionDetails=this.showLRSForm=false;
    });
  }

  getAllOrderLrsDetail(){
    this.cardService.getAllOrderLrsDetails().subscribe((resp) => {
      this.transactionList = resp.body;
      console.log(this.transactionList);
      if(this.transactionList.length > 0){
        this.showTransactionDetails = true;
        this.selectedOption = 'Yes';
        this.showLRSFormSection(true);
      }
    });

  }

  removeLRSEntry(index: number) {
    this.localLRSArray.splice(index, 1);
    if(this.localLRSArray.length == 0){
      this.showLocalTransactionDetails = false;
    }
  }

  // async checkLRSStep(){
  //   if (this.selectedOption) {
  //     if(this.localLRSArray.length > 0 && !this.isLRSSave) {
  //       this.showLoader = true;
  //       let respSaveLRSData; 
  //       let resplrsData;        
  //       respSaveLRSData = await this.cardService.lrsDeclaration(this.localLRSArray).toPromise().then(res => res).catch(err => err);        
        
  //       if (respSaveLRSData.status == 200) {
  //           this.showLoader = false;
  //         this.consumerDetail = respSaveLRSData.body;
  //         resplrsData = await this.cardService.getAllOrderLrsDetails().toPromise().then(res => res).catch(err => err);
  //         if (resplrsData.status == 200) {
  //           this.transactionList = resplrsData.body;
  //           this.callQuotation();
  //           this.showLRSForm = false;
  //           this.isLRSSave = true;
  //           this.isLRSformSubmit = false;
  //           this.horizontalWizardStepper.next();
  //         }
  //       } else {
  //               this.showLoader = false;

  //            console.log(resplrsData.error.message);
  //               Swal.fire({
  //             title:"Dear Customer", 
  //             html: resplrsData.error.message, 
  //             imageUrl: "assets/images/icons/info_icon.png",
  //             imageWidth: 80,
  //             imageHeight: 80,
  //             focusConfirm: false,
  //             confirmButtonColor: '#1D2A62'
  //           });
                 
        


  //       }
  //       this.showLoader = false;
  //     } else {        
  //       this.horizontalWizardStepper.next();
  //     }
  //   } else {
  //     this.toast.error('Please complete LRS Declaration!')
  //   }
  // }

  async checkLRSStep(){
    if (this.selectedOption) {
      if(this.localLRSArray.length > 0 && !this.isLRSSave) {
        this.showLoader = true;
         this.cardService.lrsDeclaration(this.localLRSArray).subscribe(resp => { 
          this.consumerDetail = resp.body;
           this.showLoader = false;
           this.getAllLRSData();
        
        }, err => {
               this.showLoader = false;

          console.log(err);
           Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
        
      }); 
        
      } else {
        this.horizontalWizardStepper.next();        
      }
    } else {
      this.toast.error('Please complete LRS Declaration!')
    }
  }
  async getAllLRSData() {
    this.cardService.getAllOrderLrsDetails().subscribe(resp => {
           this.getDocumentForSign();
            this.transactionList = resp.body;
            this.callQuotation();
            this.showLRSForm = false;
            this.isLRSSave = true;
            this.isLRSformSubmit = false;
            this.horizontalWizardStepper.next();

     }, err => {
      console.log(err);
       Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
     }); 
  }

  closeLRSForm() {
    this.lrsForm.reset();
    this.isLRSformSubmit = false;
    this.showLRSForm = false;
  }

  get lrsFormControl(): any {
    return this.lrsForm['controls'];
  }
  addNewCoupon = (offer) => {
    return { title: offer, promoCodeId: offer }
  };

  getReferredStatus() {
    this.remittanceService.getReferredStatus().subscribe((resp) => {
      this.referredStatus = resp.body;
      console.log("REFERRAL CODE ===== ",this.referralCodeApplied);
    }, (err) => {
      
    });
  }

  applyReferralCode() {
    if(this.referralCode == "") {
      this.toast.error("Please enter Referrer Code!");
    } else {
      this.remittanceService.updateConsumerReferrer({}, this.referralCode).subscribe((resp)=> {

      }, error => {
        this.showLoader = false;
          if (error.status == 202) { 
              let response = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
              this.toast.success(response);
              this.referralCodeApplied = true;
          } else {
              this.toast.error(error.error.message);
          }
      });
    }

  }

  gamoogaEvent(obj) {
    this.gtmPushService.dataLayerPushData(obj);
  }          

}
