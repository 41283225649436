import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { PasswordChangeModel, PasswordSetModel } from '../models/security-setting.model';
import * as shajs from 'sha.js';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'security-setting',
  templateUrl: './security-setting.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SecuritySettingComponent implements OnInit {

  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showLoader: boolean = false;
  public passwordChangeModel: PasswordChangeModel = new PasswordChangeModel();
  public passwordSetModel: PasswordSetModel = new PasswordSetModel();

  public consumerDetails: any;
  public passwordAlreadySet: any;
  passwordNotEqual: boolean;

  constructor(
    private updateProfileService: UpdateProfileService,
    public toast: ToastrService, private fb: FormBuilder,
    public _router: Router
  ) { }

  ngOnInit() {
    var consumerDataResp = localStorage.getItem(ConstantsKey.OTP_LOGIN_RESPONSE);
    this.consumerDetails = JSON.parse(consumerDataResp);

    if (this.consumerDetails?.passwordSet) {
      this.passwordAlreadySet = true;
    } else {
      this.passwordAlreadySet = false;
    }
  }


  setPasswordForm = this.fb.group({
    password: [
      null,
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
        Validators.minLength(8),
      ],
    ],
    confirmPassword: [Validators.required]
  });

  changePasswordForm = this.fb.group({
    oldPassword: [Validators.required],
    newPassword: [
      null,
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
        Validators.minLength(8),
      ],
    ],
  });

  passwordChange() {
    if (this.changePasswordForm.valid){
      if (this.updatePasswordValidation()) {
        this.showLoader = true;
        var passwordChangeModelLocal: PasswordChangeModel = new PasswordChangeModel();
        // passwordChangeModelLocal.oldPassword = shajs('sha256').update(this.passwordChangeModel.oldPassword).digest('hex') + '@1';
        // passwordChangeModelLocal.newPassword = shajs('sha256').update(this.passwordChangeModel.newPassword).digest('hex') + '@1';
    
        passwordChangeModelLocal.oldPassword = this.passwordChangeModel.oldPassword;
        passwordChangeModelLocal.newPassword = this.passwordChangeModel.newPassword;
        this.updateProfileService.passwordChange(passwordChangeModelLocal).subscribe((respData) => {
          this.showLoader = false;
          this.toast.success('Yay! Your password has been updated successfully!');
          this._router.navigate(['/login']);
        }, error => {

              this.showLoader = false;
              if(error.status==200||error.status==201){
                this.toast.success('Yay! Your password has been updated successfully!');
                this._router.navigate(['/login']);
              }else{
                this.toast.error(error.error.message);
              }
        });
        }
    }
  }

  updatePasswordValidation() {
    if (!this.validatePasswordPatter(this.passwordChangeModel.newPassword)) {
      this.toast.error("Please try again with another combination!");
      return false;
    }

    if (!this.passwordChangeModel.oldPassword) {
      this.toast.error("Please enter old password!");
      return false;
    }
    return true;
  }

  setNewPassword() {
    if (this.setPasswordValidation()) {
    this.showLoader = true;
    var passwordSetRequestModelLocal: PasswordSetModel = new PasswordSetModel();
   // passwordSetRequestModelLocal.consumerPassword = shajs('sha256').update(this.passwordSetModel.consumerPassword).digest('hex') + '@1';
    passwordSetRequestModelLocal.consumerPassword = this.passwordSetModel.consumerPassword;
    this.updateProfileService.passwordNewSet(passwordSetRequestModelLocal).subscribe((respData) => {
      this.showLoader = false;

      this.consumerDetails.passwordSet = true;
      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(this.consumerDetails));

      this.toast.success('Yay! Your password has been set successfully!');
    }, error => {
      this.showLoader = false;
      if (error.status == 200 || error.status == 201) {
                this.consumerDetails.passwordSet = true;
                localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(this.consumerDetails));
                this.toast.success('Yay! Your password has been set successfully!');
          }else{
                this.toast.error(error.error.message);
          }
    });
    }
  }

  setPasswordValidation() {
    if (this.passwordSetModel.consumerPassword != this.passwordSetModel.confirmConsumerPassword) {
      this.toast.error("New password and confirm password does not match!!!");
      return false;
    }

    // if (!this.validatePasswordPatter(this.passwordSetModel.consumerPassword)) {
    //   this.toast.error("Please try again with another combination!");
    //   return false;
    // }

    return true;
  }

  validatePasswordPatter(passwordString) {
    var passwordPattern = /^[a-zA-Z0-9*@#$]{8,15}$/;
    if (passwordPattern.test(passwordString)) {
      return true;
    } else {
      return false;
    }
  }
}