import { Component, OnInit, ViewEncapsulation, VERSION } from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { ToastrService } from 'ngx-toastr';
import { DobFormat, UpdateProfileModel } from '../models/update-profile.model';
import { UpdateProfileService } from '../services/update-profile.service';
import { Router } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import moment from 'moment';
import { DateUtils } from 'app/shared/utility/date-utils';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';
// import { DateUtils } from 'app/shared/utility/date-utils';

interface NgbDateStruct {
  day: any,
  month: any,
  year: any
}

@Component({
  selector: 'update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UpdateProfileComponent implements OnInit {

  public addressProofItems = Constants.addressProofItems;
  public occupationListItems = Constants.occupationListItems;
  public educationListItems = Constants.educationListItems;
  public incomeListItems = Constants.incomeListItems;
  public addressCityList = Constants.addressCityList;
  public addressProofListItems;
  public dob;
  public consumerDetail;
  public ngbDatepicker: NgbDateStruct;
  public paxId;
  public dobFormat = new DobFormat();
  public defaultDob;
  public occupation;
  public showLoader: boolean = false;
  public isSign: boolean = false;

  public profileListItem = [];
  public addressProofList: any;
  public updateProfileModel = new UpdateProfileModel;
  public resGetVcipStatus: any;
  public vcipMessage: any;
  public showButtonOnVCIP: boolean = false;
  public fileList: any;

  public showFileUploadCartDocument: boolean = false;
  public showUtilityBill: boolean = false;
  public showPassportFields: boolean = false;
  public showDrivingLicence: boolean = false;
  public showAadharCard: boolean = false;
  public showVotedId: boolean = false;
  public selectedAddressProofType: any;
  public currentYear: any;

  public date: any;
  public mnth: any;
  public day: any;

  public vcipAccepted: boolean = false;
  public showCheckedMark: boolean = false;
  public showCheckedMarkForPassportBack: boolean = false;
  public showSignature: boolean = false
  public dobMaxDate: any;
  public signature: any;

  public modalReference: any;
  public documentUrl: any;
  public documentTitle: any;
  public passportIssueMaxDate: any;
  public passportExpiryMinDate: any;
  public addressProofDocAvailable: boolean = false;
  public selectedAddressProof: any;

  public signatureUrl: any;
  public freezeIfVCIPCompleted: boolean = false;
  public showSign: boolean = false;
  public filePassportFront: File;
  public filePassportBack: File;
  public passportBackUrl: any;
  public passportFrontUrl: any;
  public formData: FormData = new FormData();
  public passportIssueDate: NgbDateStruct;
  public passportExpiryDate: NgbDateStruct;
  public name = 'Angular ' + VERSION.major;

  public isProfileComplete: boolean = false;
  public isVcipComplete: boolean = false;
  public showCheckedMarkForSignature : boolean = false;
  public uploadedSignature: File;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();
  public consumerProfileStatus : any;
  
  constructor(
    public updateProfileService: UpdateProfileService,
    public toast: ToastrService,
    public router: Router,
    public modalService: NgbModal,
  ) {
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.passportIssueMaxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.passportExpiryMinDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  }

  ngOnInit() {
    this.getProfileList();
    this.getVCIPStatus();
  }

  getProfileList() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;

      this.consumerDetail = resp.body;
      this.paxId = this.consumerDetail.paxResponse.id;
      localStorage.setItem(ConstantsKey.PAXID, this.paxId);
      this.updateProfileModel.pan = this.consumerDetail.paxResponse.pan;
      this.updateProfileModel.name = this.consumerDetail.paxResponse.name;
      this.updateProfileModel.email = this.consumerDetail.email;
      this.updateProfileModel.mobile = this.consumerDetail.paxResponse.mobile;
      this.updateProfileModel.gender = this.consumerDetail.paxResponse.gender;

      this.updateProfileModel.address = this.consumerDetail.paxResponse.address;
      if (this.consumerDetail.paxResponse.occupation=='STUDENT') {
        this.updateProfileModel.occupation = 'Student';
      } else if (this.consumerDetail.paxResponse.occupation=='HOMEMAKER') {
        this.updateProfileModel.occupation = 'Homemaker';
      } else if (this.consumerDetail.paxResponse.occupation=='SERVICE') {
        this.updateProfileModel.occupation = 'Service';
      }else if (this.consumerDetail.paxResponse.occupation=='BUSINESS') {
        this.updateProfileModel.occupation = 'Business';
      } else if (this.consumerDetail.paxResponse.occupation=='OTHERS') {
        this.updateProfileModel.occupation = 'Others';
      } else if (this.consumerDetail.paxResponse.occupation=='SELF_EMPLOYED') {
        this.updateProfileModel.occupation = 'Self Employed';
      }else if (this.consumerDetail.paxResponse.occupation=='RETIRED') {
        this.updateProfileModel.occupation = 'Retired';
      }
      this.occupation = this.updateProfileModel.occupation;
      this.updateProfileModel.nationality = this.consumerDetail.paxResponse.nationality;
      this.updateProfileModel.birthCountry = this.consumerDetail.paxResponse.nationality;
      this.addressProofList = this.consumerDetail.paxResponse.addressProofsType;
      this.signature = this.consumerDetail.signature;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.consumerProfileStatus =   this.consumerDetail.paxResponse?.consumer.consumerProfileStatus;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      
      if(this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed){
        this.isVcipComplete=true;
      }else if(this.consumerDetail?.paxResponse?.consumer?.offlineonboarding=='1'){
        this.isVcipComplete=true;
      }
      this.signature = this.consumerDetail.signature;
      console.log(this.signature);
      
      if (this.consumerDetail?.signature != null || this.consumerDetail?.signature != '') {
        this.showSign = true;
      }
      this.addressProofListItems = [];
      let ignoreAddressProof = ["ELECTRICITY_BILL","PASSPORT_FRONT_PAGE","PASSPORT_ADDRESS_PAGE","MOBILE_BILL"];
      this.addressProofList.forEach(singleDocumentType => {
        if(ignoreAddressProof.includes(singleDocumentType)) {
        } else {
          if(singleDocumentType == "VOTERID") {
            this.addressProofListItems.push("Voter Id");
          } else if(singleDocumentType == "AADHAR_CARD") {
            this.addressProofListItems.push("Aadhaar Card");
          } else {
            this.addressProofListItems.push((singleDocumentType.replaceAll('_', ' ')).toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()));
          }
        }
      });

      this.updateProfileModel.birthCity = this.consumerDetail.paxResponse.birthCity;
      this.updateProfileModel.cityTown = this.consumerDetail.paxResponse.cityTown;
      this.updateProfileModel.pinCode = this.consumerDetail.paxResponse.pinCode;
      if (this.consumerDetail.paxResponse.dob) {
        this.updateProfileModel.dob = DateUtils.ngbDateStructDateConverter(moment(this.consumerDetail.paxResponse.dob).format("YYYY-MM-DD"));
      }

      ///CHECK DOCUMENT UPLOADED
      this.consumerDetail.paxResponse.paxDocuments.forEach(singleDoc => {
        if (singleDoc.type.includes("PASSPORT")) {
          this.showPassportFields = true;
          this.showCheckedMark = true;
          this.selectedAddressProofType = "Passport";
        }
        if (singleDoc.type.includes("BACK") || singleDoc.type.includes("ADDRESS")) {
          this.showFileUploadCartDocument = true;
          this.showCheckedMarkForPassportBack = true;
          this.passportBackUrl = singleDoc.location;
        } else {
          this.showFileUploadCartDocument = true;
          this.showCheckedMark = true;
          this.passportFrontUrl = singleDoc.location;
        }
      });

      if (this.consumerDetail.paxResponse.paxDocuments.length > 1) {
        this.showFileUploadCartDocument = true;
      }
      if (!this.showPassportFields) {
        this.selectedAddressProofType = this.consumerDetail.paxResponse?.paxDocuments[0]?.type;
        this.selectedAddressProofType = (this.selectedAddressProofType.replaceAll('_', ' ')).toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
      } else {
        /// set passport filed
        this.updateProfileModel.passportPersonName = this.consumerDetail.paxResponse.name;
        this.updateProfileModel.passportNumber = this.consumerDetail.paxResponse.passportNumber;
        this.updateProfileModel.passportFileNumber = this.consumerDetail.paxResponse.passportFileNumber;
        this.updateProfileModel.passportIssuePlace = this.consumerDetail.paxResponse.passportIssuePlace;
        if (this.consumerDetail.paxResponse.passportIssueDate) {
          this.updateProfileModel.passportIssueDate = DateUtils.ngbDateStructDateConverter(moment(this.consumerDetail.paxResponse.passportIssueDate).format("YYYY-MM-DD"));
        }

        if (this.consumerDetail.paxResponse.passportExpiryDate) {
          this.updateProfileModel.passportExpiryDate = DateUtils.ngbDateStructDateConverter(moment(this.consumerDetail.paxResponse.passportExpiryDate).format("YYYY-MM-DD"));
        }
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  updateProfile(paxId) {
    if (this.validateProfileData()) {
      this.showLoader = true;
      let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
      this.updateProfileModel.nationality = 'INDIAN';
      this.updateProfileModel.birthCountry = 'INDIAN';
      this.updateProfileModel.dob = dateofBirth;
      if (this.showPassportFields) {
        this.updateProfileModel.nameAsPerPassport = this.updateProfileModel.passportPersonName;
        this.updateProfileModel.passportExpiryDate = DateUtils.getNgbDateStructToDate(this.updateProfileModel.passportExpiryDate);
        this.updateProfileModel.passportIssueDate = DateUtils.getNgbDateStructToDate(this.updateProfileModel.passportIssueDate);
      }
      console.log(">>>> ", JSON.stringify(this.updateProfileModel));
      this.updateProfileService.updateProfileDetails(paxId, this.updateProfileModel).subscribe(async resp => {
        this.showLoader = false;
        this.updateProfileModel.dob = this.setDOBToInput(dateofBirth);
        if (this.showPassportFields) {
          this.updateProfileModel.passportExpiryDate = this.setDOBToInput(this.updateProfileModel.passportExpiryDate);
          this.updateProfileModel.passportIssueDate = this.setDOBToInput(this.updateProfileModel.passportIssueDate);
          this.updateProfileModel.passportIssuePlace = this.updateProfileModel.passportIssuePlace;
        }
        this.toast.success("Profile updated successfully!");
        this.getProfileList();
      }, error => {
        //this.toast.error(error.error.message);
        this.updateProfileModel.dob = this.setDOBToInput(this.updateProfileModel.dob);
        this.showLoader = false;
        if (error.status == 200) {
          console.log("prodileData>>"+JSON.stringify(error));
       this.toast.success("Profile updated successfully!");
        this.getProfileList();
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
      });
    }
  }

  setDOBToInput(dateOfBirth): NgbDateStruct {
    let date = { year: parseInt(dateOfBirth.split("-")[0]), month: parseInt(dateOfBirth.split("-")[1]), day: parseInt(dateOfBirth.split("-")[2]) }
    return date;
  }

  validateProfileData() {
    if (!this.updateProfileModel.pan || this.updateProfileModel.pan.length != 10) {
      this.toast.error("Please enter valid pan number!");
      return false;
    } else if (!(this.updateProfileModel.name)) {
      this.toast.error("Please enter your name!");
      return false;
    } else if (!(this.updateProfileModel.address)) {
      this.toast.error("Please fill in details of your address!");
      return false;
    } else if (!(this.updateProfileModel.gender)) {
      this.toast.error("Please select gender!");
      return false;
    } else if (!this.updateProfileModel.occupation) {
      this.toast.error("Please select occupation");
      return false;
    } else if (!(this.updateProfileModel.dob)) {
      this.toast.error("Please select date of birth!");
      return false;
    } else if (!this.updateProfileModel.pinCode) {
      this.toast.error("Please enter pincode");
      return false;
    } else if (!this.updateProfileModel.cityTown) {
      this.toast.error("Please enter city");
      return false;
    }

    return true;
  }

  uploadDocument(fileList) {
    let fileReader = new FileReader();
    fileReader.onload = (event) => {
      console.log(fileReader.result);
    }
    fileReader.readAsText(fileList);
  }

  fileUploadPaxDocument(event, documentType) {
    this.showLoader = true;
    let docType;
    let fileList: FileList = event.target.files;
    if (documentType == 'PASSPORTBACK') {
      docType = 'PASSPORTBACK';
    } else {
      docType = this.getAddressProofCode(this.selectedAddressProofType);
    }

    if (fileList.length > 0) {
      let file: File = fileList[0];
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "PAX");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', this.paxId);
        // formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
        formData.append('documentPurpose', "ADDRESS_PROOF");
        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.updateProfileService.paxDocumentUploadUpdate(formData).subscribe((respDoc) => {
            this.showLoader = false;
            if (documentType == 'PASSPORTBACK') {
              this.showCheckedMarkForPassportBack = true;
              this.filePassportBack = fileList[0];
            } else {
              this.showCheckedMarkForPassportBack = false;
              this.filePassportBack = null;

              this.showCheckedMark = true;
              this.filePassportFront = fileList[0];
            }
            // this.addressProofDocAvailable = true;
            var localDocList = JSON.parse(JSON.stringify(respDoc.body));
            localDocList.forEach((localDocListItems) => {
              if (localDocListItems.type == 'PASSPORTBACK') {
                this.passportBackUrl = localDocListItems.location;
              } else if (localDocListItems.type == 'PASSPORT') {
                this.passportFrontUrl = localDocListItems.location;
              } else if (localDocListItems.type == docType) {
                this.passportFrontUrl = localDocListItems.location;
              }
            });

            if (this.filePassportBack && this.filePassportFront) {
              this.doOcrPassport();
            }
            this.toast.success('Document uploaded successfully!');
          }, (error) => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
        this.showLoader = false;
      }
    } else {
      console.log("Hello I m in error last,", this.showLoader);
      this.showLoader = false;
    }
  }

  getAddressProofCode(typeString) {
    return typeString.replaceAll(' ', '_').toUpperCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  }

  getSelectedAddressProofType(selectedItem) {
    this.findDocumentUploadedUrl(selectedItem);
    // this.addressProofDocAvailable = false;
    // this.showCheckedMark = false;
    this.selectedAddressProof = undefined;

    this.showFileUploadCartDocument = true;
    if (selectedItem.includes("Passport")) {
      this.showPassportFields = true;
      // this.addressProofDocAvailable = true;
    } else {
      this.showPassportFields = false;
    }
  }

  findDocumentUploadedUrl(documentType) {
    this.addressProofDocAvailable = false;
    this.showCheckedMark = false;
    var getDocumentType = this.getAddressProofCode(documentType);
    this.consumerDetail.paxResponse.paxDocuments.forEach(singleDoc => {
      console.log(JSON.stringify(singleDoc));

      if (singleDoc.type == getDocumentType) {
        this.addressProofDocAvailable = true;
        this.showCheckedMark = true;
        this.selectedAddressProof = singleDoc;
        this.passportFrontUrl = singleDoc.location;
      }

      if (getDocumentType.includes('PASSPORT')) {
        if (singleDoc.type.includes('FRONT')) {
          this.addressProofDocAvailable = true;
          this.showCheckedMark = true;
          this.selectedAddressProof = singleDoc;
          this.passportFrontUrl = singleDoc.location;
        } else if (singleDoc.type.includes('BACK')) {
          this.showCheckedMarkForPassportBack = true;
          this.passportBackUrl = singleDoc.location;
        }
        // this.showCheckedMarkForPassportBack = true;
      }
    });
  }

  uploadProfilePicture(event, docType) {
    this.showLoader = true;
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log("File >>>");
      console.log(file);
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('photo', file, file.name);
        formData.append('type', docType);

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.updateProfileService.uploadUserPhoto(formData, true).subscribe((respDoc) => {
            this.showLoader = false;
            this.showCheckedMark = true;
            this.toast.success('Profile picture is uploaded successfully!');
            this.getProfileList();
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    }
  }

  uploadSignature(event, docType) {
    this.showLoader = true;
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    console.log("consumerId++", consumerId);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log("File >>>");
      console.log(file);
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('photo', file, file.name);
        formData.append('type', docType);
        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.uploadedSignature = fileList[0];
          this.updateProfileService.uploadUserPhoto(formData, false).subscribe((respDoc) => {
            this.showLoader = false;
            this.showCheckedMarkForSignature = true;
            this.toast.success('Signature is uploaded successfully!');
            // this.getProfileList();
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    }
  }

  getPanNameOnEnter() {
    this.showLoader = true;
    var searchValue = this.updateProfileModel.pan;
    if (searchValue.length == 10) {
      this.updateProfileService.getPanName(searchValue).subscribe((respPanData) => {
        this.showLoader = false;
        let resObj = JSON.parse(JSON.stringify(respPanData.body));
        if (resObj.fullName != 'null' && resObj.fullName != '') {
          this.updateProfileModel.name = resObj.flatPan.details;
        } else if(resObj.flatPan.status == "Record Not Found in ITD Database/Invalid PAN") {
          this.updateProfileModel.name = '';
          this.updateProfileModel.dob = ''; 
          this.toast.error("Invalid Pan");
        } else {
          this.updateProfileModel.name = '';
          this.toast.error(resObj.flatPan.status);
        }
      }, error => {
        this.showLoader = false;
      });
    } else {
      this.showLoader = false;
    }
  }

  openModalForPaxDocumentView(paxDocument, documentDetails) {
    this.modalReference = this.modalService.open(paxDocument, { centered: true });
    this.documentUrl = documentDetails.location;
    this.documentTitle = documentDetails.type;
  }

  async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      this.vcipMessage = this.resGetVcipStatus.message;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.vcipAccepted = true;
        // this.freezeIfVCIPCompleted = true;
      }
      if (this.resGetVcipStatus.showBtnOnStatus) {
        this.showButtonOnVCIP = true;
      }
    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  async initiateVcip() {
    this.showLoader = true;
    var consumerPANAvailable = this.consumerDetail.paxResponse?.pan;
    if (consumerPANAvailable) {
      this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
        this.showLoader = false;
        var vcipResponse = JSON.parse(JSON.stringify(resp.body));
        let vcipUrl = vcipResponse.mobileAutologinUrl;
        window.open(vcipUrl, '_blank');
      }), error => {
        this.showLoader = false;
        this.toast.error(JSON.stringify(error.error.message));
      });
    } else {
      this.showLoader = false;
      this.toast.error('Please update your profile and initiate your Video-KYC!');
    }
  }

  doOcrPassport() {
    this.showLoader = true;
    this.formData = new FormData();
    this.formData.append('passportFront', this.filePassportFront, this.filePassportFront.name);
    this.formData.append('passportBack', this.filePassportBack, this.filePassportBack.name);

    this.updateProfileService.passportOCR(this.formData).subscribe(result => {
      let response = result.body;
      this.showLoader = false;

      if (response != null && response['response']['result']['passportNumber'] != null &&
        response['response']['result']['passportNumber'].length > 0) {
        this.updateProfileModel.passportNumber = response['response']['result']['passportNumber'];

        this.updateProfileModel.passportFileNumber = response['response']['result']['fileNumber'];
        this.updateProfileModel.passportIssuePlace = response['response']['result']['placeOfIssue'];
        this.updateProfileModel.birthCity = response['response']['result']['placeOfBirth'];
        this.updateProfileModel.passportExpiryDate = response['response']['result']['expiryDate'];
        this.updateProfileModel.passportIssueDate = response['response']['result']['issueDate'];
        this.updateProfileModel.pinCode = response['response']['result']['pincode'];
        this.updateProfileModel.dob = response['response']['result']['birthDate']
        this.updateProfileModel.address = response['response']['result']['address'];

        this.updateProfileModel.passportExpiryDate = { 'year': parseInt(this.updateProfileModel.passportExpiryDate.split("/")[2]), 'month': parseInt(this.updateProfileModel?.passportExpiryDate?.split("/")[1]), 'day': parseInt(this.updateProfileModel?.passportExpiryDate?.split("/")[0]) };
        this.updateProfileModel.passportIssueDate = { 'year': parseInt(this.updateProfileModel.passportIssueDate.split("/")[2]), 'month': parseInt(this.updateProfileModel.passportIssueDate.split("/")[1]), 'day': parseInt(this.updateProfileModel.passportIssueDate.split("/")[0]) };
        this.updateProfileModel.dob = { 'year': parseInt(this.updateProfileModel.dob.split("/")[2]), 'month': parseInt(this.updateProfileModel.dob.split("/")[1]), 'day': parseInt(this.updateProfileModel.dob.split("/")[0]) };

        this.toast.success("Yay! Your Passport has been uploaded successfully!");
      } else {
        this.toast.error('Please upload valid passport documents!');
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  downloadUploadedSignature(){ 
    this.viewLocalFileUtils.viewLocalDocument(this.uploadedSignature);
  }

}
