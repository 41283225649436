import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';



@Component({
  selector: 'my-features',
  templateUrl: './my-features.component.html',
  styleUrls: ['./my-features.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyFeaturesComponent implements OnInit {
  public showLoader: boolean = false;
 
  public menuItems = [
    {
      'icon': 'feature1.png',
      'title': 'International and Domestic Usage',
      'url': '',
    },
    {
      'icon': 'feature2.png',
      'title': 'Tap & Pay',
      'url': '',
    },
    {
      'icon': 'feature3.png',
      'title': 'Wallet to Wallet Transfer',
      'url': '',
    },
    {
      'icon': 'feature4.png',
      'title': 'Lost Card Protection Up To 3 Lakh Rupees',
      'url': '',
    },
   
    {
      'icon': 'feature5.png',
      'title': 'Safe & Secure Transactions',
      'url': '',
    },
    {
      'icon': 'feature6.png',
      'title': 'Accepted at 100M+ Visa Merchant Outlets',
      'url': '',
    },
    {
      'icon': 'feature7.png',
      'title': 'App Enabled Card Management',
      'url': '',
    },
    {
      'icon': 'feature8.png',
      'title': '24/7 Customer',
      'url': '',
    },
  ];

  constructor(
    public router: Router,
   
  ) { }

  ngOnInit() {
    this.getProfileList();
  }

  goToNewDashboard() {
    this.router.navigate(['/my-card']);
  }

  getProfileList() {
   
  }

 

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  
}
