<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h5 class="card-header-title float-left mb-0">
                            Link your WSFx {{cardData.title}}
                        </h5>
                    </div>
                </div>
                <div class="row p-20">
                    <section class="col-md-5 mt-20">
                       
                        <section >
                            <div class="row">
                                <div class="col-md-12 col-12 mt-0">
                                    <div class="ecard-form ">
                                        <label for="">Kit Numbers (8 Digits*)</label>
                                        <input type="text" class="form-control fs-12" placeholder="" name="kitNumber" [(ngModel)]="kitNumber" maxlength="9" (keypress)="numberOnly($event)"  />
                                    </div>
                                </div>

                                <div class="col-md-12 col-12 mt-10">
                                    <div class="ecard-form1">
                                            <label class="form-label"><b>Date of Birth*</b></label>
                                            <div class="input-group">
                                                <input class="form-control fs-12   border-black" placeholder="yyyy-mm-dd" name="dateofBirth" ngbDatepicker #basicDP3="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"  
                                                    [(ngModel)]="dateOfBirth" name="dateOfBirth" required>
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP3.toggle()" type="button" rippleEffect></button>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    
                                </div>
                                <div class="row" >
                                    <div class="col-md-12 text-right col-3 mt-20 "style="margin-left :10px;">
                                        <button class="btn btn-primary  fs-12" tabindex="4" rippleEffect (click)="openModalForCardLink()">Proceed</button>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="row" >
                                <div class="col-md-12 text-right col-3 mt-20">
                                    <button class="btn btn-primary  fs-12" tabindex="4" rippleEffect (click)="validateCustomerMobile()">Proceed</button>
                                </div>
                            </div>-->
                        </section>

                      
                    </section>

                    <div class="col-md-2 text-center mb-20 fs-14 text-black fw-600">
                        <div class="or-text d-block d-sm-none"><span></span></div>
                        <div class="or-text-lg d-none d-md-block">
                            <span></span>
                            <div></div>
                        </div>
                    </div>
                    
                    <section class="col-md-5 mt-20">
                        <div class="col-md-10">
                            <h5 class="content-header-title float-left mb-0" style="margin-top: -80px; font-size: 14px;    margin-left: -70px;">
                                You can find your Kit Number on the back of card
                            </h5>
                        </div>
                        <div >
                                <img src="assets/images/card/studentCardKitNo.png" class="ecard-img">
                                
                        </div>
                        
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #verifiationCardLinkModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center card-header-title modal-header-title" id="myModalLabel1">
            {{ processType == 'VERIFICATION_OPTION' ? 'Verification' : 'OTP Verification' }}
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">
            <div class="row" *ngIf="processType == 'VERIFICATION_OPTION'">
                <div class="col-12">
                    <p class="text-left">Please Verify to Proceed : Kindly select Mobile Number/ Email ID to receive the OTP</p>
                </div>
                <div class="col-12">
                    <div class="form-check text-left">
                        <input class="form-check-input" type="radio" id="mobileOtp" name="otpOption" value="mobile" [(ngModel)]="otpOption" />
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ maskMobile }}
                        </label>
                      </div>
                      <div class="form-check text-left">
                        <input class="form-check-input" type="radio" id="emailOtp" name="otpOption" value="email" [(ngModel)]="otpOption" />
                        <label class="form-check-label" for="flexCheckChecked">
                          {{ maskEmail }}
                        </label>
                      </div>
                </div>
            </div>
            <div class="row" *ngIf="processType == 'VERIFY_OTP'">
                <div class="col-12 text-left">
                    <p>{{otpSendMessage}}</p>
                </div>
                <div class="col-12">
                    <section id="otp">
                        <div class="inputs">
                          <input type="text" #optField1 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,1,optField2)" (keydown)="otpKeyDownChange($event, 1,'')" [(ngModel)]="optNum1" />
                          <input type="text" #optField2 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,2,optField3)" (keydown)="otpKeyDownChange($event, 2,optField1)"  [(ngModel)]="optNum2" />
                          <input type="text" #optField3 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,3,optField4)" (keydown)="otpKeyDownChange($event, 3,optField2)"  [(ngModel)]="optNum3" />
                          <input type="text" #optField4 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,4,optField5)" (keydown)="otpKeyDownChange($event, 4,optField3)" [(ngModel)]="optNum4" />
                          <input type="text" #optField5 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,5,optField6)" (keydown)="otpKeyDownChange($event, 5,optField4)" [(ngModel)]="optNum5" />
                          <input type="text" #optField6 inputmode="numeric" maxlength="1" class="inputOtp" value="" (input)="otpInputChange($event,6,'')" (keydown)="otpKeyDownChange($event, 6, optField5)" [(ngModel)]="optNum6" />
                        </div>
                    </section>
                </div>
                <div class="col-12 text-right">
                    <a class="resendOtp text-right ng-star-inserted float-right fs-9" (click)="sendOtpForLinkCard()" *ngIf="!showTimer && showResendButton">Resend OTP</a>
                    <div class="float-right fs-9" *ngIf="showTimer">{{displayTimer}}</div>
                    <!-- <button class="btn btn-link" (click)="sendOtpForLinkCard()">Resend OTP</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-12 text-center">
            <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="sendOtpForLinkCard()" *ngIf="processType == 'VERIFICATION_OPTION'">
                Continue
            </button>
            <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="verifyOtpForLinkCard()" *ngIf="processType == 'VERIFY_OTP'">
                Proceed
            </button>
        </div>
    </div>
</ng-template>