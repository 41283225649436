<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FEATURES</h6>
                    </div>
                   
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p><b>WSFx Student Pay Card</b> - The ultimate Forex card for your International Study. This Tech enabled USD-denominated prepaid card lets you spend anywhere in the world with zero cross-currency markups, offering transparency and significant cost savings.</p>
                    <p>The WSFx Student Pay Card is accepted worldwide in over 180 Countries and across 100 million merchant outlets & on international e-commerce sites, ensuring simple, secure and hassle-free Payments. Whether you're travelling or shopping online, experience truly effortless transactions worldwide with WSFx Student Pay Card.</p>
                    
                </div>

                
               
              <div class="benefit">
                <div class="row mt-6">  
                    <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of menuItems"> 
                        <div class="d-flex align-items-center feature-section">
                            <img src="assets/images/newfeatures/{{singleItem.icon}}" style="width: 60px; height: 60px;">
                            <div class="fs-14 fw-600" >
                                {{ singleItem.title }}
                            </div>
                        </div>
                    </div>
                </div>
            
                    <div class="row">
                        <div class="col-md-6 mb-20">
                            <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">BENEFITS</h6>
                        </div> 
                    </div>
                    <div class="row mt-6">  
                        <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of Items"> 
                            <div class="d-flex align-items-center feature-section benefits" (click)="goToFeatures(singleItem)">
                                <img src="assets/images/benefits/{{singleItem.icon}}" style="width: 60px; height: 60px;">
                                <div class="fs-14 fw-600">
                                    {{ singleItem.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="col-md-6 text-left ">
                        <button  class="btn btn-benefit" (click)="goToBackPage()">
                            Back
                        </button>
                        </div>
            </div>
        </div>
    </div>
</div>