import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { Constants } from 'app/shared/constant/constants';
import { OrderItemModel, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationRequestModel, QuotationResponseModel, WalletTransferRequestModel } from '../../models/card.model';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';

@Component({
  selector: 'encashment',
  templateUrl: './encashment.component.html',
  encapsulation: ViewEncapsulation.None
})

export class EncashmentComponent implements OnInit {
  public tncText = "I hereby wish to refund the value of foreign exchange by debiting my WSFx Smart Currency Card. I delcare that the information included in this form is true and correct, and I have read the terms and conditions.";
  public otpForEncashment : any;
  public showLoader :boolean = false;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public currencyItems: any;
  public bankAccountItems = [];
  public showBankDetails: boolean = false;
  public showAddBankAccountForm: boolean = false;

  public cardTransferList = [];
  public cardWalletList = [];
  public cardDetailsObject = [];
  public cardDetailsList = [];
  public kitId: any;

  public quotationCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse = new QuotationResponseModel();
  public orderRequestModel = new OrderRequestModel();
  public orderRequestList = new Array<OrderRequestModel>();
  public quotationCurrencyResponsesList = [];
  public checked: boolean;
  public check: boolean;
  public isChecked: boolean = false;
  public index: any;
  public transferCurrency: any;
  public currencyBalance;
  public quotationCurrencies: QuotationCurrenciesRequestModel;
  public isQuotationAvailable = false;
  public quotationDisplay: any;
  public quotationCurrencyResponse = [];
  public selectedBank: any;
  public selectedBankId: any;
  public transferAmount: any;
  public transferCurrencyList = new Array<WalletTransferRequestModel>();
  public orderRequest : any;
  public consumerDetail : any;
  public paxId : any;
  public encashmentFormURL : any;
  public consumerDetails:any;
  public name:any;
  public cardData;

  constructor(
    public updateProfileService : UpdateProfileService,
    public cardService: CardService,
    public toast: ToastrService,
    private masterDataService: MasterDataService,
    private router: Router
  ) {
    this.getPaxId();
   }

  ngOnInit() {
    this.quotationDisplay =  JSON.parse(sessionStorage.getItem(ConstantsKey.QUOTATION_RESPONSE_FOR_ENCASHMENT));
    this.orderRequest =  JSON.parse(sessionStorage.getItem(ConstantsKey.ORDER_REQUEST));
    console.log("QUOTATION >>>>",this.quotationDisplay);
    this.quotationCurrencyResponsesList = this.quotationDisplay["quotationCurrencyResponses"];
    var consumerData = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerDetails = JSON.parse(consumerData);
    // this.cardData = JSON.parse(sessionStorage.getItem(ConstantsKey.STUDENT_CARD_DATA));
    this.cardData = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARDS));
    
    console.log("CARD DATA FOR CASHOUT ------------------------------- ", this.cardData);

    // if(this.cardData.cardType == 'studentPayCard') {
      this.tncText.replace("Smart Currency Card", this.cardData.title);
    // }

    if (this.consumerDetails['firstName'] != null &&
    this.consumerDetails['firstName'] != "") {
      this.name = this.consumerDetails['firstName'];
        } else if (this.consumerDetails['lastName'] != null &&
      this.consumerDetails['lastName'] != "") {
          this.name = this.consumerDetails['lastName'];
      }
      this.tncText="I "+this.name+" hereby wish to refund the value of foreign exchange by debiting my WSFx "+ this.cardData.title +". I declare that the information included in this form is true and correct, and I have read the";
  }
   
  getPaxId() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.paxId = this.consumerDetail.paxResponse.id; 
      console.log("PAX ID>>>>",this.paxId);
    });
  }

  otpRequestForEncashment(isChecked){
    if(isChecked){
    this.cardService.getEncashmentOTP({}).subscribe(resp =>{
        this.showLoader = true;
        var responseJSON = resp.body;
        this.toast.success('OTP sent successfully !');
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  }
 
  validateOTP(){
    if(this.isChecked){
    this.showLoader = true;
    this.cardService.validateEncashmentOTP(this.otpForEncashment, {}).subscribe(resp => {
      var responseJSON = resp.body;
      this.toast.success("Your encashment request is placed successfully. The amount will be transferred to your bank account within 1-2 business days.");
      console.log("RESPONSE >>" , responseJSON);
      this.showLoader = false;
      this.router.navigate(['/my-card']);
    },  error =>{
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }else{
    this.toast.error("Please accept terms and conditions");
  }
  }

  downloadEncashmentForm(){
  let cardVendor = this.cardData.cardVendor;
  this.cardService.getEncashmentForm(this.orderRequest?.bankId, this.paxId, this.quotationDisplay.quotationSnapshotId,cardVendor).subscribe(resp=>{
    console.log("FORM", resp);
    var response = resp.body;
    this.encashmentFormURL = response['url'];
    window.open(this.encashmentFormURL, "_blank");
    }, error => {
      this.toast.error(error.error.message);
    });

  }
}
