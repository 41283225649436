<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body referer-body">
                <div class="row p-md-1 p-20 pt-0">
                    <div class="col-12 col-md-6 order-2 order-md-0 pt-md-5">
                        <div class="container-fuild">
                            <div class="row d-xs-block d-sm-block d-md-none">
                                <div class="col-12 mt-2 p-0">
                                    <div class="referral-code-mobile p-1">
                                        <label class="mb-1">Your Referral Code</label>
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control" value="{{referralCode}}" />
                                            <div class="input-group-append">
                                                <button class="btn btn-copy btn-copy-icon" (click)="copyMessage(referralCode,'CODE')" type="button"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="18" height="20"
                                                        viewBox="0 0 22 24" fill="none">
                                                        <path
                                                            d="M6.41663 9.667C6.41663 8.95967 6.6742 8.28131 7.13268 7.78115C7.59116 7.28099 8.21299 7 8.86138 7H16.8052C17.1263 7 17.4442 7.06898 17.7408 7.20301C18.0374 7.33704 18.3069 7.53349 18.5339 7.78115C18.7609 8.0288 18.941 8.32281 19.0639 8.64638C19.1867 8.96996 19.25 9.31676 19.25 9.667V18.333C19.25 18.6832 19.1867 19.03 19.0639 19.3536C18.941 19.6772 18.7609 19.9712 18.5339 20.2189C18.3069 20.4665 18.0374 20.663 17.7408 20.797C17.4442 20.931 17.1263 21 16.8052 21H8.86138C8.54033 21 8.22242 20.931 7.92581 20.797C7.6292 20.663 7.35969 20.4665 7.13268 20.2189C6.90566 19.9712 6.72558 19.6772 6.60272 19.3536C6.47986 19.03 6.41663 18.6832 6.41663 18.333V9.667Z"
                                                            stroke="white" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M3.67767 16.737C3.39625 16.5626 3.16218 16.31 2.99924 16.0049C2.83631 15.6998 2.75031 15.3531 2.75 15V5C2.75 3.9 3.575 3 4.58333 3H13.75C14.4375 3 14.8115 3.385 15.125 4"
                                                            stroke="white" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg> &nbsp;&nbsp;Copy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2 p-0">
                                    <div class="share-icons-mobile p-1">
                                        <label class="mb-1">Share Code Directly </label>
                                        <div class="share-icon">
                                            <img (click)="goToLink('whatsapp')" src="./../../../../../assets/images/refer-and-earn/Whatsapp.webp" />
                                            <img (click)="goToLink('mail')" src="./../../../../../assets/images/refer-and-earn/Gmail.webp" />
                                            <img (click)="goToLink('insta')" src="./../../../../../assets/images/refer-and-earn/Instagram.webp" />
                                            <img (click)="goToLink('facebook')" src="./../../../../../assets/images/refer-and-earn/Facebook.webp" />
                                        </div>
                                    </div>
                                </div>                            
                            </div>

                            <div class="row" style="width: 100%;">
                                <div clas="col-md-12">
                                    <div class="todo-timeline mt-0">
                                        <div class="row">
                                            <div class="col-md-12 d-none d-md-block">
                                                <h4 class="content-header-title pl-1">Refer & Earn in 4 Easy Steps</h4>
                                            </div>
                                            <div class="col-12">
                                                <ul class="v-timeline">
                                                    <li class="col d-flex">
                                                        <div class="timeline-icon">
                                                            <img src="./../../../../../assets/images/refer-and-earn/add-person-2.webp"
                                                                class="timeline-img" />
                                                        </div>
                                                        <div class="timeline-text flex-column pl-2 pt-1">
                                                            <h1 class="top-bold-label">Sign Up</h1>
                                                            <p class="mid-paragraph">Join our referral program using
                                                                WSFx Global Pay app or wsfx.in</p>
                                                        </div>
                                                    </li>
                                                    <li class="col d-flex">
                                                        <div class="timeline-icon">
                                                            <img src="./../../../../../assets/images/refer-and-earn/download-2.webp"
                                                                class="timeline-img" />
                                                        </div>
                                                        <div class="timeline-text flex-column pl-2 pt-1">
                                                            <h1 class="top-bold-label">Get Referral Code</h1>
                                                            <p class="mid-paragraph">After signing up, you will receive
                                                                a unique referral code.</p>
                                                        </div>
                                                    </li>
                                                    <li class="col d-flex">
                                                        <div class="timeline-icon">
                                                            <img src="./../../../../../assets/images/refer-and-earn/share-2.webp"
                                                                class="timeline-img" />
                                                        </div>
                                                        <div class="timeline-text flex-column pl-2 pt-1">
                                                            <h1 class="top-bold-label">Share</h1>
                                                            <p class="mid-paragraph">Share your unique referral code
                                                                with friends and family.</p>
                                                        </div>
                                                    </li>
                                                    <li class="col d-flex">
                                                        <div class="timeline-icon last-icon">
                                                            <img src="./../../../../../assets/images/refer-and-earn/reward-2.webp"
                                                                class="timeline-img" />
                                                        </div>
                                                        <div class="timeline-text flex-column pl-2 pt-1">
                                                            <h1 class="top-bold-label">Earn Rewards</h1>
                                                            <p class="mid-paragraph">Earn credit points that you can
                                                                redeem for vouchers when your friend successfully
                                                                completes their first transaction.</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="col-md-6 refer-earn-banner order-0 order-md-2 p-md-5">
                        <div class="row">
                            <img class="img-fluid responsive-banner" src="./../../../../../assets/images/refer-and-earn/refer-and-earn-banner.webp" />
                            <!-- <img class="img-fluid d-none d-sm-block d-xs-block d-md-none" src="./../../../../../assets/images/refer-and-earn/Mobile Banner.webp" />                             -->
                        </div>
                    </div>
                </div>


                <div class="row p-20 pt-0 d-none d-md-block">
                    <div class="container d-flex referer-code-section">
                        <div class="">
                            <label class="share-text">Your Referral Code</label>
                        </div>
                        <div class="">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control referer-code-input" value="{{referralCode}}" readonly />
                                <div class="input-group-append">
                                    <button class="btn btn-copy btn-copy-icon" type="button" (click)="copyMessage(referralCode,'CODE')"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="20"
                                            viewBox="0 0 22 24" fill="none">
                                            <path
                                                d="M6.41663 9.667C6.41663 8.95967 6.6742 8.28131 7.13268 7.78115C7.59116 7.28099 8.21299 7 8.86138 7H16.8052C17.1263 7 17.4442 7.06898 17.7408 7.20301C18.0374 7.33704 18.3069 7.53349 18.5339 7.78115C18.7609 8.0288 18.941 8.32281 19.0639 8.64638C19.1867 8.96996 19.25 9.31676 19.25 9.667V18.333C19.25 18.6832 19.1867 19.03 19.0639 19.3536C18.941 19.6772 18.7609 19.9712 18.5339 20.2189C18.3069 20.4665 18.0374 20.663 17.7408 20.797C17.4442 20.931 17.1263 21 16.8052 21H8.86138C8.54033 21 8.22242 20.931 7.92581 20.797C7.6292 20.663 7.35969 20.4665 7.13268 20.2189C6.90566 19.9712 6.72558 19.6772 6.60272 19.3536C6.47986 19.03 6.41663 18.6832 6.41663 18.333V9.667Z"
                                                stroke="white" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path
                                                d="M3.67767 16.737C3.39625 16.5626 3.16218 16.31 2.99924 16.0049C2.83631 15.6998 2.75031 15.3531 2.75 15V5C2.75 3.9 3.575 3 4.58333 3H13.75C14.4375 3 14.8115 3.385 15.125 4"
                                                stroke="white" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg> &nbsp;&nbsp;Copy</button>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <label class="share-text">Share Code Directly</label>
                        </div>
                        <div class="share-icon">
                            <img (click)="goToLink('whatsapp')" src="./../../../../../assets/images/refer-and-earn/Whatsapp.webp" />
                            <img (click)="goToLink('mail')" src="./../../../../../assets/images/refer-and-earn/Gmail.webp" />
                            <img (click)="goToLink('insta')" src="./../../../../../assets/images/refer-and-earn/Instagram.webp" />
                            <img (click)="goToLink('facebook')" src="./../../../../../assets/images/refer-and-earn/Facebook.webp" />
                        </div>
                    </div>
                </div>

                <div class="row p-20">
                    <div class="col-md-6 p-0">
                        <h2 class="txn_slab_1">You Earn</h2>
                        <img src="./../../../../../assets/images/refer-and-earn/txn_slab_1.webp"
                            class="img-fluid img-you-earn" />
                    </div>
                    <div class="col-md-6 p-0 mt-10 mt-md-0">
                        <h2  class="txn_slab_2">You Friend Gets
                        </h2>
                        <img src="./../../../../../assets/images/refer-and-earn/txn_slab_2.webp"
                            class="img-fluid img-friend-get" />
                    </div>
                </div>

                <div class="row p-20 term-cond">
                    For detailed Terms & Conditions &nbsp;<button type="button" class="btn btn-link tc-link p-0 m-0" (click)="openModalForTermsAndCondition(TCModal)">click here.</button>
                </div>

            </div>
        </div>
    </div>
            
        <ng-template #TCModal let-modal>            
            <div class="modal-header">
              <h4 class="modal-title tc-heading"><b>Terms & Conditions</b></h4>
              <button type="button" class="close" (click)="closeTCModal()">&times;</button>
            </div>
        
            <div class="modal-body tc-content">
                <p>Users who refer their friends to WSFx Global Pay Refer and Earn Program ("Program") will henceforth be called “Referrers” and the friends who join the Program using the referrer’s code or link will be called “Referees”</p>
                <p>Participation in the Program automatically implies an agreement to the following terms.</p>

                <ol type="1">
                    <li>Eligibility: 	Program is open to registered WSFx Global Pay users eligible to transact through WSFx Global Pay’s digital platform - wsfx.in, or WSFx Global Pay app</li>
                    <li>Referral Process: Referrers receive a unique referral code from WSFx Global Pay, which they share with Referees. Referees must use this code during their first remittance, card or card & currency transaction through WSFx Global Pay digital platform, wsfx.in, or WSFx Global Pay app. Only Currency transactions are not eligible for this program.</li>
                    <li>Rewards: Upon successful completion of the first transaction by the Referee using the Referrer's code, the Referrer earns credit points. These credit points are redeemable against vouchers for popular brands, subject to availability and terms specified by WSFx Global Pay.</li>
                    <li>Redemption: Credit points earned through the Program can be redeemed for gift vouchers within WSFx Global Pay digital platform, wsfx.in, or WSFx 	Global Pay app</li>
                    <li>Sharing: Referrers can share their unique referral code via email, WhatsApp, Facebook, Twitter, and Instagram, directing referrals to use WSFx Global Pay digital platform, wsfx.in, or WSFx Global Pay app.</li>
                    <li>Program Changes: WSFx Global Pay reserves the right to modify or terminate the Program at its discretion, with notice to participants.</li>
                    <li>Limitation of Liability<br>WSFx Global Pay shall not be liable for any loss, damage, or expense arising from or in connection with participation in the Program, except for liability which cannot be excluded by law.</li>
                </ol>  

                <p>By participating in the Program, the Referrer and Referee agree to indemnify and hold harmless WSFx Global Pay against any claims or liabilities arising out of their participation.</p>

                <ol type="a">
                    <li>General Referral Terms:</li>
                    <li>All the Referrer and Referees must be a resident of India.</li>
                    <li>WSFx Global Pay reserves the right to disqualify participants for misuse of 	referral codes or violations of Program terms.</li>
                    <li>All Referrers and Referees must comply with all the terms of the 	Program. Referrals will be counted as successful, and will be eligible for rewards if the referrals are in accordance with the terms of Program.</li>

                    <li>One person can be referred to the Program only once. In case two or more people refer the same person to join, the referral will be counted for the user whose referral code or link has been used.</li>
                    <li>Open to WSFx Global Pay customers eligible to send remittances.</li>
                    <li>Participants share a unique referral code for new remittance users.</li>


                    <li>Referrers earn points post successful remittance by the referee.</li>
                    <li>Referees will get an instant discount on their FIRST remittance transaction only.</li>
                    <li>Points can be redeemed for gift vouchers or remittance discount.</li>
                    <li>WSFx Global Pay reserves the right to verify and adjust rewards for legitimate referrals.</li>
                    <li>WSFx may modify or end the program at its 	discretion.</li>
                    <li>Participation implies understanding and agreement with these terms.</li>

                </ol>  

                <p>Governing 	Law: These Terms and any disputes arising out of or in 	connection with them shall be governed by and construed in accordance with the laws of India, without regard to its conflict of laws principles and shall have exclusive jurisdiction of the courts of Mumbai, India.</p>

            </div>
            
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->
            
        </ng-template>
</div>