import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { CardService } from 'app/modules/card/services/card.service';
import { CartItemCreateRequestList, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationCurrencyResponsesModel, QuotationRequestModel, QuotationResponseModel } from 'app/modules/card/models/card.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'app/modules/order/services/order.service';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import Swal from 'sweetalert2';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';
import { SupportService } from 'app/modules/support/services/support.service';
import { filter } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'newcard',
    templateUrl: './newcard.component.html',
    styleUrls: ['./newcard.component.scss'],
    // encapsulation: ViewEncapsulation.None
  })
  export class NewCardComponent implements OnInit {

  public showSelectedCard: boolean = true;
  public cashCurrencyList: any;
  public forexCardCurrencyList: any;
  public currencySelected: any;
  public currencySelectedCash: any;
  public currencyAmount = 0;
  public currencyAmountReceived = 0;
  public tcsPaymentSource = 'OWN';
  public quotationResponse: any;
  public quotationSnapshotId: any;
  public currencyAmountCash = 0;
  public cartColumns: string[] = ['product', 'currency', 'amount', 'rateINR', 'amountINR', 'action'];
  public loading = true;
  public loadingText: string = '';
  public margingChargesString: string = '0.0';
  public cardDetailsList = new Array<any[]>();
  public cardDetailsObject = new Array<any[]>();
  public kitId: any;
  public cardNumber: string;
  public consumerPanSelf: string;
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  // public quotationResponse = new QuotationResponseModel();
  public orderRequestList = new Array<OrderRequestModel>();
  public orderRequestModel: OrderRequestModel;
  public quotationRequestModel: QuotationRequestModel;
  public cartEditAddStatus = 0;

  public clockDisplay: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public duration: any;
  public seconds = "00";
  public minutes = "00";

  public editCardCurrency = false;
  public editCashCurrency = false;
  dobMaxDate: any;
  isPANCBChecked: boolean=false;
  panVerificationErrMsg: string;
  showErrorModal: boolean;
  public proceedWithoutVCIPCompletion = 'false';
  public cashLimitErrorMsg: string;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;
  @ViewChild('deleteConfirmationDailog') deleteConfirmationDailog: TemplateRef<any>;
  @ViewChild('vcipCompleteOrNotModal') vcipCompleteOrNotModal: TemplateRef<any>;
  @ViewChild('vcipModalNewUser') vcipModalNewUser: TemplateRef<any>;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('errorModal') errorModal: TemplateRef<any>;
  @ViewChild('EditCardModal') EditCardModal: TemplateRef<any>;

  
  @ViewChild('requestForexCardModal') requestForexCardModal: TemplateRef<any>;
  @ViewChild('succesForRegisterStudentCard') succesForRegisterStudentCard: TemplateRef<any>;


  public modalReference: any;

  public currencyCodeReload: string;
  public signature: any;
  public resGetVcipStatus: any;
  public isFromReload: boolean = false;
  public isCartEmpty: boolean = false;
  public branchAddressList = [];
  public branchId: string ;
  public isProfileComplete: boolean = false;
  public isVcipComplete: boolean = false;
  public isPANAvailable: boolean = false;
  public isOrderWithoutVcipComplete: boolean = false;
  public allowCashCurrency: boolean = true;
  public updateProfileModel = new UpdateProfileModel;


  // TRIP DETAILS
  public inTrip = false;
  public departureDate = new Date();
  public returnDate = new Date();
  public minDeparDate: Date;
  public minReturnDate: Date;
  public ssoResponseData: any;
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public rateCalculatorResponse: any;
  public country: any;
  public countries = [];
  public clockDisplayPer = 0;
  public orderCartType = 'CASH_PURCHASE';

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public progressWidth: any;
  public deleteItemMesg: string;
  public consumerData: any;
  public deleteItemIndex: any;
  public progressWidthInPercentage: number = 0;
  public currencyItems: any;
  public consumerDetail: any;
  public orderListItems: any;
  public paxId:any;
  public showLoader: boolean = false;

  public currencySelectedObj: any;
  public currencySelectedCashObj: any;
  public currencyCardAmount: any;
  public currencyCode: any;
  public cardLinkedAlready: boolean=false;
  isVCIPNow: boolean = false;
  selectedBranchAddress: any;

  public cityItemList = Constants.addressCityList;
  public city = '';
  public email = '';
  public firstName = '';
  public lastName = '';
  public mobileNo = '';
  public isApplyForCard: boolean = false;
  public allCard: any;
  public isSmartCardAvl;
  public isStudentPayAvl;
  public isTravelPayAvl;
  public isGlobalPayLink;
  public isSmartCardLink;
  public isStudentPayLink;

  public redirectFrom: string = "";
  public actionType: string = "";  
  public ssoData: any;

  public cardType: string = "ALL_CARD";

  public reqCurrencyAmount: any;
  public previousUrl: string = "";
  public cardName: string = "";
  public webActionType: string = "";
  public selectedCard;
  public isDefaultCurrencyAmt:boolean = false;

  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cardService: CardService,
    private profileService: UpdateProfileService,
    private orderService: OrderService,
    public toast: ToastrService,
    public modalService: NgbModal,
    public gtmPushService: GTMDataLayerService,
    public supportSerivce: SupportService,
    private location: LocationStrategy

  ) {
    //this.getSessionData();
    //history.pushState(null, null, window.location.href);  
    //this.location.onPopState(() => {
      //history.pushState(null, null, window.location.href);

    //}); 
  }

  ngOnInit() {
    this.getLocalData();

    this.ssoResponseData = localStorage.getItem(ConstantsKey.SSO_RESP);
    let prevPage = sessionStorage.getItem(ConstantsKey.PREV_PAGE_URL);

    if((prevPage == '/cards' || prevPage == '/view-cart')) {
      // this.cardType = sessionStorage.getItem(ConstantsKey.MY_CARD_CHOOSE);
      
      this.selectedCard = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARDS));
      this.cardType = this.selectedCard.cardType;
      this.cardName = this.selectedCard.title;
      
      this.getCurrencies();
      this.getRateRange();
      this.convertAmount();    
    } else if (this.ssoResponseData) {
      this.selectedCard = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARDS));
      this.ssoResponseData = JSON.parse( this.ssoResponseData);
      this.currencyAmount = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.currencySelected = this.ssoResponseData['requiredCurrencyCode'];
      this.redirectFrom = this.ssoResponseData['originPage'];
      this.webActionType = this.ssoResponseData['actionType'];

      this.cardType = this.selectedCard.cardType;
      this.cardName = this.selectedCard.title;

      //this.getAllCardDetails('website');
      this.getCurrencies();
      this.getRateRange();
      this.convertAmount();
    } else {

      // this.cardType = this.ssoResponseData["cardType"];
      this.convertAmount();
    }

    //this.getSessionData();          temp-comment
    // if (this.isFromReload == true) {
    //   this.checkAndFixCurrencyAddBalance();
    // }

//temp-comment
    //this.getAllCardDetails('Dashboard');
    //this.convertAmount();
    //this.getCurrencies();
    this.getProfileDetails();


    // this.getCardDetails();
    this.getPaxList();
    this.timer();
  }
  openModalForEditCart() {
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
  }
  getSessionData() {
    
    //this.redirectFrom = localStorage.getItem(ConstantsKey.CARD_LOGIN_ORIGIN);
    //this.actionType = localStorage.getItem(ConstantsKey.CARD_ACTION);

     

    this.cartCurrenciesList = JSON.parse(localStorage.getItem('cartCurrenciesList'));
    if (this.cartCurrenciesList == null) {
      console.log("cartIsEmpty");
      //this.isCartEmpty = true;
       this.cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
       this.quotationRequestModel = new QuotationRequestModel();
        this.quotationRequestModel.sellType = Constants.sellTypes[0];
        this.quotationRequestModel.partnerId = Constants.partnerId;
        let quotationCurrencies = new QuotationCurrenciesRequestModel();
        quotationCurrencies.currencyAmount = this.currencyAmountReceived;
        quotationCurrencies.currencyCode = this.currencySelected;
        quotationCurrencies.product = Constants.productsEnum[1];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';
        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
     // this.getRateRange();
      this.convertAmount();
      this.callQuotation();

    } else {
          console.log("cartIsotEmpty");

      if (this.cartCurrenciesList != null && this.cartCurrenciesList.length > 0) {
        this.callQuotation();
      }
      this.currencySelected = this.cartCurrenciesList[0].currencyCode;
      this.currencyCode = this.currencySelected;
      console.log('this.currencyCode', this.currencyCode);
    }
  }
  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);
    console.log("this.consumerData ", this.consumerData);
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
      if(this.selectedCard.cardType == 'studentPayCard' || this.selectedCard.cardType == 'travelPayCard') {
        this.currencyItems = this.currencyItems.filter(obj => obj.currencyCode == 'USD');
      }
      console.log('SINGLE RATE >> ', this.currencyItems);
      if (this.ssoResponseData) {
        if (this.currencyAmount) {
        }
      }
    });
  }

  convertAmount() {

    //let localData = localStorage.getItem(ConstantsKey.SSO_RESP);

      if(!this.currencyAmount && !this.isDefaultCurrencyAmt) {
        this.currencyAmount = 2000;
      }
  
      this.isDefaultCurrencyAmt = true;

      if(!this.currencySelected) {
        this.currencySelected = 'USD';
      }


    if (this.currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(this.currencyAmount, this.currencySelected, Constants.sellTypes[0],Constants.productsEnum[0]).subscribe(resp => {
        let rateResponse = resp.body;
        this.rateCalculatorResponse = rateResponse["cardRateResponse"];
        var cardRateCalculatorObject = {
          "requiredCurrencyCodeAmount": this.currencyAmount,
          "requiredCurrencyCode": this.currencySelected,
        }
        this.showErrorModal = false;
        //localStorage.setItem(ConstantsKey.SSO_RESP, JSON.stringify(cardRateCalculatorObject));
        console.log('this.rateCalculatorResponse_+_+', this.rateCalculatorResponse);
       //this.createQuotation();
       this.callQuotation()

      }, err => {
        console.log(err);
        if (err.error.message == 'Buying more than 25000$ or equivalent is not allowed for consumer.') {
          this.showErrorModal = true;
          this.cashLimitErrorMsg = err.error.message;
              Swal.fire({
              title:"Dear Customer", 
              html: this.cashLimitErrorMsg, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
          //this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        }
      });
    } else {
      this.rateCalculatorResponse = {};
      this.rateCalculatorResponse.inrAmount = "Amount";
    }
  }

  getProfileDetails() {
    this.profileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      this.paxId = this.consumerDetail.paxResponse.id;
      localStorage.setItem(ConstantsKey.PAXID, this.consumerDetail.paxResponse.id);

      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      } else {
        localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());
      }
      if (this.consumerDetail?.paxResponse != null && this.consumerDetail?.paxResponse?.pan) {
        this.isPANAvailable = true;
      }
      this.signature = this.consumerDetail.signature;

      if (this.signature == undefined) {
        this.isProfileComplete = false;
      }
      localStorage.setItem(ConstantsKey.CONSUMER_SIGNATURE, this.signature);
      if (this.consumerDetail?.paxResponse?.pan) {
        localStorage.setItem(ConstantsKey.CONSUMER_PAN, this.consumerDetail?.paxResponse?.pan);
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  async getVCIPStatus() {
    this.profileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      } else {
        this.getOrderList();
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  getOrderList() {
    this.orderService.getListOrderShortSnippets().subscribe((resp => {
      this.orderListItems = resp.body;
      if (this.orderListItems.length > 0) {
        this.isOrderWithoutVcipComplete = true;
        this.openModalForVCIP();
      }
    }), error => {
    });
  }

  initiateVcip() {
    this.modalReference.close();
    this.profileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  proceedWithourVCIPAfterOrder() {
    this.modalReference.close();
    //this.openModalForPANNo();
  }
  proceedWithourVCIP() {
    this.modalReference.close();
    this.isVCIPNow = false;

    if (this.isOrderWithoutVcipComplete) {
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
    } else {
      if (this.isPANAvailable) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      } else {
        this.openModalForPANNo();
      }
    }

  }
  openModalForVCIP() {
    this.modalReference = this.modalService.open(this.vcipCompleteOrNotModal, { size: 'xl', centered: true });
  }
  openModalForVCIPNewUser() {
    this.modalReference = this.modalService.open(this.vcipModalNewUser, { centered: true });
  }
  openModalForPANNo() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }
  openErrorModal() {
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  openAmountLimitModal(){
    this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
  }
  checkAndFixCurrencyAddBalance() {
    var addBalanceCurrency = localStorage.getItem(ConstantsKey.CARD_ADD_BALANCE_CURRENCY);
    if (addBalanceCurrency != null) {
      var addBalanceCurrencyObj = JSON.parse(addBalanceCurrency);
      this.currencyCode = addBalanceCurrencyObj.currencyCode;
      this.currencySelected = addBalanceCurrencyObj.currencyCode;
      this.currencySelectedCash = addBalanceCurrencyObj.currencyCode;
    }
  }

  ngOnDestroy() { clearInterval(this.interval); }

  backClick() {
      this.router.navigate(['/currency-notes-landing']);
  }

 

  getSelectedCurrencyForAddBalance() {
    this.activatedRoute.params.subscribe(params => {
      let currencyCodeReload = params['currencyCode'];
      if (currencyCodeReload) {
        this.isFromReload = true;
      }

      if (currencyCodeReload != 'None' && currencyCodeReload) {
        this.cashCurrencyList.filter((item, index) => {
          if (item.currencyCode === currencyCodeReload) {
            this.currencySelected = item;
          }
        });
      }
    });
  }

 

 
  
 
  selectDeliveryOfficeAddress(chooseOfficeAddress) {
    this.selectedBranchAddress = chooseOfficeAddress;
    this.branchId = this.selectedBranchAddress.branchId;    
  }


  

  

  getSelectedCurrencyObj(currencyCode: string,product) {
    this.showLoader = true;

    var returnCurrencyObj: any;
    if (this.currencySelectedCash) { 

    }
    if (product== Constants.productsEnum[0]) {
              this.forexCardCurrencyList.forEach(singleCurreny => {
              if (singleCurreny.currencyCode == currencyCode) {
                returnCurrencyObj = singleCurreny;
              }
            });
    } else {
                this.cashCurrencyList.forEach(singleCurreny => {
            if (singleCurreny.currencyCode == currencyCode) {
              returnCurrencyObj = singleCurreny;
            }
          });
    }
    
    return returnCurrencyObj;
  }


 

  
  
    
  

  editCartItem(singleCartCurrency) {
    this.cashCurrencyList.filter((singleItem, index) => {
      if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[0]) {
        this.currencySelected = singleItem;
        this.currencyAmount = singleCartCurrency.currencyAmount;
        this.editCardCurrency = true;
      }
      if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[1]) {
        this.currencySelectedCash = singleItem;
        this.currencyAmountCash = singleCartCurrency.currencyAmount;
        this.editCashCurrency = true;
      }
    });
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

   callQuotation() {
    this.showLoader = true;
    //this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
    if (this.currencySelected) {
      if (this.currencyAmount && this.currencyAmount >= 0) {
        let orderRequestList = [];
        let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
        singleRequestCurrency.currencyAmount = this.currencyAmount;
        singleRequestCurrency.currencyCode = this.currencySelected;
        singleRequestCurrency.product = "FOREXCARD";
       // singleRequestCurrency.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR)

        singleRequestCurrency.purpose =  'PRIVATE_VISIT';
        orderRequestList.push(singleRequestCurrency);

        this.quotationRequest.sellType = Constants.sellTypes[0];
        this.quotationRequest.partnerId = Constants.partnerId;
        this.quotationRequest.nostroChargeType = "REMITTER"
        this.quotationRequest.quotationCurrencies = orderRequestList;

        this.quotationRequest.pan = this.consumerPanSelf;
        this.quotationRequest.tcsPaymentSource = this.tcsPaymentSource;

        if (this.quotationResponse) {
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }

        this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe((respQuote) => {
          this.showLoader = false;

          this.quotationResponse = respQuote.body;
          this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
          this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }, error => {
          this.showLoader = false;
          if (error.status==200 ) {
                this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
                this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
                this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
          } else {
                this.toast.error(error.error.message);

          }
        });

      } else {
        this.showLoader = false;
        this.toast.error("Please fill in the currency amount.");
      }
    } else {
      this.showLoader = false;
      this.toast.error("Please fill in the currency");
    }
  }

  createQuotation() {
    
    let orderRequestList = [];
    this.showLoader = false;
    if (this.quotationRequest.quotationCurrencies.length > 0) {
      
        for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
          let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
          singleRequestCurrency.currencyAmount = this.quotationResponse.quotationCurrencyResponses[i].currencyAmount;
          singleRequestCurrency.currencyCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
          singleRequestCurrency.product = this.quotationResponse.quotationCurrencyResponses[i].product;
          //singleRequestCurrency.bidRate = this.quotationResponse.quotationCurrencyResponses[i].rate;
          singleRequestCurrency.sellType = Constants.sellTypes[0];
          singleRequestCurrency.purpose = "PRIVATE_VISIT";
          singleRequestCurrency.nostroChargeType = "REMITTER"
          singleRequestCurrency.branchId = this.branchId;

          orderRequestList.push(singleRequestCurrency);
        }
        if (this.quotationResponse?.quotationSnapshotId) {
          localStorage.setItem(this.quotationResponse.quotationSnapshotId, JSON.stringify(this.quotationResponse));
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }
        this.quotationRequest.calculateTcs = true;
        this.stepperDataCardModel.quotationResponse = this.quotationResponse;
        this.stepperDataCardModel.quotationRequest = this.quotationRequest;
        this.stepperDataCardModel.cartType = this.orderCartType;

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

        this.cardService.cartItemPost(orderRequestList).subscribe(response => {
          
          let eventObj = {
            'name': 'web_currency_buy_order_start',
            'event': 'web_currency_buy_order_start',
            'number': this.consumerDetail.mobile,
            'amount': orderRequestList[0].currencyAmount,
            'currency': orderRequestList[0].currencyCode
          };
          this.gamoogaEvent(eventObj);
          
          sessionStorage.setItem("fromProduct", Constants.productsEnum[0]);
          //this.router.navigate(['/card-stepper']);
          // this.router.navigate(['/view-cart']);
          this.proceedToBuySelectedCard(this.cardType);
        }, error => {
          this.toast.error(error.error.message);
        });
      
    } else {
      this.toast.error('Please enter amount to proceed!');
    }

  }

  clearCart() {
    if (this.cardDetailsList.length > 0) {
      this.orderCartType = 'CARD_RELOAD'
    }

    this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
    });
  }

  navigateNext() {
    sessionStorage.setItem("fromProduct",Constants.productsEnum[0]);
    this.router.navigate(['/card-stepper']);
  }
  checkVcip(){
    if ( !this.isCartEmpty ) {
        this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
         if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {         
            sessionStorage.setItem("showVCIPModalForCashFlow","openModalForVCIPNewUser");
            this.openModalForVCIPNewUser();
        } else{
          this.proceedToBuySelectedCard(this.selectedCard.card);
              //this.cartInitiate();
        }
      } else {
        this.toast.error("Oops...Your cart is empty");
        }
  }
  getCurrencies() {
      this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE"
   // this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[0];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
       if (this.ssoResponseData) {
        if (this.currencyAmount) {
          this.callQuotation();
        }
      }
      this.removeCurrency('SEK'); 
      this.removeCurrency('DKK');
      this.removeCurrency('INR'); 
      this.removeCurrency('NOK');

    });
    
  }
  removeCurrency(doc){
      this.currencyItems.forEach( (item, index) => {
        if(item['currencyCode'] === doc) this.currencyItems.splice(index,1);
      });
    }
  

   getCardDetails() {
    //this.showLoaderForCardDetails = true;

    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.isSmartCardAvl = true;
        //this.router.navigate(['/my-card']);
        //this.showLoaderForCardDetails = false;
      } else {
        this.router.navigate(['/newcard']);
       // this.showLoaderForCardDetails = false;
      }
    }), error => {
      this.router.navigate(['/newcard']);
      //this.showLoaderForCardDetails = false;
    });;
   }
  async proceedToBuySelectedCard(cardType) {
    this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
    if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null)) {
      this.openModalForVCIPNewUser();
    } else {
      if (cardType != null) {
        if (this.currencyAmount) {
          if (!this.showErrorModal) {
            this.quotationRequestModel = new QuotationRequestModel();
            this.quotationRequestModel.sellType = Constants.sellTypes[0];
            this.quotationRequestModel.partnerId = Constants.partnerId;
            let quotationCurrencies = new QuotationCurrenciesRequestModel();
            quotationCurrencies.currencyAmount = this.currencyAmount;
            quotationCurrencies.currencyCode = this.currencySelected;
            quotationCurrencies.product = Constants.productsEnum[0];
            quotationCurrencies.purpose = 'PRIVATE_VISIT';
            this.cartCurrenciesList.push(quotationCurrencies);
            localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
            sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
            sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
            sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');

            //let selectedCard = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARDS));
            sessionStorage.setItem(ConstantsKey.WSFX_SELECTED_CARDS, JSON.stringify(this.selectedCard));
            


            this.router.navigate(['/view-cart']);
          } else {
            this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
          }
        } else {
          this.toast.error('Please enter amount');
        }
      }else {
          this.toast.error('Please select card');
        }

    }
  }
  validatePanDetails(){
    if(!(this.updateProfileModel.pan!=null )){
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!this.validatePANNo(this.updateProfileModel.pan)) {
      this.toast.error('Please enter valid PAN');
      return false;      
    }    
    if(!(this.updateProfileModel.name!=null )){
      this.toast.error('Please enter name as per PAN');
      return false;
    } 
    if(!this.isPANCBChecked){
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if(!(this.updateProfileModel.dob!=null )){
      this.toast.error('Please select your Date of Birth ');
      return false;
    }
    
    
      return true;
  }
  validatePanAPI() {
    if(this.validatePanDetails()){
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.profileService.validatePAN(this.updateProfileModel.pan, this.updateProfileModel.name, dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if (panResponse.panStatus == 'E') { // && panResponse.fullName != "" && panResponse.dob != ""
        this.updatePAN();
      } else if (panResponse.panStatus != 'E') {
        this.panVerificationErrMsg = "Please enter valid PAN";
        this.openErrorModal();
      } /* else if (panResponse.fullName == "") {
        this.panVerificationErrMsg = "Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if (panResponse.dob == "") {
        this.panVerificationErrMsg = "Please enter valid Date of Birth";
        this.openErrorModal();
      } */
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  }
  updatePAN() {
    this.showLoader = true;
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
    let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob = dateofBirth;

    this.profileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      console.log(resp.body);
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      this.modalReference.close();
      //this.proceedToViewCart();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
        this.showLoader = false;
        this.modalReference.close();
        if(this.isVCIPNow) {
          this.initiateVcip();
        }        
        this.cartInitiate();
        //this.proceedToViewCart();
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }
  cartInitiate() {
      
      this.showLoader = true;
      if (this.cardDetailsList.length > 0) {
        this.orderCartType = 'CASH_PURCHASE'
      }
      this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
        this.createQuotation();
      });
    
  }

  buySelectedCard() {
    this.showSelectedCard = true;
  }

  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          this.callQuotation();
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }

  openSnackBar(message) {
    // this._snackBar.open(message, '', {
    //   duration: 5000,
    //   panelClass: ['green-snackbar'],
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
  }

  getPaxList() {
    this.showLoader = true;

    let consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    console.log("consumerId >>>", consumerId);
    this.profileService.getPaxesList(consumerId).subscribe(result => {
      this.showLoader = false;

      var res = JSON.parse(JSON.stringify(result.body));
      let listPax = res.paxResponses;
      for (let i = 0; i < listPax.length; i++) {
        if (listPax[i]['relationship'] == 'SELF' && listPax[i]['pan']!=null) {
          this.consumerPanSelf = listPax[i]['pan'];
          
        }
      }
    });
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }

  VCIPNow() {
    this.isVCIPNow = true;
    this.openModalForPANNo();
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  gamoogaEvent(obj) {
    this.gtmPushService.dataLayerPushData(obj);
  }
  goToNewFeatures(){
    this.router.navigate(['/newfeatures'])
  }
  goToSmartCurrCardFeatures(){
    this.router.navigate(['/my-features'])
  }
  goToLinkExistingCard(){
    this.router.navigate(['/link-existing-card'])
  }

  goToLinkStudentCardExistingCard(){
    this.router.navigate(['/link-student-card'])
  }



  openModalForRequsetForexCard() {
    this.modalReference = this.modalService.open(this.requestForexCardModal, { centered: true, size: 'lg' });
  }


  openModalForSuccesRegisterCard() {
    this.modalReference = this.modalService.open(this.succesForRegisterStudentCard, { centered: true, size: 'sm' });
  }

  closeModal() {
    this.modalReference.close();
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

  onSubmitRequestForex(event) {
    if(event.valid) {
      let comment = this.firstName + "," + this.lastName + "," + this.mobileNo + "," + this.email + "," + this.city + ",";      
      // this.openModalForSuccesRegisterCard();
      this.closeModal();

      this.supportSerivce.addServiceRequest("BUY STUDENT CARD", comment).subscribe(res => {
        this.openModalForSuccesRegisterCard();
        this.isApplyForCard = true;
        //sessionStorage.setItem(ConstantsKey.STUDENT_CARD_APPLY, this.isApplyForCard.toString());

      }, err => {
          this.toast.error(err.error.message);           
      });
      

      

    } else {
      this.toast.error("Please fill all above field"); 
    }

  }

  getAllCardDetails(redirectFrom) {
    this.cardService.getAllCardDetail().subscribe(resp => {
      this.allCard = resp.body;
      let smartCard = false;
      let isCardFound = false;
      if(this.allCard.length > 0) {

          for(let i=0 ;i < this.allCard.length ;i++) {
            if(this.allCard[i].cardVendor == 'WSFLMCC') {
              smartCard = true;
              this.isSmartCardAvl = true;
              if(this.cardType == "smartCurrencyCard" && (redirectFrom == 'website' || redirectFrom == 'CALCULATOR')) {
                this.router.navigate(['/my-card']);           
                isCardFound = true;
              }
            } else if(this.allCard[i].cardVendor == 'WSFXSTUDENTSCC') {
              this.isStudentPayAvl = true;
              sessionStorage.setItem(ConstantsKey.IS_STUDENT_PAY_CARD,"true");
              if(this.cardType == "studentPayCard" && (redirectFrom == 'website' || redirectFrom == 'CALCULATOR')) {
                this.router.navigate(['/my-card']);
                isCardFound = true;
              }
            } else if(this.allCard[i].cardVendor == 'WSFXTRAVELSCC') {
              if(this.cardType == "travelPayCard" && (redirectFrom == 'website' || redirectFrom == 'CALCULATOR')) {
                this.router.navigate(['/my-card']);
                isCardFound = true;
              }
            } else if(this.allCard[i].cardVendor == 'WSFXGLOBALSCC') {
              if(this.cardType == "globalPayCard" && (redirectFrom == 'website' || redirectFrom == 'CALCULATOR')) {
                this.router.navigate(['/my-card']);
                isCardFound = true;
              }
            }
          }

          if(isCardFound == false && this.webActionType == "LOGIN") {
            this.getSessionData();
          } else if(isCardFound == false && this.webActionType == "LINK-CARD") {
            if(this.cardType == "smartCurrencyCard") {
              this.router.navigate(['/link-existing-card']);
            } else if(this.cardType == "studentPayCard") {
              this.router.navigate(['/link-student-card']);
            } else if(this.cardType == "travelPayCard") {
              this.router.navigate(['/link-student-card']);
              // this.router.navigate(['/my-card']);
            } else if(this.cardType == "globalPayCard") {
              // this.router.navigate(['/my-card']);
            }
          }

      } else {
        this.redirectFrom = this.ssoResponseData.originPage;
        if(this.redirectFrom == "CALCULATOR") {
          this.getSessionData();
        } else if(this.webActionType == "LINK-CARD") {
          if(this.cardType == "smartCurrencyCard") {
            this.router.navigate(['/link-existing-card']);
          } else if(this.cardType == "studentPayCard") {
            this.router.navigate(['/link-student-card']);
          } else if(this.cardType == "travelPayCard") {
            this.router.navigate(['/link-student-card']);
            // this.router.navigate(['/my-card']);
          } else if(this.cardType == "globalPayCard") {
            // this.router.navigate(['/my-card']);
          }
        } else {
        // this.getCardDetails();



        }

      }

      /* if(!smartCard) {
        this.getCardDetails();
      } */

      //this.getCardDetails()cardNumOnCard
    },
    errObj =>  {
      this.toast.error(errObj.error.message);
    });
  }

  /* goToMyCard(card) {
    sessionStorage.setItem(ConstantsKey.MY_CARD_CHOOSE, card);
    this.router.navigate(['/my-card']);
  } */

  getPanName() {

    this.updateProfileModel.pan = this.updateProfileModel.pan.toUpperCase();
    if (this.updateProfileModel.pan && this.updateProfileModel.pan.length == 10 && this.validatePANNo(this.updateProfileModel.pan)) {
      this.showLoader = true;
      this.profileService.getPANName(this.updateProfileModel.pan).subscribe(async resp => {
        console.log(resp.body);
        var panResponse = JSON.parse(JSON.stringify(resp.body));
        this.showLoader = false;
        if(panResponse.panStatus == 'E') {
          if (panResponse.fullName && panResponse.fullName != 'null' && panResponse.fullName != '') {
            this.updateProfileModel.name = panResponse.fullName;
          } else {
            this.updateProfileModel.name = '';
          }
  
          if(panResponse.dob && panResponse.dob != 'null' && panResponse.dob != '') {
            this.updateProfileModel.dob = DateUtils.ngbDateStructDateConverter(panResponse.dob);
          }
        } else if(panResponse.flatPan.status == "Record Not Found in ITD Database/Invalid PAN") {
          this.updateProfileModel.name = '';
          this.updateProfileModel.dob = ''; 
          this.toast.error("Invalid Pan");
        } /* else {
          this.toast.error(panResponse.flatPan.status);
        } */

      }, error => {
        this.showLoader = false;
        this.updateProfileModel.name = '';
        this.updateProfileModel.dob = '';
        this.toast.error(error.error.message);
      });
    }
  }

  async updateCurrencyAmount() {
    if (this.reqCurrencyAmount && this.reqCurrencyAmount != '') {
      // if(this.reqCurrencyAmount < 25000) {
      this.modalReference.close();
      this.showLoader = true;
      //this.inrAmountZero = false;
      this.currencyAmount = this.reqCurrencyAmount
      // this.quotationRequest.quotationCurrencies[0].currencyAmount = this.reqCurrencyAmount;
      // this.getQuotationRequestBody();
      // await this.hitGetQuotationCall();
      await this.callQuotation();
      //this.refreshQuotationCount = this.refreshQuotationCount + 1;
      //console.log("refreshQuotationCount >>", this.refreshQuotationCount);
      this.showLoader = false;
      // } else {
      //   this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
      // }
    } else {
      //this.inrAmountZero = true;
      this.toast.error('Please enter amount');
    }
  }

  cardBuy(cardType) {
    this.cardType = cardType;
    this.convertAmount();
  }

}