import { Component, OnInit, ViewEncapsulation } from '@angular/core' 
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { CurrencyConverterService } from '../services/currency-converter.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'currency-converter',
  templateUrl: './currency-converter.component.html',
  styleUrls: ['../../../../@core/scss/angular/libs/select.component.scss',
              './currency-converter.component.scss'],
  providers:[CurrencyConverterService],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyConverterComponent implements OnInit {
  public showLoader: boolean = false;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public currencyItems : any;
  public surrenderingCurrencyCode : any;
  public requiredCurrencyCode : any;
  public amount : any;
  public currencyConverterResponse : any;
  public convertedValue : any;

  constructor(
     public masterDataService : MasterDataService,
     public currencyConverterService : CurrencyConverterService,
     public toast : ToastrService
  ) { 
    this.requiredCurrencyCode = 'EUR';
    this.surrenderingCurrencyCode = 'USD';
  }

  ngOnInit() { 
    this.getCurrencies();
  }

  getCurrencies() {
    this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = ""
    this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      console.log("currencyItems>>>",this.currencyItems);

    });
  }


  convertCurrency(){
    this.showLoader = true;
    if(this.amount){
      this.currencyConverterService.getCurrencyConverterValues(this.surrenderingCurrencyCode,this.requiredCurrencyCode,this.amount).subscribe(result => {
        this.currencyConverterResponse = result.body;
        this.convertedValue = this.currencyConverterResponse.formattedCurrencyAmountWithTaxes;
      }, error => {
        this.toast.error(error.error.message);
      });
    }else{
      this.toast.error('Please enter amount!');
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
