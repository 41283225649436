<style>
    .input-group-text {
        padding-right: 25px !important;
    }
</style>

<div class="card mt-30">
    <div class="card-body">
        <div class="row">
            <div class="col-md-7">
                <div class="fw-600 fs-18">Currency Notes</div>
                <div class="fs-12">Here are some recommended actions for you </div>

                <div class="col-md-9 mt-20 mb-20">
                    <section>
                        <div class="row card-border" (click)="showBuyCurrencyNotes('BUY')" [ngClass]="activeBuyCurrenyNotes ? 'blue-card-selected' : 'currency-card'">
                            <div class="col-md-4">
                                <img src="assets/images/smartfx-portal-assets/currency-notes/Buy-Currency-Notes.png" class="buy-img">
                            </div>
                            <div class="col-md-8 mt-10">
                                <div class="fs-18 fw-600">Buy Currency Notes</div>
                                <div class="fs-11 mt-5">Click here to Buy foreign currency notes for your upcoming trip.</div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="row  card-border currency-card" (click)="showBuyCurrencyNotes('SELL')" [ngClass]="activeSellCurrencyNotes ? 'blue-card-selected' : 'currency-card'">
                            <div class="col-md-4">
                                <img src="assets/images/smartfx-portal-assets/currency-notes/Sell-Currency-Notes.png" class="sell-card-img">
                            </div>
                            <div class="col-md-8 mt-10">
                                <div class="fs-18 fw-600">Sell Currency Notes</div>
                                <div class="fs-11 mt-5">Click here to Sell your leftover foreign currency after returning from foreign trip.</div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-md-1 dashed-border"></div>
            <div class="col-md-4">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills" style="margin-bottom: 0px !important; ">
                    <li class="nav-item w-100" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#currencyNotes" data-toggle="tab">Currency Notes</a>
                        <ng-template ngbNavContent>
                            <section id="currencyNotes">
                                <div class="row">
                                    <div class="col-md-12 mt-20">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencySelected" (change)='getCurrencyFields()' [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <!-- <input type="number" class="form-control" placeholder="Amount" [(ngModel)]="currencyAmount" name="currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187 && event.keyCode !== 190"
                                                        (keyup)="convertAmount()" /> -->
                                                        <input type="number" class="form-control" placeholder="Amount"(keypress)="keyPressNumbers($event)" [(ngModel)]="currencyAmount" name="currencyAmount"
                                                        #currencyAmountInput="ngModel" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 ">
                                                <div class="input-group  mt-20">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag"  style="height:15px;width: 15px;">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" [value]="rateCalculatorResponse?.inrAmount" readonly />
                                                </div>
                                                <small class="form-text blue-text fs-9 text-right fw-600" *ngIf="rateCalculatorResponse?.rate !=null"> 
                                                    <!-- 1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}} -->
                                                    1 {{ currencySelected }} = {{
                                                        rateCalculatorResponse?.rate }} INR

                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-10">
                                        <span class="fs-18 fw-600">You Pay -  <span class="fs-19 fw-600"> ₹ {{rateCalculatorResponse?.inrAmountWithCharges}}
                                        </span></span>
                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100" (click)="proceedToBuy()">
                                            Book Now
                                        </button>
                                    </div>

                                </div>
                            </section>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="card p-20"></div>
            </div>
        </div>
    </div>
</div>

<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center"> {{cashLimitErrorMsg}}</span><br>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
            Okay </button>
    </div>
</ng-template>
<ng-template #currenyBuyModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">Thanks for choosing WSFx Global Pay for your Forex needs
        </div>
        <div class="text-center">
            Kindly note that the <a><span style="font-weight: 14;font-weight: 700;"> Cash Currency Buy </span> </a> is coming soon to our website
        </div>
        <div class="text-center">
            Please download the
            <a class="link" target="_blank" href="https://play.google.com/store/search?q=wsfx+global+pay&c=apps&hl=en">
                <span class="blue-text"> WSFx Global Pay App </span> </a>
            to place your Cash Currency Buy order
        </div>
    </div>

    <div class="mcol-md-12  text-center">
        <div class="mb-10 fw-500 fs-10 btn btn-primary" (click)="modal.dismiss('Cross click')" style="cursor: pointer;">
            Okay
        </div>
    </div>
</ng-template>
<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipModalNewUser>
    <!-- <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Dear Customer, Your <span class="fw-600 text-black">Video Based Identification Process (VCIP)</span> is pending.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row ">
            <button class="col-md-4 offset-md-1  btn  fs-10 vcip-btn" rippleEffect (click)="VCIPNow()">
                I will complete my VCIP Now
            </button>
            <!-- <div class="col-md-1"></div> -->
            <button class="col-md-4  offset-md-1 btn btn-primary fs-10" rippleEffect (click)="proceedWithourVCIP()">
                I will complete my VCIP Later
            </button>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipCompleteOrNotModal>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="proceedWithourVCIP()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body  fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="font-bold-black  mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p class="font-normal-black">Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p class="font-bold-red">Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="font-bold-black  mb-20">{{vkycTimingText}}</p>

                <p class="font-normal-blue mb-20 ">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <!-- <button class="btn vcip-btn fs-13" (click)="proceedWithourVCIP()"> Schedule your VKYC later</button> &nbsp; &nbsp; -->
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template let-modal #PANDetailModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Customer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" (keypress)="alphaNumberOnly($event)" oninput="this.value = this.value.toUpperCase()" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN"  maxlength="10" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required minlength="10" />
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input"  style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input  class="form-control fs-12"   (click)="basicDP.toggle()" name="dobb" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker"  [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10"  rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
        </div>
</ng-template>