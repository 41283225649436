<div class="content-wrapper container-xxl">
    <div class="content-body">
        <div class="card p-0">
            <div class="card-body p-0">
                <loader *ngIf="showLoader"></loader>
                <div class="row mt-20 mb-20">
                    <div class="col-12 col-md-8 offset-md-2">

                        <swiper class="swiper-container" [config]="config" (indexChange)="onIndexChange($event)" fxFlex="auto">
                            <div class="swiper-slide">
                            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill> -->
                                <!-- <div class="col-12"> -->
                                    <div class="single-card">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/student-pay-card.png" class="newcard-img">
                                        <div class="newcard-text">Student Pay Card</div>
                                        <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                        <div class="newcard-text3"><i>Ideal for Students</i></div>
                                        <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('STUDENT_CARD')" *ngIf="!isStudentPayAvl"> <!-- && !isApplyForCard -->
                                            Buy Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('STUDENT_CARD')" *ngIf="!isStudentPayAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('STUDENT_CARD')" *ngIf="isStudentPayAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('STUDENT_CARD')">Know the Features</div>
                                    </div>
                                <!-- </div> -->

                            <!-- </div> -->

                            </div>
                            <!-- <div class="swiper-slide">
                                <div class="single-card">
                                    <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-travel-pay-card.png" class="newcard-img">
                                    <div class="newcard-text">Travel Pay Card</div>
                                    <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                    <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                    <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('TRAVEL_CARD')" *ngIf="!isTravelPayAvl">
                                        Buy Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('TRAVEL_CARD')"
                                        *ngIf="!isTravelPayAvl">
                                        Link Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('TRAVEL_CARD')" *ngIf="isTravelPayAvl">
                                        Choose Card
                                    </button>
                                    <div class="newcard-text4" (click)="goToFeatures('TRAVEL_CARD')">Know the Features</div>
                                </div>
                            </div> -->
                            <div class="swiper-slide">
                            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill> -->
                                <!-- <div class="col-12"> -->
                                    <div class="single-card">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-global-pay-card.png" class="newcard-img">
                                        <div class="newcard-text">Global Pay Card</div>
                                        <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                        <div class="newcard-text3"><i>Ideal for Business Travellers</i></div>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('GLOBAL_CARD')" *ngIf="!isGlobalPayAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('GLOBAL_CARD')" *ngIf="isGlobalPayAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('GLOBAL_CARD')">Know the Features</div>
                                    </div>
                                <!-- </div>                -->
                            <!-- </div> -->
                            </div>
                            <div class="swiper-slide">
                            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill> -->
                                <!-- <div class="col-12"> -->
                                    <div class="single-card">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/Card-Front.png" class="newcard-img">
                                        <div class="newcard-text">Smart Currency Card</div>
                                        <div class="newcard-text2">Multicurrency Card with <br> INR Wallet</div>
                                        <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                        <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('SMART_CARD')" *ngIf="!isSmartCardAvl"> 
                                            Buy Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('SMART_CARD')" *ngIf="!isSmartCardAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('SMART_CARD')" *ngIf="isSmartCardAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('SMART_CARD')">Know the Features</div>
                                    </div>
                                <!-- </div> -->
                            <!-- </div> -->
                            </div>
                        </swiper>
                

                    </div>



                    <!-- && !isApplyForCard -->
                    <!--  && !isApplyForCard -->
                    <!-- <div class="col-4">




                        <swiper-container loop="true">
                            <swiper-slide>
                                <div class="">
                                    <div class="newcard-border dash-newcard1">
                                    <img src="assets/images/smartfx-portal-assets/buy-forex/student-pay-card.png" class="newcard-img">
                                    <div class="newcard-text">Student Pay Card</div>
                                    <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                    <div class="newcard-text3"><i>Ideal for Students</i></div>
                                    <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('studentPayCard')" *ngIf="!isStudentPayAvl"> 
                                        Buy Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('studentPayCard')" *ngIf="!isStudentPayAvl">
                                        Link Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('studentPayCard')" *ngIf="isStudentPayAvl">
                                        Choose Card
                                    </button>
                                    <div class="newcard-text4" (click)="goToFeatures('studentPayCard')">Know the Features</div>
                                    </div>
                                </div>                            
                            </swiper-slide>
                            <swiper-slide>
                                <div class="">
                                    <div class="newcard-border dash-newcard1">
                                    <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-travel-pay-card.png" class="newcard-img">
                                    <div class="newcard-text">Travel Pay Card</div>
                                    <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                    <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                    <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('travelPayCard')" *ngIf="!isTravelPayAvl"> 
                                        Buy Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('travelPayCard')" *ngIf="!isTravelPayAvl">
                                        Link Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('travelPayCard')" *ngIf="isTravelPayAvl">
                                        Choose Card
                                    </button>
                                    <div class="newcard-text4" (click)="goToFeatures('travelPayCard')">Know the Features</div>
                                    </div>
                                </div>                        
                            </swiper-slide>
                            <swiper-slide>
                                <div class="">
                                    <div class="newcard-border dash-newcard1">
                                    <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-global-pay-card.png" class="newcard-img">
                                    <div class="newcard-text">Global Pay Card</div>
                                    <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                    <div class="newcard-text3"><i>Ideal for Business Travellers</i></div>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('globalPayCard')" *ngIf="!isGlobalPayAvl">
                                        Link Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('globalPayCard')" *ngIf="isGlobalPayAvl">
                                        Choose Card
                                    </button>
                                    <div class="newcard-text4" (click)="goToFeatures('globalPayCard')">Know the Features</div>
                                    </div>
                                </div>                        
                            </swiper-slide>
                            <swiper-slide>
                                <div class="">
                                    <div class="newcard-border dash-newcard1">
                                    <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" class="newcard-img">
                                    <div class="newcard-text">Smart Currency Card</div>
                                    <div class="newcard-text2">Multicurrency Card with INR Wallet</div>
                                    <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                    <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('smartCurrencyCard')" *ngIf="!isSmartCardAvl"> 
                                        Buy Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('smartCurrencyCard')" *ngIf="!isSmartCardAvl">
                                        Link Card
                                    </button>
                                    <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('smartCurrencyCard')" *ngIf="isSmartCardAvl">
                                        Choose Card
                                    </button>
                                    <div class="newcard-text4" (click)="goToFeatures('smartCurrencyCard')">Know the Features</div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div> -->
                </div>
            </div>
        </div>
<!-- && !isApplyForCard -->
<!-- && !isApplyForCard -->
        
        <!-- <div class="row mt-30">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 card-order">
                <div class="col-md-7">
                    <div class="col-md-9 mt-20">
                        <section class="card-section">
                            <div class="row">
                                <div class="swipe-container">

                                    <div class="">
                                        <div class="newcard-border dash-newcard1">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/student-pay-card.png" class="newcard-img">
                                        <div class="newcard-text">Student Pay Card</div>
                                        <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                        <div class="newcard-text3"><i>Ideal for Students</i></div>
                                        <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('studentPayCard')" *ngIf="!isStudentPayAvl"> 
                                            Buy Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('studentPayCard')" *ngIf="!isStudentPayAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('studentPayCard')" *ngIf="isStudentPayAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('studentPayCard')">Know the Features</div>
                                        </div>
                                    </div>                                    


                                    <div class="">
                                        <div class="newcard-border dash-newcard1">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-travel-pay-card.png" class="newcard-img">
                                        <div class="newcard-text">Travel Pay Card</div>
                                        <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                        <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                        <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('travelPayCard')" *ngIf="!isTravelPayAvl"> 
                                            Buy Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('travelPayCard')" *ngIf="!isTravelPayAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('travelPayCard')" *ngIf="isTravelPayAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('travelPayCard')">Know the Features</div>
                                        </div>
                                    </div>


                                    <div class="">
                                        <div class="newcard-border dash-newcard1">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/wsfx-global-pay-card.png" class="newcard-img">
                                        <div class="newcard-text">Global Pay Card</div>
                                        <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                        <div class="newcard-text3"><i>Ideal for Business Travellers</i></div>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('globalPayCard')" *ngIf="!isGlobalPayAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('globalPayCard')" *ngIf="isGlobalPayAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('globalPayCard')">Know the Features</div>
                                        </div>
                                    </div>

                                    <div class="">
                                        <div class="newcard-border dash-newcard1">
                                        <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" class="newcard-img">
                                        <div class="newcard-text">Smart Currency Card</div>
                                        <div class="newcard-text2">Multicurrency Card with INR Wallet</div>
                                        <div class="newcard-text3"><i>Ideal for International Travellers</i></div>
                                        <button class="btn btn-newcard btn-prev fs-14" (click)="cardBuy('smartCurrencyCard')" *ngIf="!isSmartCardAvl"> 
                                            Buy Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard('smartCurrencyCard')" *ngIf="!isSmartCardAvl">
                                            Link Card
                                        </button>
                                        <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('smartCurrencyCard')" *ngIf="isSmartCardAvl">
                                            Choose Card
                                        </button>
                                        <div class="newcard-text4" (click)="goToFeatures('smartCurrencyCard')">Know the Features</div>
                                        </div>
                                    </div>
                            
                                </div>
                            </div>
                          </section>
                    </div>
                </div>
            </div>
        </div> -->
        

       
    
        <!-- <hr class="dotted"> -->

       <!-- <div class="d-flex justify-content-between mt-20">
            <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect (click)="backClick()">
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle">Back</span>
                </button>
            <button type="submit" class="btn btn-primary btn-next fs-12 web-currency-buy-order-started" (click)="checkVcip()" rippleEffect>
                    <span class="align-middle">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
        </div>-->
    </div>
</div>
