import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { CardService } from 'app/modules/card/services/card.service';
import { CartItemCreateRequestList, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationCurrencyResponsesModel, QuotationRequestModel, QuotationResponseModel } from 'app/modules/card/models/card.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'app/modules/order/services/order.service';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import Swal from 'sweetalert2';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';

@Component({
  selector: 'currency-notes-view-cart',
  templateUrl: './currency-notes-view-cart.component.html',
  styleUrls: [
    './currency-notes-view-cart.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyNotesViewCartComponent implements OnInit {

  public showSelectedCard: boolean = true;
  public cashCurrencyList: any;
  public forexCardCurrencyList: any;
  public currencySelected: any;
  public currencySelectedCash: any;
  public currencyAmount = 0;
  public currencyAmountReceived = 0;
  public currencyAmountCash = 0;
  public cartColumns: string[] = ['product', 'currency', 'amount', 'rateINR', 'amountINR', 'action'];
  public loading = true;
  public loadingText: string = '';
  public margingChargesString: string = '0.0';
  public cardDetailsList = new Array<any[]>();
  public cardDetailsObject = new Array<any[]>();
  public kitId: any;
  public cardNumber: string;
  public consumerPanSelf: string;
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  // public quotationResponse = new QuotationResponseModel();
  public quotationResponse: any;
  public orderRequestList = new Array<OrderRequestModel>();
  public orderRequestModel: OrderRequestModel;
  public quotationRequestModel: QuotationRequestModel;
  public cartEditAddStatus = 0;

  public clockDisplay: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public duration: any;
  public seconds = "00";
  public minutes = "00";

  public editCardCurrency = false;
  public editCashCurrency = false;
  dobMaxDate: any;
  isPANCBChecked: boolean=false;
  panVerificationErrMsg: string;
  showErrorModal: boolean;
  public proceedWithoutVCIPCompletion = 'false';
  public cashLimitErrorMsg: string;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;
  @ViewChild('deleteConfirmationDailog') deleteConfirmationDailog: TemplateRef<any>;
  @ViewChild('vcipCompleteOrNotModal') vcipCompleteOrNotModal: TemplateRef<any>;
  @ViewChild('vcipModalNewUser') vcipModalNewUser: TemplateRef<any>;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('errorModal') errorModal: TemplateRef<any>;

  public modalReference: any;

  public currencyCodeReload: string;
  public signature: any;
  public resGetVcipStatus: any;
  public isFromReload: boolean = false;
  public isCartEmpty: boolean = false;
  public branchAddressList = [];
  public branchId: string ;
  public isProfileComplete: boolean = false;
  public isVcipComplete: boolean = false;
  public isPANAvailable: boolean = false;
  public isOrderWithoutVcipComplete: boolean = false;
  public allowCashCurrency: boolean = true;
  public updateProfileModel = new UpdateProfileModel;


  // TRIP DETAILS
  public inTrip = false;
  public departureDate = new Date();
  public returnDate = new Date();
  public minDeparDate: Date;
  public minReturnDate: Date;
  public ssoResponseData: any;
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public rateCalculatorResponse: any;
  public country: any;
  public countries = [];
  public clockDisplayPer = 0;
  public orderCartType = 'CASH_PURCHASE';

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public progressWidth: any;
  public deleteItemMesg: string;
  public consumerData: any;
  public deleteItemIndex: any;
  public progressWidthInPercentage: number = 0;
  public currencyItems: any;
  public consumerDetail: any;
  public orderListItems: any;
  public paxId:any;
  public showLoader: boolean = false;

  public currencySelectedObj: any;
  public currencySelectedCashObj: any;
  public currencyCardAmount: any;
  public currencyCode: any;
  public cardLinkedAlready: boolean=false;
  isVCIPNow: boolean = false;
  selectedBranchAddress: any;
  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cardService: CardService,
    private profileService: UpdateProfileService,
    private orderService: OrderService,
    public toast: ToastrService,
    public modalService: NgbModal,
    public gtmPushService: GTMDataLayerService

  ) {
    //this.getSessionData();
  }

  ngOnInit() {
    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      this.ssoResponseData = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.currencyAmountReceived = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.currencySelected = this.ssoResponseData['requiredCurrencyCode'];
      console.log('SSO resp');

      // this.getRateRange();
    }
    this.getLocalData();
    this.getSessionData();
    if (this.isFromReload == true) {
      this.checkAndFixCurrencyAddBalance();
    }
    this.getCurrencyRate(Constants.productsEnum[1]);
    this.getCurrencyRate(Constants.productsEnum[0]);

    this.getNearByBranchLocation();
    this.getProfileDetails();
    this.getCardDetails();
    this.getPaxList();
    this.getTripDetails();
    this.timer();
  }

  getSessionData() {
    
    this.cartCurrenciesList = JSON.parse(localStorage.getItem('cartCurrenciesList'));
    if (this.cartCurrenciesList == null) {
      console.log("cartIsEmpty");
      //this.isCartEmpty = true;
       this.cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
       this.quotationRequestModel = new QuotationRequestModel();
        this.quotationRequestModel.sellType = Constants.sellTypes[0];
        this.quotationRequestModel.partnerId = Constants.partnerId;
        let quotationCurrencies = new QuotationCurrenciesRequestModel();
        quotationCurrencies.currencyAmount = this.currencyAmountReceived;
        quotationCurrencies.currencyCode = this.currencySelected;
        quotationCurrencies.product = Constants.productsEnum[1];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';
        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
     // this.getRateRange();
      this.getCurrencyRate(Constants.productsEnum[1]);
      this.convertAmount();
      this.callQuotation(0);

    } else {
          console.log("cartIsotEmpty");

      if (this.cartCurrenciesList != null && this.cartCurrenciesList.length > 0) {
        this.callQuotation(0);
      }
      this.currencySelected = this.cartCurrenciesList[0].currencyCode;
      this.currencyCode = this.currencySelected;
      console.log('this.currencyCode', this.currencyCode);
    }
  }
  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);
    console.log("this.consumerData ", this.consumerData);
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
      console.log('SINGLE RATE >> ', this.currencyItems);
      if (this.ssoResponseData) {
        if (this.currencyAmount) {
        }
      }
    });
  }

  convertAmount() {
    if (this.currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(this.currencyAmount, this.currencySelected, Constants.sellTypes[0],Constants.productsEnum[1]).subscribe(resp => {
        let rateResponse = resp.body;
        this.rateCalculatorResponse = rateResponse["cardRateResponse"];
        var cardRateCalculatorObject = {
          "requiredCurrencyCodeAmount": this.currencyAmount,
          "requiredCurrencyCode": this.currencySelected,
        }
        this.showErrorModal = false;
        localStorage.setItem(ConstantsKey.SSO_RESP, JSON.stringify(cardRateCalculatorObject));
        console.log('this.rateCalculatorResponse_+_+', this.rateCalculatorResponse);
       //this.createQuotation();
       this.callQuotation(0)

      }, err => {
        console.log(err);
        if (err.error.message == 'Buying more than 25000$ or equivalent is not allowed for consumer.') {
          this.showErrorModal = true;
          this.cashLimitErrorMsg = err.error.message;
              Swal.fire({
              title:"Dear Customer", 
              html: this.cashLimitErrorMsg, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
          //this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        }
      });
    } else {
      this.rateCalculatorResponse = {};
      this.rateCalculatorResponse.inrAmount = "Amount";
    }
  }

  getProfileDetails() {
    this.profileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      this.paxId = this.consumerDetail.paxResponse.id;
      localStorage.setItem(ConstantsKey.PAXID, this.consumerDetail.paxResponse.id);

      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      } else {
        localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());
      }
      if (this.consumerDetail?.paxResponse != null && this.consumerDetail?.paxResponse?.pan) {
        this.isPANAvailable = true;
      }
      this.signature = this.consumerDetail.signature;

      if (this.signature == undefined) {
        this.isProfileComplete = false;
      }
      localStorage.setItem(ConstantsKey.CONSUMER_SIGNATURE, this.signature);
      if (this.consumerDetail?.paxResponse?.pan) {
        localStorage.setItem(ConstantsKey.CONSUMER_PAN, this.consumerDetail?.paxResponse?.pan);
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  async getVCIPStatus() {
    this.profileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      } else {
        this.getOrderList();
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  getOrderList() {
    this.orderService.getListOrderShortSnippets().subscribe((resp => {
      this.orderListItems = resp.body;
      if (this.orderListItems.length > 0) {
        this.isOrderWithoutVcipComplete = true;
        this.openModalForVCIP();
      }
    }), error => {
    });
  }

  initiateVcip() {
    this.modalReference.close();
    this.profileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  proceedWithourVCIPAfterOrder() {
    this.modalReference.close();
    //this.openModalForPANNo();
  }
  proceedWithourVCIP() {
    this.modalReference.close();
    this.isVCIPNow = false;

    if (this.isOrderWithoutVcipComplete) {
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
    } else {
      if (this.isPANAvailable) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      } else {
        this.openModalForPANNo();
      }
    }

  }
  openModalForVCIP() {
    this.modalReference = this.modalService.open(this.vcipCompleteOrNotModal, { size: 'xl', centered: true });
  }
  openModalForVCIPNewUser() {
    this.modalReference = this.modalService.open(this.vcipModalNewUser, { centered: true });
  }
  openModalForPANNo() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }
  openErrorModal() {
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  openAmountLimitModal(){
    this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
  }
  checkAndFixCurrencyAddBalance() {
    var addBalanceCurrency = localStorage.getItem(ConstantsKey.CARD_ADD_BALANCE_CURRENCY);
    if (addBalanceCurrency != null) {
      var addBalanceCurrencyObj = JSON.parse(addBalanceCurrency);
      this.currencyCode = addBalanceCurrencyObj.currencyCode;
      this.currencySelected = addBalanceCurrencyObj.currencyCode;
      this.currencySelectedCash = addBalanceCurrencyObj.currencyCode;
    }
  }

  ngOnDestroy() { clearInterval(this.interval); }

  backClick() {
      this.router.navigate(['/currency-notes-landing']);
  }

  getTripDetails() {
    this.showLoader = true;

    this.cardService.getTripDetails().subscribe(respTrip => {
      this.showLoader = false;
      var response = respTrip.body;

      if (response['inTrip'] != null && response['inTrip'] == true) {
        this.inTrip = response['inTrip'];

        if (this.inTrip) {
          this.allowCashCurrency = false;

          this.departureDate = new Date(moment(response['fromDate']).format("YYYY-MM-DD"));
          this.orderRequestModel.arrivalDate = moment(response['toDate']).format("YYYY-MM-DD");
          this.minDeparDate = this.departureDate;
          this.minReturnDate = new Date(moment(response['toDate']).format("YYYY-MM-DD"));
          this.country = response['destinationCountry'];

          for (var i = 0; i < this.countries.length; i++) {
            if (this.countries[i].countryName === this.country) {
              this.orderRequestModel.visitingCountry = this.countries[i].countryCode;
            }
          }
        } else {
          this.minDeparDate = new Date();
          this.minReturnDate = new Date();
          this.minReturnDate.setDate(this.minReturnDate.getDate() + 1);
        }

      } else {
        this.inTrip = false;
        this.minDeparDate = new Date();
        this.minReturnDate = new Date();
        this.minReturnDate.setDate(this.minReturnDate.getDate() + 1);
      }
    });
  }

  getSelectedCurrencyForAddBalance() {
    this.activatedRoute.params.subscribe(params => {
      let currencyCodeReload = params['currencyCode'];
      if (currencyCodeReload) {
        this.isFromReload = true;
      }

      if (currencyCodeReload != 'None' && currencyCodeReload) {
        this.cashCurrencyList.filter((item, index) => {
          if (item.currencyCode === currencyCodeReload) {
            this.currencySelected = item;
          }
        });
      }
    });
  }

 

  getCurrencyRate(product) {
    this.isFromReload = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RELOAD));
    if (this.isFromReload == null || this.isFromReload == undefined || this.isFromReload == false) {
      this.isFromReload = false;
    } else {
      this.isFromReload = true;
    }
    console.log('this.isFromReload>>>', this.isFromReload);

    // this.masterDataService.getCurrentRateListCard().subscribe(result => {
    //   var currentResult = result.body;
    //   this.cashCurrencyList = currentResult[0]['rates'];
    //   this.loading = false;
      
    // }, error => {
    //   this.loading = false;
    // });
    if (product=='CASH') {
        this.reqGetCurrencyMaster.cartType = "CASH_PURCHASE"
        this.reqGetCurrencyMaster.product = Constants.productsEnum[1];
    } else {
       this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE"
        this.reqGetCurrencyMaster.product = Constants.productsEnum[0];
    }
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      if (product=='CASH') {
          this.cashCurrencyList = result.body;
      } else {
          this.forexCardCurrencyList = result.body;
      }
        
       this.removeCurrency('NOK'); 
       this.removeCurrency('DKK');
       this.removeCurrency('INR');
       if (product==Constants.productsEnum[0]) {
          this.removeCurrency('SEK'); 
       }
        
 
    },
     error => {
    });
  }
  removeCurrency(doc){
   this.cashCurrencyList.forEach( (item, index) => {
     if(item['currencyCode'] === doc) this.cashCurrencyList.splice(index,1);
   });
}
  getNearByBranchLocation() {
    this.cardService.getNearByBranch(0.0, 0.0).subscribe(respBranchList => {
      this.branchAddressList = JSON.parse(JSON.stringify(respBranchList.body));
    });
  }
  selectDeliveryOfficeAddress(chooseOfficeAddress) {
    this.selectedBranchAddress = chooseOfficeAddress;
    this.branchId = this.selectedBranchAddress.branchId;    
  }


  addCardToCart() {

    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected, Constants.productsEnum[0]);

    if (this.currencySelectedObj) {
      if (this.currencyAmount != null) {
        
        this.showLoader = true;
      if (this.currencySelected) {
        this.currencyCode = this.currencySelected;
      }
       this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencyCode,Constants.productsEnum[0]);

    let isAlreadyExitCurrency = -1;
    this.isCartEmpty = false;
    let filterData = new Array<QuotationCurrenciesRequestModel>();
    if (this.cartCurrenciesList != null) {
      // this.showLoader = false;
      filterData = this.cartCurrenciesList.filter((item, index) => {
        if (item.currencyCode === this.currencySelectedObj.currencyCode && item.product == Constants.productsEnum[0]) {
          isAlreadyExitCurrency = index;
        }
      });
    }

    console.log("currencySelectedObj >", this.currencySelectedObj);

    let quotationCurrencies = new QuotationCurrenciesRequestModel();
    //quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
    quotationCurrencies.currencyAmount = this.currencyAmount;
    quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
    quotationCurrencies.product = Constants.productsEnum[0];
    quotationCurrencies.purpose = 'PRIVATE_VISIT';

    this.cartCurrenciesList = (localStorage.getItem('cartCurrenciesList') == null) ? [] : JSON.parse(localStorage.getItem('cartCurrenciesList'));
    this.cartEditAddStatus = 1;
    this.cartCurrenciesList.push(quotationCurrencies);
    console.log("this.cartCurrenciesList >", this.cartCurrenciesList);

    localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));

    this.callQuotation(this.cartEditAddStatus);
     this.editCardCurrency = false;
     this.editCashCurrency = false;
        this.isCartEmpty = false;
        
      } else {
        this.showLoader = false;
        this.toast.error('Please enter amount!');
      }
    } else {
      this.showLoader = false;
      this.toast.error('Please select currency first !');
    }


  }

  addCurrencyToCardFunc() {

    if (this.currencySelected) {
      this.currencyCode = this.currencySelected;
    }
    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencyCode,Constants.productsEnum[0]);

    let isAlreadyExitCurrency = -1;
    this.isCartEmpty = false;
    let filterData = new Array<QuotationCurrenciesRequestModel>();
    if (this.cartCurrenciesList != null) {
      // this.showLoader = false;
      filterData = this.cartCurrenciesList.filter((item, index) => {
        if (item.currencyCode === this.currencySelectedObj.currencyCode && item.product == Constants.productsEnum[0]) {
          isAlreadyExitCurrency = index;
        }
      });
    }

    console.log("currencySelectedObj >", this.currencySelectedObj);

    let quotationCurrencies = new QuotationCurrenciesRequestModel();
    //quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
    if (this.currencyCardAmount != null) {
      this.showLoader = true;

      quotationCurrencies.currencyAmount = this.currencyCardAmount;
      quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
      quotationCurrencies.product = Constants.productsEnum[0];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';
    
   

      this.cartCurrenciesList = (localStorage.getItem('cartCurrenciesList') == null) ? [] : JSON.parse(localStorage.getItem('cartCurrenciesList'));
      this.cartEditAddStatus = 1;
      this.cartCurrenciesList.push(quotationCurrencies);
      console.log("this.cartCurrenciesList >", this.cartCurrenciesList);

      localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));

      this.callQuotation(this.cartEditAddStatus);
      this.editCardCurrency = false;
      this.editCashCurrency = false;
      this.isCartEmpty = false;
    }
  }

  getSelectedCurrencyObj(currencyCode: string,product) {
    this.showLoader = true;

    var returnCurrencyObj: any;
    if (this.currencySelectedCash) { 

    }
    if (product== Constants.productsEnum[0]) {
              this.forexCardCurrencyList.forEach(singleCurreny => {
              if (singleCurreny.currencyCode == currencyCode) {
                returnCurrencyObj = singleCurreny;
              }
            });
    } else {
                this.cashCurrencyList.forEach(singleCurreny => {
            if (singleCurreny.currencyCode == currencyCode) {
              returnCurrencyObj = singleCurreny;
            }
          });
    }
    
    return returnCurrencyObj;
  }


  addCashCurrencyToCard() {
    if (this.currencySelectedCash) {
      this.currencySelectedCashObj = this.getSelectedCurrencyObj(this.currencySelectedCash, Constants.productsEnum[1]);
      if (this.currencyAmountCash != null && this.currencyAmountCash) {
        let isAlreadyExitCurrency = -1;
        this.isCartEmpty = false;
        let filterData = new Array<QuotationCurrenciesRequestModel>();
        if (this.cartCurrenciesList != null) {
          filterData = this.cartCurrenciesList.filter((item, index) => {
            if (item.currencyCode === this.currencySelectedCashObj.currencyCode && item.product == Constants.productsEnum[1]) {
              isAlreadyExitCurrency = index;
            }
          });
        }

        let quotationCurrencies = new QuotationCurrenciesRequestModel();
       // quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedCashObj.minSalePriceInINR);
        quotationCurrencies.currencyAmount = this.currencyAmountCash;
        quotationCurrencies.currencyCode = this.currencySelectedCashObj.currencyCode;
        quotationCurrencies.product = Constants.productsEnum[1];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';

        this.cartCurrenciesList = (localStorage.getItem('cartCurrenciesList') == null) ? [] : JSON.parse(localStorage.getItem('cartCurrenciesList'));
        this.cartEditAddStatus = 1;
        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));

        console.log("this.cartCurrenciesList in cash >>", localStorage.getItem('cartCurrenciesList'));

        this.callQuotation(this.cartEditAddStatus);
      } else {
        this.showLoader = false;
        this.toast.error('Please enter amount!');
      }
    } else {
      this.showLoader = false;
      this.toast.error('Please select currency first !');
    }
  }

  
  removeCartItemClicked(item) {
        this.cartCurrenciesList = JSON.parse(localStorage.getItem('quotationCurrencyResponsesList'));
        this.cartCurrenciesList.filter((singleItem, index) => {
          if (item.product === singleItem.product && singleItem.currencyCode === item.currencyCode) {
            this.deleteItemIndex = index;
            this.deleteItemMesg = item.product + " " + item.currencyCode + " " + item.currencyAmount + " from Cart?";
            this.modalReference = this.modalService.open(this.deleteConfirmationDailog, { centered: true, size: 'sm' });


      }
        });
  
  }

  removeCartItem() {
        this.modalReference.close();
        this.cartCurrenciesList.splice(this.deleteItemIndex , 1);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        if (this.cartCurrenciesList.length > 0) {
          this.cartEditAddStatus = 3;
          this.callQuotation(this.cartEditAddStatus);
        } else {
          this.quotationResponse = new QuotationResponseModel();
          this.quotationCurrencyResponsesList = [];
          this.isCartEmpty = true;
          this.cashLimitErrorMsg = "Your Cart is Empty !";
        }
   }
    
  

  editCartItem(singleCartCurrency) {
    this.cashCurrencyList.filter((singleItem, index) => {
      if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[0]) {
        this.currencySelected = singleItem;
        this.currencyAmount = singleCartCurrency.currencyAmount;
        this.editCardCurrency = true;
      }
      if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[1]) {
        this.currencySelectedCash = singleItem;
        this.currencyAmountCash = singleCartCurrency.currencyAmount;
        this.editCashCurrency = true;
      }
    });
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  callQuotation(cartEditAddStatus) {

    this.showLoader = true;
    console.log("this.cartCurrenciesList >> ", this.cartCurrenciesList);
    this.cartCurrenciesList.filter((singleItem, index) => {
      if (!singleItem.currencyAmount || singleItem.currencyAmount < 1|| singleItem.currencyAmount==null) {
        this.cartCurrenciesList.splice(index, 1);
      }
    });
    if(this.cartCurrenciesList!=null && this.cartCurrenciesList.length>0 ){

    this.quotationRequest.sellType = Constants.sellTypes[0];
    this.quotationRequest.partnerId = Constants.partnerId;
    this.quotationRequest.quotationCurrencies = this.cartCurrenciesList;
    this.quotationRequest.nostroChargeType = "REMITTER"


    this.quotationRequest.pan = this.consumerPanSelf;
     //this.quotationRequest.tcsPaymentSource = 'OWN';
    // this.quotationRequest.calculateTcs = true;
    if(this.quotationResponse!=null){
      this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    }
    console.log("Quotation request >>>>>>>>>", this.quotationRequest);

    this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe(response => {
    }, error => {

      this.showLoader = false;

      if (error.status == 200) {
        console.log("insdie decrypt");
        this.showLoader = false;
        this.showErrorModal = false;
      this.currencyAmount = null;
      this.currencyAmountCash = null;
      this.currencySelectedCash = null;
      this.currencyCardAmount = null;
      this.currencySelected = null;
      this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
      localStorage.setItem('quotationCurrencyResponsesList', JSON.stringify(this.quotationCurrencyResponsesList));
        console.log("quotationCurrencyResponsesList >> ", this.quotationResponse);
        
      // Save local storage for further recall
      localStorage.setItem('CARD-QUOTATION-REQ', JSON.stringify(this.quotationRequest));

      let message = "";
      if (cartEditAddStatus == 1) {
        message = "New currency added to card.";
      } else if (cartEditAddStatus == 2) {
        message = "Currency updated in your cart.";
      } else if (cartEditAddStatus == 3) {
        message = "Cart item removed.";
      } else if (cartEditAddStatus == 10) {
        message = "Rate from your card update.";
      }
      if (cartEditAddStatus > 0) {
        this.openSnackBar(message);
      }

      this.duration = this.defaultTimerLimitInSec;
      clearInterval(this.interval);
      this.startTimer();

      } else {
        this.showLoader = false;
        //this.toast.error('Something went wrong ! Please try again !');
        this.cartCurrenciesList.forEach((singleItem) => {
          this.cartCurrenciesList.splice(this.cartCurrenciesList.findIndex(item => item.product === "CASH"), 1);
        });
        this.cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
        this.showErrorModal = true;
        this.cashLimitErrorMsg = error.error.message;
        this.currencyAmountCash = null;
        this.currencySelectedCash = null;
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        //this.toast.error(error.error.message);
        this.isCartEmpty = true;
        // if (this.cashLimitErrorMsg.includes('Buying/Selling More Than ')) {
        //   this.cashLimitErrorMsg = "";
        // }
        Swal.fire({
          title:"Dear Customer", 
          html: this.cashLimitErrorMsg, 
          imageUrl: "assets/images/icons/info_icon.png",
          imageWidth: 80,
          imageHeight: 80,
          focusConfirm: false,
          confirmButtonColor: '#1D2A62'
        });
      }
     
    });
    }
  }

  createQuotation() {
    
    let orderRequestList = [];
    this.showLoader = false;
    if (this.quotationRequest.quotationCurrencies.length > 0) {
      if (this.branchId != null) {
        
      
        for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
          let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
          singleRequestCurrency.currencyAmount = this.quotationResponse.quotationCurrencyResponses[i].currencyAmount;
          singleRequestCurrency.currencyCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
          singleRequestCurrency.product = this.quotationResponse.quotationCurrencyResponses[i].product;
          //singleRequestCurrency.bidRate = this.quotationResponse.quotationCurrencyResponses[i].rate;
          singleRequestCurrency.sellType = Constants.sellTypes[0];
          singleRequestCurrency.purpose = "PRIVATE_VISIT";
          singleRequestCurrency.nostroChargeType = "REMITTER"
          singleRequestCurrency.branchId = this.branchId;

          orderRequestList.push(singleRequestCurrency);
        }
        if (this.quotationResponse?.quotationSnapshotId) {
          localStorage.setItem(this.quotationResponse.quotationSnapshotId, JSON.stringify(this.quotationResponse));
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }
        this.quotationRequest.calculateTcs = true;
        this.stepperDataCardModel.quotationResponse = this.quotationResponse;
        this.stepperDataCardModel.quotationRequest = this.quotationRequest;
        this.stepperDataCardModel.cartType = this.orderCartType;

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

        this.cardService.cartItemPost(orderRequestList).subscribe(response => {
          
          let eventObj = {
            'name': 'web_currency_buy_order_start',
            'event': 'web_currency_buy_order_start',
            'number': this.consumerDetail.mobile,
            'amount': orderRequestList[0].currencyAmount,
            'currency': orderRequestList[0].currencyCode
          };
          this.gamoogaEvent(eventObj);
          
          sessionStorage.setItem("fromProduct", Constants.productsEnum[1]);
          this.router.navigate(['/card-stepper']);
        }, error => {
          this.toast.error(error.error.message);
        });
      }
      else {
                        this.toast.error('Please select nearest serving branch');

      }
    } else {
      this.toast.error('Please enter amount to proceed!');
    }

  }

  clearCart() {
    if (this.cardDetailsList.length > 0) {
      this.orderCartType = 'CARD_RELOAD'
    }

    this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
    });
  }

  navigateNext() {
    sessionStorage.setItem("fromProduct",Constants.productsEnum[1]);
    this.router.navigate(['/card-stepper']);
  }
  checkVcip(){
    if ( !this.isCartEmpty ) {
        this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
         if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {         
            sessionStorage.setItem("showVCIPModalForCashFlow","openModalForVCIPNewUser");
            this.openModalForVCIPNewUser();
        } else{
              this.cartInitiate();
        }
      } else {
        this.toast.error("Oops...Your cart is empty");
        }
  }

  getCardDetails() {
    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.cardLinkedAlready = true;
      } else {
        this.cardLinkedAlready = false;
      }

    }), error => {
      this.cardLinkedAlready = false;

      //this.toast.error(error.error.message);
    });;
  }
  validatePanDetails(){
    if(!(this.updateProfileModel.pan!=null )){
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!this.validatePANNo(this.updateProfileModel.pan)) {
      this.toast.error('Please enter valid PAN');
      return false;      
    }    
    if(!(this.updateProfileModel.name!=null )){
      this.toast.error('Please enter name as per PAN');
      return false;
    } 
    if(!this.isPANCBChecked){
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if(!(this.updateProfileModel.dob!=null )){
      this.toast.error('Please select your Date of Birth ');
      return false;
    }
    
    
      return true;
  }
  validatePanAPI() {
    if(this.validatePanDetails()){
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.profileService.validatePAN(this.updateProfileModel.pan, this.updateProfileModel.name, dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if (panResponse.panStatus == 'E' && panResponse.fullName != "" && panResponse.dob != "") {
        this.updatePAN();
      } else if (panResponse.panStatus != 'E') {
        this.panVerificationErrMsg = "Please enter valid PAN";
        this.openErrorModal();
      } else if (panResponse.fullName == "") {
        this.panVerificationErrMsg = "Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if (panResponse.dob == "") {
        this.panVerificationErrMsg = "Please enter valid Date of Birth";
        this.openErrorModal();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  }
  updatePAN() {
    this.showLoader = true;
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
    let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob = dateofBirth;

    this.profileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      console.log(resp.body);
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      this.modalReference.close();
      //this.proceedToViewCart();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
        this.showLoader = false;
        this.modalReference.close();
        if(this.isVCIPNow) {
          this.initiateVcip();
        }        
        this.cartInitiate();
        //this.proceedToViewCart();
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }
  cartInitiate() {
      
      this.showLoader = true;
      if (this.cardDetailsList.length > 0) {
        this.orderCartType = 'CASH_PURCHASE'
      }
      this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
        this.createQuotation();
      });
    
  }

  buySelectedCard() {
    this.showSelectedCard = true;
  }

  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          this.callQuotation(10);
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }

  openSnackBar(message) {
    // this._snackBar.open(message, '', {
    //   duration: 5000,
    //   panelClass: ['green-snackbar'],
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
  }

  getPaxList() {
    this.showLoader = true;

    let consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    console.log("consumerId >>>", consumerId);
    this.profileService.getPaxesList(consumerId).subscribe(result => {
      this.showLoader = false;

      var res = JSON.parse(JSON.stringify(result.body));
      let listPax = res.paxResponses;
      for (let i = 0; i < listPax.length; i++) {
        if (listPax[i]['relationship'] == 'SELF' && listPax[i]['pan']!=null) {
          this.consumerPanSelf = listPax[i]['pan'];
          
        }
      }
    });
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }

  VCIPNow() {
    this.isVCIPNow = true;
    this.openModalForPANNo();
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  gamoogaEvent(obj) {
    this.gtmPushService.dataLayerPushData(obj);
  }

}