<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#trip-info">
                        <button class="step-trigger d-flex  d-lg-none ">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">1</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/TripInfo.png"  style="width:30%">
                                <p class="fs-11 fw-600 mb-0" >Trip Info</p>
                            </span>
                        </button>
                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Trip Information</span>
                                <span class="bs-stepper-subtitle" style="color: black">Trip details</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#student-details">

                        <button class="step-trigger d-flex  d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">2</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/Traveller.png"  style="width:30%">
                                    <p class="fs-11 fw-600 mb-0" >Traveller</p>
                            </span>
                        </button>

                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Traveller</span>
                                <span class="bs-stepper-subtitle" style="color: black"> {{travellerStepText}}</span>
                            </span>
                        </button>
                    </div>
                    <!-- <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div> -->

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc" [ngClass]="kycSelfDeclElement.classList.contains('active') ? 'esignactive' : 'noEesignactive'">

                        <button class="step-trigger d-flex  d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">3</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/KYC.png"  style="width:30%">
                                    <p class="fs-11 fw-600 mb-0" >KYC</p>
                            </span>
                        </button>

                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">KYC</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Accept A2 Form</span>
                            </span>
                        </button>
                    </div>

                    <div class="step" data-target="#lrsDeclaration">
                        <button class="step-trigger">

                        </button>
                    </div>

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step kycDeclaration" data-target="#kyc-self-declaration" #kycSelfDeclElement style="display: none;">
                        <button class="step-trigger  ">

                        </button>
                    </div>
                    <div class="step" data-target="#payment-details">

                        <button class="step-trigger d-flex d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle bs-stepper-box border border-light rounded-circle">
                                <span class="">4</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/payment.png">
                                <p class="fs-11 fw-600 mb-0" >payment</p>
                            </span>
                        </button>

                        <button class="step-trigger  d-none  d-lg-flex">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Payment</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
                            </span>
                        </button>
                    </div>
                </div>

                <div class="row pl-2 pr-2 " style="padding-top: 8px;" *ngIf="!isCartEmpty">
                    <div class="col-md-3"></div>

                    <div class="col-md-6">
                        <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                                minutes
                            </label>
                        <div class="progress">
                            <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                        </div>


                    </div>
                </div>
                <div class="bs-stepper-content">
                    <loader *ngIf="showLoader"></loader>
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <!-- First Step -->
                        <div id="trip-info" class="content" title="Trip Info">
                            <form #tripInfoForm="ngForm">
                                <div class="row mt-30">

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">


                                        <trip-information (childCallbackSender)="parentCallbackFormChild($event)">
                                        </trip-information>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>

                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="green-text-edit"><a (click)="openModalForAddCart(singleTransaction)">Add </a></span> |<span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>
                                    </div>
                                </div>
                            </form>

                            <div class="d-flex justify-content-between">
                                <!-- <button routerLink="/view-cart" class="btn
                                btn-outline-secondary btn-prev fs-12" type="button" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button> -->
                                <button *ngIf="!hideProceedButton" (click)="handleTripInfoBackClick()" type="submit" class="btn btn-primary btn-prev fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                <button *ngIf="!hideProceedButton" (click)="mapTripDetailsStep01()" type="submit" class="btn btn-primary btn-next fs-12 step" [ngClass]="{ 'web-card-order-step1' : cartType == 'CARD_PURCHASE', 'web-reload-order-step1': cartType == 'CARD_RELOAD',  'web-currency-buy-order-step1' : cartType == 'CASH_PURCHASE' }" rippleEffect>
                                    <span class="align-middle">Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                        <!-- End First Step -->

                        <!-- Second Step -->
                        <div id="student-details" class="content">
                            <form #travellerInfoForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <traveller-details (childCallbackSender)="parentCallbackFormChild($event)" [refreshQuotationCountFooter]="refreshQuotationCount">
                                        </traveller-details>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">

                                                            <th class="mob-table">Product</th>
                                                            <td>

                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="green-text-edit"><a (click)="openModalForAddCart(singleTransaction)">Add </a></span> |<span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>

                                    </div>

                                </div>



                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="mapPayerDetailsStep02()" class="btn btn-primary btn-next fs-12" [ngClass]="{ 'web_card_order_step2' : cartType == 'CARD_PURCHASE', 'web_reload_order_step2': cartType == 'CARD_RELOAD',  'web_cash_buy_order_step2' : cartType == 'CASH_PURCHASE' }" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Second Step -->

                        <!-- Third Step -->
                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <!-- <document-upload (childCallbackSender)="parentCallbackFormChild($event)"></document-upload> -->
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <div class="row mb-20">
                                            <div class="col-md-12">
                                                <h6 class="content-header-title float-left mb-0">
                                                    Upload transaction documents
                                                </h6>
                                            </div>
                                            <div class="col-md-12 fs-11">

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="showSignatureUpload">
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-12 form-group">
                                                <label for="basicInputFile">Upload Signature*</label>
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile" placeholder="Upload SIgnature*" (change)="uploadSignature($event)" />
                                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-3" *ngIf="showImageUploadedIcon || signatureUploaded">
                                                <div class="input-group-append mt-23"  style="height: 38px;">
                                                    <span class="input-group-text link uploaded-icon-bg" (click)="downloadSignature()">
                                                <img src="assets/images/icon/view-document.png" class="w-h-20">
                                            </span>
                                                    <span class="input-group-text link ml-7 uploaded-icon-bg"><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <section class="row" *ngFor="let singleDocument of transactionDocListUploaded">
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-12">
                                                <div class="form-group">
                                                    <label for="basicInputFile">{{singleDocument.title}}</label>
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-3" *ngIf="singleDocument.status == 'UPLOADED' ">
                                                <div class="input-group-append mt-23" *ngIf="true" style="height: 38px;">
                                                    <span class="input-group-text link uploaded-icon-bg" (click)="uploadDownloaddocument(singleDocument)">
                                                <img src="assets/images/icon/view-document.png" class="w-h-20">
                                            </span>
                                                    <span class="input-group-text link ml-7 uploaded-icon-bg"><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                                                </div>
                                            </div>
                                        </section>

                                        <section *ngFor="let singleDocument of transactionDocListAwaiting">
                                            <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-12">
                                                    <div class="form-group">
                                                        <label for="basicInputFile">{{singleDocument.title}}</label>
                                                        <div class="custom-file">
                                                            <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <enhance-due-diligence *ngIf="showDiligenceForm"></enhance-due-diligence>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>

                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="green-text-edit"><a (click)="openModalForAddCart(singleTransaction)">Add </a></span> |<span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>

                                    </div>


                                </div>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button (click)="checkDocumentUplodStep03()" class="btn btn-primary btn-next fs-12" [ngClass]="{ 'web_card_order_step3' : cartType == 'CARD_PURCHASE', 'web_reload_order_step3': cartType == 'CARD_RELOAD',  'web_cash_buy_order_step3' : cartType == 'CASH_PURCHASE' }" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Third Step -->


                        <div id="lrsDeclaration" class="content">
                            <form #lrsDeclaration="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="col-md-12  pl-0 content-header-title fs-16  mb-10">
                                            Traveller details
                                        </div>
                                        <div class="col-md-12  pl-0 text-black fs-14">Have you conducted any foreign exchange transactions under LRS in the current financial year through any other Regulated Entity (AD/FFMC/ADII)?*</div>
                                        <div class="col-md-12  pl-0 mt-20">
                                            <div class="row">
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='Yes' (click)="showLRSFormSection(true)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;Yes
                                                </div>
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='No' (click)="showLRSFormSection(false)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;No
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 p-0" *ngIf="showTransactionDetails || showLocalTransactionDetails">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <div class="row" *ngIf="showTransactionDetails">
                                                        <div class="col-md-12">
                                                            <section class="grey-box-padding-10 mt-10" *ngFor="let singleTransaction of transactionList">
                                                                <div class="row d-flex justify-content-between mb-20">
                                                                    <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Date of Transaction</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.dateOfTranscation | date :'dd MMM yyyy' }}</div>
                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Amount in INR</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.lrsAmount}}</div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                    <div class="col-md-12" *ngIf="showLocalTransactionDetails">
                                                        <div class="row" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                            <div class="col-md-12">
                                                                <section class="grey-box-padding-10 mt-10">
                                                                    <div class="row d-flex justify-content-between mb-20">
                                                                        <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                                        <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.institudeName}}</div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Date of Transaction</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Amount in INR</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                                    </div>
                                                                </section>
                                                            </div>
            
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div class="col-md-3 mt-30 pl-0 ">
                                                    <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="showLRSFormSection(true)" rippleEffect>
                                                        <span class="align-middle" >Add more details</span>
                                                    </button>
                                                </div>                                                
                                            </div>
                                        </div>


                                        <div class="col-md-12 pl-0 mb-20" *ngIf="showLRSForm">
                                            <section class="grey-box-padding-30 mt-30">
                                                <form [formGroup]="lrsForm" (ngSubmit)="saveLRSDataLocally(lrsForm)" novalidate>
                                                    <div class="row">
                                                        <div class="col-md-12 col-12">
                                                            <input type="text" class="form-control fs-14" placeholder="Name of the Bank/FFMC/ADII*" formControlName="instituteName" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.instituteName.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.instituteName.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-20">
                                                            <div class="input-group ">
                                                                <input class="form-control fs-12" placeholder="Date of transaction*" formControlName="selectedDateOfTransaction" (click)="basicDP.toggle()" ngbDatepicker #basicDP="ngbDatepicker" (dateSelect)="onDateSelect($event)"
                                                                    [minDate]="{year: 1950, month: 1, day: 1}" [maxDate]="maxLRSDate" [minDate]="minLRSDate">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.selectedDateOfTransaction.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.selectedDateOfTransaction.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-30">
                                                            <input type="number" class="form-control fs-14" placeholder="Amount in INR*" id="amount" formControlName="lrsDeclarationAmount" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.lrsDeclarationAmount.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.lrsDeclarationAmount.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="button" class="btn btn-outline-dark btn-prev fs-14" rippleEffect (click)="closeLRSForm()">
                                                                <span class="align-middle">Cancel</span>
                                                            </button>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="submit" class="btn btn-primary btn-next fs-14" rippleEffect>
                                                                <span class="align-middle">Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                        <!-- <div class="col-md-12 pl-0" *ngIf="showLocalTransactionDetails">
                                            <div class="row" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                <div class="col-md-12">
                                                    <section class="grey-box-padding-10 mt-10">
                                                        <div class="row d-flex justify-content-between mb-20">
                                                            <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                            <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Date of Transaction</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Amount in INR</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                        </div>
                                                    </section>
                                                </div>

                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-2 offset-md-10 mt-10  pl-0 mb-20" *ngIf="showLocalTransactionDetails">
                                            <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="proceedToLRSDeclaration()" rippleEffect>
                                                <span class="align-middle" >Submit</span>
                                            </button>
                                        </div> -->
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>Rate</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <td>
                                                                <span class="green-text-edit"><a (click)="openModalForAddCart(singleTransaction)">Add </a></span> |<span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>

                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button (click)="checkLRSStep()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Fourth Step -->
                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <a2-form (childCallbackSender)="parentCallbackFormChild($event)"></a2-form>

                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>

                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>

                                    </div>
                                </div>


                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="downloadA2FormStep04()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>

                            </form>
                        </div>
                        <!-- End Fourth Step -->

                        <!-- Fifth Step -->
                        <div id="payment-details" class="content">
                            <form #paymentDetails="ngForm">
                                <!-- <payment (childCallbackSender)="parentCallbackFormChild($event)"></payment> -->
                                <!-- <add-bank-account-for-remittance *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                                </add-bank-account-for-remittance> -->

                                <section *ngIf="!showAddBankForm">
                                    <div class="row mt-30">

                                        <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                            <div class="row mb-20">
                                                <div class="col-md-12  mb-10">
                                                    <h6 class="content-header-title float-left mb-0">
                                                        Payment
                                                    </h6>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="fs-11">Complete your payment successfully to proceed with your Card request.</div>
                                                </div>
                                            </div>

                                            <loader *ngIf="showLoader"></loader>
                                            <!-- <table class="table table-bordered ">
                                        <thead class="blue-background">
                                            <tr>
                                                <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                                                <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                                                <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                                                <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                <td scope="row" class="text-center" style="padding-top: 10px">{{item.product }}</td>
                                                <td class="text-center" style="padding-top: 10px">1 {{item.currencyCode}} = INR {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
                                                </td>
                                                <td class="text-center" style="padding-top: 10px">{{item.currencyCode}} {{item.currencyAmountString}}
                                                </td>
                                                <td class="text-center" style="padding-top: 10px">₹ {{item.inrEquivalentAmountString}}
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </tbody>
                                    </table> -->

                                    <section class="referrer-section mt-20" *ngIf="!referredStatus">
                                        <div class="row">
                                            <div class="col-md-9 col-9">
                                                <div class="form-group">
                                                    <label class="form-group m-form__group mb-0">Apply Referral Code</label>
                                                    <input type="text" name="referralCode" [(ngModel)]="referralCode" class="form-control fs-12" placeholder="Enter referral Code*" />
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-3 pl-0 d-flex align-items-end">
                                                <div class="form-group">
                                                    <button class="btn btn-primary" (click)="applyReferralCode()">Apply</button>
                                                    <!-- <button class="btn btn-danger" (click)="removeReferralCode()" *ngIf="referralCodeApplied">Remove</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                            <section class="offer-section mt-20">
                                                <div class="row">
                                                    <div class="col-md-9 col-9">
                                                        <div class="form-group m-form__group">
                                                            <label class="form-group m-form__group mb-0">Apply Coupon</label>
                                                            <!-- <select class="form-control" name="offer" placeholder="Apply Coupon" required [(ngModel)]="promoCode">
                                                       <option *ngFor="let singleOffer of listOffers" [value]="singleOffer.promoCodeId">
                                                       {{ singleOffer.title }}</option>
                                                    </select> -->
                                                    <ng-select #refPromoCodeList name="offer" [items]="listOffers" bindLabel="title" bindValue="promoCodeId" [addTag]="addNewCoupon" addTagText="Add New Coupon" [(ngModel)]="promoCode" placeholder="" class="fs-12"  placeholder="Select Coupon or add Coupon" required>
                                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                                            <b>{{item.title}}</b><br>
                                                            <span>{{item.shortDescription}}</span>
                                                        </ng-template>
                                                    </ng-select>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="!promoCodeApplied">
                                                        <button class="btn btn-primary" (click)="applyPromoCode()">Apply</button>
                                                    </div>

                                                    <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="promoCodeApplied">
                                                        <button class="btn btn-danger" (click)="removePromoCode()">Remove</button>
                                                    </div>
                                                </div>
                                            </section>

                                            <section class="col-md-12 redeempoints-section mt-20  redeem-points p-15">
                                                <div class="row">
                                                    <div class="col-md-9 col-9">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control fs-12" name="pointsToRedeem" placeholder="Enter Redeemable Points*" [(ngModel)]="pointsToRedeem" oninput="this.value = 
                                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" pattern="^[1-9][0-9]*$" required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-3 pl-0 " *ngIf="!creditPointApplied">
                                                        <button class="btn btn-primary" (click)="applyCreditPointToQuote()">Apply</button>
                                                    </div>
                                                    <div class="col-md-3 col-3 pl-0" *ngIf="creditPointApplied">
                                                        <button class="btn btn-danger" (click)="removeCreditPoint()">Remove</button>
                                                    </div>
                                                    <!-- <div class="col-md-6">
                                                        <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                                    </div>
                                                    <div class="col-md-6 text-right">
                                                        <div class="text-black fw-500 fs-10">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                                    </div> -->
                                                    <div class="col-md-12 d-flex justify-content-between align-items-center mt-2">
                                                        <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                                        <div class="text-black fw-500 fs-10 text-right">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                                    </div>
                                                </div>
                                            </section>


                                            <!-- <div class="row mb-30 mt-30">
                                                <div class="col-md-6 col-12" style="margin-top: 3px;">
                                                    <div class="fs-12 text-black fw-600">
                                                        Choose Your Bank Account
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-12 ">
                                                    <button type="button" class="btn btn-primary btn-prev fs-12 mt-2" rippleEffect (click)="openModalForAddBankAccount(addBankAccountModal)">
                                                        Add Bank Account
                                                    </button>
                                                </div>
                                            </div> -->

                                            <div class="col-md-12 mb-30 mt-30 pl-0 d-flex align-items-center">
                                                <div class="col-md-6 col-sm-6 pl-0 d-flex justify-content-start align-items-center">
                                                    <div class="fs-12 text-black pl-0 fw-600">
                                                        Choose Your Bank Account
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6 d-flex justify-content-end   mt-md-0">
                                                    <button type="button" class="btn btn-primary btn-prev fs-12" rippleeffect="" (click)="openModalForAddBankAccount(addBankAccountModal)" style="
                                                font-size: 13px !important;">Add Bank Account</button>

                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
                                                    <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                                                        <div class="row mb-10">
                                                            <!-- <div class="col-md-1 col-1">
                                                </div> -->
                                                            <div class="col-md-12 col-11">
                                                                <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />

                                                                <span class="card-title fs-12 text-black fw-600 mb-10" style="margin-left:10px">
                                                            {{ singleBank.bankName }}
                                                        </span>
                                                                <div class="fs-11  ml-25"> Account No : {{singleBank.number}}</div>
                                                                <div class="fs-11  ml-25"> IFSC Code : {{singleBank.ifscCode}}</div>
                                                                <div class="fs-11  ml-25"> Branch : {{singleBank.branch}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row mt-20">
                                                <div class="col-md-12">
                                                    <div class="fs-12 text-black fw-600">
                                                        Payment Type
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="row mt-20 mb-20">
                                                <div class="col-md-6 fs-11">
                                                    <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(false)" /> &nbsp; &nbsp; Full Payment
                                                </div>
                                                <div class="col-md-4   fw-600">
                                                    ₹ {{this.quotationResponse?.totalBillableAmount}}
                                                </div>
                                            </div> -->
                                            <div class="row mt-3 mb-3 align-items-center">
                                                <div class="col-8 col-md-6 fs-6">
                                                    <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(false)" /> &nbsp; &nbsp; Full Payment
                                                </div>
                                                <div class="col-4 col-md-4 fw-bold">
                                                    ₹ {{this.quotationResponse?.totalBillableAmount}}
                                                </div>
                                            </div>

                                            <div class="row mt-3  align-items-center">
                                                <div class="col-8 col-md-6 fs-6">
                                                    <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(true)" /> &nbsp; &nbsp; Book and Pay
                                                </div>
                                                <div class="col-4 col-md-4 fw-bold" *ngIf="partialPaymentAllowed">
                                                    ₹ {{this.quotationResponse?.partialBillableAmount}}
                                                </div>
                                            </div>

                                            <!-- <div class="row mt-20">
                                                <div class="col-md-6 fs-11" *ngIf="partialPaymentAllowed">
                                                    <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(true)" /> &nbsp; &nbsp; Book and Pay
                                                </div>
                                                <div class="col-md-4   fw-600" *ngIf="partialPaymentAllowed">
                                                    ₹ {{this.quotationResponse?.partialBillableAmount}}
                                                </div>
                                            </div> -->
                                            <div class="row mt-10 mb-20">
                                                <div class="col-md-6 fs-9 text-blue fw-600" style="margin-left: 35px!important ;" *ngIf="partialPaymentAllowed">
                                                     Book your forex order now and pay remaining later. Enjoy today’s rate by paying only 1% amount
                                                </div>
                                            </div>
                                            <div class="row  mt-30">
                                                <div class="col-md-12">
                                                    <div class="fs-12 text-black fw-600 mb-20">
                                                        Payment Method
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-20">
                                                <div class="col-md-6 fs-12 mb-20" *ngIf="showNetBanking || !showNEFT">
                                                    <input type="radio" name="paymentRadio" id="radio" value='NB' (click)="getSelectedPaymentMode('NB')" /> &nbsp; &nbsp; Net Banking
                                                </div>
                                                <div class="col-md-6 fs-12 mb-20" *ngIf="ALLOW_NEFT_PAYMENT && showNEFT">
                                                    <input type="radio" name="paymentRadio" id="radio" value='MANUAL' (click)="getSelectedPaymentMode('MANUAL')" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                                </div>

                                            </div>

                                            <div *ngIf="selectedPaymentMode == 'MANUAL'">
                                                <div class="mt-20">
                                                    <div class="fs-12 fw-600  text-black">{{ accountName }}</div>
                                                    <div class="fs-11  text-black">{{ bankName }}</div>
                                                    <div class="fs-11  text-black">{{ accountNo }}</div>
                                                    <div class="fs-11  text-black">{{ bankIFSC }}</div>
                                                    <div class="fs-11  text-black">{{ bankBranch }}</div>
                                                </div>

                                                <div class="mb-30 fs-10 text-danger mt-30  text-500">
                                                    Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section.
                                                </div>
                                            </div>
                                                    <div class="row mb-20" *ngIf="showCashRefundNote">
                                                        <div class="col-md-12 fs-12 mb-20 text-500" >
                                                            Note: If the currency is not available at the requested branch the amount
                                                            paid will be refunded in 5 working days
                                                        </div>
                        
                                                    
                                                    </div>
                                        </div>

                                        <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                            <div class="row">
                                                <div class="col-md-12  mb-5">
                                                    <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                        Your Cart Details
                                                    </h6>
                                                </div>

                                            </div>
                                            <div class="table-responsive mt-15">
                                                <div>
                                                    <table class="table table-bordered table-responsive">
                                                        <thead>
                                                            <tr class="table-header">
                                                                <th>Product</th>
                                                                <th>Currency</th>
                                                                <th>FX Amount</th>
                                                                <th>IBR</th>
                                                                <th>INR Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fs-10 text-black">
                                                            <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                                <th class="mob-table">Product</th>
                                                                <td>

                                                                    {{singleTransaction?.product}}
                                                                </td>
                                                                <th class="mob-table">Currency</th>
                                                                <td>
                                                                    {{singleTransaction?.currencyCode}}
                                                                </td>
                                                                <th class="mob-table">FX Amount</th>
                                                                <td>
                                                                    {{singleTransaction?.currencyAmount}}
                                                                </td>
                                                                <th class="mob-table">IBR</th>
                                                                <td>
                                                                    {{singleTransaction.rateString | number : '1.2-2'}}
                                                                </td>
                                                                <th class="mob-table">INR Amount</th>
                                                                <td>
                                                                    {{singleTransaction.inrEquivalentAmountString}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <card-stepper-footer [refreshQuotationCountFooter]="refreshQuotationCount"></card-stepper-footer>
                                        </div>

                                    </div>

                                </section>


                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button class="btn btn-primary btn-gradient  fs-12" rippleEffect (click)="openModalForPaymentInfo(paymentInstructionModal)">Proceed</button>
                                </div>
                            </form>
                        </div>
                        <!--End Fifth Step -->

                    </form>
                </div>
            </div>
        </section>
    </div>
</div>



<section>
    <div id="pgContainer" style="visibility:hidden">
        <b> Please wait ! We are redirecting to the Payment Gateway. </b>
        <form id="ts-app-payment-form-redirect"></form>
    </div>
</section>


<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                {{ paymentInstruction }}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" [ngClass]="{ 'web_card_order_payment' : cartType == 'CARD_PURCHASE', 'web_reload_order_payment': cartType == 'CARD_RELOAD',  'web_cash_buy_order_payment' : cartType == 'CASH_PURCHASE' }" (click)="step04Payment()" style="cursor: pointer;">
            OKAY
        </div>
    </div>
</ng-template>

<ng-template let-modal #addBankAccountModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form class="form-horizontal" autocomplete="xxvv67_ghfhg" (ngSubmit)="addBankAccount(AddBankAccount.form.valid)" #AddBankAccount="ngForm">
            <div class="col-md-12">
                <loader *ngIf="showLoader"></loader>
                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label">Name</label>
                        <input required type="text" class="form-control fs-12" placeholder="Name" [(ngModel)]="addBankAccountRequest.nameAsPerAccountNumber" name="name" autocomplete="new_name" #nameRef="ngModel" [class.error]="AddBankAccount.submitted && nameRef.invalid" (keypress)="alphaNumberOnly($event)"
                            (paste)="onPaste($event)" maxlength="100" required />
                        <span *ngIf="AddBankAccount.submitted && nameRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="nameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="country">Relationship*</label>
                        <ng-select class="fs-12" [items]="bankRelationshipList" bindValue="bankRelationshipList" [(ngModel)]="addBankAccountRequest.relation" required placeholder="Relationship" name="relationship" #relationshipRef="ngModel" [class.error]="AddBankAccount.submitted && relationshipRef.invalid"
                            [clearable]="false" [searchable]="true">
                        </ng-select>
                        <span *ngIf="AddBankAccount.submitted && relationshipRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>
                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="country">Bank Name*</label>
                        <ng-select [items]="bankDropdownItemList" bindLabel="displayName" bindValue="displayName" [(ngModel)]="addBankAccountRequest.bankName" placeholder="Select" name="bank_name" #bankNameRef="ngModel" [class.error]="AddBankAccount.submitted && bankNameRef.invalid"
                            [searchable]="false" required>
                        </ng-select>
                        <span *ngIf="AddBankAccount.submitted && bankNameRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="bankNameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Branch Name</label>
                        <input type="text" class="form-control fs-12" autocomplete="ytt86_fdhdh_00dty"  name="branch_name" placeholder="Branch Name" maxlength="100" [(ngModel)]="bankBranchName" #branchNameRef="ngModel" [class.error]="AddBankAccount.submitted && branchNameRef.invalid" required />
                        <span *ngIf="AddBankAccount.submitted && branchNameRef.invalid" class="invalid-form">
                            <small class="text-danger" *ngIf="branchNameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>
                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label">Account Number</label>
                        <div class="input-group form-password-toggle">
                            <input [type]="showAccountNo ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="addBankAccountRequest.number" placeholder="Account Number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid"
                                (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required  autocomplete="fht_dhhj_jkjk" />
                            <div class="input-group-append" (click)="showAccountNo = !showAccountNo">
                                <span class="input-group-text cursor-pointer" style="height:38px !important"><i class="feather"
                                [ngClass]="{'icon-eye-solid': showAccountNo, 'icon-eye-slash-solid': !showAccountNo }"></i></span>
                            </div>

                        </div>
                        <!-- <input required type="password" class="form-control fs-12" placeholder="Account Number" [(ngModel)]="addBankAccountRequest.number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid" required /> -->
                        <span *ngIf="AddBankAccount.submitted && accountNoRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="accountNoRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Confirm Account Number</label>
                        <input required type="text" name="confirm_acccount_no" class="form-control fs-12" placeholder="Confirm Account Number" [(ngModel)]="addBankAccountRequest.confirmAccNo" #confirmAccountNoRef="ngModel" [class.error]="AddBankAccount.submitted && confirmAccountNoRef.invalid"
                            (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required autocomplete="new_confimaccountno" />
                        <span *ngIf="AddBankAccount.submitted && confirmAccountNoRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="confirmAccountNoRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>

                <div class="row mt-15">

                    <div class="form-group col-md-6">
                        <label class="form-label">IFSC Number</label>
                        <input type="text" class="form-control fs-12" name="ifscCode" autocomplete="new_ifsc" placeholder="IFSC Number" [(ngModel)]="addBankAccountRequest.ifscCode" #ifscCodeRef="ngModel" [class.error]="AddBankAccount.submitted && ifscCodeRef.invalid" required pattern="([A-Za-z0]{4})(0\d{6})$"
                            (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="11" />
                        <span *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid" class="invalid-form">
                            <small class="text-danger" *ngIf="ifscCodeRef.errors.required">This field is required!</small>
                        </span>
                        <div class="text-danger fs-11" *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid">IFSCCode must be in valid format!</div>
                    </div>
                </div>

                <div class="row">
                    <button class="col-md-2 offset-md-5 btn btn-primary fw-500 fs-12 text-center blue-text" type="submit" style="cursor: pointer;">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template let-modal #EditCardModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>

        <div class="row">

            <div class="col-md-12 col-12 mb-1">
                <label class="form-label" for="country" style="color: black">Select Product*</label>

                <ng-select class="fs-12" name="productTypeList" [clearable]="false" [items]="productTypeList" [(ngModel)]="selectedProductType" (change)="productDropdownClicked($event)" bindValue="productTypeBindValue" [searchable]="false">
                </ng-select>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6 col-6 mb-1">

                <!-- <ng-select class="fs-12" name="productTypeList" [clearable]="false" [items]="currencyItems" [(ngModel)]="selectedProductType" (change)="productDropdownClicked($event)" bindValue="FOREX CARD"  [searchable]="false">
                            </ng-select> -->
                <label class="form-label" for="country" style="color: black">Select Currency*</label>

                <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencyCode" [searchable]="false">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                    </ng-template>
                </ng-select>

            </div>
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Enter Amount*</label>

                <input required type="number" name="editedAmount" class="form-control fs-12" style="border-color:black" placeholder="Amount*"(keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" [(ngModel)]="editAmount" required />

            </div>
        </div>
    </div>


    <!-- <div class="modal-header" style="display:block">
            <h6 class="modal-title " id="myModalLabel1" style="text-align:center">PAN Details</h6>
    
        </div> -->
    <div class="col-md-12  text-center">


        <button class="mb-10  mt-10 btn btn-primary fs-10" rippleEffect (click)="editCart()">
            Update
        </button>
    </div>
</ng-template>

<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center">Please be informed that our online portal facilitates allow transaction up to $25,000 (or equivalent).</span><br>
        <span class="text-center">If your transaction exceeds this limit, we kindly request you to utilize our branches. </span>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
        Okay </button>
    </div>
</ng-template>

<ng-template #deleteConfirmationDailog let-modal>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                <span class="text-center"> Do you want to Remove </span> <br> {{deleteItemMesg}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 modal-footer border-right">
            <div class="row col-12">
                <div class="fw-500 fs-12 blue-text" (click)="removeCartItem()" style="cursor: pointer; padding-left: 50%;">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-6 modal-footer border-left">
            <div class="row col-12">
                <div class="fw-500 fs-12 red-text-delete" (click)="modal.dismiss('Cross click')" style="cursor: pointer; padding-left: 40%;">
                    No
                </div>
            </div>
        </div>
    </div>
</ng-template>