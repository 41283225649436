import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { StepperDataCardModel, TripInfoRequestModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import Swal from 'sweetalert2';

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;
@Component({
  selector: 'trip-information',
  templateUrl: './trip-information.component.html',
  // styleUrls: ['../../../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TripInformationComponent implements OnInit {
  public return_date: NgbDateStruct;
  public departure_date: NgbDateStruct;
  public selectedCountry: any;
  public countryListItems: any;
  public tripMinDepartureDate: any;
  public tripMinReturnDate: any;
  public exoticCurrenyList = ['JPY', 'HKD', 'ZAR', 'NOK', 'DKK', 'SEK' ];
  public tripMaxDepartureDate: any;

  public countryCodeForDueDelligence: string;
  public showCrimerRegionModal: any;
  public showDueDeligenceModal: any;
  public showDueDeligenceForm: boolean = false;
  public showEmigrationnote: boolean = false;
  public showReturnDate: boolean = true;
  public isExoticCurrencyAdded: boolean = false;

  public sanctionedCountry: any;

  @ViewChild('crimeaRegionDailog') crimeaRegionDailog: ElementRef;
  @ViewChild('dueDiligenceDialog') dueDiligenceDialog: ElementRef;
  @ViewChild('noTransactionAllowedDialog') noTransactionAllowedDialog: ElementRef;
  public modalRef: any;
  public minDateForReturnDate: any;
  public isCalculateTCS: boolean = true;
  public quotationCurrencyResponsesList = [];
  public isTCSApplicable: boolean = true;
  public refreshQuotationCount: number = 0;

  public subCategoryListForexCard = [
    {
      purpseTitle: "Leisure",
      subPurpose: "LEISURE",
      purpose : 'PRIVATE_VISIT'
    },
    {
      purpseTitle: "Emigration",
      subPurpose: "IMMIGRATION",
      purpose : 'EMIGRATION'
    },
    {
      purpseTitle: "Medical",
      subPurpose: "MEDICAL",
      purpose : 'MEDICAL_TREATMENT_ABROAD'
    },
    {
      purpseTitle: "Student Travel",
      subPurpose: "STUDENT_TRAVEL",
      purpose : 'OVERSEAS_EDUCATION'
    },
    {
      purpseTitle: "Others",
      subPurpose: "OTHERS",
      purpose : 'OTHERS'
    }
  ];
  public tripInfoRequestModel: TripInfoRequestModel = new TripInfoRequestModel();

  @Output() childCallbackSender = new EventEmitter<string>();

  ///SESSION DATA
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public cardType: string='smartCurrencyCard';
  public isPurposeDisbaled: boolean = false;

  constructor(
    public masterDataService: MasterDataService,
    public cardService: CardService,
    public remittanceService: RemittanceService,
    public modalService: NgbModal
  ) {
    this.getSessionData();
  }

  ngOnInit() {
    this.getCountryList();
    let cardTypeObj = sessionStorage.getItem(ConstantsKey.STUDENT_CARD_DATA);
    if(cardTypeObj) {
      cardTypeObj = JSON.parse(cardTypeObj);
      this.cardType = cardTypeObj["cardType"];
    }
    // this.tripInfoRequestModel.purpose = "PRIVATE VISIT";
    this.tripMinDepartureDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.tripMaxDepartureDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 4, day: new Date().getDate() };
    setTimeout(() => {
      if(this.cardType == 'studentPayCard') {
        this.tripInfoRequestModel.subPurpose = 'STUDENT_TRAVEL';
        this.isPurposeDisbaled = true;
      }
    }, 500);

  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
    }
  }
  

  async updateStep01Data(inputParamater) {
    console.log('this.tripInfoRequestModel.subPurpose',this.tripInfoRequestModel.subPurpose);
    
    if (this.departure_date) {
      localStorage.setItem("departureDate",JSON.stringify(this.departure_date));
      this.tripInfoRequestModel.departure = this.getProperFormatedDate(this.departure_date);
      this.tripMinReturnDate = { year: this.departure_date.year, month: this.departure_date.month, day: this.departure_date.day };
      if(this.return_date!=null &&  after(this.departure_date, this.return_date)){
        this.return_date=null;
        this.tripInfoRequestModel.returnDate=null;
      }
    }
    if (inputParamater == 'DEPARTURE_DATE') {
      
       for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
      var currCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
       if (this.quotationResponse.quotationCurrencyResponses[i].product == Constants.productsEnum[1]) {
           this.exoticCurrenyList.filter((singleItem, index) => {
              if (singleItem == currCode ) {
                this.exoticCurrenyList.splice(index, 1);
                  this.isExoticCurrencyAdded = true;
              }
           });
        
       }
     }
                if (this.departure_date.day <= this.tripMinDepartureDate.day + 2 && this.isExoticCurrencyAdded) {
                  localStorage.setItem("showExoticCurrencyNote", "true");
                              Swal.fire({
                      title:"Dear Customer", 
                      html: "Order will be fulfilled depending on the availability. If the currency is not available, the amount paid will be refunded back to the source account", 
                      imageUrl: "assets/images/icons/info_icon.png",
                      imageWidth: 80,
                      imageHeight: 80,
                      focusConfirm: false,
                      confirmButtonColor: '#1D2A62'
                    });
              
            } else {
                localStorage.setItem("showExoticCurrencyNote", "false");
            }
    } 
    //  for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
    //   var currCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
    //       if (this.quotationResponse.quotationCurrencyResponses[i].product==Constants.productsEnum[1]) {
    //         if (currCode == 'JPY' ||currCode == 'HKD' ||currCode == 'ZAR'||currCode == 'NOK'||currCode == 'DKK'||currCode == 'SEK') {
    //           this.isExoticCurrencyAdded = true;
    //         break;
    //       }
    //       }

          
    //     }
    
    

    if (this.return_date) {
      this.tripInfoRequestModel.returnDate = this.getProperFormatedDate(this.return_date);
    }

    if (inputParamater == 'COUNTRY') {
      if (this.checkCountryDueDelligenceStatus(this.selectedCountry)) {
        this.getSessionData();
        this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;
        if (this.selectedCountry != 'UA') {
          this.stepperDataCardModel.step01Data.hideProceedButton = false;
        }
        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
        this.childCallbackSender.emit('REFRESH_DATA');
      }
    }
    if (inputParamater == 'PURPOSE') {
            if (this.tripInfoRequestModel.subPurpose=='IMMIGRATION') {
              this.showEmigrationnote = true;
            } else {
                this.showEmigrationnote = false;
            }
            if(this.tripInfoRequestModel.subPurpose=='IMMIGRATION' || this.tripInfoRequestModel.subPurpose=='STUDENT_TRAVEL'){
              this.showReturnDate=false;
            }else{
              this.showReturnDate=true;

            }

      for (let i = 0; i < this.subCategoryListForexCard.length; i++) {
        if(this.tripInfoRequestModel.subPurpose == this.subCategoryListForexCard[i].subPurpose){
          this.tripInfoRequestModel.purpose = this.subCategoryListForexCard[i].purpose;
          this.tripInfoRequestModel.subPurpose = this.subCategoryListForexCard[i].subPurpose;

          this.quotationRequest.purpose =  this.subCategoryListForexCard[i].purpose;;
          this.quotationRequest.subPurpose = this.subCategoryListForexCard[i].subPurpose;
        }
      }
    }
    this.getSessionData();
    this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;
    // this.callQuotation();

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    sessionStorage.setItem("isPurposSelected",'true');
    this.childCallbackSender.emit('REFRESH_DATA');
  }

  getProperFormatedDate(ngbDateStruct) {
    var returnDate = ngbDateStruct.year;
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.month).slice(-2);
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.day).slice(-2);
    return returnDate;
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  async checkCountryDueDelligenceStatus(countryCode) {
    this.countryCodeForDueDelligence = countryCode;

    const respData = await this.remittanceService.getDueDelligenceStatus(countryCode).toPromise()
      .then((respData) => {
        return respData;
      }).catch((error) => {
        return error;
      });

    if (respData.status == 200) {
      this.sanctionedCountry = respData.body;
      if (countryCode == "UA") {
        this.openCrimeaRegionDailog();
      }

      if (countryCode != "UA" && this.sanctionedCountry.fatfSanctions == true) {
        this.openDueDeligenceDailog();
      }

      if (this.sanctionedCountry.enhanceDueDiligence == true) {
        this.showDueDeligenceForm = true;
      }
      return true;

    } else if (respData.status == 400) {
      this.openNoTransactionAllowedDailog();
      return false;
    }
  }

  openCrimeaRegionDailog() {
    this.modalRef = this.modalService.open(this.crimeaRegionDailog, {
    });
  }

  openDueDeligenceDailog() {
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }

    this.modalRef = this.modalService.open(this.dueDiligenceDialog, {
    });
  }

  openNoTransactionAllowedDailog() {
    this.getSessionData();
    this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;
    this.stepperDataCardModel.step01Data.hideProceedButton = true;
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    this.modalRef = this.modalService.open(this.noTransactionAllowedDialog, {
    });
  }

  agreeDueDiligence() {
    this.modalRef.close();
    this.showDueDeligenceForm = true;
    this.getSessionData();

    this.stepperDataCardModel.ofacCardData.isOfacRequired = true;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
  }

  callQuotation() {
    this.getSessionData();
    var quotationRequest = this.stepperDataCardModel.quotationRequest;
    for (let i = 0; i < quotationRequest.quotationCurrencies.length; i++) {
      quotationRequest.quotationCurrencies[i].purpose = this.stepperDataCardModel.step01Data.purpose;
      quotationRequest.quotationCurrencies[i].subPurpose = this.stepperDataCardModel.step01Data.subPurpose;
    }

    quotationRequest.paymentSourceDetails = this.stepperDataCardModel?.step02Data?.paymentSourceDetails;
    quotationRequest.calculateTcs = this.isCalculateTCS;
    console.log("Quotation request >>>>>>>>>", quotationRequest);

    this.cardService.getQuotation(quotationRequest).subscribe((respQuote) => {
      var quotationResponse = JSON.parse(JSON.stringify(respQuote.body));
      this.quotationCurrencyResponsesList = quotationResponse["quotationCurrencyResponses"];
      this.stepperDataCardModel.quotationResponse = quotationResponse;

      if (this.isCalculateTCS && quotationResponse["tcs"]["tcsAmountString"] != "0.00") {
        this.isTCSApplicable = true;
      } else {
        this.isTCSApplicable = false;
      }

      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
      this.refreshQuotationCount = this.refreshQuotationCount + 1;
      this.getSessionData();
      this.childCallbackSender.emit('REFRESH_DATA');

    }, (error) => {
      console.log("Error callQuotation >", error);
    });
  }

}