<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="row card-body">
                <div class="col-md-12">
                    <div class="fs-14 fw-600" style="color: #1d2a62;">Manage Card</div>
                </div>
                <div class="col-md-12" style="margin-top: -2%;">
                    <div class="content-wrapper container-xxl p-0">
                        <section id="wishlist" class="grid-view wishlist-items-formanage-card">
                            <div class="ecommerce-card dashboard-card card-manage-option" [ngClass]="{'hide-card-option': singleItem.operation == 'INTERNATIONAL' && cardType == 'studentPayCard', 'option-active': singleItem.status}" *ngFor="let singleItem of manageCardListItems; index as i">
                                    <div class="custom-control custom-control-primary custom-switch mt-10 text-right">
                                        <input type="checkbox" [checked]="singleItem.status"
                                            class="custom-control-input blueColor" id="{{singleItem.title}}"
                                            (change)="changeCardOptionStatus(singleItem, i)"
                                             /> <!-- [disabled]="cardLockFlag == 'L'" -->
                                        <label class=" custom-control-label " for={{singleItem.title}}></label>
                                    </div>
                                    <div style=" padding-top: 10px;" class=" mb-10">
                                        <div style=" padding-top:7px; margin-left: 7px ">
                                            <img src="{{ singleItem.studentIcon }}"
                                                style=" width: 50px; display: block; ">
                                        </div>
                                    </div>

                                    <div class=" card-body mr-37 manage-option-title"  [ngClass]="{'option-active': singleItem.status}" style=" padding:10px !important;">
                                        <div class=" fw-600 text-black fs-11 mb-0 ml-7 ">
                                            <strong>{{singleItem.title}}</strong>
                                        </div>
                                        <div class="ml-7">Transactions</div>
                                    </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div class="col-md-3">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left other-card-option">
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link-borderd-top" id="payer-details" data-toggle="pill"
                                aria-expanded="false" role="tab">
                                <!-- <i data-feather="unlock" class="font-medium-3 mr-1"></i> -->
                                <div class="card-option-icon lock-unlock"></div>
                                <span class="font-weight-bold fs-10">Lock/Unlock Card Temporarily</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="card card-body tab-pane collapse-icon" id="faq-payer-details"
                                    role="tabpanel" aria-labelledby="payer-details" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="unlock" class="font-medium-2"></i>
                                        </div>
                                        <div>
                                            <div class=" mb-0 text-black fw-600 fs-12">Lock/Unlock Card Temporarily
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <section>
                                                <!-- <div class="fw-600 content-header-title fs-11" *ngIf="cardLockFlag == 'UL'"> -->
                                                <div class="fw-600 content-header-title fs-11" *ngIf="cardLockFlag == 'UL'">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lock
                                                    your Card Temporarily
                                                </div>
                                                <div class="fw-600 content-header-title fs-11" *ngIf="cardLockFlag == 'L'">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unlock
                                                    your Card 
                                                </div>
                                                <div class="col-md-12 text-black fs-11 mb-20" *ngIf="cardLockFlag == 'UL'">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do
                                                    you wish to lock your card temporarily?
                                                </div>
                                                <div class="col-md-12 text-black fs-11 mb-20" *ngIf="cardLockFlag == 'L'">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do
                                                    you wish to unlock your card 
                                                </div>
                                                <div class="fw-600 col-md-12 fs-11 mt-5" *ngIf="cardLockFlag == 'UL'" style="color: #f35050;">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please
                                                    note: this action will disable the usage of your  WSFx <span *ngIf="cardType =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardType =='studentPayCard'">Student Pay Card</span> temporarily.
                                                </div>
                                                <div class="fw-600 col-md-12 fs-11 mt-5" *ngIf="cardLockFlag == 'L'" style="color: #f35050;">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please
                                                    note: This action will enable the usage of your <span *ngIf="cardType =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardType =='studentPayCard'">Student Pay Card</span>
                                                </div>
                                                <div class="fw-600 col-md-12 fs-11 mb-20" style="color: #f35050;" *ngIf="cardLockFlag == 'UL'">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
                                                    can unlock your card anytime.
                                                </div>
                                                
                                                <div class="col-md-12 text-black fs-11">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button *ngIf="cardLockFlag == 'UL'" type="button" rippleEffect
                                                        class="btn btn-primary fs-11"
                                                        (click)="temporaryBlockCard('BLOCK')">
                                                        Lock Card
                                                    </button>

                                                    <button *ngIf="cardLockFlag == 'L'" type="button" rippleEffect
                                                        class="btn btn-primary fs-11"
                                                        (click)="temporaryBlockCard('UNBLOCK')">
                                                        Unlock Card
                                                    </button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link-borderd d-flex py-75" id="lock-card-perm" data-toggle="pill"
                                aria-expanded="false" role="tab">
                                <!-- <i data-feather="lock" class="font-medium-3 mr-1"></i> -->
                                <div class="card-option-icon lock-permanently"></div>
                                <span class="font-weight-bold fs-10">Lock Card Permanently</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="card card-body tab-pane collapse-icon" id="faq-lock-card-perm"
                                    role="tabpanel" aria-labelledby="lock-card-perm" aria-expanded="false">

                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="lock" class="font-medium-2"></i>
                                        </div>
                                        <div>
                                            <div class="fw-600 fs-12 text-black mb-0">Lock Card Permanently</div>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <section>
                                                <section class="row">
                                                    <div class="col-md-12 fw-600 text-black fs-11 mb-10">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        Do you wish to block your card permanently?
                                                    </div>
                                                    <div class="col-md-12 text-black fs-11">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        In order to enable this action you will need to call us at
                                                        <span class="fw-600" *ngIf="cardType == 'smartCurrencyCard'">080-45651100</span><span class="fw-600" *ngIf="cardType == 'studentPayCard'">8976707222</span> or e-mail us with
                                                    </div>
                                                    <div class="col-md-12 text-black fs-11 mb-10">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        your request at <span
                                                            class="fw-600" *ngIf="cardType == 'smartCurrencyCard'">wsfxsmartcard@yesbank.in or </span><span
                                                            class="fw-600">customersupport@wsfx.in</span>.
                                                    </div>
                                                    <div class="col-md-12 fw-600 text-black fs-11 mb-10"
                                                        style="color: #f35050;">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        Please note : this action will disable the usage of your WSFx  <span *ngIf="cardType =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardType =='studentPayCard'">Student Pay Card</span> permanently.
                                                    </div>
                                                </section>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link-borderd d-flex py-75" id="set-daily-limit" data-toggle="pill"
                                aria-expanded="false" role="tab">
                                <!-- <i data-feather="trending-up" class="font-medium-3 mr-1"></i> -->
                                <div class="card-option-icon daily-limit"></div>
                                <span class="font-weight-bold fs-10">Set Daily Transaction Limit</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="card card-body tab-pane collapse-icon " id="faq-set-daily-limit"
                                    role="tabpanel" aria-labelledby="set-daily-limit" aria-expanded="false">

                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="trending-up" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <div class="mb-0 fw-600 fs-12">Set Daily Transaction Limit</div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-12 ">
                                            <set-daily-limit></set-daily-limit>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link-borderd d-flex py-75" id="set-pin" data-toggle="pill"
                                aria-expanded="false" role="tab">
                                <!-- <i data-feather="monitor" class="font-medium-3 mr-1"></i> -->
                                <div class="card-option-icon set-pin"></div>
                                <span class="font-weight-bold fs-10">Set Pin</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="card card-body tab-pane collapse-icon" id="faq-set-pin" role="tabpanel"
                                    aria-labelledby="set-pin" aria-expanded="false">
                                    <div class="d-flex align-items-center mb-20">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="monitor" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <div class="fw-600 fs-12 text-black mb-0">Set a new PIN for your WSFx <span *ngIf="cardType =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardType =='studentPayCard'">Student Pay Card</span></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" *ngIf="cardLockFlag == 'UL'">
                                            <section>
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label for="" class="fw-600 text-black">New PIN (4 Digit)</label>
                                                        <div
                                                            class="input-group input-group-merge form-password-toggle mb-2">
                                                            <input [type]="newPinShow ? 'text' : 'password'"
                                                                class="borderGlow form-control fs-11" name="pin"
                                                                [(ngModel)]="setPinRequest.newPin"
                                                                id="basic-default-password1"
                                                                placeholder="New PIN (4 Digit)" maxlength="4"
                                                                (keypress)="keyPressNumbers($event)" />
                                                            <div class="borderGlow input-group-append"
                                                                (click)="newPinShow = !newPinShow">
                                                                <span class="borderGlow input-group-text cursor-pointer"
                                                                    style="border: 1px solid black !important; height: 38px !important; "><i
                                                                        class="fa" [ngClass]="{
                                                                'fa-eye-slash': newPinShow,
                                                                'fa-eye': !newPinShow
                                                              }"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-5">
                                                        <label for="" class="fw-600 text-black">Confirm new PIN</label>
                                                        <div
                                                            class="input-group input-group-merge form-password-toggle mb-2">
                                                            <input [type]="confirmPinShow ? 'text' : 'password'"
                                                                class="borderGlow form-control fs-11" name="pin"
                                                                [(ngModel)]="setPinRequest.newPinConfirm"
                                                                placeholder="Confirm new PIN" maxlength="4"
                                                                (keypress)="keyPressNumbers($event)" />
                                                            <div class="borderGlow input-group-append"
                                                                (click)="confirmPinShow = !confirmPinShow">
                                                                <span class="borderGlow input-group-text cursor-pointer"
                                                                    style="border: 1px solid black !important; height: 38px !important; "><i
                                                                        class="fa" [ngClass]="{
                                                                'fa-eye-slash': confirmPinShow,
                                                                'fa-eye': !confirmPinShow
                                                              }"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mb-20" *ngIf="cardType == 'smartCurrencyCard'">
                                                        <label for="" class="fw-600 text-black">Card Expiry Date(MMYY)</label>
                                                        <input type="text" (keypress)="keyPressNumbers($event)" class="borderGlow form-control fs-11" maxlength="4"
                                                            [(ngModel)]="setPinRequest.expDate" name="card-expiry"
                                                            placeholder="Card Expiry Date(MMYY)" />
                                                    </div>
                                                    <div class="col-md-5 mb-20" *ngIf="cardType == 'smartCurrencyCard'">
                                                        <label for="" class="fw-600 text-black">Date of Birth</label>
                                                        <div class="input-group">
                                                            <input class="borderGlow form-control fs-11"
                                                                placeholder="Select Date of Birth"
                                                                (click)="startDatePicker.toggle()"
                                                                name="Start Date Picker" [(ngModel)]="setPinRequest.dob"
                                                                ngbDatepicker #startDatePicker="ngbDatepicker" readonly
                                                                [maxDate]="setPINDobMaxDate" [minDate]="setPINMinDate">
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="borderGlow btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                                                    (click)="startDatePicker.toggle()" type="button"
                                                                    rippleEffect></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="cardType == 'smartCurrencyCard'">
                                                    <div class="col-md-5 mb-10">
                                                        <label for="" class="borderGlow fw-600 text-black">OTP</label>
                                                        <input type="text" class="borderGlow form-control fs-11" name="otp"
                                                            [(ngModel)]="setPinRequest.otp" placeholder="Enter OTP"
                                                            maxlength="6" name="otp"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                        <div class="row">
                                                            <small class="col-md-6 form-text text-left green-text fs-8"
                                                                *ngIf="otpReceived">
                                                                An OTP is sent on {{ consumerData?.mobile }}
                                                            </small>
                                                            <small *ngIf="!otpReceived"
                                                                [ngClass]="{'col-lg-6 col-md-6 col-sm-6':otpReceived, 'col-lg-12 col-md-12 col-sm-12':!otpReceived}"
                                                                class=" col-md-6 form-text text-right blue-text fs-11 "
                                                                style="cursor:pointer; " (click)="sendOTP() ">
                                                                Send OTP
                                                            </small>
                                                            <small *ngIf="otpReceived"
                                                                class=" col-md-6 form-text text-right blue-text fs-11 "
                                                                style="cursor:pointer; " (click)="sendOTP() ">
                                                                Resend OTP
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 ">
                                                        <button type="submit " rippleEffect
                                                            class="btn btn-primary fs-12 " (click)="setPin() ">
                                                            Set PIN
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        <div class="col-md-12" *ngIf="cardLockFlag == 'L'">
                                            <p>Your card is temporarily Locked. Please Unlock your card to proceed!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item " ngbNavItem>
                            <a ngbNavLink class="nav-link-borderd-bottom d-flex py-75" id="replace-card" data-toggle="pill"
                                aria-expanded="false" role="tab ">
                                <!-- <i data-feather="credit-card" class="font-medium-3 mr-1 "></i> -->
                                <div class="card-option-icon replace-card"></div>
                                <span class="font-weight-bold fs-10 ">Replace Card</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="card card-body tab-pane collapse-icon" id="faq-replace-card" role="tabpanel"
                                    aria-labelledby="replace-card" aria-expanded="false ">
                                    <div class="d-flex align-items-center mb-20">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="credit-card" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <div class="mb-0 fw-600 fs-12">Replace Card</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <section>
                                                <section class="row ">
                                                    <div class="col-md-12 fs-10">
                                                        <div class="card-title fs-11 text-black ">Please let us know the
                                                            reason to replace your WSFx <span *ngIf="cardType =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardType =='studentPayCard'">Student Pay Card</span>:
                                                        </div>
                                                        <div class="text-black mb-10">
                                                            <input type="radio"
                                                                (change)="setReplacingReason('Card Expired')"
                                                                name="radios" id="radio" />
                                                            <span class="card-title fs-13 fw-600 text-black ml-1">
                                                                Card Expired
                                                            </span>
                                                        </div>
                                                        <div class="text-black mb-10 ">
                                                            <input type="radio"
                                                                (change)="setReplacingReason('Card Hot Listed')"
                                                                name="radios" id="radio" />
                                                            <span class="card-title fs-13 fw-600 text-black ml-1">
                                                                Card Hot Listed
                                                            </span>
                                                        </div>
                                                        <div class="text-black mb-10 ">
                                                            <input type="radio"
                                                                (change)="setReplacingReason('Card Damaged')"
                                                                name="radios" id="radio" />
                                                            <span class="card-title fs-13 fw-600 text-black ml-1">
                                                                Card Damaged
                                                            </span>
                                                        </div>
                                                        <div class="text-black mb-10 ">
                                                            <input type="radio" (change)="setReplacingReason('Other')"
                                                                name="radios" id="radio" [checked]="showCommentInput" />
                                                            <span class="card-title fs-13 fw-600 text-black ml-1">
                                                                Other
                                                            </span>
                                                        </div>
                                                        <div class="text-black mb-20 " *ngIf="showCommentInput">
                                                            <input type="text" class="form-control fs-11"
                                                                name="comments"
                                                                placeholder="Please provide more details"
                                                                [(ngModel)]="replaceCardOthersComment" />
                                                        </div>
                                                        <button type="submit" rippleEffect
                                                            class="btn btn-primary fs-12 "
                                                            (click)="replacingYourCard() ">Submit</button>
                                                    </div>
                                                </section>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>

               
                <div [ngbNavOutlet]="navVertical " class="col-12 col-md-9 "></div>
                <div class="d-flex justify-content-between ml-2">
                    <button (click)="goToPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                        <span class="align-middle">Back</span>
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</div>

<ng-template #disableCardModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">
            {{disableCardModalMsg}}
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 fw-500 fs-10 text-center blue-text btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>