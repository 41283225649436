<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-6 col-6">
                        <h6 class="content-header-title float-left mb-0">
                            Statement
                        </h6>
                    </div>
                    <!-- <div class="col-md-6 col-6 fs-12 link text-right">
                        <h6 class="blue-text"  (click)="downloadStatement()"  > Download Statement </h6>
                    </div> -->
                </div>
                <div class="row mb-20">
                    <div class="col-md-2 col-12">
                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencyCode" [searchable]="false">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                <img *ngIf="item.currencyCode != 'All Currencies'" src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <form class="form-horizontal" (ngSubmit)="fetchStatementList(false)">

                    <div class="row mt-20">
                        <div class="col-md-3 col-12">
                            <label for="">From</label>
                            <div class="form-group">
                                <div class="input-group">
                                    <input class="form-control fs-12" placeholder="Select Start Date" (click)="startDatePicker.toggle()" name="Start Date Picker" [(ngModel)]="startDate" ngbDatepicker #startDatePicker="ngbDatepicker" (dateSelect)="onDateSelect($event, 'startDate')" readonly
                                        [maxDate]="maxDate">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="startDatePicker.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-12">
                            <label for="">To</label>
                            <div class="form-group">
                                <div class="input-group">
                                    <input class="form-control fs-12" placeholder="Select End Date" (click)="endDatePicker.toggle()" name="End Date Picker" [(ngModel)]="endDate" ngbDatepicker #endDatePicker="ngbDatepicker" (dateSelect)="onDateSelect($event, 'endDate')" readonly [maxDate]="maxDate"
                                        [minDate]="minDateForEndDate">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="( enableEndDatePicker ) ? endDatePicker.toggle() : showErrorMessage()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-12 mt-23">
                            <button type="submit" class="btn btn-primary btn-prev btn-block fs-12" rippleEffect>
                                <span class="align-middle"  >Apply Filters</span>
                            </button>
                        </div>
                        <div class="col-md-2 col-12 mt-23">
                            <button type="button" *ngIf="!noDataFound" (click)="downloadStatement()" class="btn btn-primary btn-prev btn-block fs-12" rippleEffect>
                                <span class="align-middle">Downlaod Statement</span>
                            </button> 
                        </div>
                        <div class="col-md-2 col-12 mt-23">
                            <button type="button" *ngIf="!noDataFound" (click)="sendEmail()" class="btn btn-primary btn-prev btn-block fs-12" rippleEffect>
                                <span class="align-middle">Send an Email</span>
                            </button>
                        </div>
                    </div>
                </form>
                <loader *ngIf="showLoader"></loader>
                <div class="row">
                    <div class="col-md-12 text-center mt-20" *ngIf="noDataFound">
                        <div class="text-black fw-500 ">
                            <div class="fs-12"> No statement found </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12 mt-20 border-bottom-grey" *ngFor="let singleStatement of statementList">
                        <div class="" style="cursor: pointer; box-shadow: 0 0 0 0 !important;">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-title fs-13 fw-600 text-black">Balance : {{singleStatement.balance}} </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Beneficiary Name : </span>
                                    <span class="fs-11 text-black">{{singleStatement.beneficiaryName}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Description : </span>
                                    <span class="fs-11 text-black">{{singleStatement.description}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12 mb-20">
                                    <span class="card-text fs-11 text-black fw-600">Reference number : </span>
                                    <span class="fs-11 text-black">{{singleStatement.txRef}} </span>
                                    <span class="fs-11 text-danger fw-500" (click)="goToRaiseDispute(singleStatement)"> Dispute</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>