import { Component, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { fadeInLeft, zoomIn, fadeIn } from '@core/animations/core.animation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationStart, Router, Event as NavigationEvent } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInLeft, zoomIn, fadeIn]
})
export class ContentComponent {
  public coreConfig: any;
  public animate;
  public hideButton: boolean = false;
  private routerEvent;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   *
   */
  constructor(private _coreConfigService: CoreConfigService, public router: Router) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.routerEvent = this.router.events.subscribe(
      (event: NavigationEvent) => {
        if(event instanceof NavigationStart) {

          let currentPage = sessionStorage.getItem(ConstantsKey.CURRENT_PAGE_URL);
          if(currentPage) {
            if(currentPage !== event.url) {
              sessionStorage.setItem(ConstantsKey.PREV_PAGE_URL, currentPage);
            }
          } else {
            sessionStorage.setItem(ConstantsKey.PREV_PAGE_URL, event.url);
          }
          sessionStorage.setItem(ConstantsKey.CURRENT_PAGE_URL, event.url);
          var sliceUrl = event.url.slice(0, 18);
          if (event.url == '/login' || event.url == '/' || event.url == '/support' || sliceUrl == '/email-login?data=') {
            this.hideButton = true;
          } else {
            this.hideButton = false;
          }
        }
      }
    );
  }

  /**
   * Fade In Left Animation
   *
   * @param outlet
   */
  fadeInLeft(outlet) {
    if (this.animate === 'fadeInLeft') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Zoom In Animation
   *
   * @param outlet
   */
  zoomIn(outlet) {
    if (this.animate === 'zoomIn') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet) {
    if (this.animate === 'fadeIn') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.animate = this.coreConfig.layout.animation;
    });
  }

  goToSupport() {
    this.router.navigate(['/support'])
  }  

  ngOnDestroy() {
    this.routerEvent.unsubscribe();
  }

}
