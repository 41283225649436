import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';


@Component({
  selector: 'globalpay-features',
  templateUrl: './globalpay-features.component.html',
  styleUrls: ['./globalpay-features.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalPayFeaturesComponent implements OnInit {
  public showLoader: boolean = false;
 
  public menuItems = [
    {
      'icon': 'globalpayfeatures1.png',
      'title': 'Zero Cross Currency Fees',
      'url': '',
    },
    {
        'icon': 'globalpayfeatures2.png',
        'title': 'Reload Card Anytime / Anywhere',
        'url': '',
      },
    {
      'icon': 'globalpayfeatures3.png',
      'title': 'Tap & Pay',
      'url': '',
    },
    {
      'icon': 'globalpayfeatures4.png',
      'title': 'App Enabled Card Management',
      'url': '',
    },
    {
      'icon': 'globalpayfeatures5.png',
      'title': 'Chip & Pin - Based Card',
      'url': '',
    },
   
    {
      'icon': 'globalpayfeatures6.png',
      'title': 'Real Time Transaction alerts',
      'url': '',
    },
    {
      'icon': 'globalpayfeatures7.png',
      'title': 'Emergency Cash(GCAS)',
      'url': '',
    },
    {
      'icon': 'globalpayfeatures8.png',
      'title': 'Card Insurance Coverage upto 5Lac',
      'url': '',
    },
    {
      'icon': 'globalpayfeatures9.png',
      'title': '3D Secure Authentication',
      'url': '',
    },
    {
        'icon': 'globalpayfeatures10.png',
        'title': 'Not Linked To Your Bank Account',
        'url': '',
      },
      {
        'icon': 'globalpayfeatures11.png',
        'title': 'Surcharge Free withdrawals at AllPoint ATMs',
        'url': '',
      },
      {
        'icon': 'globalpayfeatures12.png',
        'title': 'Claim Management',
        'url': '',
      },
    
  ];
 

  constructor(
    public router: Router,
    public toast: ToastrService,
    private _location: Location
   
  ) { }

  ngOnInit() {
    this.getProfileList();
  }

 
  getProfileList() {
   
  }

 

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  goToBackPage() {
    this._location.back();
  }

  
}
