<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 mb-20 text-center">
                        <h6 class="content-header-title  mb-0 ml-10" style="color: black">Currency Converter</h6>
                    </div>

                    <div class="col-md-4 offset-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" [(ngModel)]="surrenderingCurrencyCode" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Surrender Currency" [searchable]="false">
                                    <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                    </ng-template>
                                </ng-select>
                            </div>


                            <div class="col-md-12 mt-15">
                                <input type="text" name="amount" class="form-control fs-12" style="border-color:black" placeholder="Enter Amount" (keypress)="keyPressNumbers($event)" required [(ngModel)]="amount">
                            </div>

                            <div class="col-md-12 mt-15">
                                <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" [(ngModel)]="requiredCurrencyCode" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Required Currency" [searchable]="false">
                                    <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                    </ng-template>
                                </ng-select>
                            </div>

                            <div class="col-md-12 mt-10">
                                <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100 mt-10" (click)="convertCurrency()">
                                    Convert
                                </button>
                            </div>
                            <div class="col-md-12">
                                <div class="input-group  mt-30">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-inr" id="basic-addon3"> 
                                              {{requiredCurrencyCode}}
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" [(ngModel)]="convertedValue" value={{convertedValue}} readonly />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <small class="form-text text-muted fs-9 text-center fw-600">
                                    *Charges and Taxes will be applicable 
                                </small>
                            </div>

                            <div class="col-md-12">
                                <small class="form-text text-black20 fs-9 text-center fw-600" *ngIf="surrenderingCurrencyCode && requiredCurrencyCode && currencyConverterResponse">
                                    {{surrenderingCurrencyCode}} 1= {{requiredCurrencyCode}} {{currencyConverterResponse?.conversionRate}}
                                </small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>