import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CardBlockModel, CartItemCreateRequestList, ManageCardUpdateModel, OTPRequestModel, QuotationCurrenciesRequestModel, QuotationRequestModel, RemittanceStep01Model, ReplaceCardModel, SetCardLimitModel, SetNewPinModel } from '../../models/card.model';
import { CardService } from '../../services/card.service';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { Constants } from 'app/shared/constant/constants';
import { NewDashboardPurposeList } from 'app/shared/constant/new-dashboard-menu';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { CryptoUtils } from "app/shared/utility/crypto.utils";
import moment from 'moment';

@Component({
  selector: 'my-card',
  templateUrl: './my-card.component.html',
  styleUrls: [
    '../../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})


export class MyCardComponent implements OnInit {  

  public cardDetails: any;
  public isDataLoaded: boolean = false;
  public showLoader: boolean = false;
  public showLoaderForCardDetails: boolean = false;
  public recentTransactionList: any;
  public showManageCardSection: boolean = true;
  public kitId: any;
  public cardLockFlag: any;
  public inrWalletStatus: any;
  public consumerData: any;
  public showBuyCard: boolean = false;
  public countryCodeForCurrencyWallet: string;

  public manageCardUpdateModel = new ManageCardUpdateModel();
  public setPinRequest = new SetNewPinModel();
  public oTPRequestModel = new OTPRequestModel();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public cardBlockModel = new CardBlockModel();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();

  public otpReceived: boolean = false;
  public setPINDobMaxDate: any;
  public setPINMinDate: any;
  public selectedPurposeItem: any;
  public modalReference: any;
  public currencyItems: any;
  public walletOTP: any;
  disableCardModalMsg: any;
  public replacingReason: string;
  public replaceCardOthersComment: string;
  public vcipStatusDetails: any;
  public vcipMessage: any;
  public currencyAmount;
  public currencySelectedObj: any;
  public vcipAccepted: any;
  public currencySelected: any;
  public quotationResponse: any;
  public quotationSnapshotId: any;
  public consumerDetail: any;
  public selectedCurrency: any;

  public consumerPanSelf: string;
  public tcsPaymentSource = 'OWN';
  public quotationCurrencyResponsesList = [];
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();

  public showButtonOnVCIP: boolean = false;
  public newPinShow: boolean = false;
  public confirmPinShow: boolean = false;
  public showPricing: boolean = false;
  public showCommentInput: boolean = false;
  public showBuyForexForm: boolean = false;
  public activeBuyForexCard: boolean = false;
  public showMore : boolean = false;

  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public remittanceStep01: RemittanceStep01Model = new RemittanceStep01Model();
  public quotationRequest = new QuotationRequestModel();
  public quotationRequestModel: QuotationRequestModel;
  public disableCardFunctionality: boolean = false;
  public isCorporateCustomer: boolean = false;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public isCardFaceSlide: boolean = true;
  public cardNumbers = [];
  public expDate; 
  public cvvCode;
  public noDataFound;

  @ViewChild('disableCardModal') disableCardModal: TemplateRef<any>;
  @ViewChild('disableINRWallet') disableINRWallet: TemplateRef<any>;
  @ViewChild('currencyWalletModal') currencyWalletModal: TemplateRef<any>;


  public modalRef: any;

  public manageCardListItems = [
    { 'operation': 'ATM', 'title': 'ATM Transactions', 'icon': 'assets/images/manage-card/atm-transactions.png', 'status': true },
    { 'operation': 'ECOM', 'title': 'Online Transactions', 'icon': 'assets/images/manage-card/Online-transactions.png', 'status': true },
    { 'operation': 'INTERNATIONAL', 'title': 'International Transactions', 'icon': 'assets/images/manage-card/International-Transactions.png', 'status': true },
    { 'operation': 'POS', 'title': 'Physical Swipe Transactions', 'icon': 'assets/images/manage-card/swipe-transactions.png', 'status': true },
    { 'operation': 'CONTACTLESS', 'title': 'Contactless Transactions', 'icon': 'assets/images/manage-card/contactless-transactions.png', 'status': false },
  ];

  constructor(
    private cardService: CardService,
    private masterDataService: MasterDataService,
    private router: Router,
    public updateProfileService: UpdateProfileService,
    public toast: ToastrService,
    public modalService: NgbModal
  ) {
    this.currencySelected = "USD";

    this.setPINMinDate = { year: 1900, month: 1, day: 1 };
    this.setPINDobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }
  }

  ngOnInit() {
    this.disableCardModalMsg = "Your card is temporarily Locked. Please Unlock your card to proceed!";
    this.getLocalData();
    this.getCardDetails();
    // this.getCurrencies();
    this.getRateRange();
    this.getVCIPStatus();
    this.getProfileDetails();

    this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType('STUDENT');
    console.log(this.selectedPurposeItem);
  }

  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);
    console.log("this.consumerData ", this.consumerData);
  }

  getCardDetails() {
    this.showLoaderForCardDetails = true;

    this.cardService.getCardDetailsEncrypted().subscribe((respCardData => {
      /* var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      console.log(responseBody);

      this.isDataLoaded = true;
      
      if (responseBody.length > 0) {
        this.cardDetails = responseBody[0];
        if (responseBody[0].cardLockFlag == 'L') {
          this.disableCardFunctionality = true;
        } else {
          this.disableCardFunctionality = false;
        }
        this.showLoaderForCardDetails = false;
        this.recentTransactionList = this.cardDetails.paxStatementSnippets;

        this.kitId = this.cardDetails['kitId'];
        this.cardLockFlag = this.cardDetails['cardLockFlag'];
        this.inrWalletStatus = this.cardDetails['inrWalletActive'];

        this.getCardStatus();
      } else {
        // this.router.navigate(['/dashboard'])
        this.showBuyCard = true;
      } */
    }), error => {
      
      this.showLoaderForCardDetails = false;
      if (error.status == 200) {
        let responseBody = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        console.log(responseBody);
  
        this.isDataLoaded = true;
 
    

        if (responseBody.length > 0) {
          this.cardDetails = responseBody[0];
          if (responseBody[0].cardLockFlag == 'L') {
            this.disableCardFunctionality = true;
          } else {
            this.disableCardFunctionality = false;
          }
          
          this.cardNumbers =  this.cardDetails["cardNumber"].split("-");
          this.expDate = this.cardDetails["expiryDate"];
          this.cvvCode = "***";
          this.showLoaderForCardDetails = false;
          this.recentTransactionList = this.cardDetails.paxStatementSnippets;
  
          this.kitId = this.cardDetails['kitId'];
          this.cardLockFlag = this.cardDetails['cardLockFlag'];
          this.inrWalletStatus = this.cardDetails['inrWalletActive'];
  

          let validCurrencyList = JSON.parse(localStorage.getItem("validCardCurrencyList"));
          let oldCardObj = this.cardDetails?.cardWallet;
          for(let i = 0; i < oldCardObj.length; i++) {
            oldCardObj[i]["currencyName"] = validCurrencyList.filter((data) => data.code == oldCardObj[i].currencyCode)[0].currencyName;
          }
          this.cardDetails.cardWallet = oldCardObj.filter(e => e.currencyCode != "SEK");
          this.getCardStatus();
        } else {
          // this.router.navigate(['/dashboard'])
          this.showBuyCard = true;
        }
      } else {
        //this.showLoaderForCardDetails = false;
        this.router.navigate(['/home'])
          .then(() => {
            window.location.reload();
          });
        this.toast.error(error.error.message);
      }
    });;
  }
  

  getCardStatus() {
    this.showLoader = true;
    this.cardService.getManageCardOptions(this.kitId).subscribe(respCardOption => {

      var settingList = JSON.parse(JSON.stringify(respCardOption.body));
      this.showLoader = false;

      settingList.forEach((value, index) => {
        if (value.operation == 'ATM') {
          this.manageCardListItems[0]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
        }

        if (value.operation == 'ECOM') {
          this.manageCardListItems[1]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
        }

        if (value.operation == 'INTERNATIONAL') {
          this.manageCardListItems[2]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
        }

        if (value.operation == 'POS') {
          this.manageCardListItems[3]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
        }

        if (value.operation == 'CONTACTLESS') {
          this.manageCardListItems[4]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
        }
      });
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  gotoTansferBalance(selectedCurrencyDetails) {
    this.modalRef.close();
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/transfer-balance'], { queryParams: selectedCurrencyDetails });
    } else {
      this.showCardBlockPopup();
    }
  }

  navigateAddBalanceToCard(singleCurrency) {
    this.modalRef.close();
    if (!this.disableCardFunctionality) {
      sessionStorage.clear();
      localStorage.removeItem('cartCurrenciesList')
      console.log("singleCurrency add balance >", singleCurrency);
      localStorage.setItem(ConstantsKey.CARD_ADD_BALANCE_CURRENCY, JSON.stringify(singleCurrency));
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'true');
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
      sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
      this.router.navigate(['/view-cart']);
    } else {
      this.showCardBlockPopup();
    }

  }
  toggleView() {
    this.showMore = !this.showMore;
  }

  changeCardOptionStatus(selectedCard) {
    this.showLoader = true;
    this.manageCardUpdateModel.cardManageOperations = selectedCard.operation;
    this.manageCardUpdateModel.cardSwitch = (!selectedCard.status) ? 'UNBLOCK' : 'BLOCK';
    this.manageCardUpdateModel.comment = 'USER UPDATE';
    this.manageCardUpdateModel.kitId = this.kitId;

    this.cardService.manageCardUpdate(this.manageCardUpdateModel).subscribe(resp => {
      this.showLoader = false;
      this.getCardStatus();
      this.toast.success(selectedCard.title + " " + (this.manageCardUpdateModel.cardSwitch).toLowerCase() + " successfully!");
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  setPin() {
    if (this.setUpPinValidation()) {
      this.showLoader = true;
      const setpinModel = new SetNewPinModel();
      setpinModel.newPin = btoa(this.setPinRequest['newPin']);
      //setpinModel.newPinConfirm = this.setPinRequest['newPinConfirm'];
      setpinModel.kitNo = this.kitId;
      setpinModel.expDate = this.setPinRequest['expDate'];
      setpinModel.dob = DateUtils.getNgbDateStructToCardPinDate(this.setPinRequest['dob']);
      setpinModel.otp = this.setPinRequest['otp'];
      console.log("setpinModel>>>>"+ JSON.stringify(setpinModel));        
      return this.cardService.changeCardPin(setpinModel).subscribe(data => {
        this.showLoader = false;
      }, error => {
                this.showLoader = false;
        if (error.status == 200) {
            this.toast.success('Card PIN is set successfully');

        } else {
          this.toast.error(error.error.message);
        }
        
      });
    }
  }

  setUpPinValidation() {
    if (this.setPinRequest['newPin'] == null) {
      this.toast.error('Please enter new pin');
      return false;
    }

    if (this.setPinRequest['newPinConfirm'] == null) {
      this.toast.error('Please enter confirm new pin');
      return false;
    }

    if (this.setPinRequest['expDate'] == null) {
      this.toast.error('Please enter card expiry date');
      return false;
    }

    if (this.setPinRequest['dob'] == null) {
      this.toast.error('Please enter date of birth!');
      return false;
    }

    if (this.setPinRequest['otp'] == null) {
      this.toast.error('Please enter the OTP received');
      return false;
    }

    if (this.setPinRequest['newPin'] != this.setPinRequest['newPinConfirm']) {
      this.toast.error('New pin & confirm pin does not match.');
      return false;
    }

    return true;
  }

  sendOTP() {
    this.oTPRequestModel.authType = 'MOBILE';
    this.oTPRequestModel.id = this.consumerData?.mobile;
    this.oTPRequestModel.otpRequestReason = 'SET_CARD_PIN_OTP';

    this.cardService.getOTP(this.oTPRequestModel).subscribe(respOtp => {
      var responseBody = respOtp.body;
      this.otpReceived = true;
      this.toast.success('OTP sent successfully.');
    });
  }

  setReplacingReason(replacingReason: string) {
    this.replacingReason = replacingReason;
    if (replacingReason == 'Other') {
      this.showCommentInput = true;
    } else {
      this.showCommentInput = false;
    }
  }

  replacingYourCard() {
    const cardReplaceModel = new ReplaceCardModel();

    if (this.replacingReason.length > 0) {
      if (this.replacingReason == 'Other') {
        if (this.replaceCardOthersComment && this.replaceCardOthersComment.length > 0) {
          this.replacingReason = this.replaceCardOthersComment;
          cardReplaceModel.reason = this.replacingReason;
          cardReplaceModel.kitId = this.kitId;

          this.cardService.replaceCard(cardReplaceModel).subscribe(respData => {
            console.log(respData);
            this.toast.success('Card replacement request has been placed.');
            this.replaceCardOthersComment = null;
            this.showCommentInput = false;
          });
        } else {
          this.toast.error('Kindly provide the reason.');
        }
      } else {
        cardReplaceModel.reason = this.replacingReason;
        cardReplaceModel.kitId = this.kitId;
        this.cardService.replaceCard(cardReplaceModel).subscribe((respData) => {
          this.toast.success('Card Replacment request has been placed.');
        });
      }
    } else {
      this.toast.error('Kindly provide the reason.');
    }
  }

  openModalForActiveINRWallet(activeINRWalletModal) {
    if (!this.disableCardFunctionality) {
      // this.modalReference = this.modalService.open(activeINRWalletModal, { centered: true });
      // this.getOTPForINRWallet();
      //this.showDisableINRWalletPopup();
     this.router.navigate(['/activate-inr-wallet'], { queryParams: this.consumerDetail?.paxResponse })


    } else {
      this.showCardBlockPopup();
    }
  }

  getOTPForINRWallet() {
    this.cardService.getINRWalletOTP().subscribe((resp => {
      this.toast.success('OTP has been sent successfully!');
    }), error => {
      this.toast.error(error.error.message);
    });
  }

  validateOTP() {
    this.cardService.validateActiveINRWalletOTP(this.walletOTP).subscribe((resp => {
      this.getCardDetails();
    }), error => {
      this.toast.error(error.error.message);
    });
  }
  
  openCurrencyWalletModal(singleCurrency) {
    console.log(singleCurrency);
    this.selectedCurrency = singleCurrency;
    this.modalRef = this.modalService.open(this.currencyWalletModal , {centered:true, size:'md',  windowClass: 'currency-wallet-modal'});
  }
 

  cashoutToINR() {
    this.router.navigate(['/cashout-to-inr-wallet']);
  }

  temporaryBlockCard(cardSwitch) {
    this.cardBlockModel.cardManageOperations = 'BLOCK';
    this.cardBlockModel.cardSwitch = cardSwitch;
    this.cardBlockModel.kitId = this.kitId;
    this.cardBlockModel.comment = 'USER ' + cardSwitch;

    this.cardService.temporaryBlockCard(this.cardBlockModel).subscribe(data => {
      var msgDisplay;
      if (cardSwitch == 'BLOCK') {
        msgDisplay = ' Your card is locked successfully!';
      } else {
        msgDisplay = ' Your card is unlocked successfully!';
      }
      this.toast.success(msgDisplay);
      this.getCardDetails();
    });
  }

  


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.vcipStatusDetails = resp.body;
      this.vcipMessage = this.vcipStatusDetails.message;
      this.showButtonOnVCIP = this.vcipStatusDetails.showBtnOnStatus;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.vcipAccepted = true;
      } else {
        this.vcipAccepted = false;
      }
    }), error => {
      this.showLoader = false;
    });
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
      console.log('SINGLE RATE >> ', this.singleRateResItem);

    });
  }


  showHidePricingRange(showPricing: boolean) {
    this.showPricing = showPricing;
  }

  getCurrencyFields() {
    this.currencyAmount = '';
    this.showPricing = false;
  }

  callQuotation() {
    this.showLoader = true;
    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
    if (this.currencySelected) {
      if (this.currencyAmount && this.currencyAmount >= 0) {
        let orderRequestList = [];
        let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
        singleRequestCurrency.currencyAmount = this.currencyAmount;
        singleRequestCurrency.currencyCode = this.currencySelectedObj.currencyCode;
        singleRequestCurrency.product = "FOREXCARD";
        singleRequestCurrency.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR)

        singleRequestCurrency.purpose = (this.remittanceStep01.purpose) ? this.remittanceStep01.purpose : 'UNIVERSITY_FEES';
        orderRequestList.push(singleRequestCurrency);

        this.quotationRequest.sellType = Constants.sellTypes[0];
        this.quotationRequest.partnerId = Constants.partnerId;
        this.quotationRequest.nostroChargeType = "REMITTER"
        this.quotationRequest.quotationCurrencies = orderRequestList;

        this.quotationRequest.pan = this.consumerPanSelf;
        this.quotationRequest.tcsPaymentSource = this.tcsPaymentSource;

        if (this.quotationResponse) {
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }

        this.cardService.getQuotation(this.quotationRequest).subscribe((respQuote) => {
          this.showLoader = false;

          this.quotationResponse = respQuote.body;
          this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
          this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }, error => {
          this.showLoader = false;
          this.showPricing = false;
          this.toast.error(error.error.message);
        });

        this.showPricing = true;
      }
      else {
        this.showLoader = false;
        this.toast.error("Please fill in the currency amount.");
      }
    }
    else {
      this.showLoader = false;
      this.toast.error("Please fill in the currency");
    }
  }

  getSelectedCurrencyObj(currencyCode: string) {
    var returnCurrencyObj: any;
    this.currencyItems.forEach(singleCurreny => {
      if (singleCurreny.currencyCode == currencyCode) {
        returnCurrencyObj = singleCurreny;
      }
    });
    return returnCurrencyObj;
  }


  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    this.isCorporateCustomer = this.consumerDetail['corporateCustomer'];
    console.log("this.consumerDetail >>", this.consumerDetail);

    return true;
  }

  async proceedToBuySelectedCard() {

    var vcipKycStatus = this.consumerDetail?.vcipKyc;
    if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
      vcipKycStatus = true;
    } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
      vcipKycStatus = true;
    }
    if (vcipKycStatus) {
      this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);

      this.quotationRequestModel = new QuotationRequestModel();
      this.quotationRequestModel.sellType = Constants.sellTypes[0];
      this.quotationRequestModel.partnerId = Constants.partnerId;

      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
      quotationCurrencies.currencyAmount = this.currencyAmount;
      quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
      quotationCurrencies.product = Constants.productsEnum[0];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
      sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
      this.router.navigate(['/view-cart']);
    } else {
      this.toast.error('Kindly complete your profile and VCIP!');
    }
  }

  showBuyForex() {
    this.showBuyForexForm = true;
    this.activeBuyForexCard = true;
  }


  showCardBlockPopup() {
    this.modalReference = this.modalService.open(this.disableCardModal, { centered: true });
  }
  showDisableINRWalletPopup() {
    this.modalReference = this.modalService.open(this.disableINRWallet, { centered: true });
  }

  goToStateMentPage() {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/statement']);
    } else {
      this.showCardBlockPopup();
    }
  }

  goToCashout() {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/cashout']);
    } else {
      this.showCardBlockPopup();
    }
  }

  goToManageCard() {
          this.router.navigate(['/manage-card']);

    // if (!this.disableCardFunctionality) {
    //   this.router.navigate(['/manage-card']);
    // } else {
    //   this.showCardBlockPopup();
    // }
  }
  goToFeatures(){
    this.router.navigate(['/my-features'])
  }
  goToFaqs(){
    this.router.navigate(['/my-faqs'])
  }
   
  bottomFunction() {
    this.showManageCardSection = true;
     window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  
  // window.scrollTo(0, document.body.scrollHeight);
  //window.scrollBy(0, 50);
 }
scrollToTopFunction() {
     window.scrollTo({ top: 0, behavior: 'smooth' })
  
  // window.scrollTo(0, document.body.scrollHeight);
  //window.scrollBy(0, 50);
 }


  navChanged(tabName) {
    if (tabName) {
      if (!this.disableCardFunctionality) {

      } else {
        this.showCardBlockPopup();
      }
    }
  }

  changeCardSlide(isSlide) {
    this.isCardFaceSlide = isSlide;
  }

  fetchStatementList() {
    this.showLoader = true;

    let dateFrom = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD');
    let dateTo = moment(new Date()).format('YYYY-MM-DD');

    this.cardService.fetchStatement(dateFrom, dateTo, false, 'All Currencies').subscribe((resp => {

    }), error => {
       this.showLoader = false;
       this.noDataFound = false;

      if (error.status == 200) {
              var resp = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            this.recentTransactionList = resp['paxStatementSnippets'];
            if(this.recentTransactionList.length == 0){
              this.noDataFound = true;
            } else {
              this.noDataFound = false;
            }
      } else {
            this.toast.error(error.error.message);
      }
    });
  }  

  changeTab(event) {
    if(event.nextId == "2") {
      this.fetchStatementList();
    }
  }

}
