import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { CardService } from '../../services/card.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { OrderService } from 'app/modules/order/services/order.service';
import { ToastrService } from 'ngx-toastr';

import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Constants } from 'app/shared/constant/constants';

@Component({
    selector: 'cards',
    templateUrl: './card-list.component.html',
    styleUrls: ['./card-list.component.scss'],
    // encapsulation: ViewEncapsulation.None
  })
  export class CardListComponent implements OnInit {

  public showLoader: boolean = false;
  public reqCurrencyAmount: any;
  public previousUrl: string = "";
  public consumerData: any;
  public isSmartCardAvl: boolean = false;
  public isStudentPayAvl: boolean = false;
  public isTravelPayAvl: boolean = false;
  public isGlobalPayAvl: boolean = false;

  public isApplyForCard: boolean = false;
  public allCard: any;
  public cards;

  @ViewChild(SwiperComponent) cardListRef: SwiperComponent;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    // autoHeight: true,
    slidesPerView: 2,
    keyboard: false,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
    centeredSlides: true,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        centeredSlides: false,
        // spaceBetween: 20
      },
      480: {
        slidesPerView: 1.2,
        spaceBetween: 30,
        centeredSlides: false,
      },
      640: {
        slidesPerView: 3,
        scrollbar: true, 
        mousewheel: false,
        centeredSlides: false,
        spaceBetween: 5
      }
    }
  };

  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cardService: CardService,
    private profileService: UpdateProfileService,
    private orderService: OrderService,
        public toast: ToastrService) {
  }

  ngOnInit() {
    this.getLocalData();
    this.getAllCardDetails();
    this.cards = Constants.cardData;
  }

  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);
    console.log("this.consumerData ", this.consumerData);
  }

  getSessionData() {

  }


  cardBuy(cardType) {
    //sessionStorage.setItem(ConstantsKey.MY_CARD_CHOOSE, cardType);
    let card = this.cards.filter(obj => obj.card == cardType)[0];
    sessionStorage.setItem(ConstantsKey.WSFX_SELECTED_CARDS, JSON.stringify(card));
    this.router.navigate(['/newcard']);
  }

  goToLinkExistingCard(cardType) {
    let card = this.cards.filter(obj => obj.card == cardType)[0];
    sessionStorage.setItem(ConstantsKey.WSFX_SELECTED_CARDS, JSON.stringify(card));
    if(cardType == 'SMART_CARD') {
      this.router.navigate(['/link-existing-card']);
    } else if(cardType == 'STUDENT_CARD') {
      this.router.navigate(['/link-student-card']);
    } else if(cardType == 'TRAVEL_CARD') {
      // this.router.navigate(['/newcard']);
    } else if(cardType == 'GLOBAL_CARD') {
      this.router.navigate(['/link-student-card']);
    }
  }

  goToMyCard(cardType) {
    //sessionStorage.setItem(ConstantsKey.MY_CARD_CHOOSE, cardType);    
    let card = this.cards.filter(obj => obj.card == cardType)[0];
    sessionStorage.setItem(ConstantsKey.WSFX_SELECTED_CARDS, JSON.stringify(card));
    this.router.navigate(['/my-card']);
  }

  goToFeatures(cardType) {
    if(cardType == 'SMART_CARD') {
      this.router.navigate(['/my-features']);
    } else if(cardType == 'STUDENT_CARD') {
      this.router.navigate(['/newfeatures']);
    } else if(cardType == 'TRAVEL_CARD') {
      this.router.navigate(['/travelpay-features']);
    } else if(cardType == 'GLOBAL_CARD') {
      this.router.navigate(['/globalpay-features']);
    }
  }


  getAllCardDetails() {
    this.cardService.getAllCardDetail().subscribe(resp => {
      let smartCard = false;

      this.allCard = resp.body;
      if(this.allCard.length > 0) {
          for(let i=0 ;i < this.allCard.length ;i++) {
            if(this.allCard[i].cardVendor == 'WSFLMCC') {
              smartCard = true;
              this.isSmartCardAvl = true;
            } else if(this.allCard[i].cardVendor == 'WSFXSTUDENTSCC') {
              this.isStudentPayAvl = true;
            } else if(this.allCard[i].cardVendor == 'WSFXTRAVELSCC') {
              this.isTravelPayAvl = true;
            } else if(this.allCard[i].cardVendor == 'WSFXGLOBALSCC') {
              this.isGlobalPayAvl = true;
            }
          }
          //If card not foudn in smart card
          if(!smartCard) {
            this.getCardDetails();
          }          
      } else {
        this.getCardDetails();
      }

    },
    errObj =>  {
      this.toast.error(errObj.error.message);
    });
  }

 
  getCardDetails() {
    //this.showLoaderForCardDetails = true;

    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.isSmartCardAvl = true;
      }
    }), error => {
      //this.showLoaderForCardDetails = false;
    });;
   }

   public onIndexChange(index: number) {
    console.log('Swiper index: ', index);
  }

}