import { Injectable } from "@angular/core";

@Injectable()
export class EncApiList {
    public encApi = [
        "b2c/consumer/otpLogin",
        "b2c/travelInsuranceMyOrders",
        "b2c/travelInsuranceForm",
        "b2c/insuranceCartPlaceOrder",
        "b2c/validateCustomer",
        "b2c/pan?pan=",
        "b2c/consumers/pax",
        "b2c/quotationsV2",
        "b2c/walletToWalletTransfer",
        "b2c/activateInrWalletNew",
        "b2c/inrWalletForm",
        "b2c/setPin",
        "b2c/cardLimit?currency=",
        "b2c/cardLimit",
        "b2c/consumers/",
        "b2c/fetchStatementPdf",
        "b2c/updateConsumerReferrer",
        "b2c/getCardDetails"    
    ];
}