import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { OrderItemModel, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationRequestModel, QuotationResponseModel, WalletTransferRequestModel } from '../../models/card.model';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { Constants } from 'app/shared/constant/constants';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'cashout',
  templateUrl: './cashout.component.html',
  styleUrls: [
    // '../../../../../assets/scss/select.component.scss',
    '../card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CashoutComponent implements OnInit {

  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  // public currencyItems: any;
  public bankAccountItems = [];
  public showBankDetails: boolean = false;
  public showAddBankAccountForm: boolean = false;
  public cryptoUtils: CryptoUtils = new CryptoUtils();

  public showLoader: boolean = false;

  public cardTransferList = [];
  public cardWalletList = [];
  public cardDetailsObject = [];
  public cardDetailsList = [];
  public branchAddressList = [];
  public branchId: string ;
  public kitId: any;

  public quotationCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse = new QuotationResponseModel();
  public orderRequestModel = new OrderRequestModel();
  public orderRequestList = new Array<OrderRequestModel>();
  public quotationCurrencyResponsesList = [];
  public checked: boolean;
  public check: boolean;
  public index: any;
  public transferCurrency: any;
  public currencyBalance;
  public quotationCurrencies: QuotationCurrenciesRequestModel;
  public isQuotationAvailable = false;
  public quotationDisplay: any;
  public quotationCurrencyResponse = [];
  public selectedBank: any;
  public selectedBankId: any;
  public transferAmount: any;
  public transferCurrencyList = new Array<WalletTransferRequestModel>();
  public selectedBankDetails :any;
  selectedBranchAddress: any;
  cardType;
  studentCardData;
  cardData;


  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private masterDataService: MasterDataService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.getCurrencies();

    let card = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARD_DATA));  
    this.cardType = card.cardType;
    if(this.cardType == 'studentPayCard') {
      this.studentCardData = JSON.parse(sessionStorage.getItem(ConstantsKey.STUDENT_CASHOUT_DATA));

      this.getStudentCardData();
    } else {
      this.getCardDetails();
    }
    this.getBankAccounts();
    this.getNearByBranchLocation();
  }

  /* getCurrencies() {
    this.reqGetCurrencyMaster.cartType = ""
    this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
    });
  } */

  getTransferAmount(event) {
    this.transferAmount = event.target.value;
  }

  getTransferCurrency(checkboxCheckedStatus, index, currencyCode) {
    console.log("checkboxCheckedStatus >>", checkboxCheckedStatus);
    if (!checkboxCheckedStatus) {
      this.index = index;
      this.cardTransferList[this.index].isAdded = true;
      this.transferCurrency = currencyCode;
      var currBalList = this.cardTransferList.filter((currency) => currency["currencyCode"] == this.transferCurrency);
      console.log("currBalList >>", currBalList);
      this.currencyBalance = currBalList[0]["currencyBalance"];
    } else {
      this.removeCashoutItem(index);
    }
  }

  amountChanges(currencyCode, index) {
    this.showLoader = true;
    this.transferAmount = this.transferAmount;
    this.transferCurrency = currencyCode;
    this.cardTransferList[index].fxAmount = this.transferAmount;

    if (this.transferAmount != "" && this.transferAmount != 0) {
      if (parseInt(this.transferAmount) < parseInt(this.cardTransferList[index]['currencyBalance']) || parseInt(this.transferAmount) == parseInt(this.cardTransferList[index]['currencyBalance'])) {
        this.cardService.getConvertedAmount(currencyCode, "INR", this.transferAmount).subscribe((respCashbout) => {
          this.showLoader = false;
          var respBody = respCashbout.body;
          this.cardTransferList[index].currencyAmount = respBody["currencyAmount"];
          this.setQuotationData();
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else {
        this.showLoader = false;
        this.toast.error("Entered amount is more than available balance!");
      }
    } else {
      this.removeCashoutItem(index);
    }
  }

  removeCashoutItem(index) {
    this.cardTransferList[index]['isAdded'] = false;
    this.setQuotationData();
  }

  removeCartItem(item) {
    console.log("in cashout remote item");
    console.log("item", item);
    this.quotationCurrenciesList.filter((singleItem, index) => {
      if (item.product == singleItem.product && singleItem.currencyCode == item.currencyCode) {
        // this.openSnackBar(this.quotationCurrenciesList[index]['currencyCode'] + ' removed form the cart!');
        this.quotationCurrenciesList.splice(index, 1);
        if (this.quotationCurrenciesList.length > 0) {
          this.getQuotation();
        } else {
          this.quotationResponse = new QuotationResponseModel();
          this.quotationCurrencyResponsesList = [];
          this.isQuotationAvailable = false;
        }
      }
    });
  }

  setQuotationData() {
    this.quotationCurrenciesList = [];
    for (var i = 0; i < this.cardTransferList.length; i++) {
      if (this.cardTransferList[i]['isAdded']) {
        this.quotationCurrencies = new QuotationCurrenciesRequestModel();
        this.quotationCurrencies.bidRate = this.cardTransferList[i]['rate'];
        this.quotationCurrencies.currencyCode = this.cardTransferList[i]['currencyCode'];
        this.quotationCurrencies.currencyAmount = this.cardTransferList[i]['fxAmount'];
        this.quotationCurrencies.product = Constants.productsEnum[0];
        this.quotationCurrencies.purpose = 'PRIVATE_VISIT';
        this.quotationCurrenciesList.push(this.quotationCurrencies);
      }
    }

    if (this.quotationCurrenciesList.length > 0) {
      this.getQuotation();
    } else {
      this.isQuotationAvailable = false;
    }
  }

  getQuotation() {
    this.showLoader = true;
    this.quotationRequest.sellType = Constants.sellTypes[1];
    this.quotationRequest.partnerId = Constants.partnerId;
    this.quotationRequest.nostroChargeType = "REMITTER";
    this.quotationRequest.issuerCode = this.cardType == 'studentPayCard' ? 'WSFXSTUDENTSCC' : 'WSFLMCC';        
    this.quotationRequest.quotationCurrencies = this.quotationCurrenciesList;

    this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe((respQuote) => {
      this.showLoader = false;

      this.quotationDisplay = JSON.parse(JSON.stringify(respQuote.body));
      this.quotationCurrencyResponse = this.quotationDisplay["quotationCurrencyResponses"];
      this.quotationCurrencyResponsesList = this.quotationDisplay["quotationCurrencyResponses"];
      this.isQuotationAvailable = true;

      sessionStorage.setItem(ConstantsKey.QUOTATION_REQUEST_FOR_ENCASHMENT, JSON.stringify(this.quotationRequest));
      sessionStorage.setItem(ConstantsKey.QUOTATION_RESPONSE_FOR_ENCASHMENT, JSON.stringify(this.quotationDisplay));
    }, error => {
            this.showLoader = false;
             if (error.status==200 ) {
                     this.quotationDisplay = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
                    this.quotationCurrencyResponse = this.quotationDisplay["quotationCurrencyResponses"];
                    this.quotationCurrencyResponsesList = this.quotationDisplay["quotationCurrencyResponses"];
                    this.isQuotationAvailable = true;

                    sessionStorage.setItem(ConstantsKey.QUOTATION_REQUEST_FOR_ENCASHMENT, JSON.stringify(this.quotationRequest));
                    sessionStorage.setItem(ConstantsKey.QUOTATION_RESPONSE_FOR_ENCASHMENT, JSON.stringify(this.quotationDisplay));
            }else{
                Swal.fire("Dear Customer",error.error.message, "error");
            }
    });
  }

  executeTransfer() {
    if (this.orderRequestModel.bankId != null && this.branchId!=null ) {
      this.callQuotation();
    } else {
      if (this.orderRequestModel.bankId == null) {
              this.toast.error("Please select bank account!");
      } else {
                this.toast.error("Please select your nearest location");

      }
    }
  }

  callQuotation() {
    this.showLoader = true;
    this.quotationRequest.sellType = Constants.sellTypes[1];
    this.quotationRequest.partnerId = Constants.partnerId;
    this.quotationRequest.nostroChargeType = "REMITTER";
    this.quotationRequest.quotationCurrencies = this.quotationCurrenciesList;
    for (var i = 0; i < this.quotationCurrenciesList.length; i++) {
      var orderItemModel = new OrderItemModel();
      orderItemModel.bidRate = this.cardTransferList[i]["rate"];
      orderItemModel.currencyCode = this.quotationCurrenciesList[i].currencyCode;
      orderItemModel.currencyAmount = this.quotationCurrenciesList[i].currencyAmount;
      orderItemModel.product = this.quotationCurrenciesList[i].product;
      orderItemModel.purpose = this.quotationCurrenciesList[i].purpose;
      orderItemModel.cardNumber = this.cardDetailsObject["cardNumber"];
      orderItemModel.orderType = "CASH_OUT";
      this.orderRequestModel.orderItems.push(orderItemModel);
    }

    this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe((respQuote) => {
      this.showLoader = false;

      // this.quotationResponse = JSON.parse(JSON.stringify(respQuote.body));

      // this.createOrder();
    }, (error) => {
          this.showLoader = false;
             if (error.status==200 ) {
                    this.quotationResponse =JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));

                     this.createOrder();
            }else{
                this.toast.error(error.error.message);
            }
    });
  }

  createOrder() {
    this.showLoader = true;
    this.orderRequestModel.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.orderRequestModel.sellType = Constants.sellTypes[1];
    this.orderRequestModel.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    this.orderRequestModel.kitId = this.kitId;
    this.orderRequestList.push(this.orderRequestModel);
    this.orderRequestModel.wsflReferenceBranchId = this.branchId;

    this.cardService.createOrder(this.orderRequestList).subscribe((respCOrder) => {
      this.showLoader = false;
      

      // this.toast.success("Your encashment request is placed successfully. The amount will be transferred to your bank account within 1-2 business days.");
    }, (error) => {
      this.showLoader = false;
      if(error.status==200){
        this.quotationCurrenciesList = [];
        this.quotationRequest = new QuotationRequestModel();
        this.transferCurrencyList = [];

      this.router.navigate(['/encashment']);
      }else{
        this.toast.error(error.error.message);
      }
    });
  }

  getBankAccounts() {
    this.cardService.getBankAccounts().subscribe((respData) => {
      this.bankAccountItems = [];
      var bankAccountItemsLocal = JSON.parse(JSON.stringify(respData.body));
      bankAccountItemsLocal.forEach(singleBankAccount => {
        singleBankAccount.showTitle = singleBankAccount.bankName + "-" + singleBankAccount.number + " | IFSC-" + singleBankAccount.ifscCode + " | " + singleBankAccount.branch
        this.bankAccountItems.push(singleBankAccount);
      });
    });
  }
   getNearByBranchLocation() {
    this.cardService.getNearByBranch(0.0, 0.0).subscribe(respBranchList => {
      this.branchAddressList = JSON.parse(JSON.stringify(respBranchList.body));
    });
  }
  selectDeliveryOfficeAddress(chooseOfficeAddress) {
    this.selectedBranchAddress = chooseOfficeAddress;
    this.branchId = this.selectedBranchAddress.branchId;    
  }

  setSelectedBank(selectedBankId) {
    var bank: any;
    this.bankAccountItems.forEach(singleBankAccount => {
      if (singleBankAccount.id == selectedBankId.id) {
        bank = singleBankAccount;
      }
    });

    this.selectedBankDetails = bank;
    this.orderRequestModel.bankId = bank.id;
    this.showBankDetails = true;
    console.log("this.orderRequestModel.bankId", this.orderRequestModel.bankId);
    sessionStorage.setItem(ConstantsKey.ORDER_REQUEST, JSON.stringify(this.orderRequestModel));
  }

  getCardDetails() {
    this.showLoader = true;
    this.cardService.getCardDetails().subscribe((respCardData) => {
      this.showLoader = false;

      this.cardDetailsList = JSON.parse(JSON.stringify(respCardData.body));
      for (var i = 0; i < this.cardDetailsList.length; i++) {
        if (this.cardDetailsList[i]["cardIssuer"] == "WSFLMCC") {
          this.cardDetailsObject = this.cardDetailsList[i];
        }
      }
      this.cardWalletList = this.cardDetailsObject["cardWallet"];
      this.kitId = this.cardDetailsObject["kitId"];
      for (var i = 0; i < this.cardWalletList.length; i++) {
        if (this.cardWalletList[i]["currencyBalance"] > 0 && this.cardWalletList[i]["currencyCode"] != "INR") {
          this.getRateRange(this.cardWalletList[i]["currencyCode"], i);
          this.cardWalletList[i]["isAdded"] = false;
          this.cardWalletList[i]["fxAmount"] = 0;
          this.cardWalletList[i]["inrAmount"] = false;
          this.cardTransferList.push(this.cardWalletList[i]);
        }
      }
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    };
  }

  getRateRange(currencyCode, i) {
    this.cardService.getRaterangeList(Constants.productsEnum[0], currencyCode, Constants.sellTypes[1], Constants.partnerId).subscribe((respRate) => {
      var res = respRate.body;
      this.cardWalletList[i]["rate"] = res[0]["minimumRateInINR"];;
    });
  }

  getStudentCardData() {
    this.cardWalletList.push(this.studentCardData);
    for(let i=0 ; i < this.cardWalletList.length; i++) {
      this.getRateRange(this.cardWalletList[i]["currencyCode"], i);
      this.cardTransferList.push(this.cardWalletList[i]);
    }
    let cardData = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARD_DATA));
    this.kitId = cardData.kitId;
  }

  
}
