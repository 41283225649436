<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body"style="margin-bottom:10px">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FAQ'S</h6>
                    </div>
                    </div>
                    
                    <div class="accordion collapse-margin mt-1">
                        <div class="tab-pane collapse-icon" id="faq-delivery" role="tabpanel" aria-labelledby="delivery" aria-expanded="false">
                        <ng-container>
                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                <ngb-panel  [cardClass]="'collapse-margin'">
                                    <ng-template ngbPanelTitle>
                                        <span  class="text-black fw-600 fs-16">1. What is the WSFx Smart Currency Card?</span>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        WSFx Smart Currency Card, is a multi-currency forex card from WSFx Global Pay Ltd,
                                        powered by YES BANK. It is a foreign currency denominated Prepaid Card which can be
                                        loaded with multiple currencies on a single Card that makes your foreign trip totally
                                        hassle-free and convenient. Your WSFx Smart Currency Card comes with a unique INR wallet
                                        for your domestic payments. It is globally accepted at more than 31.4 million VISA
                                        merchant outlets. You can also use this card on international e-commerce websites.
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-container>

                        <div class="accordion collapse-margin mt-2">
                            <ng-container>
                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                        <ng-template ngbPanelTitle>
                                            <span  class="text-black fw-600 fs-16">2. What features make the WSFx Smart Currency
                                                Card unique?</span>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <ul>
                                                <li>App-enabled card management</li>
                                                <li>INR wallet feature</li>
                                                <li>Tap and Pay money on your trip abroad</li>
                                                <li>Wallet to Wallet transfer</li>
                                                <li>Globally accepted at more than 31.4 million VISA merchant outlets</li>
                                                <li>Instant transaction updates through SMS and Email</li>
                                                <li>24/7 Customer Service Support</li>
                                                <li>Chip and PIN based card for enhanced security against fraud and misuse</li>
                                            </ul>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </ng-container>

                            <div class="accordion collapse-margin mt-2">
                                <ng-container>
                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                            <ng-template ngbPanelTitle>
                                                <span  class="text-black fw-600 fs-16">3. Where do I get the WSFx Smart Currency
                                                    Card?</span>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                WSFx Smart Currency Card is available for purchase in the WSFx Global Pay App and over the
                                                counter at WSFx Global Pay Ltd branches. You simply need to place a “Buy New
                                                Card” request in the App or walk into our branch with required documentation. The card
                                                will get activated within 24 business hours from realization of funds.<br>
                                                Please <a href="<?= base_url(
                                                    'contact-us'
                                                ) ?>" target="_blank">click here </a>to locate a
                                                nearest WSFx Branch
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </ng-container>

                                <div class="accordion collapse-margin mt-2">
                                    <ng-container>
                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                <ng-template ngbPanelTitle>
                                                    <span  class="text-black fw-600 fs-16">4. What is the WSFx Smart Currency Card INR
                                                        Wallet?</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    Your WSFx Smart Currency Card comes with an INR wallet for your domestic needs. After
                                your return from your foreign trip you can transfer your unspent foreign currency from
                                your foreign currency wallet to your INR wallet and use it for your domestic purchases
                                at VISA merchant establishments.<br>
                                
                                Please note that you will not be able to transfer funds from your INR wallet to foreign
                                currency wallet, but you can withdraw your funds from your INR wallet from your nearest
                                ATM.<br>
                                *The amount loaded after conversion on INR currency wallet/card will be done as per the
                                RBI vide PPI Guidelines (Currently INR 100000)<br>
                                *Conversion fee of 1% will be applicable
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                    <ng-template ngbPanelTitle>
                                                        <span  class="text-black fw-600 fs-16">5. What is the validity of the WSFx Smart
                                                            Currency Card?</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        The WSFx Smart Currency Card is valid for five (5) years from the date of its purchase.
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>


                                        <div class="accordion collapse-margin mt-2">
                                            <ng-container>
                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                        <ng-template ngbPanelTitle>
                                                            <span  class="text-black fw-600 fs-16">6. What is the maximum limit to carry forex in
                                                                the WSFx Smart Currency Card?</span>
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            The overall limit available for an individual, under Liberalized Remittance Scheme for
                                                            Resident Individuals (LRS) is USD 2,50,000 per financial year and the other limits as
                                                            per Schedule III pertaining to travel, business, medical, education, immigration, etc.
                                                            have been subsumed under the LRS limit (within the overall limit of USD 2,50,000 per
                                                            financial year). Eligibility criteria for Minors is up to 10 years or below.
                                                        </ng-template>
                                                    </ngb-panel>
                                                </ngb-accordion>
                                            </ng-container>

                                            <div class="accordion collapse-margin mt-2">
                                                <ng-container>
                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                            <ng-template ngbPanelTitle>
                                                                <span  class="text-black fw-600 fs-16">7. What documents should I submit for
                                                                    purchasing the WSFx Smart Currency Card?</span>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <ul>
                                                                    <li>Duly filled and signed WSFx Smart Currency Card Application Form</li>
                                                                    <li>Copy of self-attested Passport (carry your original Passport for verification)
                                                                    </li>
                                                                    <li>Copy of self-attested Ticket or Visa (carry originals for verification)</li>
                                                                    <li>PAN Number has to be mandatorily mentioned on the Application Form with a
                                                                        self-attested copy of the same</li>
                                                                    <li>If the address mentioned on the application form differs from the address
                                                                        mentioned on the Passport, then an additional address proof needs to be
                                                                        submitted</li>
                                                                </ul>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </ng-container>


                                                <div class="accordion collapse-margin mt-2">
                                                    <ng-container>
                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                <ng-template ngbPanelTitle>
                                                                    <span class="text-black fw-600 fs-16">8. Will I have to submit my transactional
                                                                        documents physically after uploading in the WSFx Global Pay App?</span>
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    Yes, our service executive will pick up the physical documents from your mentioned
                                                                    address on the app. Please make sure to keep them ready and signed on time.
                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </ng-container>

                                                    <div class="accordion collapse-margin mt-2">
                                                        <ng-container>
                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                    <ng-template ngbPanelTitle>
                                                                        <span class="text-black fw-600 fs-16">9. Which all currencies can be loaded on the WSFx Smart Currency Card?</span>
                                                                    </ng-template>
                                                                    <ng-template ngbPanelContent>
                                                                        Your WSFx Smart Currency Card can be loaded with world’s 14 major currencies along with
                                                                        INR;<br>
                                                                        <ul>
                                                                            <li>United States Dollar (USD)</li>
                                                                            <li>Great Britain Pound (GBP)</li>
                                                                            <li>Euro (EUR)</li>
                                                                            <li>Canadian Dollar (CAD)</li>
                                                                            <li>Australian Dollar (AUD)</li>
                                                                            <li>Singapore Dollar (SGD)</li>
                                                                            <li>Arab Emirates Dirham (AED)</li>
                                                                            <li>Japanese Yen (JPY)</li>
                                                                            <li>Saudi Riyal (SAR)</li>
                                                                            <li>Thai Baht (THB)</li>
                                                                            <li>New Zealand Dollar (NZD)</li>
                                                                            <li>South African Rand (ZAR)</li>
                                                                            <li>Hong Kong Dollar (HKD)</li>
                                                                            <li>Swiss Franc (CHF)</li>
                                        
                                                                        </ul>
                                        
                                                                    </ng-template>
                                                                </ngb-panel>
                                                            </ngb-accordion>
                                                        </ng-container>


                                                        <div class="accordion collapse-margin mt-2">
                                                            <ng-container>
                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                        <ng-template ngbPanelTitle>
                                                                            <span  class="text-black fw-600 fs-16">10. How can I confirm if my WSFx Smart
                                                                                Currency Card has been charged/loaded and when can I start using it?</span>
                                                                        </ng-template>
                                                                        <ng-template ngbPanelContent>
                                                                            Your WSFx Smart Currency Card is ready for use once you get an activation mail and SMS
                                                                            on your registered email ID/ Indian Mobile number shortly after your card is loaded,
                                                                            reloaded or refunded. To check the balance of the card, download the WSFx Global Pay App
                                                                            from Play Store/App Store and check your transaction history anytime.
                                                                        </ng-template>
                                                                    </ngb-panel>
                                                                </ngb-accordion>
                                                            </ng-container>

                                                            <div class="accordion collapse-margin mt-2">
                                                                <ng-container>
                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                            <ng-template ngbPanelTitle>
                                                                                <span  class="text-black fw-600 fs-16">11. Are there any restrictions on the usage
                                                                                    of the WSFx Smart Currency Card in any of the country or currency?</span>
                                                                            </ng-template>
                                                                            <ng-template ngbPanelContent>
                                                                                Yes, this Card cannot be used in Nepal, Bhutan and in countries as per UN sanction list
                                                                                time to time.
                                                                            </ng-template>
                                                                        </ngb-panel>
                                                                    </ngb-accordion>
                                                                </ng-container>

                                                                <div class="accordion collapse-margin mt-2">
                                                                    <ng-container>
                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                <ng-template ngbPanelTitle>
                                                                                    <span  class="text-black fw-600 fs-16">12. Which ATMs accept the WSFx Smart Currency
                                                                                        Card?</span>
                                                                                </ng-template>
                                                                                <ng-template ngbPanelContent>
                                                                                    All ATMs overseas displaying the VISA symbol will accept your WSFx Smart Currency Card.

                                                                                </ng-template>
                                                                            </ngb-panel>
                                                                        </ngb-accordion>
                                                                    </ng-container>

                                                                    <div class="accordion collapse-margin mt-2">
                                                                        <ng-container>
                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                    <ng-template ngbPanelTitle>
                                                                                        <span class="text-black fw-600 fs-16">13. What are the fees and charges of the WSFx
                                                                                            Smart Currency Card?</span>
                                                                                    </ng-template>
                                                                                    <ng-template ngbPanelContent>
                                                                                        <table class="table table-bordered">
                                                                                            <tr>
                                                                                                <th>Issuance Fee</th>
                                                                                                <th>Reload Fee</th>
                                                                                                <th>Cross Currency Fee (Mark-up Fee)</th>
                                                                                                <th>Cash Advance / Cash Disbursement Fee</th>
                                                                                                <th>Wallet to Wallet Transfer Fee*</th>
                                                                                                <th>Inactivity Fee</th>
                                                                                                <th>Lost Card Replacement Fee</th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>INR 150/-</td>
                                                                                                <td>INR 100/-</td>
                                                                                                <td>3% of Transaction Amount</td>
                                                                                                <td>2% of Transaction Amount</td>
                                                                                                <td>2% of Transaction Amount</td>
                                                                                                <td>NIL</td>
                                                                                                <td>International Location: INR 2500.00 ** ; Indian Locations: NIL </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                        <table class="table table-bordered">
                                                                                            <tr>
                                                                                                <th>Currency</th>
                                                                                                <th>USD</th>
                                                                                                <th>GBP</th>
                                                                                                <th>EUR</th>
                                                                                                <th>CAD</th>
                                                                                                <th>AUD</th>
                                                                                                <th>SGD</th>
                                                                                                <th>AED</th>
                                                                                                <th>JPY</th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Balance Enquiry Fee at ATM</td>
                                                                                                <td>0.50</td>
                                                                                                <td>0.50</td>
                                                                                                <td>0.50</td>
                                                                                                <td>0.75</td>
                                                                                                <td>0.75</td>
                                                                                                <td>0.75</td>
                                                                                                <td>2.00</td>
                                                                                                <td>60.00</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>ATM Cash Withdrawal Fee</td>
                                                                                                <td>2.00</td>
                                                                                                <td>1.50</td>
                                                                                                <td>1.75</td>
                                                                                                <td>2.50</td>
                                                                                                <td>2.50</td>
                                                                                                <td>2.75</td>
                                                                                                <td>7.25</td>
                                                                                                <td>225.00</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>ATM Cash Withdrawal Limit Per Day</td>
                                                                                                <td>1,500</td>
                                                                                                <td>1,000</td>
                                                                                                <td>1,000</td>
                                                                                                <td>1,500</td>
                                                                                                <td>2,000</td>
                                                                                                <td>1,500</td>
                                                                                                <td>5,000</td>
                                                                                                <td>150,000</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>POS / Ecom / Cash Advance / Cash @ POS limit Per day</td>
                                                                                                <td>7,500</td>
                                                                                                <td>5,500</td>
                                                                                                <td>6,500</td>
                                                                                                <td>9,500</td>
                                                                                                <td>10,000</td>
                                                                                                <td>10,000</td>
                                                                                                <td>25,000</td>
                                                                                                <td>850,000</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                        <table class="table table-bordered">
                                                                                            <tr>
                                                                                                <th>Currency</th>
                                                                                                <th>SAR</th>
                                                                                                <th>THB</th>
                                                                                                <th>NZD</th>
                                                                                                <th>ZAR</th>
                                                                                                <th>HKD</th>
                                                                                                <th>CHF</th>
                                                                                                <th>INR</th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Balance Enquiry Fee at ATM</td>
                                                                                                <td>1.75</td>
                                                                                                <td>16.00</td>
                                                                                                <td>0.75</td>
                                                                                                <td>7.00</td>
                                                                                                <td>4.00</td>
                                                                                                <td>0.50</td>
                                                                                                <td>8.00</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>ATM Cash Withdrawal Fee</td>
                                                                                                <td>7.50</td>
                                                                                                <td>65.00</td>
                                                                                                <td>2.75</td>
                                                                                                <td>28.00</td>
                                                                                                <td>15.00</td>
                                                                                                <td>2.00</td>
                                                                                                <td>20.00</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>ATM Cash Withdrawal Limit Per Day</td>
                                                                                                <td>5,000</td>
                                                                                                <td>45,000</td>
                                                                                                <td>1,500</td>
                                                                                                <td>12,500</td>
                                                                                                <td>7,500</td>
                                                                                                <td>1,000</td>
                                                                                                <td>10,000</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>POS / Ecom / Cash Advance / Cash @ POS limit Per day</td>
                                                                                                <td>25,000</td>
                                                                                                <td>250,000</td>
                                                                                                <td>10,500</td>
                                                                                                <td>1,00,000</td>
                                                                                                <td>55,000</td>
                                                                                                <td>7,500</td>
                                                                                                <td>20,000</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                        <span style="font-weight: 600;">
                                                                                            *Wallet to wallet transfer from your FC wallet to your INR wallet will have a fee of
                                                                                            1% on the transaction amount<br>
                                                                                            *Govt Taxes as applicable will be additional <br>
                                                                                            **For International location, payment link requesting INR 2500 will be sent to your registered email id for replacement fee covering shipping cost.
                                                                                            Post payment, replacement card will be dispatched.
                                                                                        </span>
                                                                                    </ng-template>
                                                                                </ngb-panel>
                                                                            </ngb-accordion>
                                                                        </ng-container>

                                                                        <div class="accordion collapse-margin mt-2">
                                                                            <ng-container>
                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                        <ng-template ngbPanelTitle>
                                                                                            <span class="text-black fw-600 fs-16">14. Can I reload the WSFx Smart Currency
                                                                                                Card?</span>
                                                                                        </ng-template>
                                                                                        <ng-template ngbPanelContent>
                                                                                            Yes, your WSFx Smart Currency Card can be reloaded multiple times in future till the
                                                                                            card gets expired.<br>
                                                                                             <ul>
                                                                                          <li>Instantly reload through the WSFx Global Pay App</li>
                                                                                          <li>Or get the card reloaded through the WSFx branch</li>
                                                                                          <li>Reload requests can also be made by you or the following person on your behalf
                                        by submitting the reload application and making the payment.
                                        <ol style="list-style-type: none;">
                                            <li>a) Spouse </li>
                                            <li>b) Father or Stepfather </li>
                                            <li>c) Mother or Stepmother</li>
                                            <li>d) Son or Stepson </li>
                                            <li>e) Son's Wife</li>
                                            <li>f) Daughter or Stepdaughter </li>
                                            <li>g) Daughter's husband </li>
                                            <li>h) Brother or Stepbrother </li>
                                            <li>i) Sister or Stepsister</li>
                                        </ol>
                                    </li>
                                </ul>
                                <span style="font-weight: 600;">*A Reload Fee will be applicable.</span>
                                                                                        </ng-template>
                                                                                    </ngb-panel>
                                                                                </ngb-accordion>
                                                                            </ng-container>

                                                                            <div class="accordion collapse-margin mt-2">
                                                                                <ng-container>
                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                            <ng-template ngbPanelTitle>
                                                                                                <span  class="text-black fw-600 fs-16">15. Can I transfer currency on my Card from
                                                                                                    one wallet to other?</span>
                                                                                            </ng-template>
                                                                                            <ng-template ngbPanelContent>
                                                                                                Yes, you can transfer balance from one currency wallet to other currency wallet using
                                your WSFx Global Pay App. Please note that this option should be used judiciously as there
                                is a wallet to wallet Transfer Fee applicable for each transfer. 
                                                                                            </ng-template>
                                                                                        </ngb-panel>
                                                                                    </ngb-accordion>
                                                                                </ng-container>

                                                                                <div class="accordion collapse-margin mt-2">
                                                                                    <ng-container>
                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                <ng-template ngbPanelTitle>
                                                                                                    <span  class="text-black fw-600 fs-16">16. How to use the WSFx Smart Currency card
                                                                                                        at VISA ATM’s and Merchant establishments?</span>
                                                                                                </ng-template>
                                                                                                <ng-template ngbPanelContent>
                                                                                                    <ul>
                                                                                                        <li>The merchant inserts/dips the chip card in the POS terminal</li>
                                                                                                        <li>The chip card must remain in the terminal till the transaction is completed</li>
                                                                                                        <li>If the merchant accidentally swipes the card at the place of inserting/dipping
                                                                                                            the card, the terminal will automatically identify it as a chip card. The
                                                                                                            terminal will prompt the cashier to insert the chip card instead of swiping it
                                                                                                        </li>
                                                                                                        <li>If the POS terminal does not read the chip-enabled card, the card can be used
                                                                                                            for normal magnetic stripe swipe on the POS terminal as well</li>
                                                                                                        <li>You may have to enter your ATM PIN if the POS machine prompts for the same while
                                                                                                            using on any VISA enabled merchant establishments</li>
                                                                                                        <li>The card has an NFC (Near Field Communication) feature enabled in it. You can
                                                                                                            look for Visa PayWave mark or a contactless logo at the Point of Sale terminal
                                                                                                            machine at merchant outlets</li>
                                                                                                        <li>The card can be used by a tap/wave or bringing it close to the terminal screen
                                                                                                            (within 4 cms)</li>
                                                                                                        <li>The transaction amount will be deducted from the available balance</li>
                                                                                                        <li>Enter 4-digit secured ATM/POS PIN (if prompted)</li>
                                                                                                        <li>A green light will signal indicating that the transaction is completed</li>
                                                                                                        <li>Collect your WSFx Smart Currency card, customer copy of transaction slip and
                                                                                                            purchase invoice</li>
                                                                                                    </ul>
                                                                    
                                                                                                    Please note that the requirement of PIN entry on the POS terminal at the merchant outlet is
                                                                                                    a function of country-specific regulations and is subject to change. PIN input is not
                                                                                                    mandatory for purchases below the permissible local limit, hence you are advised to
                                                                                                    check the same with the merchant before usage. In case the transaction amount is greater
                                                                                                    than the permitted transaction limit,the merchant would swipe/ dip the card in the normal
                                                                                                    POS terminal.
                                                                                                </ng-template>
                                                                                            </ngb-panel>
                                                                                        </ngb-accordion>
                                                                                    </ng-container>

                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                        <ng-container>
                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                    <ng-template ngbPanelTitle>
                                                                                                        <span  class="text-black fw-600 fs-16">17. What if I have forgotten my ATM PIN?</span>
                                                                                                    </ng-template>
                                                                                                    <ng-template ngbPanelContent>
                                                                                                        The card gets blocked for use at ATMs, if the card has been used with the wrong ATM PIN
                                                                                                        more than 3 times. This is for your own security and to prevent any fraudulent use on
                                                                                                       the card. If you have forgotten your PIN, you may log on to the WSFx Global Pay App to
                                                                                                        reset the ATM PIN/write to wsfxsmartcard@yesbank.in or call the customer care @
                                                                                                        080-45651100 for generating a new ATM PIN.
                                                                                                    </ng-template>
                                                                                                </ngb-panel>
                                                                                            </ngb-accordion>
                                                                                        </ng-container>
                                                                                        <div class="accordion collapse-margin mt-2">
                                                                                            <ng-container>
                                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                        <ng-template ngbPanelTitle>
                                                                                                            <span  class="text-black fw-600 fs-16">18. How can I withdraw cash from an ATM abroad?</span>
                                                                                                        </ng-template>
                                                                                                        <ng-template ngbPanelContent>
                                                                                                            You can withdraw cash from any networked ATM. Kindly ensure that you select the type of
                                                                                                            Account as "Checking / Current" or "Credit”. The Savings option will not work for
                                                                                                            prepaid Forex Card.
                                                                                                        </ng-template>
                                                                                                    </ngb-panel>
                                                                                                </ngb-accordion>
                                                                                            </ng-container>

                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                <ng-container>
                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                <span  class="text-black fw-600 fs-16">19. How can you do secure
                                                                                                                    e-commerce/online transactions through the WSFx Smart Currency Card?</span>
                                                                                                            </ng-template>
                                                                                                            <ng-template ngbPanelContent>
                                                                                                                <ul>
                                                                                                                    <li>Firstly, confirm if the “International Transaction” in the ‘Manage Card’
                                                                                                                        section is enabled in your WSFx Global Pay App.</li>
                                                                                                                    <li>Log on to the International merchant website</li>
                                                                                                                    <li>Select “Credit card” option as the mode of payment</li>
                                                                                                                    <li>Enter card details, billing information and your ATM PIN as second factor
                                                                                                                        authentication. This will be a dynamic OTP received on your registered
                                                                                                                        Indian mobile number.</li>
                                                                                                                    <li>Confirm to make the payment</li>
                                                                                                                </ul>
                                                                                                            </ng-template>
                                                                                                        </ngb-panel>
                                                                                                    </ngb-accordion>
                                                                                                </ng-container>

                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                    <ng-container>
                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                    <span class="text-black fw-600 fs-16">20. What do I do if my card is lost or
                                                                                                                        stolen?</span>
                                                                                                                </ng-template>
                                                                                                                <ng-template ngbPanelContent>
                                                                                                                    <ul>
                                                                                                                        <li>Temporary block your card through the WSFx Global Pay App</li>
                                                                                                                        <li>Hot-list (Permanent Block) the card by calling our 24/7 customer care
                                                                                                                            immediately @ 080-45651100</li>
                                                                                                                        <li>The cardholder will be liable for all the charges incurred on the card until the
                                                                                                                            card is hot-listed</li>
                                                                                                                        <li>It is advisable to file a complaint with the local police if an unauthorized
                                                                                                                            transaction has been made on the card</li>
                                                                                                                    </ul>
                                                                                                                </ng-template>
                                                                                                            </ngb-panel>
                                                                                                        </ngb-accordion>
                                                                                                    </ng-container>

                                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                                        <ng-container>
                                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                    <ng-template ngbPanelTitle>
                                                                                                                        <span class="text-black fw-600 fs-16">21. Is this possible to transfer funds from
                                                                                                                            forex card to overseas bank account?</span>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template ngbPanelContent>
                                                                                                                        Yes, you can however, transfer money to your account via POS at foreign bank branch.
                                                                                                                        This is known as Cash Advance facility which is chargeable. Please check the schedule of
                                                                                                                        fees and charges to understand more.
                                                                                                                    </ng-template>
                                                                                                                </ngb-panel>
                                                                                                            </ngb-accordion>
                                                                                                        </ng-container>

                                                                                                        <div class="accordion collapse-margin mt-2">
                                                                                                            <ng-container>
                                                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                        <ng-template ngbPanelTitle>
                                                                                                                            <span class="text-black fw-600 fs-16">22. How can I know if the transaction is
                                                                                                                                successfully processed through Contactless WSFx Smart Currency Card?</span>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template ngbPanelContent>
                                                                                                                            You can check your “Statement” in the WSFx Global Pay App to know more about your
                                                                                                                            transactions. You will receive a charge slip from the merchant, and also receive an
                                                                                                                            Email/ SMS on your registered Email ID and Indian phone number once the transaction is
                                                                                                                            successful.
                                                                                                                        </ng-template>
                                                                                                                    </ngb-panel>
                                                                                                                </ngb-accordion>
                                                                                                            </ng-container>

                                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                                <ng-container>
                                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                                <span class="text-black fw-600 fs-16">23. What if I don’t have sufficient balance
                                                                                                                                    in the wallet corresponding to currency of transaction?</span>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template ngbPanelContent>
                                                                                                                                If the balance in your wallet in the respective currency of transaction goes down, then
                                                                                                                                your card checks for balances in the other currency wallets and transaction is processed
                                                                                                                                by debiting the currency wallet having sufficient balance by converting the wallet
                                                                                                                                currency into the currency of transaction. In this case currency conversion charges are
                                                                                                                                applicable. Please note that in such a scenario entire transaction amount would be
                                                                                                                                debited from currency wallet with sufficient balance.<br>
                                                                                                                                USD-EUR-GBP-SGD-JPY-CAD-AUD-NZD-AED-THB-HKD-CHF-SAR-ZAR will be the Currency Flow.
                                                                                                                            </ng-template>
                                                                                                                        </ngb-panel>
                                                                                                                    </ngb-accordion>
                                                                                                                </ng-container>
                                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                                    <ng-container>
                                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                                    <span  class="text-black fw-600 fs-16">24. How do I set enable/disable
                                                                                                                                        transactions?</span>
                                                                                                                                </ng-template>
                                                                                                                                <ng-template ngbPanelContent>
                                                                                                                                    <ul>
                                                                                                                                        <li>Please Login to the WSFx Global Pay App with your phone number & password.</li>
                                                                                                                                        <li>Link your WSFx Smart Currency Card</li>
                                                                                                                                        <li>Click the “Manage” tab</li>
                                                                                                                                        <li>Drag the toggle button to the right to enable the desired mode ATM/ POS/ Online/
                                                                                                                                            Contactless/ International usage instantly (turns green when enabled & you will see an instant notification on screen about successful setting).</li>
                                                                                                                                        <li>For disabling instantly, please drag the button to the left (turns grey when
                                                                                                                                            disabled & you will get an instant screen notification when done).</li>
                                                                                                                                        <li>A SMS/email alert will be sent notifying about the changes made.</li>
                                                                                                                                        <li>Alternately, you may call our Customer Care on 080-45651100 for doing the
                                                                                                                                            needful as per your request.</li>
                                                                                                                                    </ul>
                                                                                                                                </ng-template>
                                                                                                                            </ngb-panel>
                                                                                                                        </ngb-accordion>
                                                                                                                    </ng-container>

                                                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                                                        <ng-container>
                                                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                    <ng-template ngbPanelTitle>
                                                                                                                                        <span  class="text-black fw-600 fs-16">25. What transactions/usage modes can I
                                                                                                                                            enable/disable?</span>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template ngbPanelContent>
                                                                                                                                        ATM/POS/Online/Contactless/International transactions.
                                                                                                                                    </ng-template>
                                                                                                                                </ngb-panel>
                                                                                                                            </ngb-accordion>
                                                                                                                        </ng-container>

                                                                                                                        <div class="accordion collapse-margin mt-2">
                                                                                                                            <ng-container>
                                                                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                        <ng-template ngbPanelTitle>
                                                                                                                                            <span  class="text-black fw-600 fs-16">26. How do I set transaction limit?</span>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template ngbPanelContent>
                                                                                                                                            <ul>
                                                                                                                                                <li>Please note transaction limit can be set for daily usage, i.e. limit applicable
                                                                                                                                                    for usage multiple times during the day until the set transaction limit value.
                                                                                                                                                </li>
                                                                                                                                                <li>Login to the WSFx Global Pay App</li>
                                                                                                                                                <li>Click on ‘Set Daily Transaction Limit’ tab.</li>
                                                                                                                                                <li>Drag the value in USD to set the daily limit under the respective Transaction
                                                                                                                                                    type: ATM/POS/Online.</li>
                                                                                                                                                <li>The USD value is used as a reference to arrive at the equivalent currency value
                                                                                                                                                    available across all wallets for transaction mode selected.</li>
                                                                                                                                                <li>Click on ‘Apply’ to enable changes immediately. (Else changes will not be saved
                                                                                                                                                    & old limit values will be continued).</li>
                                                                                                                                                <li>Once changes are made, it will be instantly displayed on the screen.</li>
                                                                                                                                                <li>SMS-email alert will be sent notifying about the changes made</li>
                                                                                                                                                <li>Alternately, you may call our Customer Care on 080-45651100 for doing the
                                                                                                                                                    needful as per your request.</li>
                                                                                                            
                                                                                                                                            </ul>
                                                                                                                                        </ng-template>
                                                                                                                                    </ngb-panel>
                                                                                                                                </ngb-accordion>
                                                                                                                            </ng-container>

                                                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                                                <ng-container>
                                                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                                                <span  class="text-black fw-600 fs-16">27. What is the maximum/minimum limit I can
                                                                                                                                                    set for my transactions/usage modes- ATM/Online/POS?</span>
                                                                                                                                            </ng-template>
                                                                                                                                            <ng-template ngbPanelContent>
                                                                                                                                               
                                                                                                                                                    The maximum & minimum limits are displayed in USD on the screen under ‘Modify
                                                                                                                                                        Transaction Limit’ tab in the WSFx Global Pay App.
                                                                                                                                                   
                                                                                                                                               
                                                                                                                                            </ng-template>
                                                                                                                                        </ngb-panel>
                                                                                                                                    </ngb-accordion>
                                                                                                                                </ng-container>

                                                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                                                    <ng-container>
                                                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                                                    <span  class="text-black fw-600 fs-16">28. What is the daily contactless/NFC usage
                                                                                                                                                        limit on my Card?</span>
                                                                                                                                                </ng-template>
                                                                                                                                                <ng-template ngbPanelContent>
                                                                                                                                                   65 USD or equivalent.
                                                                                                                    
                                                                                                                                                </ng-template>
                                                                                                                                            </ngb-panel>
                                                                                                                                        </ngb-accordion>
                                                                                                                                    </ng-container>

                                                                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                                                                        <ng-container>
                                                                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                    <ng-template ngbPanelTitle>
                                                                                                                                                        <span  class="text-black fw-600 fs-16">29. How long is the set transaction limit
                                                                                                                                                            (for ATM/POS/Online) applicable?</span>
                                                                                                                                                    </ng-template>
                                                                                                                                                    <ng-template ngbPanelContent>
                                                                                                                                                       The transaction limit can be set for daily usage only, i.e. limit applicable for
                                                                                                                                                                usage multiple times during the day until the set transaction limit value is
                                                                                                                                                                reached. Post which transactions will remain disabled until day end (23:59 hours
                                                                                                                                                                IST). Limits reset next day (00 hours IST).
                                                                                                                                                            However, you may modify limit as per your convenience for higher values. Please
                                                                                                                                                                refer Q25 for details.
                                                                                                                        
                                                                                                                                                        
                                                                                                                                                    </ng-template>
                                                                                                                                                </ngb-panel>
                                                                                                                                            </ngb-accordion>
                                                                                                                                        </ng-container>

                                                                                                                                       

                                                                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                                                                <ng-container>
                                                                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                                                                <span  class="text-black fw-600 fs-16">30. What is Dynamic Currency Conversion?</span>
                                                                                                                                                            </ng-template>
                                                                                                                                                            <ng-template ngbPanelContent>
                                                                                                                                                                In certain countries, the overseas banks providing ATMs or POS machine payment services
                                                                                                                                                                may offer to charge you in Indian Rupees, this concept is called “Dynamic Currency
                                                                                                                                                                Conversion” (DCC). This process allows the ATMs or the Merchants to charge significantly
                                                                                                                                                                higher mark-up on the currency conversion.<br>
                                                                                                                                                                In your interest, the WSFx Smart Currency Card in general will decline DCC transactions
                                                                                                                                                                thereby saving you from unnecessary charges. However, despite the safeguards, it should
                                                                                                                                                                be noted that if in case a transaction is approved under DCC, the customer will have to
                                                                                                                                                                bear the loss related to exchange mark-up. The card issuer is not liable for “Dynamic
                                                                                                                                                                Currency Conversion” related charges and will not be able to refund any such charges in
                                                                                                                                                                case you proceed with DCC transaction and accepts the DCC charges in the ATM / POS
                                                                                                                                                                machine.
                                                                                                                                                            </ng-template>
                                                                                                                                                        </ngb-panel>
                                                                                                                                                    </ngb-accordion>
                                                                                                                                                </ng-container>

                                                                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                                                                    <ng-container>
                                                                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                                                                    <span  class="text-black fw-600 fs-16">31. What is Pre-Authorization &how to handle
                                                                                                                                                                        a Pre-Authorization?</span>
                                                                                                                                                                </ng-template>
                                                                                                                                                                <ng-template ngbPanelContent>
                                                                                                                                                                    When you check into a hotel or rent a car using your WSFx Smart Currency Card, the
                                                                                                                                                                    merchant may block a certain amount on your card. This is called pre-authorization and
                                                                                                                                                                    the funds on your card will be blocked till such time that the bills are finally
                                                                                                                                                                    settled.
                                                                                                                                                                    Based on Visa norms, WSFx may not be able to credit you the pre-authorized amount for up
                                                                                                                                                                    to 30 days from the date of pre-authorization.
                                                                                                                                                                    <ul>
                                                                                                                                                                        <li>Avoid using your WSFx Smart Currency Card for Pre-authorization</li>
                                                                                                                                                                        <li>However, you may modify limit as per your convenience for higher values. Please
                                                                                                                                                                            refer Q25 for details.</li>
                                                                                                                                    
                                                                                                                                                                    </ul>
                                                                                                                                                                </ng-template>
                                                                                                                                                            </ngb-panel>
                                                                                                                                                        </ngb-accordion>
                                                                                                                                                    </ng-container>

                                                                                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                                                                                        <ng-container>
                                                                                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                    <ng-template ngbPanelTitle>
                                                                                                                                                                        <span  class="text-black fw-600 fs-16">32. Whom should I contact, in case I have any
                                                                                                                                                                            dispute on a transaction?</span>
                                                                                                                                                                    </ng-template>
                                                                                                                                                                    <ng-template ngbPanelContent>
                                                                                                                                                                        In case you have a dispute on any specific transaction, you are requested to raise a
                                                                                                                                                                        ticket for the particular transaction in the WSFx Global Pay App or provide the details of
                                                                                                                                                                        the transaction to our contact center @<a href="tel:080-45651100"> 080-45651100</a> or
                                                                                                                                                                        write your dispute to us at<span style="text-decoration: underline;"><a
                                                                                                                                                                                href="mailto:wsfxsmartcard@yesbank.in"> wsfxsmartcard@yesbank.in</a></span>
                                                                                                                                                                    </ng-template>
                                                                                                                                                                </ngb-panel>
                                                                                                                                                            </ngb-accordion>
                                                                                                                                                        </ng-container>

                                                                                                                                                        <div class="accordion collapse-margin mt-2">
                                                                                                                                                            <ng-container>
                                                                                                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                        <ng-template ngbPanelTitle>
                                                                                                                                                                            <span class="text-black fw-600 fs-16">33. What should I do if my WSFx Smart
                                                                                                                                                                                Currency Card has expired but there is an un-utilized balance on the card?</span>
                                                                                                                                                                        </ng-template>
                                                                                                                                                                        <ng-template ngbPanelContent>
                                                                                                                                                                            If your WSFx Smart Currency Card has expired before all funds have been utilized, you
                                                                                                                                                                            can contact any of the WSFx branch for refund of the un-utilized balance, or apply for a
                                                                                                                                                                            replacement card to continue to access the remaining funds for payment through the card
                                                                                                                                                                            in future.
                                                                                                                                                                        </ng-template>
                                                                                                                                                                    </ngb-panel>
                                                                                                                                                                </ngb-accordion>
                                                                                                                                                            </ng-container>

                                                                                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                                                                                <ng-container>
                                                                                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                                                                                <span class="text-black fw-600 fs-16">34. What is the Insurance Covered and the
                                                                                                                                                                                    necessary documents required on the WSFx Smart Currency Card?</span>
                                                                                                                                                                            </ng-template>
                                                                                                                                                                            <ng-template ngbPanelContent>
                                                                                                                                                                                Zero Liability Insurance is applicable only on fraudulent Point of Sale transactions and
                                                                                                                                                                                not for ATM transactions. It means if the card holder has reported loss of card to the bank
                                                                                                                                                                                and card blocking is acknowledged by the Bank,the Customer will not be liable for any further
                                                                                                                                                                                usage on the card. We offer a complimentary insurance cover to safe guard you from any
                                                                                                                                                                                fraudulent usage on your WSFx Smart Currency Card. For lost/counterfeit/stolen cards the
                                                                                                                                                                                insurance cover is up to INR 3,00,000.<br>
                                                                                                                                                                                <span>Terms & Conditions for the Insurance
                                                                                                                                                                                    Cover:</span>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li>The claim will be admissible if there is any transaction (Financial or
                                                                                                                                                                                        Non-financial) by the cardholder in the past 6 months.</li>
                                                                                                                                                                                    <li>The claim documents must be submitted within 7 working days from the time of
                                                                                                                                                                                        reporting the fraudulent usage of the card.</li>
                                                                                                                                                
                                                                                                                                                                                </ul>
                                                                                                                                                                                <span>Below documents are required for an Insurance
                                                                                                                                                                                    Claim:</span>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li>Duly filled and signed insurance claim form.</li>
                                                                                                                                                                                    <li>FIR Copy/ General Complaint filed with Local Police Authority.</li>
                                                                                                                                                                                    <li>WSFx Smart Currency Card statement of last six months before the date of
                                                                                                                                                                                        incidence.</li>
                                                                                                                                                                                    <li>Any other relevant documents/information asked from time to time to carry out
                                                                                                                                                                                        investigation.</li>
                                                                                                                                                
                                                                                                                                                                                </ul>
                                                                                                                                                                            </ng-template>
                                                                                                                                                                        </ngb-panel>
                                                                                                                                                                    </ngb-accordion>
                                                                                                                                                                </ng-container>

                                                                                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                                                                                    <ng-container>
                                                                                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                                                                                    <span class="text-black fw-600 fs-16" >35. What should I do if the merchant says
                                                                                                                                                                                        that my forex card does not work?</span>
                                                                                                                                                                                </ng-template>
                                                                                                                                                                                <ng-template ngbPanelContent>
                                                                                                                                                                                    Please ask the merchant to ensure that he is swiping your card through a credit card
                                                                                                                                                                                    terminal and not a debit card terminal. He also needs to ensure that the machine is
                                                                                                                                                                                    online and connected. In case both these scenarios don’t work, please report it to us
                                                                                                                                                                                    immediately.
                                                                                                                                                                                </ng-template>
                                                                                                                                                                            </ngb-panel>
                                                                                                                                                                        </ngb-accordion>
                                                                                                                                                                    </ng-container>

                                                                                                                                                                    <div class="accordion collapse-margin mt-2">
                                                                                                                                                                        <ng-container>
                                                                                                                                                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                                <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                                    <ng-template ngbPanelTitle>
                                                                                                                                                                                        <span class="text-black fw-600 fs-16">36. Can I retain foreign currency balance on
                                                                                                                                                                                            my card after returning to India?</span>
                                                                                                                                                                                    </ng-template>
                                                                                                                                                                                    <ng-template ngbPanelContent>
                                                                                                                                                                                        Yes, you can retain the card if the leftover balance on card is less than USD 2000 or
                                                                                                                                                                                        its equivalent. In case the balance exceeds this limit, per regulatory norms, you are
                                                                                                                                                                                        required to surrender it in not more than 180 days of your return.
                                                                                                                                                                                    </ng-template>
                                                                                                                                                                                </ngb-panel>
                                                                                                                                                                            </ngb-accordion>
                                                                                                                                                                        </ng-container>

                                                                                                                                                                        <div class="accordion collapse-margin mt-2">
                                                                                                                                                                            <ng-container>
                                                                                                                                                                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                                        <ng-template ngbPanelTitle>
                                                                                                                                                                                            <span class="text-black fw-600 fs-16" >37. Who should I contact for my card
                                                                                                                                                                                                replacement? Is there a charge for replacement?</span>
                                                                                                                                                                                        </ng-template>
                                                                                                                                                                                        <ng-template ngbPanelContent>
                                                                                                                                                                                            You can place the request for a replacement card in the WSFx Global Pay App/ you can call
                                                                                                                                                                                            our customer care @ 080-45651100 or write to us at <span
                                                                                                                                                                                                style="text-decoration: underline;">wsfxsmartcard@yesbank.in</span> .The charges for
                                                                                                                                                                                            the reissuance are given in the schedule of charges section.
                                                                                                                                                                                        </ng-template>
                                                                                                                                                                                    </ngb-panel>
                                                                                                                                                                                </ngb-accordion>
                                                                                                                                                                            </ng-container>
                                                                                                                                                                            <div class="accordion collapse-margin mt-2">
                                                                                                                                                                                <ng-container>
                                                                                                                                                                                    <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                                        <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                                            <ng-template ngbPanelTitle>
                                                                                                                                                                                                <span class="text-black fw-600 fs-16" >38. How long does it take to process the
                                                                                                                                                                                                    refund of the unused balance in my Forex Card?</span>
                                                                                                                                                                                            </ng-template>
                                                                                                                                                                                            <ng-template ngbPanelContent>
                                                                                                                                                                                                You can encash the unused balance in your card once you return to India. However, the
                                                                                                                                                                                                last transaction done on the card should be more than 10 days prior to the date of
                                                                                                                                                                                                encashment.<br>
                                                                                                                                                                                                Please note that it takes up to 2-3 working days to encash the balance on your card
                                                                                                                                                                                                after the request is received.<br>
                                                                                                                                                                                                <span style="text-decoration: underline;font-weight: 600;">Note: </span>It may take more
                                                                                                                                                                                                than 3 working days if there is any discrepancy in the claimed balance in the forex card
                                                                                                                                                                                                or account details shared by the card holder are found to be incorrect.
                                                                                                                                                                                            </ng-template>
                                                                                                                                                                                        </ngb-panel>
                                                                                                                                                                                    </ngb-accordion>
                                                                                                                                                                                </ng-container>
                                                                                                                                                                                <div class="accordion collapse-margin mt-2">
                                                                                                                                                                                    <ng-container>
                                                                                                                                                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                                                                                                                                                            <ngb-panel  [cardClass]="'collapse-margin'">
                                                                                                                                                                                                <ng-template ngbPanelTitle>
                                                                                                                                                                                                    <span class="text-black fw-600 fs-16">39. What is Currency Auth Order?</span>
                                                                                                                                                                                                </ng-template>
                                                                                                                                                                                                <ng-template ngbPanelContent>
                                                                                                                                                                                                    This is applicable when you have more than one Currency Wallet loaded on your Account,
                                                                                                                                                                                                    the order that the transactions or fees may be applied to your Account will be as per
                                                                                                                                                                                                    Currency Auth Order. Currency Auth Order will be defined by the card issuer and in case
                                                                                                                                                                                                    of any change in the order the card issuer may send notification to cardholders. Current
                                                                                                                                                                                                    “Currency Auth Order” for your WSFx Smart Currency Card is as follows: <br>
                                                                                                                                                                                                    USD, EUR, GBP, SGD, JPY, CAD, AUD, NZD, AED, THB, HKD, CHF, SAR and ZAR.
                                                                                                                                                                                                    For e.g. Cardholder performs following transactions where Currency Auth Order will be
                                                                                                                                                                                                    applicable:<br><br>
                                                                                                                                                                                                    Scenario 1 – If the local currency is part of the offered multiple currencies on the
                                                                                                                                                                                                    WSFx Smart Currency Card but Cardholder does not have sufficient balance in local
                                                                                                                                                                                                    currency wallet, however if the customer has sufficient balance (equivalent to local
                                                                                                                                                                                                    currency) on default currency wallet (USD) then the transaction will be processed using
                                                                                                                                                                                                    the balances on the default currency wallet. Value from the default currency will be
                                                                                                                                                                                                    converted to local currency using cross currency conversion rate applicable.<br><br>
                                                                                                                                                                                                    Scenario 2 – If the local currency is part of the offered multiple currencies on the
                                                                                                                                                                                                    WSFx Smart Currency Card but customer does not have sufficient balance in local currency
                                                                                                                                                                                                    wallet as well as default currency wallet (USD) however if the customer has sufficient
                                                                                                                                                                                                    balance (equivalent to local currency) on any other currency wallet then the transaction
                                                                                                                                                                                                    will be processed using the balances from the respective currency wallet.<br><br>
                                                                                                                                                                                                    Scenario 3 – If the local currency is not part of the offered multiple currencies on the
                                                                                                                                                                                                    WSFx Smart Currency Card however if the customer has sufficient balance (equivalent to
                                                                                                                                                                                                    local currency) on any other currency wallet then the transaction will be processed
                                                                                                                                                                                                    using the balances from the respective currency wallet.<br><br>
                                                                                                                                                                                                    Value from the respective currency will be converted to local currency using cross
                                                                                                                                                                                                    currency conversion rate decided by the card issuer. This rate will be calculated with a
                                                                                                                                                                                                    Currency Conversion Fee on the prevailing day’s foreign exchange rate. Cross currency
                                                                                                                                                                                                    conversion would take place as per existing Conversion rates as applicable. <br><br>
                                                                                                                                                                                                    Partial transactions in different currency wallets are not allowed. If the customer does
                                                                                                                                                                                                    not have sufficient balance to process the transactions individually in any of the
                                                                                                                                                                                                    currency wallets, the transaction is declined even if the collective balances on the
                                                                                                                                                                                                    WSFx Smart Currency Card across all currencies is sufficient to process the
                                                                                                                                                                                                    transaction.<br><br>
                                                                                                                                                                                                </ng-template>
                                                                                                                                                                                            </ngb-panel>
                                                                                                                                                                                        </ngb-accordion>
                                                                                                                                                                                    </ng-container>
                                                                                                                                                                                   
                                                                                                                                                                               </div>
                                                                                                                                                                               
                                                                                                                                                                            </div>
                                                                                                                                                                            <div class="col-md-6 text-left">
                                                                                                                                                                                <button  class="btn btn-feature-dark" (click)="goToNewDashboard()">
                                                                                                                                                                                    Back
                                                                                                                                                                                </button>
                                                                                                                                                                                </div>
</div>
</div>
</div>
</div>
