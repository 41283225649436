import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';



@Component({
  selector: 'my-faqs',
  templateUrl: './my-faqs.component.html',
  styleUrls: ['./my-faqs.component.scss'],

})
export class MyFaqsComponent {
  public showLoader : boolean = true;

  goToNewDashboard() {
    this.router.navigate(['/my-card']);
  }
  constructor(
    public router: Router,
   
  ) { }
  

 
}




 
 

  

 
 

