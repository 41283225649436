import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CartItemCreateRequestList, OrderRequestModel, QuotationCurrenciesRequestModel, QuotationCurrencyResponsesModel, QuotationRequestModel, QuotationResponseModel } from '../../../models/card.model';
import { CardService } from '../../../services/card.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';

@Component({
  selector: 'view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: [
    './view-cart.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ViewCartComponent implements OnInit {

  public showSelectedCard: boolean = true;
  public cashCurrencyList: any;
  public forexCardCurrencyList: any;
  public currencySelected: any;
  public currencySelectedCash: any;
  public currencyAmount = 0;
  public currencyAmountCash = 0;
  public cartColumns: string[] = ['product', 'currency', 'amount', 'rateINR', 'amountINR', 'action'];
  public loading = true;
  public loadingText: string = '';

  public margingChargesString: string = '0.0';
  public cardDetailsList = new Array<any[]>();
  public cardDetailsObject = new Array<any[]>();
  public branchAddressList = [];
  public branchId: string ;
  public kitId: any;
  public cardNumber: string;
  public cardType: string='SmartCurrencyCard';
  public consumerPanSelf: string;
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationRequest = new QuotationRequestModel();
  // public quotationResponse = new QuotationResponseModel();
  public quotationResponse: any;
  public orderRequestList = new Array<OrderRequestModel>();
  public orderRequestModel: OrderRequestModel;
  public cartEditAddStatus = 0;
  public deleteItemMesg: string;
  public deleteItemIndex: any;
  @ViewChild('deleteConfirmationDailog') deleteConfirmationDailog: TemplateRef<any>;
  public modalReference: any;
  public clockDisplay: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public duration: any;
  public seconds = "00";
  public minutes = "00";

  public editCardCurrency = false;
  public editCashCurrency = false;

  public currencyCodeReload: string;
  public isFromReload: boolean = false;
  public isCartEmpty: boolean = false;
  public allowCashCurrency: boolean = true;

  // TRIP DETAILS
  public inTrip = false;
  public departureDate = new Date();
  public returnDate = new Date();
  public minDeparDate: Date;
  public minReturnDate: Date;
  public country: any;
  public countries = [];
  public clockDisplayPer = 0;
  public orderCartType = 'CARD_PURCHASE';

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public currencyItems: any;
  public showLoader: boolean = false;

  public currencySelectedObj: any;
  public currencySelectedCashObj: any;
  public currencyCardAmount: any;
  public currencyCode: any;
  selectedBranchAddress: any;
  consumerDetails: any;
  selectedCard: any;

  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cardService: CardService,
    private profileService: UpdateProfileService,
    public modalService: NgbModal,
    public toast: ToastrService,
    public gtmPushService: GTMDataLayerService
  ) {
    //this.getSessionData();
  }

  ngOnInit() {

    
    this.selectedCard = sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARDS);
    if(this.selectedCard) {
      this.selectedCard = JSON.parse(this.selectedCard);
      this.cardType = this.selectedCard.cardType;      
    }/*  else {
      let cardTypeObj = (sessionStorage.getItem(ConstantsKey.MY_CARD_CHOOSE));
      if(cardTypeObj) {
        this.cardType = cardTypeObj;
      }  
    } */
    

    this.getSessionData();
    if (this.isFromReload == true) {
      this.checkAndFixCurrencyAddBalance();
    }
    //this.cardType=sessionStorage.getItem(ConstantsKey.CARD_TYPE);
    this.getCardDetails();
    this.getNearByBranchLocation();
    //this.getCurrencyRate();
    this.getCurrencies(Constants.productsEnum[0]);
    this.getCurrencies(Constants.productsEnum[1]);
    this.getPaxList();
    this.getTripDetails();
    this.timer();
  }

  getSessionData() {
    this.isFromReload = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RELOAD));
    if (this.isFromReload == null || this.isFromReload == undefined || this.isFromReload == false) {
      this.isFromReload = false;
    } else {
      this.isFromReload = true;
    }
    console.log('this.isFromReload>>>', this.isFromReload);

    this.cartCurrenciesList = JSON.parse(localStorage.getItem('cartCurrenciesList'));
    if (this.cartCurrenciesList == null) {
      this.isCartEmpty = true;
    } else {
      if (this.cartCurrenciesList != null && this.cartCurrenciesList.length > 0) {
        this.callQuotation(0);
      }
      this.currencySelected = this.cartCurrenciesList[0].currencyCode;
      this.currencyCode = this.currencySelected;
      console.log('this.currencyCode', this.currencyCode);
    }
  }


  checkAndFixCurrencyAddBalance() {
    var addBalanceCurrency = localStorage.getItem(ConstantsKey.CARD_ADD_BALANCE_CURRENCY);
    if (addBalanceCurrency != null) {
      var addBalanceCurrencyObj = JSON.parse(addBalanceCurrency);
      this.currencyCode = addBalanceCurrencyObj.currencyCode;
      this.currencySelected = addBalanceCurrencyObj.currencyCode;
      this.currencySelectedCash = addBalanceCurrencyObj.currencyCode;
    }
  }

  ngOnDestroy() { clearInterval(this.interval); }

  backClick() {
    localStorage.removeItem('cartCurrenciesList');
    if (this.isFromReload) {
      this.router.navigate(['/my-card']);
      this.clearCart();
    } else {
      this.router.navigate(['/newcard']);
      this.clearCart();
    }
  }

  getTripDetails() {
    this.showLoader = true;

    this.cardService.getTripDetails().subscribe(respTrip => {
      this.showLoader = false;
      var response = respTrip.body;

      if (response['inTrip'] != null && response['inTrip'] == true) {
        this.inTrip = response['inTrip'];

        if (this.inTrip) {
          this.allowCashCurrency = false;

          this.departureDate = new Date(moment(response['fromDate']).format("YYYY-MM-DD"));
          this.orderRequestModel.arrivalDate = moment(response['toDate']).format("YYYY-MM-DD");
          this.minDeparDate = this.departureDate;
          this.minReturnDate = new Date(moment(response['toDate']).format("YYYY-MM-DD"));
          this.country = response['destinationCountry'];

          for (var i = 0; i < this.countries.length; i++) {
            if (this.countries[i].countryName === this.country) {
              this.orderRequestModel.visitingCountry = this.countries[i].countryCode;
            }
          }
        } else {
          this.minDeparDate = new Date();
          this.minReturnDate = new Date();
          this.minReturnDate.setDate(this.minReturnDate.getDate() + 1);
        }

      } else {
        this.inTrip = false;
        this.minDeparDate = new Date();
        this.minReturnDate = new Date();
        this.minReturnDate.setDate(this.minReturnDate.getDate() + 1);
      }
    });
  }
  getNearByBranchLocation() {
    this.cardService.getNearByBranch(0.0, 0.0).subscribe(respBranchList => {
      this.branchAddressList = JSON.parse(JSON.stringify(respBranchList.body));
    });
  }
  selectDeliveryOfficeAddress(chooseOfficeAddress) {
    this.selectedBranchAddress = chooseOfficeAddress;
    this.branchId = this.selectedBranchAddress.branchId;    
  }

  // getSelectedCurrencyForAddBalance() {
  //   this.activatedRoute.params.subscribe(params => {
  //     let currencyCodeReload = params['currencyCode'];
  //     if (currencyCodeReload) {
  //       this.isFromReload = true;
  //     }

  //     if (currencyCodeReload != 'None' && currencyCodeReload) {
  //       this.currencyCountryList.filter((item, index) => {
  //         if (item.currencyCode === currencyCodeReload) {
  //           this.currencySelected = item;
  //         }
  //       });
  //     }
  //   });
  // }

  getCardDetails() {
    this.showLoader = true;

    this.cardService.getCardDetails().subscribe(respData => {
      this.showLoader = false;
      this.cardDetailsList = JSON.parse(JSON.stringify(respData.body));

      for (var i = 0; i < this.cardDetailsList.length; i++) {
        if (this.cardDetailsList[i]['cardIssuer'] == 'WSFLMCC') {
          this.cardDetailsObject = this.cardDetailsList[i];
        }
      }
      this.cardNumber = this.cardDetailsObject['cardNumber'];
      this.kitId = this.cardDetailsObject['kitId'];
    });
  }

  // getCurrencyRate() {
  //   this.isFromReload = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RELOAD));
  //   if (this.isFromReload == null || this.isFromReload == undefined || this.isFromReload == false) {
  //     this.isFromReload = false;
  //   } else {
  //     this.isFromReload = true;
  //   }
  //   console.log('this.isFromReload>>>', this.isFromReload);

  //   this.masterDataService.getCurrentRateListCard().subscribe(result => {
  //     var currentResult = result.body;
  //     this.currencyCountryList = currentResult[0]['rates'];
  //     this.loading = false;
  //     if (this.isFromReload == null || this.isFromReload == false) {
  //       this.addCurrencyToCardFunc();
  //     }
  //   }, error => {
  //     this.loading = false;
  //   });
  // }

  getCurrencies(product) {
    
    
     if (product=='CASH') {
        this.reqGetCurrencyMaster.cartType = "CASH_PURCHASE"
        this.reqGetCurrencyMaster.product = Constants.productsEnum[1];
    } else {
       this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE"
        this.reqGetCurrencyMaster.product = Constants.productsEnum[0];
    }
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {

      
       if (product=='CASH') {
          this.cashCurrencyList = result.body;
       } else {
              this.isFromReload = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RELOAD));
            if (this.isFromReload == null || this.isFromReload == undefined || this.isFromReload == false) {
              this.isFromReload = false;
            } else {
              this.isFromReload = true;
            }
            this.forexCardCurrencyList = result.body;
          if(this.selectedCard.cardType == 'studentPayCard' || this.selectedCard.cardType == 'travelPayCard') {
            this.forexCardCurrencyList = this.forexCardCurrencyList.filter(obj => obj.currencyCode == 'USD');
          }
           if (this.isFromReload == null || this.isFromReload == false) {
           this.addCurrencyToCardFunc();
          }
      }
       
      this.removeCurrency('DKK');
      this.removeCurrency('INR'); 
      this.removeCurrency('NOK');
       if (product==Constants.productsEnum[0]) {
          this.removeCurrency('SEK'); 
       }

    });
    
  }
   removeCurrency(doc){
   this.currencyItems.forEach( (item, index) => {
     if(item['currencyCode'] === doc) this.currencyItems.splice(index,1);
   });
}


  addCurrencyToCard() {

    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected,Constants.productsEnum[0]);

    if (this.currencySelectedObj) {
      if (this.currencyCardAmount != null && this.currencyCardAmount != '') {
        this.addCurrencyToCardFunc();
      } else {
        this.showLoader = false;
        this.toast.error('Please enter amount!');
      }
    } else {
      this.showLoader = false;
      this.toast.error('Please select currency first !');
    }


  }

  addCurrencyToCardFunc() {

    this.showLoader = true;
    if (this.currencySelected) {
      this.currencyCode = this.currencySelected;
    }
    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencyCode,Constants.productsEnum[0]);

    let isAlreadyExitCurrency = -1;
    this.isCartEmpty = false;
    let filterData = new Array<QuotationCurrenciesRequestModel>();
    if (this.cartCurrenciesList != null) {
      // this.showLoader = false;
      filterData = this.cartCurrenciesList.filter((item, index) => {
        if (item.currencyCode === this.currencySelectedObj.currencyCode && item.product == Constants.productsEnum[0]) {
          isAlreadyExitCurrency = index;
        }
      });
    }

    console.log("currencySelectedObj >", this.currencySelectedObj);

    let quotationCurrencies = new QuotationCurrenciesRequestModel();
    //quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
    quotationCurrencies.currencyAmount = this.currencyCardAmount;
    quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
    quotationCurrencies.product = Constants.productsEnum[0];
    quotationCurrencies.purpose = 'PRIVATE_VISIT';

    this.cartCurrenciesList = (localStorage.getItem('cartCurrenciesList') == null) ? [] : JSON.parse(localStorage.getItem('cartCurrenciesList'));
    this.cartEditAddStatus = 1;
    this.cartCurrenciesList.push(quotationCurrencies);
    console.log("this.cartCurrenciesList >", this.cartCurrenciesList);

    localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));

    this.callQuotation(this.cartEditAddStatus);
    this.editCardCurrency = false;
    this.editCashCurrency = false;
    this.isCartEmpty = false;
  }

  getSelectedCurrencyObj(currencyCode: string,product) {
    this.showLoader = true;

    var returnCurrencyObj: any;
   

    if (product== Constants.productsEnum[0]) {
              this.forexCardCurrencyList.forEach(singleCurreny => {
              if (singleCurreny.currencyCode == currencyCode) {
                returnCurrencyObj = singleCurreny;
              }
            });
    } else {
                this.cashCurrencyList.forEach(singleCurreny => {
            if (singleCurreny.currencyCode == currencyCode) {
              returnCurrencyObj = singleCurreny;
            }
          });
    }
    return returnCurrencyObj;
  }


  addCashCurrencyToCard() {
    if (this.currencySelectedCash) {
      this.currencySelectedCashObj = this.getSelectedCurrencyObj(this.currencySelectedCash,Constants.productsEnum[1]);
      if (this.currencyAmountCash != null && this.currencyAmountCash) {
        let isAlreadyExitCurrency = -1;
        this.isCartEmpty = false;
        let filterData = new Array<QuotationCurrenciesRequestModel>();
        if (this.cartCurrenciesList != null) {
          filterData = this.cartCurrenciesList.filter((item, index) => {
            if (item.currencyCode === this.currencySelectedCashObj.currencyCode && item.product == Constants.productsEnum[1]) {
              isAlreadyExitCurrency = index;
            }
          });
        }

        let quotationCurrencies = new QuotationCurrenciesRequestModel();
       // quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedCashObj.minSalePriceInINR);
        quotationCurrencies.currencyAmount = this.currencyAmountCash;
        quotationCurrencies.currencyCode = this.currencySelectedCashObj.currencyCode;
        quotationCurrencies.product = Constants.productsEnum[1];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';

        this.cartCurrenciesList = (localStorage.getItem('cartCurrenciesList') == null) ? [] : JSON.parse(localStorage.getItem('cartCurrenciesList'));
        this.cartEditAddStatus = 1;
        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));

        console.log("this.cartCurrenciesList in cash >>", localStorage.getItem('cartCurrenciesList'));

        this.callQuotation(this.cartEditAddStatus);
      } else {
        this.showLoader = false;
        this.toast.error('Please enter amount!');
      }
    } else {
      this.showLoader = false;
      this.toast.error('Please select currency first !');
    }
  }

  removeCartItemClicked(item) {
        this.cartCurrenciesList = JSON.parse(localStorage.getItem('quotationCurrencyResponsesList'));
        this.cartCurrenciesList.filter((singleItem, index) => {
          if (item.product === singleItem.product && singleItem.currencyCode === item.currencyCode) {
            this.deleteItemIndex = index;
            this.deleteItemMesg = item.product + " " + item.currencyCode + " " + item.currencyAmount + " from Cart?";
            this.modalReference = this.modalService.open(this.deleteConfirmationDailog, { centered: true, size: 'sm' });


      }
        });
  
  }

  removeCartItem() {
        this.modalReference.close();
        this.cartCurrenciesList.splice(this.deleteItemIndex, 1);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        if (this.cartCurrenciesList.length > 0) {
          this.cartEditAddStatus = 3;
          this.callQuotation(this.cartEditAddStatus);
        } else {
          this.quotationResponse = new QuotationResponseModel();
          this.quotationCurrencyResponsesList = [];
          this.isCartEmpty=true;
        }
      
    
  }

  // editCartItem(singleCartCurrency) {
  //   this.currencyCountryList.filter((singleItem, index) => {
  //     if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[0]) {
  //       this.currencySelected = singleItem;
  //       this.currencyAmount = singleCartCurrency.currencyAmount;
  //       this.editCardCurrency = true;
  //     }
  //     if (singleItem.currencyCode === singleCartCurrency.currencyCode && singleCartCurrency.product == Constants.productsEnum[1]) {
  //       this.currencySelectedCash = singleItem;
  //       this.currencyAmountCash = singleCartCurrency.currencyAmount;
  //       this.editCashCurrency = true;
  //     }
  //   });
  // }

  callQuotation(cartEditAddStatus) {

    this.showLoader = true;
    console.log("this.cartCurrenciesList >> ", this.cartCurrenciesList);
    this.cartCurrenciesList.filter((singleItem, index) => {
      if (!singleItem.currencyAmount || singleItem.currencyAmount < 1) {
        this.cartCurrenciesList.splice(index, 1);
      }
    });

    this.quotationRequest.sellType = Constants.sellTypes[0];
    this.quotationRequest.partnerId = Constants.partnerId;
    this.quotationRequest.nostroChargeType = 'REMITTER';
    this.quotationRequest.quotationCurrencies = this.cartCurrenciesList;
    //this.quotationRequest.issuerCode = (this.cardType == 'studentPayCard') ? 'WSFXSTUDENTSCC' : 'WSFLMCC';
    this.quotationRequest.issuerCode = this.selectedCard.cardVendor;
    this.quotationRequest.pan = this.consumerPanSelf;
     this.quotationRequest.tcsPaymentSource = 'OWN';
    // this.quotationRequest.calculateTcs = true;
    if(this.quotationResponse!=null){
      this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    }
    console.log("Quotation request >>>>>>>>>", this.quotationRequest);

    this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe(response => {
      // this.showLoader = false;
      // this.currencyAmount = null;
      // this.currencyAmountCash = null;
      // this.currencySelectedCash = null;
      // this.currencyCardAmount = null;
      // this.currencySelected = null;
      // this.quotationResponse = JSON.parse(JSON.stringify(response.body));
      // this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
      // localStorage.setItem('quotationCurrencyResponsesList', JSON.stringify(this.quotationCurrencyResponsesList));

      // localStorage.setItem('CARD-QUOTATION-REQ', JSON.stringify(this.quotationRequest));

      // let message = "";
      // if (cartEditAddStatus == 1) {
      //   message = "New currency added to card.";
      // } else if (cartEditAddStatus == 2) {
      //   message = "Currency updated in your cart.";
      // } else if (cartEditAddStatus == 3) {
      //   message = "Cart item removed.";
      // } else if (cartEditAddStatus == 10) {
      //   message = "Rate from your card update.";
      // }
      // if (cartEditAddStatus > 0) {
      //   this.openSnackBar(message);
      // }

      // this.duration = this.defaultTimerLimitInSec;
      // clearInterval(this.interval);
      // this.startTimer();
    }, error => {


      this.showLoader = false;

      if (error.status == 200) {
        //this.insuranceDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        console.log("insdie decrypt");
        this.showLoader = false;
      this.currencyAmount = null;
      this.currencyAmountCash = null;
      this.currencySelectedCash = null;
      this.currencyCardAmount = null;
      this.currencySelected = null;
      this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
      localStorage.setItem('quotationCurrencyResponsesList', JSON.stringify(this.quotationCurrencyResponsesList));

      // Save local storage for further recall
      localStorage.setItem('CARD-QUOTATION-REQ', JSON.stringify(this.quotationRequest));

      let message = "";
      if (cartEditAddStatus == 1) {
        message = "New currency added to card.";
      } else if (cartEditAddStatus == 2) {
        message = "Currency updated in your cart.";
      } else if (cartEditAddStatus == 3) {
        message = "Cart item removed.";
      } else if (cartEditAddStatus == 10) {
        message = "Rate from your card update.";
      }
      if (cartEditAddStatus > 0) {
        this.openSnackBar(message);
      }

      this.duration = this.defaultTimerLimitInSec;
      clearInterval(this.interval);
      this.startTimer();

      } else {
        //this.toast.error('Something went wrong ! Please try again !');
        this.cartCurrenciesList.forEach((singleItem) => {
          this.cartCurrenciesList.splice(this.cartCurrenciesList.findIndex(item => item.product === "CASH"), 1);
        });
        this.currencyAmountCash = null;
        this.currencySelectedCash = null;
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        this.toast.error(error.error.message);
      }
     
    });
  }

  createQuotation() {
    
      let orderRequestList = [];
      this.showLoader = false;
    if (this.quotationRequest.quotationCurrencies.length > 0) {
        if (this.branchId != null) {

        for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
          let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
          singleRequestCurrency.currencyAmount = this.quotationResponse.quotationCurrencyResponses[i].currencyAmount;
          singleRequestCurrency.currencyCode = this.quotationResponse.quotationCurrencyResponses[i].currencyCode;
          singleRequestCurrency.product = this.quotationResponse.quotationCurrencyResponses[i].product;
          singleRequestCurrency.bidRate = this.quotationResponse.quotationCurrencyResponses[i].rate;
          singleRequestCurrency.sellType = Constants.sellTypes[0];
          singleRequestCurrency.purpose = "PRIVATE_VISIT";
          singleRequestCurrency.branchId = this.branchId;
          // singleRequestCurrency.issuerCode = (this.cardType == 'studentPayCard') ? 'WSFXSTUDENTSCC' : 'WSFLMCC';
          singleRequestCurrency.issuerCode = this.selectedCard.cardVendor;
          orderRequestList.push(singleRequestCurrency);
        }
        if (this.quotationResponse?.quotationSnapshotId) {
          localStorage.setItem(this.quotationResponse.quotationSnapshotId, JSON.stringify(this.quotationResponse));
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }
        this.quotationRequest.calculateTcs = true;
        this.stepperDataCardModel.quotationResponse = this.quotationResponse;
        this.stepperDataCardModel.quotationRequest = this.quotationRequest;
        this.stepperDataCardModel.cartType = this.orderCartType;

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

        this.cardService.cartItemPost(orderRequestList).subscribe(response => {
          sessionStorage.setItem("fromProduct", Constants.productsEnum[0]);

          console.log("Consumer details");
          console.log(this.consumerDetails);
      
          let eventName = this.isFromReload == true ? "web_reload_card_order_start" : "web_buy_card_order_start" 

          let eventObj = {
            'name': eventName,
            'event': eventName,
            'orderType': this.orderCartType,
            'amount': orderRequestList[0].currencyAmount,
            'currency': orderRequestList[0].currencyCode,
            'product': orderRequestList[0].product,
            'mobile': this.consumerDetails.mobile
          };
      
          this.gamoogaEvent(eventObj);
      

          this.router.navigate(['/card-stepper']);
        }, error => {
          this.toast.error(error.error.message);
        });
        } else {
                  this.toast.error('Please select nearest serving branch');

        }
      } else {
        this.toast.error('Please enter amount to proceed!');
      }
    

  }

  clearCart() {
    if (this.cardDetailsList.length > 0) {
      this.orderCartType = 'CARD_RELOAD'
    }

    this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
    });
  }

  navigateNext() {
    sessionStorage.setItem("fromProduct",Constants.productsEnum[0]);
    this.router.navigate(['/card-stepper']);
  }

  cartInitiate() {
    this.showLoader = true;

    let cartOrderType = sessionStorage.getItem(ConstantsKey.CART_ORDER_TYPE);
    if(cartOrderType == 'CARD_RELOAD') {
        this.orderCartType = 'CARD_RELOAD';
    } else {
      if (this.cardDetailsList.length > 0) {
        this.orderCartType = 'CARD_RELOAD'
      }
    }

    this.cardService.cartPrepare(this.orderCartType).subscribe(response => {
      this.createQuotation();
    });
  }

  buySelectedCard() {
    this.showSelectedCard = true;
  }

  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          this.callQuotation(10);
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }

  openSnackBar(message) {
    // this._snackBar.open(message, '', {
    //   duration: 5000,
    //   panelClass: ['green-snackbar'],
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
  }

  getPaxList() {
    this.showLoader = true;

    let consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    console.log("consumerId >>>", consumerId);
    this.profileService.getPaxesList(consumerId).subscribe(result => {
      this.showLoader = false;

      var res = JSON.parse(JSON.stringify(result.body));
      let listPax = res.paxResponses;
      for (let i = 0; i < listPax.length; i++) {
        if (listPax[i]['relationship'] == 'SELF') {
          this.consumerDetails = listPax[i];
          if(listPax[i]['pan']!=null) {
            this.consumerPanSelf = listPax[i]['pan'];
          }
        }
      }
    });
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }

  gamoogaEvent(obj) {
    this.gtmPushService.dataLayerPushData(obj);
  }          
  keyPressNumbers(event) {

    var charCode = (event.which) ? event.which : event.keyCode;

    // Only Numbers 0-9

    if ((charCode < 48 || charCode > 57)) {

      event.preventDefault();

      return false;

    } else {

      return true;

    }

  }
}