import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { SetCardLimitModel } from "app/modules/card/models/card.model";
import { MasterDataService } from "app/shared/services/master-data.service";
import { ToastrService } from "ngx-toastr";
import { CardService } from "../../../services/card.service";
import { ReqGetCurrencyMaster } from "app/shared/models/shared-models";
import { Constants } from "app/shared/constant/constants";
import { CryptoUtils } from "app/shared/utility/crypto.utils";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'set-daily-limit',
  templateUrl: './set-daily-limit.component.html',
  styleUrls: [
    '../../../../../../@core/scss/angular/libs/noui-slider.component.scss',
    // '../../../../../../assets/scss/select.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SetDailyLimitComponent implements OnInit {

  public atmLimit: number = 300;
  public ecomLimit: number = 430;
  public posLimit: number = 660;
  public currencyItems:any = [];
  public currencyCode: any;

  ///SET DAILY LIMIT
  public settingList = [];
  public currencySelected: any;
  public currencyCountryList: any;
  public cardLimitList = [];
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public setCardLimitModel = new SetCardLimitModel();
  public cardDetailsObject = [];

  public isOther = false;
  public dailyAtmLimitValue: string;
  public dailyPosLimitValue: string;
  public dailyEcomLimitValue: string;
  public mySelections: any;

  public valueAtm: any;
  public valueEcom: any;
  public valuePos: any;
  public loading = false;

  public tempDailyATMLimit :any = 1500;
  public tempDailyPOSLimit :any = 10000;
  public tempDailyEcomLImit : any = 10000;
  public tempDailyContactLessLImit : any = 10000;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public cardType: any = 'smartCurrencyCard';
  public studentCardData: any;
  public kitId = '';
  public manageCardStatus;
  public modalReference: any;

    @ViewChild('disableCardModal') disableCardModal: TemplateRef<any>;

  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private _router: Router,
    public masterDataService: MasterDataService,
    public modalService: NgbModal
  ) {
    this.currencyCode = 'USD';
  }

  ngOnInit() {
    this.setCardLimitModel.dailyAtmLimitValue = "0";
    this.setCardLimitModel.dailyPosLimitValue = "0";
    this.setCardLimitModel.dailyEcomLimitValue = "0";
    this.setCardLimitModel.dailyContactLessLimitValue = "0";    
    this.getCurrencies();
    this.getCurrencyRate();
    //this.cardType = sessionStorage.getItem(ConstantsKey.SELECTED_CART_TYPE);
    this.studentCardData = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARD_DATA));
    this.cardType = this.studentCardData.cardType;
    if(this.cardType == 'smartCurrencyCard') {
      this.getCardLimit();
    } else if(this.cardType == 'studentPayCard' || this.cardType == 'globalPayCard') {
      this.studentCardData = JSON.parse(sessionStorage.getItem(ConstantsKey.WSFX_SELECTED_CARD_DATA));
      this.kitId = this.studentCardData.kitId;
      this.getStudentCardLimit();
    }

  }

  getCurrencyRate() {
    this.loading = true;
    this.cardService.getCurrentRate().subscribe((respRate) => {
      var currentResult = respRate.body;
      this.loading = false;
      this.currencyCountryList = currentResult[0]['rates'];
      for (var i = 0; i < this.currencyCountryList.length; i++) {
        if (this.currencyCountryList[i]['currencyCode'] == 'USD') {
          this.cardDetailsObject[0] = this.currencyCountryList[i];
        }
      }

      this.currencySelected = this.currencyCountryList[0];
      console.log("this.currencySelected >>" + JSON.stringify(this.currencySelected));
    });
  }

  getCardLimit() {
    this.loading = true;
    this.cardService.getCardLimit(this.currencyCode).subscribe((respLimit) => {
      this.loading = false;

      /* this.tempDailyATMLimit = this.setCardLimitModel.dailyAtmLimitValue;
      this.tempDailyPOSLimit = this.setCardLimitModel.dailyPosLimitValue;
      this.tempDailyEcomLImit = this.setCardLimitModel.dailyEcomLimitValue; */
    }, error => {
            this.loading = false;

      if (error.status == 200) { 
            this.cardLimitList = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
             console.log(this.cardLimitList[0]['upperLimitAtm']);
              this.setCardLimitModel.dailyAtmLimitValue = this.cardLimitList[0]['actualLimitAtm'];
              this.setCardLimitModel.dailyPosLimitValue = this.cardLimitList[0]['actualLimitPos'];
              this.setCardLimitModel.dailyEcomLimitValue = this.cardLimitList[0]['actualLimitEcom'];

      
                this.tempDailyATMLimit = this.cardLimitList[0]['upperLimitAtm'];
                this.tempDailyPOSLimit = this.cardLimitList[0]['upperLimitPos'];
                this.tempDailyEcomLImit = this.cardLimitList[0]['upperLimitEcom'];

                console.log("this.setCardLimitModel >>", this.setCardLimitModel);


      } else {
        let errObj = error.error.message;
        this.toast.error(errObj.shortMessage);
      }

      
    });
  }

  setCardLimit() {
    this.manageCardStatus = sessionStorage.getItem(ConstantsKey.MANAGE_CARD_STATUS);    
    if (this.manageCardStatus == 'UL') {
    if(this.cardType == 'smartCurrencyCard') {
      this.loading = true;
      this.setCardLimitModel.currencyCode = this.currencyCode; //this.currencySelected.currencyCode;
      delete this.setCardLimitModel["dailyContactLessLimitValue"];
      this.cardService.setCardLimit(this.setCardLimitModel).subscribe(data => {
        this.loading = false;
        // this._router.navigate(['/manageCard']);
      }, err => {
              this.loading = false;
        if (err.status == 200) {
          this.toast.success('Card daily limit has been set successfully.');
  
        } else {
          this.toast.error(err.error.message);
        }
        
      });
    } else if(this.cardType == 'studentPayCard' || this.cardType == 'globalPayCard') {
      this.updateStudetnCardLimit();
    }
  }else{
    this.showCardBlockPopup();
    }

  }

  getCurrencies() {
    this.loading = true;
    this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE",
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[0];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      console.log(result.body);
      this.currencyItems = result.body;
    });
  }

  onCurrencyChange(evt) {
    this.getCardLimit();
  }

  getStudentCardLimit() {

    this.cardService.getStudentPayCardManageCardOption(this.kitId).subscribe(res => {
        console.log("Manage Card Options ----------------------------------------", res);
        let respObj:any = res.body;
        respObj.forEach((value, index) => {
          if (value.type == 'ATM') {
            this.setCardLimitModel.dailyAtmLimitValue = value.maxTransactionAmountPerDay;
          } else if(value.type == 'E-com') {
            this.setCardLimitModel.dailyEcomLimitValue = value.maxTransactionAmountPerDay;
          } else if(value.type == 'POS') {
            this.setCardLimitModel.dailyPosLimitValue =  value.maxTransactionAmountPerDay;
          } else if(value.type == 'ContactLess') {
            this.setCardLimitModel.dailyContactLessLimitValue = value.maxTransactionAmountPerDay;
          }
 
        });

        /* this.tempDailyATMLimit = this.cardLimitList[0]['upperLimitAtm'];
        this.tempDailyPOSLimit = this.cardLimitList[0]['upperLimitPos'];
        this.tempDailyEcomLImit = this.cardLimitList[0]['upperLimitEcom'];
        this.tempDailyEcomLImit = this.cardLimitList[0]['upperLimitEcom']; */

      }, err => {
      console.log(err.error.message);
    });
  }

  updateStudetnCardLimit() {
    this.loading = true;
    this.setCardLimitModel.currencyCode = this.currencyCode; //this.currencySelected.currencyCode;
    let limitObj = [{
      "preferenceType": "ATM",
      "maxTransactionAmountPerDay": this.setCardLimitModel.dailyAtmLimitValue
    },{
      "preferenceType": "POS",
      "maxTransactionAmountPerDay": this.setCardLimitModel.dailyPosLimitValue
    },{
      "preferenceType": "ContactLess",
      "maxTransactionAmountPerDay": this.setCardLimitModel.dailyContactLessLimitValue
    },{
      "preferenceType": "E-com",
      "maxTransactionAmountPerDay": this.setCardLimitModel.dailyEcomLimitValue
    },
  ];

    let updateLimit = {
      "kit": this.kitId,
      "updateLimits": limitObj
    }

    this.cardService.updateStudentCardLimit(updateLimit).subscribe(res => {
      let msg = res.body;
      if(msg["result"].message == 'Preferences updated successfully') {
        this.toast.success("Card daily limit has been set successfully.");
      }
    }, err => {
      this.toast.error(err.error.message);
    });
  }

  showCardBlockPopup() {
    this.modalReference = this.modalService.open(this.disableCardModal, { centered: true });
  }


}
