export class CartItemModel {
    currencyAmount: string;
    currencyCode: string;
    product: string;
    subPurpose: string;
    bidRate: string;
    ibrRate: string;
    userId: string;
    partnerId: string;
    branchId: string;
    currencyInUsd: string;
    cardNumber: string;
    orderType: string;
    nostroCharges: string;
    sellType: string;
    countryCode: string;
    purpose: string;
    beneficiaryName: string;
    nostroChargeType: string;
    issuerCode: string;
}

export class RequestMapBeneficiaryModel {
    beneficiaryId: string;
}

export class RequestCartMapPayer {
    payerName: string;
    payerPan: string;
    paidBy: string;
    paxId: string;
    remitterType: string;
    travellerPaxId: string;
    payerPaxId: string;
    deliveryRequest: DeliveryRequest;
}
export class DeliveryRequest {
    scheduledDate: string;
    scheduledTime: string;
    consumerAddressId: string;
    address: string;
    comment: string;
    deliveryAddressType: string;
}

export class RequestAadhaarEsignDetail {
    consumerId: string;
    refOrderId: string;
}

export class OfacFatfModel {
    occupationLengthResidenceForeign: string;
    sourceOfFund: string;
    nationality: string;
    lengthResidenceForeign: string;
    natureBusinessInIndia: string;
    companyProfile: string;
    transactionHistory: string;
    refOrderId: string;
    isFrequentFlyerCountry: boolean;
}