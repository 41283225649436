<div class="row mt-20 mb-20">
    <div class="col-md-12">
        <img src="assets/images/illustration/a2form.png" class="mb-30 image-self-declaration" style="height:120px !important;">
    </div>
</div>

<div class="row">
    <div class="col-md-12 fs-13 text-black fw-600 mb-20 text-center">Please proceed for Self Declaration</div>
</div>

<div class=" mt-20 mb-30" *ngIf="true">
    <div class="row">
        <div class="col-md-12">
            <div class="custom-control custom-checkbox mb-20">
                <input type="checkbox" class="custom-control-input" id="declaration" [(ngModel)]="isChecked" (change)="checkCheckBoxvalue(isChecked)" />
                <label class="custom-control-label text-black fs-12" for="declaration"></label>
                <label>
                    I hereby acknowledge that I have read, understood, and accepted the
                    <a *ngIf="cartType != 'CASH_PURCHASE' && cardSelected =='smartCurrencyCard'" class="link" target="_blank"
                        href="https://b2capptnc.s3.ap-south-1.amazonaws.com/ForexCardT%26C.pdf"> <span
                            class="blue-text"> terms and conditions, </span> </a>
                            <a *ngIf="cartType != 'CASH_PURCHASE' && cardSelected =='studentPayCard'" class="link" target="_blank"
                            href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/WSFx_Forex_Prepaid_Card_T&C.pdf"> <span
                                class="blue-text"> terms and conditions, </span> </a>
                    <a *ngIf="cartType == 'CASH_PURCHASE'" class="link" target="_blank"
                                href="https://b2capptnc.s3.ap-south-1.amazonaws.com/Card-CurrencyT%26C.pdf"> <span class="blue-text"> terms and
                                    conditions, </span> </a>
                    <a class="link" (click)="getCardReloadForm()" target="_blank" *ngIf="cartType == 'CARD_RELOAD'">
                        <span class="blue-text"> Reload form cum declaration, </span>
                    </a>

                    <a class="link" (click)="getCardReloadForm()" target="_blank" *ngIf="cartType == 'CARD_PURCHASE'">
                        <span class="blue-text">WSFx <span *ngIf="cardSelected =='smartCurrencyCard'">Smart Currency Card</span><span *ngIf="cardSelected =='studentPayCard'">Student Pay Card</span> application form cum declaration. </span>
                    </a>
                    <span >
                    Form A2 cum Declaration, and the submission of my KYC documents as required by WSFx Global Pay Limited. By accepting the terms through the provided OTP, I confirm my agreement to be legally bound by the Terms and Conditions,
                    the contents of the Form A2 cum Declaration, and the submission of my KYC documents.</span>
                </label>
            </div>
        </div>

        <div class="col-md-4">
            <label for="basicInputFile">OTP</label>
            <!-- <input type="text" class="form-control fs-11" [(ngModel)]="otpValue" (change)="updateSessionData()" placeholder="OTP for Acceptance*" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            /> -->
            <input type="text" class="form-control fs-11" [(ngModel)]="otpValue" (change)="updateSessionData()" placeholder="OTP for Acceptance*" maxlength="6" name="otp" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
            <small class="form-text text-right fs-11 blue-text" style="cursor: pointer;" (click)="sendA2FormAcknowledge()">Resend OTP</small>
        </div>

        <!-- <div [ngClass]="{'col-lg-3 col-md-3 col-sm-3 col-xs-12 col-9 ':showImageUploadedIcon, 'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12':!showImageUploadedIcon}">
            <div class="form-group">
                <label for="basicInputFile">Upload signature</label>
                <div class="custom-file">
                    <input type="file" (change)="uploadSignature($event)" class="form-control fs-12" accept=".jpeg,.jpg,.png" id="customFile-papersign" placeholder="Upload signature *" />
                    <label class="custom-file-label" for="customFile-papersign">
                        Choose file
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-3  mt-23" *ngIf="showImageUploadedIcon">
            <div class="input-group-append" style="height: 38px;"> -->
        <!-- <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                        style="width:20px;width:20px"
                        ></span> -->
        <!-- <span class="input-group-text "><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
            </div>
        </div> -->


        <!-- <div class="col-md-12 text-black fs-12 mt-10">
            *Please note that the A2 Form will be sent to you registered email ID. You will be required to sign the A2 Form and upload it or have it emailed to us at customersupport@wsfx.in for the transaction to be processed successfully. The physical copy of the
            A2 Form will be subsequently collected by our service executive.
        </div> -->
    </div>
</div>


<div class="row" *ngIf="false">
    <div class="col-md-12">
        <span class="custom-control custom-checkbox mb-20">
            <input type="checkbox" class="custom-control-input" id="declaration" [(ngModel)]="isChecked"
                (change)="checkCheckBoxvalue(isChecked)" />
            <label for="declaration" class="custom-control-label" style="border-color: black;"></label>
            <label>
                I hereby acknowledge that I have read, fully understood and affix my signature in acceptance of the
                <span class="blue-text"><a (click)="openTermsAndConditions()">Terms & Conditions, </a></span>
        <span class="blue-text link"> <a (click)="getA2FormLink()">Form A2 cum Declaration </a> </span>
        </label>
        </span>

        <div class="mt-20 fs-11 fw-600 mb-10">
            Note : Aadhaar eSign signs your documents needed for a successful completion of your KYC to process a remittance request with WSFx GLobal Pay Limited.
        </div>
    </div>
</div>
<!-- <card-stepper-footer></card-stepper-footer> -->