<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FEATURES</h6>
                    </div>
                   
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p><b>WSFx Global Pay Card</b> - The perfect partner for global business travel! This advanced USD-denominated prepaid Forex card is specifically designed for business professionals, offering zero cross-currency markups for complete transparency and significant cost savings.</p>
                    <p>With worldwide acceptance in over <b>180 countries</b> and at more than <b>100 million merchant outlets</b>, including top international e-commerce platforms, the <b>WSFx Global Pay Card</b> ensures smooth, secure, and efficient transactions. Whether handling travel expenses or making global purchases, enjoy unmatched financial control with the WSFx Global Pay Card.</p>
                </div>

                
               
              <div class="globalpay">
                <div class="row mt-6">  
                    <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of menuItems"> 
                        <div class="d-flex align-items-center globalpay-section">
                            <img src="assets/images/globalpay-features/{{singleItem.icon}}" style="width: 70px; height: 70px;">
                            <div class="fs-14 fw-600" >
                                {{ singleItem.title }}
                            </div>
                        </div>
                    </div>
                </div>
            
                   
                </div>
                    <div class="col-md-6 text-left ">
                        <button  class="btn btn-globalpay" (click)="goToBackPage()">
                            Back
                        </button>
                        </div>
            </div>
        </div>
    </div>
</div>