<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">

                <!-- Stepper Head Section -->
                <div class="bs-stepper-header">
                    <div class="step" data-target="#beneficiary-details">

                        <!-- trial  -->
                        <button class="step-trigger d-flex  d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">1</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/Traveller.png"  style="width:20%">
                                         <p class="fs-11 fw-600 mb-0">Beneficiary  </p>
                            </span>
                        </button>
                        <!-- trial ends  -->
                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Beneficiary</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Beneficiary Details </span>
                            </span>
                        </button>
                    </div>

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc">
                        <!-- trial  -->
                        <button class="step-trigger d-flex  d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">2</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/KYC.png"  style="width:30%">
                                         <p class="fs-11 fw-600 mb-0">KYC </p>
                            </span>
                        </button>
                        <!-- trial ends  -->

                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">KYC</span>
                                <span class="bs-stepper-subtitle" style="color: black">Complete your
                                    KYC</span>
                            </span>
                        </button>
                    </div>

                    <div class="step" data-target="#lrsDeclaration">
                        <button class="step-trigger">
                        </button>
                    </div>

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc-self-declaration" style="display:none">
                        <button class="step-trigger"></button>
                    </div>

                    <div class="step" data-target="#payment-details">

                        <!-- trial  -->
                        <button class="step-trigger d-flex  d-lg-none">
                            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                                <span class="">3</span>
                            </span>
                            <span class="bs-stepper-label">
                                <img src="assets/images/cartStepper/payment.png"  style="width:30%">
                                         <p class="fs-11 fw-600 mb-0">Payment </p>
                            </span>
                        </button>
                        <!-- trial ends  -->

                        <button class="step-trigger d-none  d-lg-flex">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Payment</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
                            </span>
                        </button>
                    </div>
                </div>
                <!-- End Stepper Head Section -->
                <div class="row pl-2 pr-2" style="padding-top: 8px;">
                    <div class="col-md-3"></div>

                    <div class="col-md-6">
                        <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                                minutes
                            </label>
                        <div class="progress">
                            <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                        </div>


                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <loader *ngIf="showLoader"></loader>

                        <!-- First Step -->
                        <div id="beneficiary-details" class="content">
                            <form #accountDetailsForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <section>
                                            <div class="row">
                                                <div class="col-md-12  mb-10">
                                                    <h6 class="content-header-title
                                            float-left mb-0">
                                                        {{ contentHeader }}
                                                    </h6>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="fs-11">
                                                        Please fill in beneficiary details
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-6 mb-20">
                                            <div style="background: #b6b1b1;padding: 10px;border-radius: 5px;">
                                                <div class="row">
                                                    
                                                    <div class="col-md-8 col-7">
                                                        <div class="row">
                                                            <div class="col-md-3 pr-0 pl-0">
                                                                <div ngbDropdown class="input-group-prepend">
                                                                    <ng-select class="dropdown-currency fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationRequest.quotationCurrencies[0].currencyCode" (change)="updateCurrencyAmount()"
                                                                        [searchable]="false">
                                                                        <ng-template class="text-white ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                            <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                               
                                                            </div>
                                                            <div class="col-md-2 pl-0">
                                                                <input type="text" name="amount" class="form-control  amount-input" placeholder="Amount" [(ngModel)]="reqCurrencyAmount" (keyup.enter)="updateCurrencyAmount()" (change)="updateCurrencyAmount()" (keyup)="updateCurrencyAmount()" />
                                                            </div>
                                                            =
                                                            <div class="col-md-1">
                                                                <span><img src="assets/images/logo/india.png"
                                                                    class="flag-icon"></span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="fw-600 text-black fs-12" style="color: black" *ngIf="!inrAmountZero">
                                                                    ₹ {{
                                                                    quotationResponse.quotationCurrencyResponses[0].inrEquivalentAmountString
                                                                    }}
                                                                </span>
                                                                <span class="fw-600 text-black fs-12" style="color: black" *ngIf="inrAmountZero">
                                                                0
                                                                </span>

                                                            </div>

                                                        </div>

                                                    

                                                        </div>
                                                    <div class="col-md-4 col-5 text-right">
                                                        <div class="fs-10 text-black fw-500 middle-text"> 1 {{quotationResponse.quotationCurrencyResponses[0].currencyCode}} = {{quotationResponse.quotationCurrencyResponses[0].rateString}} INR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                            </div>
                                        </section>

                                        <section class="pb-15" style="border-bottom: 1px solid #eee;">
                                            <div class="row" *ngIf="selectedPurpose.purposeCode === 'UNIVERSITY_FEES' || selectedPurpose.purposeCode === 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="country">Country</label>
                                                    <ng-select placeholder="Select Country" name="countryListItems" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="selectedCountry" (change)="getUniversityList(selectedCountry)" class="fs-12" [clearable]="false" [searchable]="true">
                                                    </ng-select>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="country">University</label>
                                                    <ng-select placeholder="Select University" name="universityListItems" [items]="universityListItems" bindLabel="name" [(ngModel)]="selectedUniversity" addTagText="Add New University" [addTag]="addNewUniversity" (change)="navigateToAddBeneficiary(selectedUniversity.name, selectedCountry)"
                                                        class="fs-12" [clearable]="false" [searchable]="true">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </section>

                                        <list-beneficiary-stepper (childCallbackSender)="parentCallbackReceiverForListBeneficiary($event)">
                                        </list-beneficiary-stepper>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text"><a (click)="openModalForEditCart()">Edit </a></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>

                                </div>
                                <div class="d-flex justify-content-between" style="margin-top: 12px;">
                                    <!-- <button type="button" class="btn btn-outline-dark btn-prev fs-12" rippleEffect
                                        (click)="gotoDashboard()">
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Back</span>
                                    </button> -->
                                    <button (click)="gotoDashboard()" type="submit" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Back</span>
                                    </button>
                                    <button (click)="step01CreateCartItems()" type="submit" class="btn btn-primary btn-next fs-12 personal-remittance-step1" rippleEffect>
                                        <span class="align-middle ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End First Step-->

                        <!-- Third Step -->
                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row mb-20">
                                            <div class="col-md-12  mb-10">
                                                <h6 class="content-header-title
                                            float-left mb-0">
                                                    {{ contentHeader }}
                                                </h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="fs-11"> Upload your transaction documents.
                                                </div>
                                            </div>
                                        </div>
                                        <loader *ngIf="showLoader"></loader>

                                        <!-- <section class="row" *ngFor="let singleDocument of transactionDocListUploaded">
                                    <div [ngClass]="{'col-lg-5 col-md-5 col-sm-5 col-xs-12 col-7':singleDocument.status == 'UPLOADED', 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12': singleDocument.status != 'UPLOADED'}">
                                        <div class="form-group">
                                            <label for="basicInputFile">{{singleDocument.title}}</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                <label class="custom-file-label" for="customFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 col-5" *ngIf="singleDocument.status == 'UPLOADED' ">
                                        <div class="input-group-append mt-23" style="height: 38px;">
                                            
                                            <span class="input-group-text" (click)="downloadUploadedDoc(singleDocument)"><img
                                                    src="assets/images/icon/download.png" class="w-h-20"></span>
                                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                                    class="w-h-20"></span>
                                        </div>
                                    </div>
                                </section> -->

                                        <!-- changed for ui  -->

                                        <section class="row" *ngFor="let singleDocument of transactionDocListUploaded">
                                            <div class="col-12">
                                                <label for="basicInputFile">{{singleDocument.title}}</label>
                                            </div>

                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 col-7" *ngIf="singleDocument.status == 'UPLOADED'">
                                                <div class="form-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event, singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12" *ngIf="singleDocument.status != 'UPLOADED'">
                                                <div class="form-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event, singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-1 col-5" *ngIf="singleDocument.status == 'UPLOADED' ">
                                                <div class="input-group-append " style="height: 38px;">
                                                    <span class="input-group-text" (click)="downloadUploadedDoc(singleDocument)">
                                                <img src="assets/images/icon/download.png" class="w-h-20">
                                            </span>
                                                    <span class="input-group-text">
                                                <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                            </span>
                                                </div>
                                            </div>
                                        </section>


                                        <!-- changed for ui  ends  -->

                                        <section *ngFor="let singleDocument of transactionDocListAwaiting">
                                            <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="basicInputFile">{{ singleDocument.title }}</label>
                                                        <div class="custom-file">
                                                            <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                            <label class="custom-file-label" for="customFile">
                                                        Choose file
                                                    </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section *ngIf="isAddress==false">
                                            <div class="row mb-20">
                                                <div class="col-md-8 fs-12 mb-20">
                                                    <label style="color: black" class=" col-md-12 pl-0"> Add Address*</label>
                                                    <textarea type="textarea" [(ngModel)]="updateProfileModel.address" name="address" rows="3" class="w-100">  </textarea>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text"><a (click)="openModalForEditCart()">Edit </a></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between" style="margin-top: 12px;">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                            ">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                            ">Back</span>            
                                    </button>
                                    <button (click)="(navigateToFourthStep) ? step03GetDocumentProceed(kycForm) : showToast()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle
                                            ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Third Step -->
                        <div id="lrsDeclaration" class="content">
                            <form #lrsDeclaration="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="col-md-12  pl-0 content-header-title fs-16  mb-10">
                                            Traveller details
                                        </div>
                                        <div class="col-md-12  pl-0 text-black fs-14">Have you conducted any foreign exchange transactions under LRS in the current financial year through any other Regulated Entity (AD/FFMC/ADII)?*</div>
                                        <div class="col-md-12  pl-0 mt-20">
                                            <div class="row">
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='Yes' (click)="showLRSFormSection(true)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;Yes
                                                </div>
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='No' (click)="showLRSFormSection(false)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;No
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0" *ngIf="showTransactionDetails || showLocalTransactionDetails">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <div class="row" *ngIf="showTransactionDetails">
                                                        <div class="col-md-12">
                                                            <section class="grey-box-padding-10 mt-10" *ngFor="let singleTransaction of transactionList">
                                                                <div class="row d-flex justify-content-between mb-20">
                                                                    <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>

                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Date of Transaction</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.dateOfTranscation | date :'dd MMM yyyy' }}</div>
                                                                </div>
                                                                <div class="row d-flex justify-content-between mb-10">
                                                                    <div class="col-md-8">Amount in INR</div>
                                                                    <div class="col-md-4 text-right">{{singleTransaction.lrsAmount}}</div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="showLocalTransactionDetails">
                                                        <div class="col-md-12">
                                                                <section class="grey-box-padding-10 mt-10" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                                    <div class="row d-flex justify-content-between mb-20">
                                                                        <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                                        <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.institudeName}}</div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Date of Transaction</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                                    </div>
                                                                    <div class="row d-flex justify-content-between mb-10">
                                                                        <div class="col-md-8">Amount in INR</div>
                                                                        <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                                    </div>
                                                                </section>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 mt-30 pl-0 ">
                                                    <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="showLRSFormSection(true)" rippleEffect>
                                                        <span class="align-middle" >Add more details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 pl-0 mb-20" *ngIf="showLRSForm">
                                            <section class="grey-box-padding-30 mt-30">
                                                <form [formGroup]="lrsForm" (ngSubmit)="saveLRSDataLocally(lrsForm)" novalidate>
                                                    <div class="row">
                                                        <div class="col-md-12 col-12">
                                                            <input type="text" class="form-control fs-14" placeholder="Name of the Bank/FFMC/ADII*" formControlName="instituteName" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.instituteName.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.instituteName.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-20">
                                                            <div class="input-group ">
                                                                <input class="form-control fs-12" placeholder="Date of transaction*" formControlName="selectedDateOfTransaction" (click)="basicDP.toggle()" ngbDatepicker #basicDP="ngbDatepicker" (dateSelect)="onDateSelect($event)"
                                                                    [minDate]="{year: 1950, month: 1, day: 1}" [maxDate]="maxLRSDate" [minDate]="minLRSDate">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.selectedDateOfTransaction.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.selectedDateOfTransaction.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-30">
                                                            <input type="number" class="form-control fs-14" placeholder="Amount in INR*" id="amount" formControlName="lrsDeclarationAmount" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.lrsDeclarationAmount.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.lrsDeclarationAmount.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="button" class="btn btn-outline-dark btn-prev fs-14" rippleEffect (click)="closeLRSForm()">
                                                                <span class="align-middle">Cancel</span>
                                                            </button>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="submit" class="btn btn-primary btn-next fs-14" rippleEffect>
                                                                <span class="align-middle">Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                        <!-- <div class="col-md-12 pl-0" *ngIf="showLocalTransactionDetails">
                                            <div class="row" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                <div class="col-md-12">
                                                    <section class="grey-box-padding-10 mt-10">
                                                        <div class="row d-flex justify-content-between mb-20">
                                                            <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                            <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Date of Transaction</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Amount in INR</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                        </div>
                                                    </section>
                                                </div>

                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-2 offset-md-10 mt-10  pl-0 mb-20" *ngIf="showLocalTransactionDetails">
                                            <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="proceedToLRSDeclaration()" rippleEffect>
                                                <span class="align-middle" >Submit</span>
                                            </button>
                                        </div> -->
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>Rate</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <td>
                                                                <span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a
                                                                                                                                    (click)="removeCartItem(singleTransaction)">Delete </a></span>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button (click)="checkLRSStep()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <!-- Fourth Step -->
                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <loader *ngIf="showLoader"></loader>
                                <esign *ngIf="!showESignIframe"></esign>
                                <div class="col-md-12 text-right pr-0">
                                    <button class="btn btn-primary btn-next mb-20" rippleEffect *ngIf="signatureMode == 'AADHAR_ESIGN' && !showESignIframe" (click)="showIframeForEsign()">
                                        <span class="align-middle ">Proceed </span>
                                    </button>
                                </div>
                                <iframe *ngIf="showESignIframe" width="100%" height="500" [src]="url" id="adharesign" style="border:1px solid #bbb"></iframe>

                                <!-- <stepper-footer></stepper-footer> -->
                                <div class="d-flex justify-content-between mt-30" *ngIf="signatureMode != 'AADHAR_ESIGN' || showESignIframe">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Back</span>                                        
                                    </button>
                                    <button (click)="step03EsignOrOtpValidate()" class="btn btn-primary btn-next fs-12 personal-remittance-esign" rippleEffect>
                                        <span class="align-middle ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Fourth Step -->

                        <!-- Fifth Step -->
                        <div id="payment-details" class="content">
                            <add-bank-account-for-remittance *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                            </add-bank-account-for-remittance>
                            <form #paymentDetails="ngForm" *ngIf="!showAddBankForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row mb-20">
                                            <div class="col-md-12  mb-10">
                                                <h6 class="content-header-title
                                            float-left mb-0">
                                                    {{ contentHeader }}
                                                </h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="fs-11">Complete your payment successfully to proceed with your remittance request.</div>
                                            </div>
                                        </div>

                                        <loader *ngIf="showLoader"></loader>

                                        <!-- <table class="table table-bordered">
                                    <thead class="blue-background">
                                        <tr>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" class="text-center" style="padding-top: 10px">{{this.quotationResponse.quotationCurrencyResponses[0].product }}</td>
                                            <td class="text-center" style="padding-top: 10px">1 {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} = INR {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">{{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} {{this.quotationResponse.quotationCurrencyResponses[0].currencyAmountString}}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">₹ {{this.quotationResponse.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                                            </td>
                                        </tr>
                                        <tr></tr>
                                    </tbody>
                                </table> -->

                                <section class="referrer-section mt-20" *ngIf="!referredStatus">
                                    <div class="row">
                                        <div class="col-md-9 col-9">
                                            <div class="form-group">
                                                <label class="form-group m-form__group mb-0">Apply Referral Code</label>
                                                <input type="text" name="referralCode" [(ngModel)]="referralCode" class="form-control fs-12" placeholder="Enter referral Code*" />
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-3 pl-0 d-flex align-items-end">
                                            <div class="form-group">
                                                <button class="btn btn-primary" (click)="applyReferralCode()">Apply</button>
                                                <!-- <button class="btn btn-danger" (click)="removeReferralCode()" *ngIf="referralCodeApplied">Remove</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="offer-section mt-20">
                                    <div class="row">
                                        <div class="col-md-9 col-9">
                                            <div class="form-group m-form__group">
                                                <label class="form-group m-form__group mb-0">Apply Coupon</label>
                                                <!-- <select class="form-control" name="offer" placeholder="Apply Coupon" required [(ngModel)]="requestQuotationModel.promoCode">
                                                   <option *ngFor="let singleOffer of listOffers" [value]="singleOffer.promoCodeId">
                                                   {{ singleOffer.title }}</option>
                                                </select> -->
                                                <ng-select #refPromoCodeList name="offer" [items]="listOffers" bindLabel="title" bindValue="promoCodeId" [addTag]="addNewCoupon" addTagText="Add New Coupon" [(ngModel)]="requestQuotationModel.promoCode" class="fs-12"  placeholder="Select Coupon or add Coupon" required>
                                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                                        <b>{{item.title}}</b><br>
                                                        <span>{{item.shortDescription}}</span>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="!promoCodeApplied">
                                            <button class="btn btn-primary" (click)="applyPromoCode()">Apply</button>
                                        </div>

                                        <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="promoCodeApplied">
                                            <button class="btn btn-danger" (click)="removePromoCode()">Remove</button>
                                        </div>
                                    </div>
                                </section>

                                                <!-- <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="promoCodeApplied">
                                                    <button class="btn btn-danger" (click)="removePromoCode()">Remove</button>
                                                </div>
                                            </div>
                                        </section> -->

                                        <section class="col-md-12 redeempoints-section mt-20  redeem-points p-15">
                                            <div class="row">
                                                <div class="col-md-9 col-9">
                                                    <div class="form-group">
                                                        <input type="number" name="pointsToRedeem" [(ngModel)]="requestQuotationModel.pointsToRedeem" class="form-control fs-12" placeholder="Enter Redeemable Points *" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-3 pl-0" *ngIf="!creditPointApplied">
                                                    <button class="btn btn-primary" (click)="applyCreditPointToQuote()">Apply</button>
                                                </div>
                                                <div class="col-md-3 col-3 pl-0" *ngIf="creditPointApplied">
                                                    <button class="btn btn-danger" (click)="removeCreditPoint()">Remove</button>
                                                </div>
                                                <!-- <div class="col-md-6">
                                            <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <div class="text-black fw-500 fs-10">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                        </div> -->
                                                <div class="col-md-12 d-flex justify-content-between align-items-center mt-2">
                                                    <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                                    <div class="text-black fw-500 fs-10 text-right">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                                </div>
                                            </div>
                                        </section>

                                        <hr class="dotted">

                                        <!-- <div class="row mb-30">
                                    <div class="col-md-8 col-12" style="margin-top: 3px;">
                                        <div class="fs-16 text-black fw-600" style="color: black">
                                            Choose Your Bank Account
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12">
                                        <button type="button" class="btn btn-primary btn-prev fs-14" rippleEffect (click)="showAddBankForm = true" style="color: black">
                                            Add Bank Account
                                        </button>
                                    </div>
                                </div> -->

                                        <div class="col-md-12 mb-30 mt-30 pl-0 d-flex align-items-center">
                                            <div class="col-md-6 col-sm-6 pl-0 d-flex justify-content-start align-items-center">
                                                <div class="fs-12 text-black pl-0 fw-600">
                                                    Choose Your Bank Account
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 d-flex justify-content-end   mt-md-0">
                                                <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true" style="
                                    font-size: 8px !important;color: black">Add Bank Account</button>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i = index">
                                                <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                                                    <div class="row mb-10">

                                                        <div class="col-md-11 col-10">
                                                            <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                                            <span class="card-title fs-12 text-black fw-600 mb-10">
                                                        {{singleBank.bankName}}
                                                    </span>
                                                            <div class="fs-11 ml-15"> Account No : {{singleBank.number}}</div>
                                                            <div class="fs-11 ml-15"> IFSC Code : {{singleBank.ifscCode}}</div>
                                                            <div class="fs-11 ml-15"> Branch : {{singleBank.branch}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-md-4 col-12 mt-20">
                                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true">
                                            Add Bank Account
                                        </button>
                                    </div> -->
                                        </div>

                                        <div class="row mt-20">
                                            <div class="col-md-12">
                                                <div class="fs-12 text-black fw-600">
                                                    Payment Type
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row mt-10 mb-20">
                                    <div class="col-md-6 fs-11">
                                        <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="setPaymentModeAsFullPayment()" /> &nbsp; &nbsp; Full Payment
                                    </div>
                                    <div class="col-md-4 fw-600">
                                        ₹ {{this.quotationResponse.totalBillableAmount}}
                                    </div>
                                </div> -->
                                        <div class="row mt-3 mb-3 align-items-center">
                                            <div class="col-8 col-md-6 fs-6">
                                                <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="setPaymentModeAsFullPayment()" /> &nbsp; &nbsp; Full Payment
                                            </div>
                                            <div class="col-4 col-md-4 fw-bold">
                                                ₹ {{this.quotationResponse.totalBillableAmount}}
                                            </div>
                                        </div>
                                        <!-- <div class="row mt-20">
                                    <div class="col-md-6 fs-11" *ngIf="partialPaymentAllowed">
                                        <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="setPaymentMethodAsPartial()" /> &nbsp; &nbsp; Book and Pay
                                    </div>
                                    <div class="col-md-4   fw-600" *ngIf="partialPaymentAllowed">
                                        ₹ {{this.quotationResponse.partialBillableAmount}}
                                    </div>
                                </div> -->
                                        <div class="row mt-3 mb-3 align-items-center">
                                            <div class="col-8 col-md-6 fs-6">
                                                <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="setPaymentMethodAsPartial()" /> &nbsp; &nbsp; Book and Pay
                                            </div>
                                            <div class="col-4 col-md-4 fw-bold" *ngIf="partialPaymentAllowed">
                                                ₹ {{this.quotationResponse.partialBillableAmount}}
                                            </div>
                                        </div>
                                        <div class="row mt-10 mb-20">
                                            <div class="col-md-12 fs-9 text-danger fw-600" *ngIf="partialPaymentAllowed">
                                                ₹ {{this.quotationResponse.partialAmountNote}}
                                            </div>
                                        </div>

                                        <div class="row mt-30">
                                            <div class="col-md-12">
                                                <div class="fs-12 text-black fw-600  ">
                                                    Payment Method
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-30 mt-20">
                                            <div class="col-md-6 col-12 fs-14  mb-20" *ngIf="showNetBanking  || !showNEFT">
                                                <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; Net Banking
                                            </div>

                                            <div class="col-md-6 col-12 fs-14  mb-20 " *ngIf="ALLOW_NEFT_PAYMENT && showNEFT">
                                                <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                            </div>
                                        </div>


                                        <div class=" mt-10" *ngIf="paymentMode == 'MANUAL'">
                                            <div class="fs-12 fw-600  text-black">{{accountName}}
                                            </div>
                                            <div class="fs-11  text-black">{{bankName}}</div>
                                            <div class="fs-11  text-black">{{accountNo}}</div>
                                            <div class="fs-11  text-black">{{bankIFSC}}</div>
                                            <div class="fs-11  text-black">{{bankBranch}}</div>
                                        </div>

                                        <div class="mb-30 fs-10 text-danger mt-30  text-500" *ngIf="paymentMode == 'MANUAL'">
                                            Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section.
                                        </div>

                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <!-- <th>Action</th> -->
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <!-- <td>
                                                        <span class="blue-text"><a (click)="openModalForEditCart()">Edit </a></span>
                                                    </td> -->
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>

                                </div>
                                <div class="d-flex justify-content-between" style="margin-top: 12px;">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn  btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Back</span>
                                    </button> -->
                                    <button class="btn btn-outline-dark btn-prev  btn-submit fs-12" rippleEffect (click)="horizontalWizardStepperPrevious()">
                                       <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                       <span class="align-middle ">Back</span>
                                    </button>
                                    <button class="btn btn-primary btn-gradient btn-submit fs-12 personal-remittance-payment" rippleEffect (click)="openModalForPaymentInfo(paymentInstructionModal)">
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!--End Fifth Step -->

                    </form>
                </div>
            </div>
        </section>
    </div>
</div>


<ng-template #transactionDocumentViewModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">{{ documentTitle }}</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <img src={{documentUrl}} style="height:400px;display:block;margin:auto;width:100%;object-fit: contain;">
        </div>
    </div>
</ng-template>


<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                {{paymentInstruction}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" (click)="step04Payment()" style="cursor: pointer;">
            <a> OKAY</a></div>
    </div>
</ng-template>
<ng-template let-modal #EditCardModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">

            <div class="col-md-6 col-6 mb-1">


                <label class="form-label" for="country" style="color: black">Select Currency*</label>

                <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationRequest.quotationCurrencies[0].currencyCode" [searchable]="true">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                    </ng-template>
                </ng-select>

            </div>
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Enter Amount*</label>

                <input required type="number" name="amount" class="form-control fs-12" style="border-color:black" placeholder="Amount*" (paste)="onPaste($event)"(keypress)="keyPressNumbers($event)" [(ngModel)]="reqCurrencyAmount" required/>

            </div>
        </div>
    </div>



    <div class="col-md-12  text-center">

        <button class="mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="updateCurrencyAmount()">
            Update
        </button>
    </div>
</ng-template>

<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center">Please be informed that our online portal facilitates allow transaction up to $25,000 (or equivalent).</span><br>
        <span class="text-center">If your transaction exceeds this limit, we kindly request you to utilize our branches. </span>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
        Okay </button>
    </div>
</ng-template>