import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';


@Component({
  selector: 'newfeatures',
  templateUrl: './newfeatures.component.html',
  styleUrls: ['./newfeatures.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewFeaturesComponent implements OnInit {
  public showLoader: boolean = false;
 
  public menuItems = [
    {
      'icon': 'newfeature1.png',
      'title': 'Zero Cross Currency Mark-up',
      'url': '',
    },
    {
        'icon': 'newfeature2.png',
        'title': 'Reload Card Anytime / Anywhere',
        'url': '',
      },
    {
      'icon': 'newfeature3.png',
      'title': 'Tap & Pay',
      'url': '',
    },
    {
      'icon': 'newfeature4.png',
      'title': 'App enable card management',
      'url': '',
    },
    {
      'icon': 'newfeature5.png',
      'title': 'Chip and Pin Protected',
      'url': '',
    },
   
    {
      'icon': 'newfeature6.png',
      'title': 'Real Time Transaction alerts',
      'url': '',
    },
    {
      'icon': 'newfeature7.png',
      'title': 'Emergency Cash(GCAS)',
      'url': '',
    },
    {
      'icon': 'newfeature8.png',
      'title': 'Insurance Coverage upto 5 Lac',
      'url': '',
    },
    {
      'icon': 'newfeature9.png',
      'title': 'Surcharge free withdrawals at AllPoint ATMs',
      'url': '',
    },
    
  ];
  public Items = [
    {
      'icon': 'benefit1.png',
      'title': 'International Sim',
      'url': 'esim',
    },
    {
        'icon': 'benefit2.png',
        'title': 'Free ISIC Card',
        'url': 'isicCard',
      },
      {
        'icon': 'benefit3.png',
        'title': 'International Lounge Access',
        'url': 'lounge-access',
      },
];

  constructor(
    public router: Router,
    public toast: ToastrService,
    private _location: Location
   
  ) { }

  ngOnInit() {
    this.getProfileList();
  }

  goToNewCard() {
    this.router.navigate(['/newcard']);
  }

  getProfileList() {
   
  }

 

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  goToFeatures(singleItem) {
    // console.log("single ITEM === == ",singleItem);
    // if(singleItem.url == 'esim' || singleItem.url == 'lounge-access') {
      // this.toast.error('Coming soon!');
    // } else {
      this.router.navigate([singleItem.url]);
    // }

  }

  goToBackPage() {
    this._location.back();
  }
  
}
