<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FEATURES</h6>
                    </div>
                   
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p><b>WSFx Travel Pay Card</b> - A cutting-edge USD-denominated prepaid Forex card designed for global travelers who value seamless international payment solutions. With zero cross-currency markups, it ensures complete transparency, significant cost savings, and the convenience of making payments in local currencies without additional charges.</p>
                    <p>Accepted in over <b>180 countries</b> and at more than <b>100 million merchant outlets</b>, including international e-commerce platforms, the <b>WSFx Travel Pay Card offers secure, hassle-free, and convenient transactions</b> wherever your adventures take you. Whether exploring new destinations, shopping at retail stores, withdrawing cash from ATMs, or making online purchases, experience effortless spending with the WSFx Travel Pay Card.</p>
                </div>

                
               
              <div class="travelpay">
                <div class="row mt-6">  
                    <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of menuItems"> 
                        <div class="d-flex align-items-center travelpay-section">
                            <img src="assets/images/travelpay-features/{{singleItem.icon}}" style="width: 70px; height: 70px;">
                            <div class="fs-14 fw-600" >
                                {{ singleItem.title }}
                            </div>
                        </div>
                    </div>
                </div>
            
                   
                </div>
                    <div class="col-md-6 text-left ">
                        <button  class="btn btn-travelpay" (click)="goToBackPage()">
                            Back
                        </button>
                    </div>
            </div>
        </div>
    </div>
</div>