<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card mb-0">
            <div class="card-body">
                <form class="form-horizontal" (ngSubmit)="createBeneficiary(AddBeneficiaryForm.form.valid)" #AddBeneficiaryForm="ngForm">

                    <div class="fs-16 text-black fw-600 mb-30">{{queryParams.title}}</div>
                    <loader *ngIf="showLoader"></loader>
                    <div class="row mt-15">
                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Select Currency</b></label>
                            <ng-select [clearable]="false" name="currency" placeholder="Select" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="beneficiaryCreateRequestModel.currencyCode" class="fs-12" [disabled]="currencySelected" [searchable]="false">
                                <!-- <ng-select class="fs-11" style="border-color:black" [clearable]="false" [items]="currencyItems" [(ngModel)]="beneficiaryCreateRequestModel.currencyCode" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency"> -->
                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                    <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="form-label" for="country"><b>Country</b></label>
                            <ng-select placeholder="Select Country" style="border-color:black" name="countryName" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="beneficiaryCreateRequestModel.countryCode" (change)="getUniversityList(beneficiaryCreateRequestModel.countryCode)"
                                [disabled]="freezeCountryField" [readonly]="isReadOnly" [searchable]="true">
                            </ng-select>
                        </div>
                        <div class="form-group col-md-6" *ngIf="( queryParams.purposeCode == 'UNIVERSITY_FEES' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT' )  ">
                            <label class="form-label"><b>University Name*</b></label>
                            <input required type="text" name="university_name" class="form-control fs-12" style="border-color:black" placeholder="University Name" [(ngModel)]="beneficiaryCreateRequestModel.viaAgentName" #universityNameRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && universityNameRef.invalid"
                                maxlength="100" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" />
                            <!-- <label class="form-label" for="country">University</label>
                             <ng-select name="universityListItems" [items]="universityListItems"
                               bindLabel="beneficiaryName" [(ngModel)]="beneficiaryCreateRequestModel.viaAgentName"
                               #universityNameRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && universityNameRef.invalid"
                               placeholder="Select University" class="fs-12" [clearable]="false">
                             </ng-select> -->
                            <span *ngIf="AddBeneficiaryForm.submitted && universityNameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="universityNameRef.errors.required">
                                    This field is required!
                                </small>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="form-label"><b>City</b></label>
                            <input type="text" name="city" class="form-control fs-12" style="border-color:black" placeholder="City" [(ngModel)]="beneficiaryCreateRequestModel.city" maxlength="50" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" />
                        </div>
                        <div class="form-group col-md-6" *ngIf="queryParams.purposeCode == 'INVESTMENT_IN_DEBT'">
                            <div class="row mt-20">
                                <div class="col-md-6 fs-12">
                                    <input type="radio" name="radios" id="radio" value='Equity' /> Equity
                                </div>
                                <div class="col-md-6 fs-12">
                                    <input type="radio" name="radios" id="radio" value='Debt' /> Debt
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <section *ngIf="queryParams.purposeCode != 'UNIVERSITY_FEES' && queryParams.purposeCode != 'INVESTMENT_IN_DEBT' &&  !showForm">
                    <div class=" content-header mt-15">
                        <h5 class="mb-20  text-black ">Transfer Mode</h5>
                    </div>
                    <div class="row mb-30">
                        <div class="col-md-4">
                            <input type="radio" name="paymentRadio" id="paymentRadio" class="middle-text" value='Bank Account' /> Bank Transfer
                        </div>

                        <div class="col-md-2 text-right">
                            <button type="submit" rippleEffect class="btn btn-primary mr-1" (click)="showFormFields()">Next</button>
                        </div>
                    </div>
                </section> -->

                    <h6 class="mb-0 text-black fw-600 mb-20 mt-20">Bank Details</h6>
                    <div class="row mt-10">
                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Beneficiary Name*</b></label>
                            <input required type="text" name="beneficiary_name" class="form-control fs-12" style="border-color:black" placeholder="Account Holder Name" [(ngModel)]="beneficiaryCreateRequestModel.name" #beneficiaryNameRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && beneficiaryNameRef.invalid"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="100" />
                            <span *ngIf="AddBeneficiaryForm.submitted && beneficiaryNameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="beneficiaryNameRef.errors.required">This
                                    field is required!</small>
                            </span>
                        </div>

                        <div class="form-group col-md-6" *ngIf="queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                            <label class="form-label"><b>My Relationship with Beneficiary*</b></label>
                            <ng-select name="relationshipWithBeneficiary" placeholder="Select" style="border-color:black" [items]="relationshipWithBeneficiary" bindLabel="name" bindValue="name" [(ngModel)]="beneficiaryCreateRequestModel.remittanceForRelationship" #relationshipWithBeneficiaryRef="ngModel"
                                [class.error]="AddBeneficiaryForm.submitted && relationshipWithBeneficiaryRef.invalid" class="fs-12" required [clearable]="false" [searchable]="false"> </ng-select>
                            <span *ngIf="AddBeneficiaryForm.submitted && relationshipWithBeneficiaryRef.invalid" class="invalid-form">
                                <small class="form-text text-danger"
                                    *ngIf="relationshipWithBeneficiaryRef.errors.required">This field is
                                    required!</small>
                            </span>
                        </div>


                        <div *ngIf="queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT'" [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':idProofSelected, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!idProofSelected}">
                            <div class="form-group">
                                <label for="basicInputFile"><b>Bene ID/ Relationship Proof</b></label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadProofDocument($event)" />
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="(idProofSelected) && (queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT')" class="col-md-2 col-3">
                            <div class="input-group-append mt-23" *ngIf="true" style="height: 38px;">
                                <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                                        class="w-h-20" (click)="downloadUploadedDoc(proofDocumentFile)"></span>
                                <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                        class="w-h-20"></span>
                            </div>
                        </div>

                        <!-- <div class="form-group col-md-6" *ngIf="queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                <label>Bene ID/ Relationship Proof</label>
                                <div class="input-group mb-2">
                                    <input type="file" placeholder="Bene ID/ Relationship Proof" class="form-control fs-12" name="relationshipProof">
                                </div>
                            </div> -->

                        <div *ngIf="queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT'" [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':additionalDOcSelected, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!additionalDOcSelected}">
                            <div class="form-group">
                                <label for="basicInputFile"><b>Additional Documents</b></label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadAdditionalDocument($event)" />
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="(additionalDOcSelected) && (queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT')" class="col-md-2 col-3">
                            <div class="input-group-append mt-23" *ngIf="true" style="height: 38px;">
                                <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                                        class="w-h-20" (click)="downloadUploadedDoc(additionalDocumentFile)"></span>
                                <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                        class="w-h-20"></span>
                            </div>
                        </div>

                        <!-- <div class="form-group col-md-6" *ngIf="queryParams.purposeCode == 'GIFT' || queryParams.purposeCode == 'FAMILY_MAINTENANCE' || queryParams.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                <label>Additional Documents</label>
                                <div class="input-group mb-2">
                                    <input type="file" placeholder="Additional Documents" class="form-control fs-12" name="additionalDocument">
                                </div>
                            </div> -->

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Beneficiary Address*</b></label>
                            <input required type="text" name="beneficiaryAddress" class="form-control fs-12" style="border-color:black" placeholder="Account Holder Address" [(ngModel)]="beneficiaryCreateRequestModel.address" #beneficiaryAddressRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && beneficiaryAddressRef.invalid"
                                maxlength="100" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" />
                            <span *ngIf="AddBeneficiaryForm.submitted && beneficiaryAddressRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="beneficiaryAddressRef.errors.required">This
                                    field is required!</small>
                            </span>
                        </div>

                        <!-- <div class="form-group col-md-6">
                            <label class="form-label"><b>Select Currency</b></label>
                            <ng-select class="fs-11" [clearable]="false" [items]="currencyItems" style="border-color:black" [(ngModel)]="beneficiaryCreateRequestModel.currencyCode" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency">
                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                    <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                                </ng-template>
                            </ng-select>
                        </div> -->

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Account Number/IBAN Number*</b></label>
                            <input required type="text" name="acccountNo" class="form-control fs-12" style="border-color:black" placeholder="Account Number/IBAN Number" [(ngModel)]="beneficiaryCreateRequestModel.bankAccountNumber" #acccountNoRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && acccountNoRef.invalid"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="30" />
                            <span *ngIf="AddBeneficiaryForm.submitted && acccountNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="acccountNoRef.errors.required">This
                                    field is required!</small>
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Confirm Account Number/IBAN Number*</b></label>
                            <input required type="text" name="confirmAcccountNo" class="form-control fs-12" style="border-color:black" placeholder="Confirm Account Number/IBAN Number" [(ngModel)]="beneficiaryCreateRequestModel.confirmBankAccountNumber" #confirmAcccountNoRef="ngModel"
                                [class.error]="AddBeneficiaryForm.submitted && confirmAcccountNoRef.invalid" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="30" />
                            <span *ngIf="AddBeneficiaryForm.submitted && confirmAcccountNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="confirmAcccountNoRef.errors.required">This
                                    field is required!</small>
                                <div *ngIf="AddBeneficiaryForm.confirmAcccountNoRef.errors.mustMatch">Passwords must
                                    match</div>
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Bank Name*</b></label>
                            <input required type="text" class="form-control fs-12" name="bank_name" style="border-color:black" placeholder="Bank Name" [(ngModel)]="beneficiaryCreateRequestModel.bankName" #bankNameRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && bankNameRef.invalid"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="100" />
                            <span *ngIf="AddBeneficiaryForm.submitted && bankNameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="bankNameRef.errors.required">This field
                                    is required!</small>
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Bank Address*</b></label>
                            <input type="text" name="bank_address" class="form-control fs-12" style="border-color:black" placeholder="Bank Address" [(ngModel)]="beneficiaryCreateRequestModel.bankAddress" #bankAddressRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && bankAddressRef.invalid"
                                required maxlength="100" />
                            <span *ngIf="AddBeneficiaryForm.submitted && bankAddressRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="bankAddressRef.errors.required">This
                                    field is required!</small>
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Pincode</b></label>
                            <input type="text" name="pincode" id="pincode" class="form-control fs-12" style="border-color:black" placeholder="Pincode" (keypress)="keyPressNumbers($event)" maxlength="6" (paste)="onPaste($event)" />
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Bank SWIFT Code</b></label>
                            <input type="text" name="bank_swift_code" id="bank_swift_code" class="form-control fs-12" style="border-color:black" placeholder="Bank SWIFT Code" [(ngModel)]="beneficiaryCreateRequestModel.swiftCode" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)"
                            />
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Bank ABA/Routing*</b></label>
                            <input type="text" name="bank_aba_routing" id="bank_aba_routing" class="form-control fs-12" style="border-color:black" placeholder="Bank ABA/Routing*" [(ngModel)]="beneficiaryCreateRequestModel.sortCode" #sortCodeRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && sortCodeRef.invalid"
                                required />
                            <span *ngIf="AddBeneficiaryForm.submitted && sortCodeRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="sortCodeRef.errors.required">This field
                                    is required!</small>
                            </span>
                        </div>
                    </div>

                    <div class="content-header mt-15">
                        <h6 class="mb-0 text-black fw-600">Intermediary Bank Details</h6>
                    </div>
                    <div class="row mt-20">
                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Intermediary Bank Name</b></label>
                            <input type="text" name="intermediary_bank_name" id="intermediary_bank_name" class="form-control fs-12" style="border-color:black" placeholder="Intermediary Bank Name" [(ngModel)]="beneficiaryCreateRequestModel.intermediaryBankName" (keypress)="alphaNumberOnly($event)"
                                (paste)="onPaste($event)" maxlength="100" />
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Intermediary Bank SWIFT Code</b></label>
                            <input type="text" name="intermediary_bank_swift_code" id="intermediary_bank_swift_code" class="form-control fs-12" style="border-color:black" placeholder="Intermediary Bank SWIFT Code" [(ngModel)]="beneficiaryCreateRequestModel.intermediaryBankSwiftCode"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="12" />
                        </div>
                    </div>

                    <div class="content-header mt-15">
                        <h6 class="mb-0  text-black fw-600">Other Details</h6>
                    </div>
                    <div class="row mt-20">
                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Reference Number</b></label>
                            <input type="text" name="reference_no" id="reference_no" class="form-control fs-12" style="border-color:black" placeholder="Reference Number" [(ngModel)]="beneficiaryCreateRequestModel.flyWireReferenceNumber" maxlength="100" />
                        </div>

                        <div class="form-group col-md-6" *ngIf="queryParams.purposeCode == 'UNIVERSITY_FEES'">
                            <label class="form-label"><b>Student ID*</b></label>
                            <input type="text" name="studentId" id="student_id" class="form-control fs-12" style="border-color:black" placeholder="Student ID" [(ngModel)]="beneficiaryCreateRequestModel.studentId" #studentIDRef="ngModel" [class.error]="AddBeneficiaryForm.submitted && studentIDRef.invalid"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="30" required />
                            <span *ngIf="AddBeneficiaryForm.submitted && studentIDRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="studentIDRef.errors.required">
                                    This field is required!
                                </small>
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label"><b>Other Details</b></label>
                            <input type="text" name="other_details" id="other_details" class="form-control fs-12" style="border-color:black" placeholder="Other Details" [(ngModel)]="beneficiaryCreateRequestModel.additionalDetails" maxlength="100" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" />
                        </div>
                    </div>
                    <div class="row mt-30">
                        <div class="col-md-3 col-3">
                            <button type="button" rippleEffect class="btn btn-primary  fs-12" (click)="backClicked()">Back</button>
                        </div>
                        <div class="col-md-9 col-9 mb-20 text-right ">
                            <button type="submit" rippleEffect class="btn btn-primary  fs-12">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>