import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RequestDirectLogin } from 'app/init-widget/models/init-widget.model';
import { RequestLoginModel } from 'app/modules/onboarding/models/login.models';
import { LoginService } from 'app/modules/onboarding/services/login.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Subject } from 'rxjs';
import { SsoService } from '../services/sso.service';
import { FormGroup } from '@angular/forms';
import { ISICCardService } from 'app/modules/isic-card/services/isic-card.service';
import { ToastrService } from 'ngx-toastr';
import { CardService } from 'app/modules/card/services/card.service';
import { Constants } from 'app/shared/constant/constants';

@Component({
  selector: 'sso',
  templateUrl: './sso.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService, SsoService, CardService]
})
export class SsoComponent implements OnInit {

  public coreConfig: any;
  public agentCode: string;
  public themeName: string;
  public submitted: any;
  public showLoader: boolean = false;
  public loginForm: FormGroup;
  public error = '';
  public parseID: any;
  public product : any;
  public currencyCode : any;
  public currencyCodeAmount  :any;
  public type : any;
  public xAuthToken : any;

  private _unsubscribeAll: Subject<any>;
  public requestLoginModel = new RequestLoginModel();
  public requestDirectLogin: RequestDirectLogin = new RequestDirectLogin();
  public ssoObj;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _coreConfigService: CoreConfigService,
    public router: Router,
    public route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService,
    public sosService: SsoService,
    public toast : ToastrService,
    public _cardService: CardService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit() {
    localStorage.clear();

    this.route.queryParams.subscribe(async params => {
      var getBase64Data = params['data'];
      this.parseID = atob(getBase64Data);
      this.sosService.getDataForSso(this.parseID).subscribe(resp => {
        var responseJSON = resp.body;
        console.log(JSON.stringify(responseJSON));
        this.ssoObj = responseJSON;
        this.product = responseJSON['product'];
        this.currencyCode = responseJSON['requiredCurrencyCode'];
        this.currencyCodeAmount = responseJSON['requiredCurrencyCodeAmount'];
        //responseJSON['requiredCurrencyCodeAmount']=2000;
        this.type = responseJSON['type'];
        localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, responseJSON['xAuthToken']);
        localStorage.setItem(ConstantsKey.SSO_RESP,JSON.stringify(responseJSON));
        localStorage.setItem(ConstantsKey.PRODUCT, this.product);
        this.sosService.getConsumerProfileData().subscribe(resp => {
          this.showLoader = true;
          var respBody = JSON.parse(JSON.stringify(resp.body));
         localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.id);
         localStorage.setItem(ConstantsKey.PAXID, respBody.paxResponse.id);
         localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody));
         localStorage.setItem("validCardCurrencyList",JSON.stringify(respBody["validCardCurrencyDetails"]));
         if(respBody?.paxResponse?.pan){
          localStorage.setItem(ConstantsKey.CONSUMER_PAN, respBody?.paxResponse?.pan);
        }
          if(this.product == 'SEND-MONEY'){
            this.router.navigate(['/send-money-dashboard']);
          }else if(this.product == 'BUY-FOREX'){

            // localStorage.setItem(ConstantsKey.CARD_LOGIN_ORIGIN, responseJSON['originPage']);
            // localStorage.setItem(ConstantsKey.CARD_ACTION, responseJSON['actionType']);
            // this.router.navigate(['/newcard']);            
            this.handleCardRedirection('BUY-FOREX');
            /* let actionType = responseJSON['actionType'];
            let loginFrom = responseJSON['originPage'];
            this.handleCardRedirection(); */
            /* if(actionType == "LINK-CARD") {
              if(loginFrom == "STUDENT-CARD-PAGE") {
                this.router.navigate(['/link-existing-card']);               
              } else {
                this.router.navigate(['/link-student-card']); 
              }
            } else {
              this.router.navigate(["/newcard"]);
            } */
          }else  if (this.product == 'SELL-FOREX'){
              this.handleCardRedirection('SELL-FOREX');
              // this.router.navigate(['/cashout']);
          }else  if (this.product == 'BUY-CASH'){
              this.router.navigate(['/currency-notes-view-cart']);
          }else  if (this.product == 'SELL-CASH'){
              this.router.navigate(['/sell-currency-notes']);
          }else  if (this.product == 'REFER-N-EARN'){
              this.router.navigate(['/refer-and-earn']);
          }else  if (this.product == 'SPEND-N-EARN'){
            this.router.navigate(['/rewards-credit-points']);
          } else if(this.product == 'OFFER-COUPONS') {
            this.router.navigate(['/offer-coupons']);            
          }
          else {
              this.router.navigate(['/new-dashboard']);
          }
          
          console.log(respBody);
        }, error => {
          this.showLoader = false;
          this.error = error.error.message;
        });
      }, error => {
        this.toast.error(error.error);
      });
    });
  }

  handleCardRedirection(PRODUCT_TYPE) { //PRODUCT_TYPE  

    let isCardAvl = false;
    let cards = Constants.cardData;
    let selectedCard = cards.filter(card => card.card == this.ssoObj["cardType"])[0];
    sessionStorage.setItem(ConstantsKey.WSFX_SELECTED_CARDS, JSON.stringify(selectedCard));
    if(selectedCard.card == 'SMART_CARD') {
      this.getSmartCardDetails();
    } else {
      this._cardService.getAllCardDetail().subscribe(res => {
        //let ssoObj = localStorage.getItem(ConstantsKey.SSO_RESP);
        let allCard:any = res.body;
        if(allCard.length > 0) {
          for(let i = 0 ;i < allCard.length ;i++) {
            if(selectedCard.cardVendor == allCard[i]["cardVendor"]) {
              isCardAvl = true;
              sessionStorage.setItem(ConstantsKey.ALL_USER_CARDS, JSON.stringify(allCard));
              break;
            }
          } 
          if(!isCardAvl) {
            if(this.ssoObj["actionType"] == 'LINK-CARD') {
              this.router.navigate(['/link-student-card']);          
            } else {
              if(PRODUCT_TYPE == 'BUY-FOREX') {
                this.router.navigate(['/newcard']);
              } else if(PRODUCT_TYPE == 'SELL-FOREX') {
                this.router.navigate(['/cards']);
              }

            }
          } else {
            this.router.navigate(['/my-card']);
          }
        } else {
          if(this.ssoObj["actionType"] == 'LINK-CARD') {
            this.router.navigate(['/link-student-card']);          
          } else {
            this.router.navigate(['/newcard']);
          }
        }
      });
    } 



      

      /* let card = this.ssoObj["cardType"];

      if(card == "STUDENT_CARD" */
      /* if(PRODUCT_TYPE == 'SELL-FOREX') {
        if(this.ssoObj["cardType"] == "STUDENT_CARD" || this.
          ssoObj["cardType"] == "TRAVEL_CARD" || this.ssoObj["cardType"] == "SMART_CARD") {
          this.router.navigate(['/cashout']);
        } else if(this.ssoObj["cardType"] == "GLOBAL_CARD") {
          this.router.navigate(['/newcard']);
        }
      } */
      //console.log("SSO OBJECT FROM WEBSITE ----- ", ssoObj);

  }

  getSmartCardDetails() {
    this._cardService.getCardDetails().subscribe((respCardData => {
      var responseBody:any = respCardData.body;
      if (responseBody.length > 0) {
        this.router.navigate(['/my-card']);
      } else {
        if(this.ssoObj["actionType"] == 'LINK-CARD') {
          this.router.navigate(['/link-existing-card']);          
        } else {
          if(this.ssoObj["product"] == "BUY-FOREX") {
            this.router.navigate(['/newcard']);
          } else if(this.ssoObj["product"] == "SELL-FOREX") {
            this.router.navigate(['/cards']);
          }

        }
      }
    }), error => {
      if(this.ssoObj["actionType"] == 'LINK-CARD') {
        this.router.navigate(['/link-existing-card']);          
      } else {
        if(this.ssoObj["product"] == "BUY-FOREX") {
          this.router.navigate(['/newcard']);
        } else if(this.ssoObj["product"] == "SELL-FOREX") {
          this.router.navigate(['/cards']);
        }
      }
    });;
  }

}

 
