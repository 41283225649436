import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ToastrService } from 'ngx-toastr';
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Router } from '@angular/router';
import { CardService } from 'app/modules/card/services/card.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { NewDashboardPurposeList } from 'app/shared/constant/new-dashboard-menu';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from 'app/modules/order/services/order.service';
import { DateUtils } from 'app/shared/utility/date-utils';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { exit } from 'process';
import Swal from 'sweetalert2';
import { GTMDataLayerService } from 'app/shared/services/gtmDataLayer.service';
declare const gtag: Function;
import moment from 'moment';
import { ISICCardService } from 'app/modules/isic-card/services/isic-card.service';

@Component({
  selector: 'new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss',
    '../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../../../../@core/scss/angular/libs/ratings.component.scss',
    '../../../../@core/scss/angular/libs/select.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})

export class NewDashboardComponent implements OnInit {
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public showLoader: boolean = false;
  public isValidPAN: boolean = false;
  public isValidName: boolean = false;
  public isValidDOB: boolean = false;
  public showLoaderForGetCardDetails: boolean = false;
  public currencyCode: any;
  public currencyItems: any;
  public ngbDatepicker: NgbDateStruct;
  public studentRemittance: any;
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public quotationResponse: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public resGetVcipStatus: any;
  public dobMaxDate: any;
  public vkycTimingText: any;
  public panVerificationErrMsg: any;

  public purposeListRemittance: any = [];
  public selectedPurposeItem: any;

  @ViewChild('selectedTab') selectedTab;
  public cartType: any;
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public showCardDetailsBox: boolean = false;
  public consumerDetail: any;
  public activeTab = 'SEND_MONEY';
  public orderListItems: any;
  public deletedItem: any;

  public signature: any;
  public isProfileComplete: boolean = false;
  public isVcipComplete: boolean = false;
  public isOrderWithoutVcipComplete: boolean = false;
  public activeState = '';
  public configData: any;
  public panName: string;


  public menuItem;
  public addOnMenuItem;
  public mainMenuShow: boolean = true;
  public product: any;
  public ssoResponseData: any;

  @ViewChild('vcipCompleteOrNotModal') vcipCompleteOrNotModal: TemplateRef<any>;
  @ViewChild('vcipModalNewUser') vcipModalNewUser: TemplateRef<any>;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;
  @ViewChild('currenyBuyModal') currenyBuyModal: TemplateRef<any>;



  public modalReference: any;
  public proceedWithoutVCIPCompletion = 'false';
  public productTypeList = [
    "FOREX CARD",
    "CURRENCY NOTES",
  ];

  public selectedProductType: String = "FOREX CARD";
  @Output() childCallback = new EventEmitter<string>();
  public cardLinkedAlready: boolean = false;

  public inrRate = 0.0;
  public remittanceTotalAmount = 0.0;
  public forexcardTotalAmount = 0.0;
  public rateCalculatorResponse: any;
  public rateCalculatorAllResponse: any;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('errorModal') errorModal: TemplateRef<any>;

  public updateProfileModel = new UpdateProfileModel;
  public paxId: any;
  public dob: any;

  public formGroup: FormGroup;
  public skipVcipStatus: boolean = false;
  public remittanceType: any;
  public isPANAvailable: boolean = false;
  showErrorModal: boolean;
  isPANCBChecked: boolean = false;
  isVCIPNow: boolean = false;

  constructor(
    private masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    public toast: ToastrService,
    public router: Router,
    public formBuilder: FormBuilder,
    private cardService: CardService,
    private orderService: OrderService,
    public updateProfileService: UpdateProfileService,
    public masterService: MasterDataService,
    public gtmPushService: GTMDataLayerService,
    public isicCardService: ISICCardService
  ) {
    this.activeTab = 'SEND_MONEY';

    this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType('STUDENT');
    console.log(this.selectedPurposeItem);

  }

  ngOnInit() {
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.menuItem = NewDashboardPurposeList.getDashboardMenu();
    this.addOnMenuItem = NewDashboardPurposeList.getAddOnMenu();
    this.product = localStorage.getItem(ConstantsKey.PRODUCT);
    this.vkycTimingText = Constants.vkycTimingText;
    this.getCardDetails();
    this.getProfileDetails();
    this.getMenuItemFromConfig();
    this.getCurrencies();
    this.convertAmount();
    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      this.ssoResponseData = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.quotationCurrencyItem.currencyAmount = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.quotationCurrencyItem.currencyCode = this.ssoResponseData['requiredCurrencyCode'];
      this.convertAmount();
    } else {
      this.quotationCurrencyItem.currencyCode = "USD";
      this.quotationCurrencyItem.currencyAmount = "2000";
      this.convertAmount();
    }

    // if (this.product == 'SEND-MONEY' || this.product == 'BUY-FOREX') {
    //   this.getSubMenu(this.product);
    // } else if (this.product == 'SELL-FOREX') {
    //   this.router.navigate(['/enquiry-form']);
    // } 


    this.formGroup = this.formBuilder.group({
      cb: [false, Validators.requiredTrue],
      panNo: [Validators]
    });
  }
  // ngAfterViewInit(): void {
  //   var product = localStorage.getItem(ConstantsKey.PRODUCT);
  //   if (this.product == 'BUY-CASH') {
  //     this.openModalCurrencyBuy();
  //   }
  //   localStorage.removeItem(ConstantsKey.PRODUCT);

  // }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  getMenuItemFromConfig() {
    let data = sessionStorage.getItem('CONFIG_DATA');

    this.configData = {
      "remittanceFlowEnable": true,
      "personalFlowEnable": true,
      "cardFlowEnable": true,
      "insuranceEnable": true,
      "dashboardEnable": true,
      "myAccountMenuEnable": true,
      "myAccountSubMenuEnable": true,
      "relationshipEnable": true,
      "beneficiaryEnable": true,
      "bankAccountEnable": true,
      "ordersEnable": true,
      "pendingOrdersEnable": true,
      "rewardsEnable": true,
      "offersEnable": true,
      "branchNetworkEnable": true,
      "currencyConverterEnable": true,
      "resetPasswordEnable": true,
      "ratesEnable": true,
      "setRateAlertEnable": true,
      "supportEnable": true,
      "addressEnable": true,
      "faqEnable": true,
      "tutorialEnable": true,
      "paymentModeNBEnable": true,
      "paymentModeNEFTEnable": true,
      "partialPaymentEnable": true,
      "openGICAccountEnable": true,
      "blockedAccountEnable": true,
      "zolveCreditCardEnable": true,
      "isicCardEnable": true
    }
    this.buildMenuItem();

    // if (data == null) {
    //   this.masterService.geConfigJSON().subscribe(res => {
    //     data = JSON.stringify(res);
    //     this.configData = JSON.parse(data);
    //     this.buildMenuItem();
    //     sessionStorage.setItem('CONFIG_DATA', this.configData);
    //   });
    // } else {
    //   this.configData = JSON.parse(data);
    //   this.buildMenuItem();
    // }
  }

  buildMenuItem() {
    let menuItemsLocal = [];
    this.menuItem.forEach(singleMenu => {
      if (singleMenu.key == 'TRAVELINSURANCE' && this.configData?.insuranceEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'FOREXCARD' && this.configData?.cardFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'STUDENT' && this.configData?.remittanceFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'OTHERS' && this.configData?.personalFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'OPENGICACCOUNT' && this.configData?.openGICAccountEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'BLOCKEDACCOUNT' && this.configData?.blockedAccountEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'ZOLVEDCREDITCARD' && this.configData?.zolveCreditCardEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'ISICCARD' && this.configData?.isicCardEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'CURRENCYNOTES') {
        menuItemsLocal.push(singleMenu);
      }
    });
    this.menuItem = menuItemsLocal;
  }

  productDropdownClicked(value) {
    this.selectedProductType = value;
    if (value == this.productTypeList[0]) {
      this.rateCalculatorResponse = this.rateCalculatorAllResponse["cardRateResponse"];
    } else {
      this.rateCalculatorResponse = this.rateCalculatorAllResponse["cashRateResponse"];
    }
    this.convertAmount();
  }

  checkCardLink() {
    var requestModel = {
      silentValidation: "true"
    }
    this.cardService.linkCard(requestModel).subscribe((result => {
      localStorage.setItem(ConstantsKey.LINK_CARD_NAVIGATED, "true");
      if (result.body == null) {
        this.router.navigate(['/new-dashboard']);
      } else {
        var data = result.body
        if (data['msg'] != null && data['msg'] == 'true') {
          this.router.navigate(['/link-card']);
        } else {
          this.router.navigate(['/new-dashboard']);
        }
      }
    }), error => {
      this.router.navigate(['/new-dashboard']);
    });
  }

  getActiveTab(active) {
    this.activeTab = active;
    if (this.activeTab == 'SEND_MONEY') {
      if (this.menuItem.length == 3) {
        this.menuItem.splice(0, 0, this.deletedItem[0]);
      }
    } else if (this.activeTab == 'BUY_FOREX') {
      if (this.menuItem.length == 4) {
        this.deletedItem = this.menuItem.splice(this.menuItem.findIndex(item => item.key === 'STUDENT'), 1);
        console.log(this.deletedItem);
      }
    }
    this.convertAmount();
  }

  getActiveSectionTitle(singleMenu) {
    console.log(singleMenu);
    this.gamoogaOrderStartEvent(singleMenu);
    if (singleMenu.pageUrl != '') {
      if (!this.showErrorModal) {
        this.navigateToNextPage(singleMenu.pageUrl);
      }
    } else if (singleMenu.purposeCode == '' && singleMenu.pageUrl == '') {
      this.toast.error('Coming soon!');
    }

    this.selectedPurposeItem = singleMenu;
  }

  getCurrencies() {
    this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = this.selectedPurposeItem.remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      Constants.currenciesListGlobal = result.body;
    });
  }

  showMainMenuSection() {
    this.mainMenuShow = true;
  }

  getSubMenu(singleItemKey) {
    if (!this.showErrorModal) {
      this.mainMenuShow = false;
      this.remittanceType = singleItemKey;
      this.activeState = singleItemKey;
      if (singleItemKey == 'TRAVELINSURANCE') {
        this.router.navigate(['/insurance']);
      }
      if (singleItemKey == 'FOREXCARD' || singleItemKey == 'BUY-FOREX') {
        //this.router.navigate(['/buy-forex-landing']);
        //  this.router.navigate(['/newcard']);
        this.router.navigate(['/cards']);              

      }
      if (singleItemKey == 'BLOCKEDACCOUNT') {
        this.router.navigate(['/open-blocked-account']);
      }
      if (singleItemKey == 'OPENGICACCOUNT') {
        this.router.navigate(['/open-gic-account']);
      }
      if (singleItemKey == 'ZOLVEDCREDITCARD') {
        this.router.navigate(['/zolve-credit-card']);
      }
      if (singleItemKey == 'ISICCARD') {
        // this.router.navigate(['/isicCard']);
        this.mainMenuShow = true;
        this.handleISICClick()
      }
      if (singleItemKey == 'CURRENCYNOTES') {
        this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');

        if(this.isOrderWithoutVcipComplete){
          sessionStorage.setItem("showVCIPModalForCashFlow","openModalForVCIPOrder");
          

       } else if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {         
          sessionStorage.setItem("showVCIPModalForCashFlow","openModalForVCIPNewUser");

       } 
       console.log("showVCIPModalForCashFlow>>>"+sessionStorage.getItem("showVCIPModalForCashFlow"));
        this.router.navigate(['/currency-notes-landing']);

      }
      if (this.remittanceType == 'STUDENT' || singleItemKey == 'SEND-MONEY') {
        this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
        if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {
          //this.openModalForVCIP();
          this.openModalForVCIPNewUser();
          this.mainMenuShow = true;

        } else {
          this.studentRemittance = NewDashboardPurposeList.getStudentSubMenu();
          this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType(this.remittanceType);
        }
      }
      if (this.remittanceType == 'OTHERS') {
        this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
        if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {
          //this.openModalForVCIP();
          this.openModalForVCIPNewUser();
          this.mainMenuShow = true;
        } else {
          this.studentRemittance = NewDashboardPurposeList.getPersonalSubMenu();
          this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType(this.remittanceType);
        }
      }
      if (singleItemKey == 'ESIM') {
        this.router.navigate(['/esim']);
      }
      if (singleItemKey == 'LOUNGE') {
        this.router.navigate(['/lounge-access']);
      }
      console.log(this.selectedPurposeItem);
      console.log(this.remittanceType);
    } else {
      this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
    }
  }

  openModalForVCIP() {
    this.modalReference = this.modalService.open(this.vcipCompleteOrNotModal, { size: 'xl', centered: true });
  }
  openModalCurrencyBuy() {
    this.modalReference = this.modalService.open(this.currenyBuyModal, { size: 'lg', centered: true });
  }
  openModalForVCIPNewUser() {
    this.modalReference = this.modalService.open(this.vcipModalNewUser, { centered: true });
  }


  getRateRange() {
    let productEnum: any;
    if (this.product == 'SEND-MONEY') {
      productEnum = Constants.productsEnum[2]
    } else if (this.product == 'BUY-FOREX') {
      productEnum = Constants.productsEnum[0];
    } else {
      productEnum = Constants.productsEnum[1];
    }
    this.showLoader = true;
    this.masterDataService.getRateRange(
      productEnum,
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      console.log(this.singleRateResItem);

    }, error => {
      this.showLoader = false;
    });
  }


  convertAmount() {
    let sellType;
    let productType;
    if (this.activeTab == 'SEND_MONEY') {
      sellType = Constants.sellTypes[0];
      productType = Constants.productsEnum[2];
    } else if (this.activeTab == 'BUY_FOREX') {
      sellType = Constants.sellTypes[0];
          if (this.selectedProductType == this.productTypeList[0]) { 
                  productType = Constants.productsEnum[0];

          } else {
                  productType = Constants.productsEnum[1];

          }

    } else {
      sellType = Constants.sellTypes[1];
           if (this.selectedProductType == this.productTypeList[0]) { 
                  productType = Constants.productsEnum[0];

          } else {
                  productType = Constants.productsEnum[1];

          }
    }

    let currencyAmount = this.quotationCurrencyItem.currencyAmount;
    let currencyCode = this.quotationCurrencyItem.currencyCode;

    if (currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(currencyAmount, currencyCode, sellType,productType).subscribe(resp => {
        let rateResponse = resp.body;
        var cardRateCalculatorObject = {
          "requiredCurrencyCodeAmount": currencyAmount,
          "requiredCurrencyCode": currencyCode,
        };
        this.showErrorModal = false;
        localStorage.setItem(ConstantsKey.SSO_RESP, JSON.stringify(cardRateCalculatorObject));
        if (this.activeTab == 'SEND_MONEY') {
          this.rateCalculatorResponse = rateResponse["remittanceRateResponse"];
        } else if (this.activeTab == 'BUY_FOREX') {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];

          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        } else {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        }
        console.log("Rate calculator");
        console.log(this.rateCalculatorResponse);
      }, err => {
        console.log(err.error.message);
          // this.showErrorModal = true;
        // this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        if (this.activeTab != 'SEND_MONEY') {
          Swal.fire({
          title:"Dear Customer", 
          html: err.error.message, 
          imageUrl: "assets/images/icons/info_icon.png",
          imageWidth: 80,
          imageHeight: 80,
          focusConfirm: false,
          confirmButtonColor: '#1D2A62'
        });
        }
          
        
      });
    } else {
      this.rateCalculatorResponse = {};
    }


  }

  enterCurrencyAmount() {
    if (this.quotationCurrencyItem.currencyAmount) {
      this.getQuotationRequestBody();
      this.remittanceService.getQuotationEncrypted(JSON.parse(JSON.stringify(this.requestQuotationModel))).subscribe(resp => {
        //this.quotationResponse = resp.body;

      }, error => {
        this.showLoader = false;

        if (error.status == 200) {
          this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
          console.log("Quotation Response");
          console.log(this.quotationResponse);
        } else {
          this.toast.error(JSON.stringify(error.error.message));
        }
      });
    }
  }

  getQuotationRequestBody() {
    let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
    /// prepare quotation request
    this.quotationCurrencyItem.product = Constants.productsEnum[2];
    this.quotationCurrencyItem.bidRate = this.rateCalculatorResponse["inrRate"];//this.singleRateResItem.minimumRateInINR;
    this.quotationCurrencyItem.purpose = this.selectedPurposeItem?.purposeCode;
    // this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
    //   ? this.selectedPurposeItem.purposeCode
    //   : "UNIVERSITY_FEES";

    listQuotationCurrencies.push(this.quotationCurrencyItem);

    this.requestQuotationModel.sellType = Constants.sellTypes[0];
    this.requestQuotationModel.partnerId = Constants.partnerId;
    this.requestQuotationModel.nostroChargeType = 'REMITTER';
    this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  proceedToStepper() {
   
      if (this.selectedPurposeItem.length >= 0) {
        this.toast.error('Oops! It looks like you forgot to choose the purpose. Please pick one to get started.');
      } else if (this.selectedPurposeItem.key == 'GIC') {
        if (this.quotationCurrencyItem.currencyCode != 'CAD') {
          this.toast.error('Please select currency as CAD to proceed with GIC!');
        } else {
          if (!this.showErrorModal) {
            this.navigateToNext();
          } else {
            this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
          }
        }
      } else if (this.selectedPurposeItem.key == 'BlockAccount') {
        if (this.quotationCurrencyItem.currencyCode != 'EUR') {
          this.toast.error('Please select currency as EUR to proceed with Blocked Account!');
        } else {
          if (!this.showErrorModal) {
            this.navigateToNext();
          } else {
            this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
          }
        }
      } else {
        if (!this.showErrorModal) {
          this.navigateToNext();
        } else {
          this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        }
      }
    
  }

  navigateToNext() {
    if (this.selectedPurposeItem && this.quotationCurrencyItem.currencyAmount) {
      this.showLoader = true;

      this.cartType = this.selectedPurposeItem.remittanceType;

      this.remittanceService.cartPrepare(this.cartType).subscribe(result => {
        this.getQuotationRequestBody();
        this.remittanceService.getQuotationEncrypted(this.requestQuotationModel).subscribe(resp => {
          ///store active order in session storage

        }, error => {
          this.showLoader = false;
          if (error.status == 200) {
            this.stepperDataModel.purposeItem = this.selectedPurposeItem;
            this.stepperDataModel.quotationRequest = this.requestQuotationModel;
            this.stepperDataModel.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));

            sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
            this.showLoader = false;
            if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
              this.router.navigate(['/stepper3step'])
            } else {
              this.router.navigate(['/stepper'])
            }
            sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'true');
            sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
            sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
            console.log("Quotation Response");
            console.log(this.quotationResponse);
          } else {
            this.toast.error(JSON.stringify(error.error.message));
          }
        });
      }, error => {
        this.toast.error(error.error.message);
      });
    } else {
      var errorMessage = 'Please enter amount';
      if (!this.selectedPurposeItem) {
        errorMessage = "Please select purpose";
      }
      this.toast.error(errorMessage);
    }
  }

  navigateToNextPage(pageUrl) {
    if (pageUrl) {
      console.log("url>>>" + pageUrl);
      this.router.navigate([pageUrl]);
    }
    // else {
    //   this.proceedToStepper();
    // }
  }

  getCardDetails() {
    this.showLoaderForGetCardDetails = true;
    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.cardLinkedAlready = true;
        this.showCardDetailsBox = false;
        this.showLoaderForGetCardDetails = false;
        this.isVcipComplete = true;

      } else {
        this.cardLinkedAlready = false;
        if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
          this.checkCardLink();
        }
        this.showCardDetailsBox = true;
        this.showLoaderForGetCardDetails = false;
      }

    }), error => {
      this.showLoaderForGetCardDetails = false;
      this.showCardDetailsBox = true;
      if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
        this.checkCardLink();
      }
      //this.toast.error(error.error.message);
    });;
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      this.paxId = this.consumerDetail.paxResponse.id;
      localStorage.setItem(ConstantsKey.PAXID, this.consumerDetail.paxResponse.id);

      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      } else {
        localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());
      }
      if (this.consumerDetail?.paxResponse != null && this.consumerDetail?.paxResponse?.pan) {
        this.isPANAvailable = true;
      }
      this.signature = this.consumerDetail.signature;

      if (this.signature == undefined) {
        this.isProfileComplete = false;
      }
      localStorage.setItem(ConstantsKey.CONSUMER_SIGNATURE, this.signature);
      if (this.consumerDetail?.paxResponse?.pan) {
        localStorage.setItem(ConstantsKey.CONSUMER_PAN, this.consumerDetail?.paxResponse?.pan);
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      } else {
        this.getOrderList();
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  getOrderList() {
    this.orderService.getListOrderShortSnippets().subscribe((resp => {
      this.orderListItems = resp.body;
      if (this.orderListItems.length > 0 && !this.isVcipComplete) {
        this.isOrderWithoutVcipComplete = true;
        this.openModalForVCIP();
      }
    }), error => {
    });
  }

  initiateVcip() {
    this.modalReference.close();
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  proceedWithourVCIPAfterOrder() {
    this.modalReference.close();
    //this.openModalForPANNo();
  }
  proceedWithourVCIP() {
    this.modalReference.close();
    this.isVCIPNow = false;

    if (this.isOrderWithoutVcipComplete) {
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
    } else {
      if (this.isPANAvailable) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      } else {
        this.openModalForPANNo();
      }
    }

  }

  showToastMesage() {
    // if (this.selectedProductType == this.productTypeList[1] && this.activeTab == 'BUY_FOREX') {
    //   this.openModalCurrencyBuy();
    // } else {
    //   this.toast.error('Oops! It looks like you forgot to choose the product. Please pick one to get started.');
    // }
          this.toast.error('Oops! It looks like you forgot to choose the product. Please pick one to get started.');

  }

  openModalForPANNo() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }
  openErrorModal() {
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  getPanName() {

    this.updateProfileModel.pan = this.updateProfileModel.pan.toUpperCase();
    if (this.updateProfileModel.pan && this.updateProfileModel.pan.length == 10 && this.validatePANNo(this.updateProfileModel.pan)) {
      this.showLoader = true;
      this.updateProfileService.getPANName(this.updateProfileModel.pan).subscribe(async resp => {
        console.log(resp.body);
        var panResponse = JSON.parse(JSON.stringify(resp.body));
        this.showLoader = false;
        if(panResponse.panStatus == 'E') {
          if (panResponse.fullName && panResponse.fullName != 'null' && panResponse.fullName != '') {
            this.updateProfileModel.name = panResponse.fullName;
          } else {
            this.updateProfileModel.name = '';
          }
  
          if(panResponse.dob && panResponse.dob != 'null' && panResponse.dob != '') {
            this.updateProfileModel.dob = DateUtils.ngbDateStructDateConverter(panResponse.dob);
          }
        } else if(panResponse.flatPan.status == "Record Not Found in ITD Database/Invalid PAN") {
          this.updateProfileModel.name = '';
          this.updateProfileModel.dob = ''; 
          this.toast.error("Invalid Pan");
        } /* else {
          this.toast.error(panResponse.flatPan.status);
        } */

      }, error => {
        this.showLoader = false;
        this.updateProfileModel.name = '';
        // this.updateProfileModel.dob = '';
        this.toast.error(error.error.message);
      });
    }
  }
  validatePanAPI() {

    if(this.validatePanDetails()) {
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.updateProfileService.validatePAN(this.updateProfileModel.pan, this.updateProfileModel.name, dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if (panResponse.panStatus == 'E') { // && panResponse.fullName != "" && panResponse.dob != ""
        this.updatePAN();
      } else if (panResponse.panStatus != 'E') {
        this.panVerificationErrMsg = "Please enter valid PAN";
        this.openErrorModal();
      } /* else if (panResponse.fullName == "") {
        this.panVerificationErrMsg = "Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if (panResponse.dob == "") {
        this.panVerificationErrMsg = "Please enter valid Date of Birth";
        this.openErrorModal();
      } */
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  }
  validatePanDetails() {
    if (!(this.updateProfileModel.pan != null)) {
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!this.validatePANNo(this.updateProfileModel.pan)) {
      this.toast.error('Please enter valid PAN');
      return false;      
    }    
    if (!(this.updateProfileModel.name != null)) {
      this.toast.error('Please enter name as per PAN');
      return false;
    }
    if (!this.isPANCBChecked) {
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if (!(this.updateProfileModel.dob != null)) {
      this.toast.error('Please select your Date of Birth ');
      return false;
    }


    return true;
  }

  updatePAN() {
    this.showLoader = true;
    // var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    // var consumerData = JSON.parse(consumerDataLocal);
    // if (consumerData != null && consumerData.mobile != null) {
    //   this.updateProfileModel.mobile = consumerData.mobile;
    //   this.updateProfileModel.email = consumerData.email;
    //   this.updateProfileModel.consumerId = consumerData.id;
    // } else {
    //   this.updateProfileModel.mobile = this.consumerDetail.mobile;
    //   this.updateProfileModel.email = this.consumerDetail.mobile;
    //   this.updateProfileModel.consumerId = this.consumerDetail.mobile;
    // }
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
    let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob = dateofBirth;
    console.log("pax model>>>> ", JSON.stringify(this.updateProfileModel));
    this.updateProfileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.toast.success('Your PAN details are updated successfully!');

      this.showLoader = false;
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      this.modalReference.close();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
        this.modalReference.close();
        if(this.isVCIPNow) {
          this.initiateVcip();
        }        
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }

  VCIPNow() {
    this.isVCIPNow = true;
    this.openModalForPANNo();
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }

  gamoogaOrderStartEvent(data) {
    if(this.remittanceType == 'STUDENT') {
      let gtmObj = {
        "event": "web_student_remittance_order_start",
        "name": "web_student_remittance_order_start",
        "title": data.title,
        "mobile": this.consumerDetail.mobile,
        "email": this.consumerDetail.email
      };
      this.gtmPushService.dataLayerPushData(gtmObj);
    } else if(this.remittanceType == 'OTHERS') {
      let gtmObj = {
        "event": "web_personal_remittance_order_start",
        "name": "web_personal_remittance_order_start",
        "title": data.title,
        "mobile": this.consumerDetail.mobile,
        "email": this.consumerDetail.email
      };
      this.gtmPushService.dataLayerPushData(gtmObj);
    }

  }

  handleISICClick() {

    this.showLoader = true;
    this.isicCardService.isicApplicationStatus().subscribe(res => {
      this.showLoader = false;
      console.log(" RESPONSE == ",res.body);
      let respObj = res.body;
      if(!respObj) {
        this.router.navigate(['/isicCard']);
      } else if(respObj && respObj["isicCardStatus"] == 'IN_PROCESS') {
        Swal.fire("Your application is under process, Please try after sometime!!!");
      } else if(respObj && respObj["isicCardStatus"] == 'ACTIVE' && respObj["photoStatus"] == 'PENDING') {
        sessionStorage.setItem(ConstantsKey.ISIC_STATUS_DATA, JSON.stringify(respObj));
        this.router.navigate(['/link-isic-card']);
      } else if(respObj && respObj["isicCardStatus"] == 'ACTIVE' && respObj["photoStatus"] == 'UPLOAD') {
        sessionStorage.setItem(ConstantsKey.ISIC_STATUS_DATA, JSON.stringify(respObj));
        this.router.navigate(['/link-isic-card']);
      }
      
      // this.router.navigate(['/isicCard']);
    }, err => {

    })
  }

}
