import { Injectable } from "@angular/core";
import { Constants } from "app/shared/constant/constants";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { LinkCardRequest02Model } from "../models/card.model";

@Injectable()
export class CardService extends BaseProvider {

    ///CARD API 
    getCardDetails() {
        var resourceUrl = "getCardDetails";
        return this.makeGetCall(resourceUrl);
    }

    getCardDetailsEncrypted() {
        var resourceUrl = "b2c/getCardDetails";
        return this.makeGetCall(resourceUrl);
    }

    getManageCardOptions(kitId: string) {
        var resourceUrl = "manageCardOptions?kitId=" + kitId;
        return this.makeGetCall(resourceUrl);
    }

    ///ORDER API
    cartPrepare(cartType) {
        var resourceUrl = "cartPrepare?cartType=" + cartType;
        return this.makePostCall(resourceUrl, {});
    }

    getTripDetails() {
        var resourceUrl = "preOrderDetails";
        return this.makeGetCall(resourceUrl);
    }

    mapTripDetails(request) {
        var resourceUrl = "cartTravel";
        return this.makePutCall(resourceUrl, request);
    }

    getSourceOfFundList() {
        var url = "sourceOfFunds";
        return this.makeGetCall(url);
    }

    getFinanceInstituteList() {
        let url = "tcsLoanProvider";
        return this.makeGetCall(url);
    }

    getAddressesHome() {
        return this.makeGetCall("consumers/address");
    }

    getNearByBranch(latitude = 0.0, longitude = 0.0) {
        var resourceUrl = "nearestBranchToCustomer?latitude=" + latitude + "&longitude=" + longitude;
        return this.makeGetCall(resourceUrl);
    }

    getPaxesList() {
        let consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        let resourceUrl = "consumers/" + consumerId + "/paxes";
        return this.makeGetCall(resourceUrl);
    }

    mapPayerTraveller(request) {
        var resourceUrl = "cartPayer";
        return this.makePutCall(resourceUrl, request);
    }

    cartTotalVersusUploadedDocuments() {
        var resourceUrl = "cartTotalVersusUploadedDocuments";
        return this.makeGetCall(resourceUrl);
    }

    uploadDocument(imageData) {
        return this.makeUploadCall("cartDocument", imageData);
    }

    getA2FormCart(a2AcceptanceRequest) {
        var resourceUrl = "cartA2"
        return this.makePutCall(resourceUrl, a2AcceptanceRequest);
    }

    getCartToUpdateStepNumber(step) {
        var resourceUrl = "cart";
        if (step == 4) {
            resourceUrl = "cart?stepToComplete=" + step.toString();
        }
        return this.makeGetCall(resourceUrl);
    }

    getBankAccountList() {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'bankAccount?consumerId=' + consumerId;
        return this.makeGetCall(resourceUrl);
    }

    getQuotation(requestQuotationModel: any) {
        var resourceUrl = "quotationsV2";
        return this.makePostCall(resourceUrl, requestQuotationModel);
    }
    getQuotationEncrypted(requestQuotationModel: any) {
        var resourceUrl = "b2c/quotationsV2";
        return this.makePostCall(resourceUrl, requestQuotationModel);
    }

    cartItemPost(request) {
        var resourceUrl = "cartItem";
        return this.makePutCall(resourceUrl, request);
    }

    mapBeneficiary(request) {
        var resourceUrl = 'cartBenefeciary';
        return this.makePutCall(resourceUrl, request);
    }

    validateCustomer(request) {
        var resourceUrl = 'b2c/validateCustomer';
        return this.makePostCall(resourceUrl, request);
    }

    validateExpiryDob(request) {
        var resourceUrl = 'validateExpiryDob';
        return this.makePostCall(resourceUrl, request);
    }

    otpRequestForBalanceTransfer(request) {
        var resourceUrl = 'otp';
        return this.makePostCall(resourceUrl, request);
    }
     getOTPINRWallet() {
        var resourceUrl = 'b2c/generateInrWalletOtp';
        return this.makePostCall(resourceUrl,{});
     }
      getINRWalletFormFields() {
        var resourceUrl = 'b2c/inrWalletForm';
        return this.makeGetCall(resourceUrl);
     }
     activateINRWallet(request)  {
            var resourceUrl = 'b2c/activateInrWalletNew';
           return this.makePostCall(resourceUrl,request);
     }

    getCurrencyRateList(currencyCode) {
        var resourceUrl = 'pricing/rateRange?product=' + 'FOREXCARD' + '&currencyCode=' + currencyCode + '&partnerId=' + 'p_wsfxConsumer' + '&sellType=' + 'SELL';
        return this.makeGetCall(resourceUrl);
    }

    getCurrencyConversionRateList(currencyCode, selectedCurrencyCode, amount) {
        var resourceUrl = 'pricing/convertCurrency?partnerId=' + 'p_wsfxConsumer' + '&surrenderingCurrencyCode=' + currencyCode + '&requiredCurrencyCode=' + selectedCurrencyCode + '&surrenderingCurrencyAmount=' + amount + '&conversionType=' + 'WALLET';
        return this.makeGetCall(resourceUrl);
    }

    walletToWalletTransfer(request) {
        var resourceUrl = 'b2c/walletToWalletTransfer';
        return this.makePostCall(resourceUrl, request);
    }

    manageCardUpdate(request) {
        var resourceUrl = 'manageCard';
        return this.makePostCall(resourceUrl, request);
    }

    getRaterangeList(product, currencyCode, sellType, partnerId) {
        var serviceURL = "pricing/rateRangeList?product=" + product + "&currencyCode=" + currencyCode + "&partnerId=" + partnerId + "&sellType=" + sellType;
        return this.makeGetCall(serviceURL);
    }

    getBankAccounts() {
        var url = "bankAccount";
        return this.makeGetCall(url);
    }

    getA2FormAcknowledge() {
        var resourceUrl = 'CartA2OtpInit';
        return this.makePostCall(resourceUrl, {});
    }

    getPaymentMethods() {
        var orderURL = "collectionMethods";
        return this.makeGetCall(orderURL);
    }

    initiatePaymentforCart(request, quotationId) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "b2cPayments?consumerId=" + consumerId + "&quotationSnapshotId=" + quotationId;
        return this.makePostCall(resourceUrl, request);
    }

    makePGCall(resourceURL: string) {
        this.addHeader("Content-Type", "application/x-www-form-urlencoded");
        this.addHeader("channel", "web");
        this.addHeader("Accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8");
        window.open(resourceURL, "_self");
        return this.makeGetCall(resourceURL);
    }

    getConvertedAmount(surrenderingCurrencyCode, requiredCurrencyCode, requiredCurrencyAmount) {
        var resourceUrl = "pricing/convertCurrency?partnerId=p_wsfxConsumer&surrenderingCurrencyCode=" +
            surrenderingCurrencyCode + "&requiredCurrencyCode=" + requiredCurrencyCode + "&surrenderingCurrencyAmount=" + requiredCurrencyAmount;
        return this.makeGetCall(resourceUrl);
    }

    createOrder(request) {
        var userId = Constants.userId;
        var partnerId = Constants.partnerId;
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "b2c/consumers/" + consumerId + "/orders?userId=" + userId + "&partnerId=" + partnerId;
        return this.makePostCallWitList(resourceUrl, request);
    }

    fetchStatement(startDate, endDate,isSendEmail,currencyCode) {
        var resourceUrl = 'b2c/fetchStatementPdf?fromDate=' + startDate + '&toDate=' + endDate+"&pageNo=0"+ '&sendEmail='+isSendEmail+ (currencyCode != 'All Currencies' ? '&currencyCode='+currencyCode : '');
        return this.makeGetCall(resourceUrl);
    }

    getOTP(request) {
        var resourceUrl = "otp";
        return this.makePostCall(resourceUrl, request);
    }

    changeCardPin(request) {
        var resourceUrl = "b2c/setPin";
        return this.makePostCall(resourceUrl, request);
    }

    getCurrentRate() {
        var resourceUrl = "rates/current?partnerId=p_wsfxConsumer&products=FOREXCARD&isMultiCurrencyCard=true";
        return this.makeGetCall(resourceUrl);
    }

    getCardLimit(currencyCode) {
        var resourceUrl = "b2c/cardLimit?currency="+currencyCode;
        return this.makeGetCall(resourceUrl);
    }

    setCardLimit(request) {
        var resourceUrl = "b2c/cardLimit";
        return this.makePostCall(resourceUrl, request);
    }

    replaceCard(request) {
        var resourceUrl = "replaceCard";
        return this.makePostCall(resourceUrl, request);
    }

    getReloadForm(orderId, type) {
        var resourceUrl = 'cardApplicationReloadForm/' + orderId + '?cartType=' + type;
        return this.makeGetCall(resourceUrl);
    }

    getA2Form(otp, request) {
        var resourceUrl = "cartA2?otp=" + otp;
        return this.makePutCall(resourceUrl, request);
    }

    getCardLinkOTP(isResend, request) {
        let resourceUrl = "validateMobileCustomer?resend=" + isResend;
        return this.makePostCall(resourceUrl, request);
    }

    cardLinkStep02(linkCardModel02: LinkCardRequest02Model) {
        var resourceUrl = "validateExpiryDob";
        return this.makePostCall(resourceUrl, linkCardModel02);
    }

    getDueDelligenceStatus(countryCode) {
        var resourceUrl = 'getCartCountriesSanctionsStatus?countryCode=' + countryCode;
        return this.makeGetCall(resourceUrl);
    }

    getINRWalletOTP() {
        var resourceUrl = "generateInrWalletOtp";
        return this.makePostCall(resourceUrl, {});
    }

    validateActiveINRWalletOTP(otpValue) {
        var resourceUrl = "activateInrWallet?otp=" + otpValue;
        return this.makePostCall(resourceUrl, {});
    }

    temporaryBlockCard(request) {
        var resourceUrl = "manageCard";
        return this.makePostCall(resourceUrl, request);
    }

    getEncashmentOTP(request) {
        return this.makePostCall('cartEncashmentOtpInit', request);
    }

    validateEncashmentOTP(otp, request) {
        var orderURL = 'cartEncashment?otp=' + otp;
        return this.makePutCall(orderURL, request);
    }
    
    getEncashmentForm(bankId, paxId, quotationSnapshotId) {
        var resourceURL = 'getEncashmentForm?bankId=' + bankId + '&paxId=' + paxId + '&quotationSnapshotId=' + quotationSnapshotId;
        return this.makeGetCall(resourceURL);
    }

    linkCard(request){
        var resourceUrl = "b2c/validateCustomer";
        return this.makePostCall(resourceUrl, request);
        
    }
     
    getAllOrderLrsDetails() {
        var resourceURL = 'getAllOrderLrsDetails';
        return this.makeGetCall(resourceURL);
    }

    lrsDeclaration(request){
        var resourceUrl = "lrsDeclaration";
        return this.makePostCall(resourceUrl, request);
    }
}