<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h6 class="content-header-title float-left mb-0">
                            Transfer Balance
                        </h6>
                    </div>
                </div>
                <loader *ngIf="showLoader"></loader>
                <div class="row">
                    <div class="col-md-6 mt-10 mb-10 fs-12">
                        <div class="fw-500 text-black text-center" style="background-color: #f2f2f2; padding : 10px;border-radius: 5px;">{{ cardDetails?.cardNumber }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mt-10 mb-10 fs-12">
                        <label class="form-label">From Currency</label>
                        <!-- <input type="text" class="form-control fs-12" [(ngModel)]="currencyDetails.currencyCode" /> -->
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1" style="height:38px !important"><img src="assets/images/currencyFlag/{{ currencyDetails.currencyCode }}.png " style="height:20px "></span>
                            </div>
                            <input type="text" class="form-control fs-12" [(ngModel)]="currencyDetails.currencyCode"  disabled />
                        </div>
                        <label class="fs-10">Currency Balance : {{ currencyDetails.currencyBalance }}</label>

                    </div>
                    <div class="col-md-6 mt-10 mb-10 form-group fs-12">
                        <label class="form-label">To Currency</label>
                        <!-- <input type="text" class="form-control fs-12" placeholder="Currency" /> -->
                        <ng-select name="currency" placeholder="Select" [items]="cardWalletListItems" bindLabel="currencyCode" bindValue="currencyCode" class="fs-12" [(ngModel)]="selectedCurrencyCode" (change)="getSelectedCurrencyCode(selectedCurrencyCode)" [searchable]="false">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                <img class="mr-1" src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="width: 20px;"> {{item.currencyCode}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-md-6 mt-10 mb-10 form-group fs-12" *ngIf="selectedCurrencyCode">
                        <label for="">Amount to Transfer</label>
                        <input type="text" class="form-control fs-12" placeholder="Amount" [(ngModel)]="transferableAmount" (ngModelChange)="showInvoiceBoxCard($event,transferableAmount)" />
                        <label *ngIf="transferableAmount" class="fs-10">Equivalent Amount = {{ convertCurrencyRate?.currencyAmount }}</label>
                    </div>
                </div>


                <div class="row" *ngIf="selectedCurrencyCode">
                    <div class="custom-control custom-checkbox  col-md-12 ml-2 mt-20 fs-12">
                        <input type="checkbox" id="checkboxForAcceptTermsAndCondition" class="custom-control-input" [(ngModel)]="isChecked" (change)="checkCheckBoxvalue(isChecked)">
                        <label class="custom-control-label text-black fs-12" for="checkboxForAcceptTermsAndCondition"> Accept <a href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/ForexCardTC.pdf" target="_blank" class="blue-text">Terms & Conditions</a></label>
                    </div>
                </div>
                <div class="row" *ngIf="selectedCurrencyCode">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-20 fs-12 form-group">
                        <input type="text" class="form-control fs-12" placeholder="Enter OTP" [(ngModel)]='otpForTransferBalance' maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190"/>
                        <div class="d-flex justify-content-between mt-5" style="color: #3CC9EA" *ngIf="!showTimer && showResendButton">
                            <label></label>
                            <a (click)="otpRequestForBalanceTransfer()"><small class="fs-10">Resend OTP</small></a>
                        </div>
                        <div *ngIf="showTimer" class="text-right float-right fs-10">{{displayTimer}}</div>

                    </div>
                    <!-- <div class="col-lg-8 col-md-6 mt-20 fs-12 text-right">
                        <button class="btn btn-primary fs-12" tabindex="4" rippleEffect (click)="transferBalance(transferableAmount,otpForTransferBalance)">Transfer</button>
                    </div> -->
                </div>

                <div class="row" *ngIf="isShowInvoiceBox">
                    <div class="col-md-12 table-responsive mt-15">
                        <table class="table table-condensed table-borderless  fs-12">
                            <tbody>
                                <tr>
                                    <td class="thick-line text-black fw-600" colspan="3"><strong>IBR</strong></td>
                                    <td class="thick-line text-right text-black fw-600 "> {{ currencyDetails?.currencyCode }} 1 = {{ convertCurrencyRate?.currencyCode }} {{ convertCurrencyRate?.conversionRate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Total Amount </strong></td>
                                    <td class="no-line text-right"> {{ convertCurrencyRate?.currencyAmountWithTaxes }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>W2W Fee (2.0%)</strong></td>
                                    <td class="no-line text-right">- {{ convertCurrencyRate?.totalCharges }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Taxes on W2W Fee (18%)</strong></td>
                                    <td class="no-line text-right">- {{ convertCurrencyRate?.totalChargesTax }} </td>
                                </tr>

                                <tr style="border-top: 1px solid #eee;">
                                    <td class="no-line text-black fw-800 pt-10" colspan="3"> Transferable Amount </td>
                                    <td class="no-line text-right text-black fw-800 pt-10"> {{ convertCurrencyRate?.currencyAmount }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md-12 d-flex justify-content-between mt-20">
                        <button type="button" class="btn btn-outline-dark btn-prev fs-12" routerLink="/my-card" rippleEffect>
                            <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                            <span class="align-middle ">Back</span>
                        </button>
                        <button type="submit" class="btn btn-primary btn-next fs-12" (click)="transferBalance(transferableAmount,otpForTransferBalance)" rippleEffect>
                            <span class="align-middle ">Transfer</span>
                            <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                        </button>
                    </div>

                    <!-- <div class="col-lg-6 offset-lg-6 col-md-8 offset-md-4 col-sm-6 col-xs-12 fs-12 text-right">
                        <section class="invoice-preview-wrapper mb-20">
                            <div class="row invoice-preview">
                                <div class="card-body card-padding">
                                    <div class="row invoice-sales-total-wrapper">
                                        <div class="col-md-12  order-md-2 order-1">
                                            <div class="invoice-total-wrapper bg-light-grey p-15">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 text-black fw-700">IBR</p>
                                                    <p class="invoice-total-amount text-black fw-700 fs-12">{{ currencyDetails?.currencyCode }} 1= {{ convertCurrencyRate?.currencyCode }} {{ convertCurrencyRate?.conversionRate }}</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black">Total Amount </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">{{ convertCurrencyRate?.currencyAmountWithTaxes }}</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black">W2W Fee (2.0%) </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">-{{ convertCurrencyRate?.totalCharges }}</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black ">Taxes on W2W Fee (18%) </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">-{{ convertCurrencyRate?.totalChargesTax }}</p>
                                                </div>
                                                <hr class="my-50 hr-color">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title text-black fw-700 fs-12">Transferable Amount</p>
                                                    <p class="invoice-total-amount text-black fw-700 fs-12">{{ convertCurrencyRate?.currencyAmount }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>